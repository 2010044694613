import JSZip from 'jszip';
import { CapturePosition } from '../../common';
import { useFiles } from '../useFiles';

export function useMaestro3D() {
  const dynamicModelsFullPath = '/Content/maestro3D';
  const convertedSuffix = 'converted';
  const fileExtension = '.zip';
  const { isDir, removeResourcesByName } = useFiles();

  const removeExtension = (filename: string) => {
    return filename.substring(0, filename.lastIndexOf(`.`)) || filename;
  };

  const pathSource = (fileName: string, teethArch: CapturePosition) => {
    return `${dynamicModelsFullPath}/${teethArch}_${fileName}`;
  };

  const pathConverted = (fileName: string, teethArch: CapturePosition) => {
    return `${dynamicModelsFullPath}/${teethArch}_${removeExtension(fileName)}_${convertedSuffix}`;
  };

  const downloadFileName = (filename: string) => {
    return `${removeExtension(filename)}_${convertedSuffix}${fileExtension}`;
  };

  const loadMaestro3DImportFile = (files: FileList, teethArch: CapturePosition) => {
    if (files.length !== 1) {
      return;
    }

    const file = files[0];
    // Añadir siempre prefijo de arcada, por que el usuario puede
    // subir ficheros con el mismo nombre pero diferente contenido por arcada.
    const fileFullPath = pathSource(file.name, teethArch);

    if (!isDir(dynamicModelsFullPath)) {
      Module.FS.mkdir(dynamicModelsFullPath);
    }

    const reader = new FileReader();
    reader.onload = () => {
      const stream: ArrayBuffer = reader.result as ArrayBuffer;
      const data = new Int8Array(stream);
      Module.FS.writeFile(fileFullPath, data);

      window.App.webEventsProxy.common.importMaestro3Dv6(fileFullPath, teethArch);
    };
    reader.readAsArrayBuffer(file);
  };

  const downloadMaestro3DConvertedFile = (sourceFilename: string, arch: CapturePosition) => {
    const zipper = new JSZip();
    const filePath = pathConverted(sourceFilename, arch);

    if (!isDir(filePath)) {
      return;
    }

    Module.FS.readdir(filePath).forEach((path: string) => {
      if (path !== '.' && path !== '..') {
        zipper.file(path, Module.FS.readFile(`${filePath}/${path}`));
      }
    });

    zipper.generateAsync({ type: 'blob' }).then((blob) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = downloadFileName(sourceFilename);
      downloadLink.click();
      downloadLink = undefined;
    });
  };

  const removeMaestro3DFiles = (fileName: string, teethArch: CapturePosition) => {
    try {
      removeResourcesByName(`${teethArch}_${removeExtension(fileName)}`, dynamicModelsFullPath);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    loadMaestro3DImportFile,
    downloadMaestro3DConvertedFile,
    removeMaestro3DFiles
  };
}
