import { useCallback, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as OverpositionIcon } from '../../../assets/icons/overposition.svg';

import { useTranslation } from 'react-i18next';
import Radio from '../radio/Radio';
import { OverpositionMode } from './Timeline';
import './timelineOverposition.scss';

interface TimelineOverpositionProps {
  isOverpositionActivated: boolean;
  enableFollowUpOverposition: boolean;
  overpositionMode?: OverpositionMode;
  isDisabled?: boolean;
  onClick: () => void;
  onChangeOverpositionMode: (mode: OverpositionMode) => void;
}

export function TimelineOverposition({
  isOverpositionActivated,
  enableFollowUpOverposition,
  overpositionMode,
  isDisabled,
  onClick,
  onChangeOverpositionMode
}: TimelineOverpositionProps) {
  const [t] = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const handleOptionSelect = (selected: OverpositionMode) => {
    onChangeOverpositionMode(selected);
    setShowMenu(false);
  };
  const toggleMenu = useCallback(() => {
    if (isOverpositionActivated) {
      setShowMenu(false);
      onClick();
    } else {
      setShowMenu(!showMenu);
    }
  }, [setShowMenu, onClick, showMenu]);

  return (
    <div
      className={`timelineoverposition ${isOverpositionActivated ? 'timelineoverposition--activated' : ''} ${
        showMenu ? 'timelineoverposition--showing' : ''
      }`}
    >
      <Dropdown drop="up" autoClose="outside" show={showMenu}>
        <Dropdown.Toggle
          disabled={isDisabled}
          onClick={toggleMenu}
          variant="timelineoverposition"
          className={`timelineoverposition-button timelineoverposition-button--reverse ${
            isOverpositionActivated && 'is-active'
          }`}
        >
          <OverpositionIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu className="timelineoverposition-options" id="timeline">
          <Dropdown.Item
            className="grouped-actions-dropdown_item"
            onClick={() => handleOptionSelect(OverpositionMode.MajorVersions)}
          >
            <span className="caseversioninput-radio">
              <Radio active={overpositionMode === OverpositionMode.MajorVersions} />
            </span>
            <span>{t('dentalMovements.timeline.overlapCurrentPlan')}</span>
          </Dropdown.Item>
          {/*
          <Dropdown.Item
            className="grouped-actions-dropdown_item"
            onClick={() => handleOptionSelect(OverpositionMode.MinorVersions)}
            disabled={!enableFollowUpOverposition}
          >
            <span className="caseversioninput-radio">
              <Radio active={overpositionMode === OverpositionMode.MinorVersions} />
            </span>
            <span>{t('dentalMovements.timeline.overlapFollowUpPlan')}</span>
          </Dropdown.Item>
        */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
