import { ToolbarConfigProps } from '../../../common';
import { ReactComponent as Autofix } from '../../../assets/icons/evergineTools/auto-fix.svg';
import { ReactComponent as Inspector } from '../../../assets/icons/evergineTools/inspector.svg';
import { ReactComponent as Soft } from '../../../assets/icons/evergineTools/soft.svg';
import { ReactComponent as Expand } from '../../../assets/icons/evergineTools/expand.svg';
import { ReactComponent as Delete } from '../../../assets/icons/evergineTools/delete.svg';
import { ReactComponent as Extrusion } from '../../../assets/icons/evergineTools/extrusion.svg';
import { ReactComponent as Gap } from '../../../assets/icons/evergineTools/gap.svg';
import { ReactComponent as FlatSlice } from '../../../assets/icons/evergineTools/flat-slice.svg';

export const autofixToolbarElement: ToolbarConfigProps = {
  icon: Autofix,
  tooltipKey: 'evergineTools.autofix',
  handleClick: () => undefined
};

export const inspectorToolbarElement: ToolbarConfigProps = {
  icon: Inspector,
  tooltipKey: 'evergineTools.inspector',
  handleClick: () => undefined
};

export const softToolbarElement: ToolbarConfigProps = {
  icon: Soft,
  tooltipKey: 'evergineTools.soft',
  handleClick: () => undefined
};

export const expandToolbarElement: ToolbarConfigProps = {
  icon: Expand,
  tooltipKey: 'evergineTools.expand',
  handleClick: () => undefined
};

export const deleteToolbarElement: ToolbarConfigProps = {
  icon: Delete,
  tooltipKey: 'evergineTools.delete',
  handleClick: () => undefined
};

export const extrusionToolbarElement: ToolbarConfigProps = {
  icon: Extrusion,
  tooltipKey: 'evergineTools.extrusion',
  handleClick: () => undefined
};

export const gapToolbarElement: ToolbarConfigProps = {
  icon: Gap,
  tooltipKey: 'evergineTools.gap',
  handleClick: () => undefined
};

export const flatSliceToolbarElement: ToolbarConfigProps = {
  icon: FlatSlice,
  tooltipKey: 'evergineTools.flat-slice',
  handleClick: () => undefined
};
