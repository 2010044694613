import { ReactComponent as Info } from '../../../assets/icons/info.svg';

import './infoMessage.scss';

interface InfoMessageProps {
  message?: string;
}

export function InfoMessage({ message }: InfoMessageProps) {
  return (
    <>
      {!!message && (
        <div className="infomessage">
          <Info />
          <span>{message}</span>
        </div>
      )}
    </>
  );
}
