import React from 'react';
import { ReactComponent as ToothIcon } from '../../../assets/icons/tooth.svg';
import './datacard.scss';

type DataCardBaseProps = {
  title: string;
  isToothCard?: boolean;
  noCard?: boolean;
};

export function DataCardBase(props: React.PropsWithChildren<DataCardBaseProps>) {
  const { children, title, isToothCard, noCard } = props;

  return (
    <div className={`datacard ${noCard ? 'no-card' : ''}`}>
      <div className="datacard-title">
        {isToothCard && (
          <span className="datacard-title--icon">
            <ToothIcon />
          </span>
        )}
        <span className="datacard-title--text">{title}</span>
      </div>
      <div className="datacard-data">{children}</div>
    </div>
  );
}
