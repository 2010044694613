import { CustomDropdown, DropdownItem } from '../custom-dropdown/CustomDropdown';
import './imageHeader.scss';

export function ImageHeader(
  props: React.PropsWithChildren<{
    title: string;
    dropdownMode?: boolean;
    items?: DropdownItem[];
    onItemClicked?: (selectedItem: string) => void;
    className?: string;
  }>
) {
  const { title, dropdownMode, items, onItemClicked, children, className } = props;

  return (
    <div className={`${className} capture-header d-flex justify-content-between align-items-center flex-fill`}>
      {dropdownMode ? (
        <CustomDropdown items={items} onClick={onItemClicked} />
      ) : (
        <p className="capture-title mb-0">{title}</p>
      )}

      <div className="capture-actions d-flex flex-row">{children}</div>
    </div>
  );
}
