/*  Information
    Example: localhost:3000/orthodontics/general-information/s-123456
      Domain = localhost:3000         [0]
      CaseType = orthodontics         [1]
      RouteView = general-information [2]
      CaseId = s-123456               [3]
*/

export enum PathLevels {
  Domain = 0,
  CaseType = 1,
  RouteView = 2,
  CaseId = 3
}
