import { StateCreator } from 'zustand';

export interface CanvasSlice {
  showCanvas: boolean;
  setShowCanvas: (showCanvas: boolean) => void;
}

export const createCanvasSlice: StateCreator<CanvasSlice, [], [], CanvasSlice> = (set) => ({
  showCanvas: true,
  setShowCanvas: (showCanvas: boolean) => set((_) => ({ showCanvas }))
});
