import { useCallback } from 'react';

export function useCustomNavigate() {
  const getParametersToKeep = useCallback(() => {
    const paramsToDelete = ['authToken'];
    const currentLocation = window.location;
    const searchParams = new URLSearchParams(currentLocation.search);

    paramsToDelete.forEach((param) => {
      searchParams.delete(param);
    });
    return searchParams;
  }, []);

  const getRouteKeepingParameters = useCallback((route: string) => {
    return `${route}?${getParametersToKeep().toString()}`;
  }, []);

  const getRouteWithNewVersionId = useCallback((route: string, versionId: string) => {
    const parametersToKeep = getParametersToKeep();
    parametersToKeep.set('versionId', versionId);
    return `${route}?${parametersToKeep.toString()}`;
  }, []);

  return { getRouteKeepingParameters, getRouteWithNewVersionId } as const;
}
