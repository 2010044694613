import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DownloadFileIcon } from '../../../../../assets/icons/download.svg';

interface DownloadFileProps {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function DownloadFile({ disabled, onClick }: DownloadFileProps) {
  const [t] = useTranslation();

  return (
    <button className="capture-action" data-for="downloadFile" data-tip onClick={onClick} disabled={disabled}>
      <DownloadFileIcon />
      <ReactTooltip id="downloadFile" place="bottom" effect="solid" className="tooltip d-flex flex-column" type="dark">
        <span>{t(`imageManagement.actions.downloadFile`)}</span>
      </ReactTooltip>
    </button>
  );
}
