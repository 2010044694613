import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ILoginService } from './ilogin.service';
export interface LoginCredentials {
  username: string;
  password: string;
}
export interface LoginResult {
  token: string;
  user: {
    createdAt: string;
    email: string;
    id: string;
    updatedAt: string;
  };
}
// this is a temporal solution until we receive the auth token on being redirected from MrMilu login
@injectable()
export class LoginService implements ILoginService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  public async fetchToken(): Promise<string> {
    const result = await this._httpService.get('/config/auth/login');
    // const result = await this._httpService.post('/mock/auth/login', userCredentials);

    if (result?.data) {
      const loginResult = result.data as LoginResult;
      return loginResult.token;
    }
    return null;
  }
}
