export const INJECTED_TYPES = {
  IHttpService: Symbol.for('IHttpService'),
  ICasesService: Symbol.for('ICasesService'),
  IClientPlanValidationService: Symbol.for('IClientPlanValidationService'),
  IDentalMovementsService: Symbol.for('IDentalMovementsService'),
  ITeethSegmentationService: Symbol.for('ITeethSegmentationService'),
  ICalculationAttachesService: Symbol.for('ICalculationAttachesService'),
  IAxisRootsOrientationService: Symbol.for('IAxisRootsOrientationService'),
  IAuthService: Symbol.for('IAuthService'),
  IUserProfileService: Symbol.for('IUserProfileService'),
  ILoginService: Symbol.for('ILoginService'),
  IClinicService: Symbol.for('IClinicService'),
  IFileService: Symbol.for('IFileService'),
  IImplantsService: Symbol.for('IImplantsService'),
  IManufacturersService: Symbol.for('IManufacturersService'),
  IChatService: Symbol.for('IChatService'),
  IHistoryTreatmentChangeService: Symbol.for('IHistoryTreatmentChangeService'),
  ICommandInvokerService: Symbol.for('ICommandInvoker'),
  ICaseStatusService: Symbol.for('ICaseStatusService'),
  IAIMovementsService: Symbol.for('IAIMovementsService'),
  IErrorHandlerService: Symbol.for('IErrorHandlerService')
};
