import { MouseEvent, useCallback, useState } from 'react';

import './switch.scss';

type SwitchProps = {
  id?: string;
  handleToggle: (value: boolean, id: string) => void;
  disabled?: boolean;
  defaultValue?: boolean;
};

export default function Switch({ id = 'switch', handleToggle, disabled, defaultValue }: SwitchProps) {
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue);
  const onClickToggle = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const toggleChecked = !isChecked;
      setIsChecked(toggleChecked);
      handleToggle(toggleChecked, id);
    },
    [isChecked, setIsChecked]
  );

  return (
    <button className={`switch ${isChecked ? 'on' : 'off'}`} onClick={onClickToggle} disabled={disabled}>
      <span className="switch-pin" />
    </button>
  );
}
