import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseType } from '../../../models';
import { PatientCase } from '../../../shared';
import { CheckedInfo, DataCardBase } from '../../../surgeries/components';
import { ClinicDataCard } from './ClinicDataCard';
import { PatientDataCard } from './PatientDataCard';

type BaseInfoProps = {
  patientCase: PatientCase;
};
export function BaseInfo({ patientCase }: BaseInfoProps) {
  const [t] = useTranslation();
  const isSurgeryCase = useMemo(() => patientCase?.type === CaseType.Surgery, [patientCase]);

  return (
    <div className="generalinfo-data col-lg-8 col-md-12 mt-1">
      <div className="row gy-4">
        <div className="col-md-12 col-lg-6">
          <PatientDataCard patientCase={patientCase} />
        </div>
        <div className="col-lg-6 col-md-12">
          {isSurgeryCase ? (
            <DataCardBase title={t('generalInfo.anamnesis.title')}>
              {patientCase.anamnesis.map((anamnesisCheck, i) => {
                return <CheckedInfo key={`anamnesis-${i}`} info={anamnesisCheck} />;
              })}
            </DataCardBase>
          ) : (
            <ClinicDataCard patientCase={patientCase} />
          )}
        </div>
      </div>
      <div className="row g-4 mt-0">
        {isSurgeryCase && (
          <div className="col-lg-6 col-md-12">
            <ClinicDataCard patientCase={patientCase} />
          </div>
        )}
        <div className={`col-lg-${isSurgeryCase ? '6' : '12'} col-md-12`}>
          <DataCardBase title={t('generalInfo.comments.title')}>{patientCase.comments}</DataCardBase>
        </div>
      </div>
    </div>
  );
}
