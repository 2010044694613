import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { OrthContextSubMenu } from './OrthContextSubMenu';
import './orthContextMenu.scss';
import { ContextMenuOptions, useOrthContextMenu } from './useOrthContextMenu';
import { ToothHoverData } from '../../../../../common';
import { PointerPosition } from '../../../../stores/slices/orthContextMenuSlice';

type OrthContextualMenuProps = {
  preventContextMenu?: boolean;
};

export function OrthContextualMenu({ preventContextMenu = false }: OrthContextualMenuProps) {
  const { isOrthContextMenuVisible, pointerPosition, setIsOrthContextMenuVisible } = useOrthBoundStore();
  const [t] = useTranslation();
  const { toothHoverData } = useOrthContextMenu();
  const [selectedTeeth, setSelectedTeeth] = useState<ToothHoverData>();
  const [showContextMenu, setShowContextMenu] = useState<boolean>();
  const [selectedTeethPosition, setSelectedTeethPosition] = useState<PointerPosition>();
  const options = [
    {
      labelKey: 'contextMenuOptions.removeFromStepOne',
      action: () => {
        console.log('quitar desde paso 1');
        if (selectedTeeth) {
          window.App.webEventsProxy.extractions.markToothAsExtracted(selectedTeeth.fdi);
          closeContextMenu();
        }
      },
      disabled: false,
      isHidden: false
    }
  ] as ContextMenuOptions[];
  const styles: CSSProperties = useMemo(() => {
    return { left: selectedTeethPosition?.clientX, top: selectedTeethPosition?.clientY };
  }, [selectedTeethPosition]);

  const closeContextMenu = useCallback(() => {
    setIsOrthContextMenuVisible(false);
    setShowContextMenu(false);
  }, []);

  useEffect(() => {
    if (isOrthContextMenuVisible && selectedTeethPosition !== pointerPosition && !preventContextMenu) {
      setSelectedTeeth(toothHoverData);

      if (toothHoverData === null || toothHoverData === undefined) {
        closeContextMenu();
        return;
      }
      setSelectedTeethPosition(pointerPosition);
      setShowContextMenu(true);
    }
  }, [selectedTeethPosition, pointerPosition, toothHoverData]);

  return (
    <div>
      <Dropdown
        className="orth-context-menu"
        style={styles}
        show={showContextMenu && !!selectedTeeth}
        onToggle={() => closeContextMenu()}
      >
        <Dropdown.Menu className="orth-context-menu">
          {options.map((option, index) => {
            const id = `orth-context-menu__item-${index}`;
            return option?.children ? (
              <div key={id}>
                <OrthContextSubMenu children={option.children} labelKey={option.labelKey} />
              </div>
            ) : (
              <Dropdown.Item key={id} className="orth-context-menu" onClick={option.action} disabled={option.disabled}>
                {t(option.labelKey)}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
