import { FunctionComponent, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Key } from 'ts-key-enum';
import { ReactComponent as PlayerStart } from '../../../assets/icons/player-first.svg';
import { ReactComponent as PlayerEnd } from '../../../assets/icons/player-last.svg';
import { ReactComponent as PlayerNext } from '../../../assets/icons/player-next.svg';
import { ReactComponent as PlayerPause } from '../../../assets/icons/player-pause.svg';
import { ReactComponent as Play } from '../../../assets/icons/player-play.svg';
import { ReactComponent as PlayerPrev } from '../../../assets/icons/player-previous.svg';

type ButtonProps = {
  icon: FunctionComponent;
  actionType: ActionType;
};

export enum ActionType {
  Start,
  Previous,
  Play,
  Next,
  Last
}

const playActionList: ButtonProps[] = [
  {
    icon: PlayerStart,
    actionType: ActionType.Start
  },
  {
    icon: PlayerPrev,
    actionType: ActionType.Previous
  },
  {
    icon: Play,
    actionType: ActionType.Play
  },
  {
    icon: PlayerNext,
    actionType: ActionType.Next
  },
  {
    icon: PlayerEnd,
    actionType: ActionType.Last
  }
];

type PlayerActionsProps = {
  isPlaying: boolean;
  maxStepsLength: number;
  onHandlePlayAction: (newValue: boolean) => void;
  currentStep: number;
  onClickPlayerAction: (newValue: number) => void;
  isDisabled?: boolean;
};

export const PlayerActions = ({
  isPlaying,
  onHandlePlayAction,
  maxStepsLength,
  currentStep,
  onClickPlayerAction,
  isDisabled
}: PlayerActionsProps) => {
  useHotkeys([Key.ArrowLeft, Key.ArrowRight, Key.ArrowUp, Key.ArrowDown], (e) => {
    if (!e?.key || isDisabled) {
      return;
    }

    const actionDictionary: { [key: string]: ActionType } = {
      [Key.ArrowRight]: ActionType.Next,
      [Key.ArrowLeft]: ActionType.Previous,
      [Key.ArrowUp]: ActionType.Start,
      [Key.ArrowDown]: ActionType.Last
    };

    const type = actionDictionary[e.key];

    onClick(type);
  });

  const onClick = useCallback(
    (type: ActionType) => {
      const activeStep = currentStep;
      const listLength = maxStepsLength - 1;
      const actions: { [key: string]: number } = {
        [ActionType.Start]: 0,
        [ActionType.Next]: activeStep !== listLength ? activeStep + 1 : listLength,
        [ActionType.Previous]: activeStep !== 0 ? activeStep - 1 : 0,
        [ActionType.Last]: listLength,
        [ActionType.Play]: activeStep >= listLength ? 0 : activeStep
      };

      const newValue = actions[type];

      if (type === ActionType.Play) {
        const newPlayState = !isPlaying;
        onHandlePlayAction(newPlayState);

        if (newPlayState) {
          onClickPlayerAction(newValue);
        }
        return;
      }

      onHandlePlayAction(false);
      onClickPlayerAction(newValue);
    },
    [currentStep, maxStepsLength, isPlaying]
  );

  const TimelineActionButton = useCallback(
    (id: string, props: ButtonProps) => {
      const isPlayingActive = props.actionType === ActionType.Play && isPlaying;

      return (
        <button
          disabled={isDisabled}
          key={id}
          className={`timeline-button ${isPlayingActive ? 'is-active' : ''}`}
          onClick={() => onClick(props.actionType)}
        >
          {isPlayingActive ? <PlayerPause /> : <props.icon />}
        </button>
      );
    },
    [isPlaying, onClick]
  );

  return (
    <>
      {playActionList.map((action, i) => {
        const id = `player-${i}`;
        return TimelineActionButton(id, action);
      })}
    </>
  );
};
