import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { Clinic, IClinicService } from './iclinic.service';

type CityDTO = {
  name: string;
};

type ProvinceDTO = {
  name: string;
};

export type ClinicDTO = {
  id: string;
  address: string;
  addressFloor: string;
  addressNumber: string;
  postalCode: string;
  name: string;
  city: CityDTO;
  province: ProvinceDTO;
};

@injectable()
export class ClinicService implements IClinicService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  // private readonly apiEndpoint = '/api/clinics';
  private readonly apiEndpoint = '/mock/clinics';

  public async getById(id: string): Promise<Clinic> {
    const url = `${this.apiEndpoint}/${id}`;
    const clinicDTO = (await this._httpService.get<ClinicDTO>(url)).data;
    const clinic: Clinic = {
      address: `${clinicDTO.address}, ${clinicDTO.addressNumber} ${clinicDTO.addressFloor} - ${clinicDTO.postalCode} ${clinicDTO.city.name} (${clinicDTO.province.name})`,
      name: clinicDTO.name
    };
    return clinic;
  }
}
