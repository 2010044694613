import { StateCreator } from 'zustand';
import { TeethArchPosition } from '../../../models';

export interface OrthTeethArchSlice {
  orthTeethArch: TeethArchPosition;
  setOrthTeethArch: (teethArchPosition: TeethArchPosition) => void;
  showUpperArch: boolean;
  setShowUpperArch: (showUpperArch: boolean) => void;
  showLowerArch: boolean;
  setShowLowerArch: (showLowerArch: boolean) => void;
}

export const createOrthTeethArchSlice: StateCreator<OrthTeethArchSlice, [], [], OrthTeethArchSlice> = (set) => ({
  orthTeethArch: TeethArchPosition.BOTH,
  setOrthTeethArch: (orthTeethArch: TeethArchPosition) => set((_) => ({ orthTeethArch })),
  showUpperArch: true,
  setShowUpperArch: (showUpperArch: boolean) => set((_) => ({ showUpperArch })),
  showLowerArch: true,
  setShowLowerArch: (showLowerArch: boolean) => set((_) => ({ showLowerArch }))
});
