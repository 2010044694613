import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isStringVoid } from '../../../../../shared';
import { DownloadFile, ImageHeader, RemoveFile, UploadFile } from '../../../layout';
import { SlideShowBackdrop } from '../slideShowBackdrop';
import './photoCapture.scss';

interface PhotoCaptureBaseProps {
  title: string;
  fileId: string;
  isDownloadable?: boolean;
  onClickBackdrop: () => void;
  onClickUploadFile: (e: React.ChangeEvent, fileId: string) => void;
  onClickRemoveFile: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDownloadFile?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface PhotoCaptureProps extends PhotoCaptureBaseProps {
  imageUrl: string;
}
interface PhotoCaptureMultipleProps extends PhotoCaptureBaseProps {
  imageUrls: string[];
}

export function PhotoCapture(props: PhotoCaptureProps) {
  const {
    title,
    fileId,
    imageUrl,
    isDownloadable,
    onClickBackdrop,
    onClickUploadFile,
    onClickRemoveFile,
    onClickDownloadFile
  } = props;
  const [open, setOpen] = useState<boolean>();
  const [t] = useTranslation();

  return (
    <div className="capture position-relative">
      <ImageHeader title={title} dropdownMode={false}>
        {isStringVoid(imageUrl) ? (
          <UploadFile fileId={fileId} onChange={onClickUploadFile} typesAllowed="image/*" />
        ) : (
          <>
            {isDownloadable && <DownloadFile onClick={onClickDownloadFile} />}
            <RemoveFile onClick={onClickRemoveFile} />
          </>
        )}
      </ImageHeader>
      {!imageUrl || imageUrl?.trim() === '' ? (
        <div className="capture-message position-absolute top-50">
          <div className="section-message">
            <span>{t(`imageManagement.common.noFiles`)}</span>
          </div>
        </div>
      ) : (
        <div
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          className="slideshow"
        >
          <SlideShowBackdrop open={open} onClick={onClickBackdrop}>
            <img className="capture-image" src={imageUrl} />
          </SlideShowBackdrop>
        </div>
      )}
    </div>
  );
}

export function PhotoCaptureMultiple(props: PhotoCaptureMultipleProps) {
  const { title, fileId, imageUrls, onClickBackdrop, onClickUploadFile } = props;
  const [open, setOpen] = useState<boolean>();
  const [t] = useTranslation();

  return (
    <div className="capture position-relative">
      <ImageHeader title={`${title} ${imageUrls?.length > 0 ? `1/${imageUrls.length}` : ''}`} dropdownMode={false}>
        <UploadFile fileId={fileId} onChange={onClickUploadFile} typesAllowed="image/*" allowMultiple={true} />
      </ImageHeader>
      {imageUrls?.length === 0 ? (
        <div className="capture-message position-absolute top-50">
          <div className="section-message">
            <span>{t(`imageManagement.common.noFiles`)}</span>
          </div>
        </div>
      ) : (
        <div
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          className="slideshow"
        >
          <SlideShowBackdrop open={open} onClick={onClickBackdrop}>
            {imageUrls.length > 0 && <img className="capture-image" src={imageUrls[0]} />}
          </SlideShowBackdrop>
        </div>
      )}
    </div>
  );
}
