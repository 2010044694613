import { useEvergineStore } from 'evergine-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EvergineToolbar, PagesWithTools, Stage, Step, Timeline, toolbarConfig } from '../../../common';
import { LateralPanel } from '../../../common/components/lateralPanel';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useCaseId, useRenderModels } from '../../../hooks';
import { useDentalMovements } from '../../../hooks/orthodontics';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { OrthContextualMenu, OrthTeethMovements } from '../evergineToolbarElements';
import { OcclusogramLegend } from '../occlusogramLegend';

const firstStepIndex = 0;

export function FinalPosition() {
  const ability = useContext(AbilityContext);
  const [caseId] = useCaseId();
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);
  useRenderModels(caseId, Stage.FinalPosition);
  const { evergineReady } = useEvergineStore();
  const {
    showMovements,
    lowerDentalMovements,
    upperDentalMovements,
    stageIsLoaded,
    isInFinalPositionStage,
    showMiniOcclusogram
  } = useOrthBoundStore();
  const { setMessageInfo } = useBoundStore();
  const pageTools = toolbarConfig[PagesWithTools.FinalPosition];
  const { activeStep, setActiveStep } = useCommonBoundStore();
  const [upperStepsForFinalPositionStage, setUpperStepsForFinalPositionStage] = useState<Step[]>([]);
  const [lowerStepsForFinalPositionStage, setLowerStepsForFinalPositionStage] = useState<Step[]>([]);
  const [canShowTimeline, setCanShowTimeline] = useState<boolean>(false);

  const lastStepIndex = useMemo(
    () => Math.max(lowerDentalMovements?.steps?.length, upperDentalMovements?.steps?.length) - 1,
    [lowerDentalMovements, upperDentalMovements]
  );

  const showLateralPanelIfAllowed = useCallback(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />;
    }
  }, [showInfoPanel, ability, handleInfoPanelToggle]);

  useEffect(() => {
    if (evergineReady) {
      setActiveStep(lastStepIndex);
      window.App.webEventsProxy.common.setEditionMode(true);
      setMessageInfo('pageInfoMessages.finalPosition.initial');
    }
  }, [evergineReady]);

  useEffect(() => {
    if (lastStepIndex && lastStepIndex !== -1) {
      setActiveStep(lastStepIndex);
    }
  }, [lastStepIndex]);

  useEffect(() => {
    if (lowerDentalMovements && lowerDentalMovements.steps?.length >= 2) {
      setLowerStepsForFinalPositionStage([
        lowerDentalMovements.steps[0],
        lowerDentalMovements.steps[lowerDentalMovements.steps.length - 1]
      ]);
    }
    if (upperDentalMovements && upperDentalMovements.steps?.length >= 2) {
      setUpperStepsForFinalPositionStage([
        upperDentalMovements.steps[0],
        upperDentalMovements.steps[upperDentalMovements.steps.length - 1]
      ]);
    }
  }, [lowerDentalMovements, upperDentalMovements]);

  useEffect(() => {
    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady &&
      isInFinalPositionStage &&
      (upperStepsForFinalPositionStage.length > 0 || lowerStepsForFinalPositionStage.length > 0);

    if (result) {
      const maxStepIndex = [...upperStepsForFinalPositionStage, ...lowerStepsForFinalPositionStage].reduce(
        (max, step) => (step.stepIndex > max ? step.stepIndex : max),
        -Infinity
      );
      setActiveStep(maxStepIndex);
    }
    setCanShowTimeline(result);
  }, [
    lowerDentalMovements,
    upperDentalMovements,
    evergineReady,
    stageIsLoaded,
    upperStepsForFinalPositionStage,
    lowerStepsForFinalPositionStage,
    isInFinalPositionStage
  ]);

  return (
    <div className="captures container-fluid g-0 d-flex">
      {showLateralPanelIfAllowed()}
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      <OrthContextualMenu preventContextMenu={activeStep === firstStepIndex} />
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showMovements && <OrthTeethMovements />}
      {canShowTimeline && (
        <Timeline
          upperTeeth={upperDentalMovements?.teeth}
          lowerTeeth={lowerDentalMovements?.teeth}
          lowerSteps={lowerStepsForFinalPositionStage}
          upperSteps={upperStepsForFinalPositionStage}
          upperAttachments={upperDentalMovements?.attachments}
          lowerAttachments={lowerDentalMovements?.attachments}
          comparerActivated={false}
          vertical={false}
          enableFollowUpOverposition={false}
          storeCurrentStep={activeStep}
          updateStoreCurrentStep={(newValue: number) => setActiveStep(newValue)}
          isDesigner={true}
          isFinalPositionStage={true}
        />
      )}
    </div>
  );
}
