import { StateCreator } from 'zustand';
import { CaseVersion, PatientCase } from '../../../shared/cases/case';

export interface CaseDataSlice {
  caseId: string;
  patientCase: PatientCase;
  updateCaseId: (caseId: string) => void;
  updatePatientCase: (patientCase: PatientCase) => void;
  versions: CaseVersion[];
  updateVersions: (versions: CaseVersion[]) => void;
}

export const createCaseDataSlice: StateCreator<CaseDataSlice, [], [], CaseDataSlice> = (set) => ({
  caseId: null,
  updateCaseId: (caseId) => set(() => ({ caseId: caseId })),
  patientCase: null,
  updatePatientCase: (patientCase) => set(() => ({ patientCase: patientCase })),
  versions: null,
  updateVersions: (newVersions) => set(() => ({ versions: newVersions }))
});
