import { StateCreator } from 'zustand';
import { CaseStatus } from '../../../shared/caseStatus';

export interface OrthCaseStatusSlice {
  caseStatus: CaseStatus;
  updateCaseStatus: (caseStatus: CaseStatus) => void;
  proccessUrlByEntryPoint: boolean;
  setProccessUrlByEntryPoint: (proccess: boolean) => void;
}

export const createOrthCaseStatusSlice: StateCreator<OrthCaseStatusSlice, [], [], OrthCaseStatusSlice> = (set) => ({
  caseStatus: undefined,
  updateCaseStatus: (caseStatus: CaseStatus) => set((_) => ({ caseStatus })),
  proccessUrlByEntryPoint: false,
  setProccessUrlByEntryPoint: (proccess: boolean) => set((_) => ({ proccessUrlByEntryPoint: proccess }))
});
