export type CaptureToUpload = {
  name: string;
  modelId?: string;
  path: string;
  size?: number;
  type: FileCaptureType | string;
};

export type FileToUploadDTO = {
  key: string;
  originalName: string;
  totalSize: number;
  mimeType: string;
  file: string;
};

export enum FileCaptureType {
  UPPER_DICOM = 'radiography-teleradiography',
  LOWER_DICOM = 'radiography-orthopantomography',
  UPPER_MODEL3D = 'upper-wepmd',
  LOWER_MODEL3D = 'lower-wepmd',
  UPPER_STL = 'upper-stl',
  LOWER_STL = 'lower-stl',
  GUIDE_MODEL3D = 'guide-stl',
  PHOTOGRAPHS_OCLUSSION_LEFT = 'photographs-occlusion-left',
  PHOTOGRAPHS_OCLUSSION_RIGHT = 'photographs-occlusion-right',
  PHOTOGRAPHS_OCLUSSION_UPPER = 'photographs-occlusion-upper',
  PHOTOGRAPHS_OCLUSSION_LOWER = 'photographs-occlusion-lower',
  PHOTOGRAPHS_FRONT_SMILE = 'photographs-front-smile',
  RADIOGRAPHY_ORTHOPANTOMOGRAPHY = 'radiography-orthopantomography',
  RADIOGRAPHY_TELERADIOGRAPHY = 'radiography-teleradiography',
  PHOTOGRAPHS_OTHERS = 'photographs-others',
  TOOTH = 'tooth-'
}
export enum PhotoCaptureType {
  PHOTOGRAPHS_OCLUSSION_LEFT = 'left-occlusal',
  PHOTOGRAPHS_OCLUSSION_RIGHT = 'right-occlusal',
  PHOTOGRAPHS_OCLUSSION_UPPER = 'upper-occlusal',
  PHOTOGRAPHS_OCLUSSION_LOWER = 'lower-occlusal',
  PHOTOGRAPHS_FRONT_SMILE = 'frontal-occlusal',
  RADIOGRAPHY_ORTHOPANTOMOGRAPHY = 'radiography-orthopantomography',
  RADIOGRAPHY_TELERADIOGRAPHY = 'radiography-teleradiography',
  PHOTOGRAPHS_OTHERS = 'photographs-others'
}

export enum AllowFileMimes {
  STL = 'application/stl',
  OCTET_STREAM = 'application/octet-stream'
}
