import { useTranslation } from 'react-i18next';
import { useCaseData, useCaseId } from '../../../hooks';
import './tadsTab.scss';
import { useEffect, useState } from 'react';
import { useOrthBoundStore } from '../../stores/useStore';
import { CaseVersionDTO } from '../../../shared';

export function TadsTab() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { patientCase, getPatientCaseVersion } = useCaseData(caseId);
  const { currentVersion } = useOrthBoundStore();
  const [patientCaseVersion, setPatientCaseVersion] = useState<CaseVersionDTO>();

  useEffect(() => {
    const getLegacyPlan = async () => {
      if (currentVersion) {
        const caseVersion = await getPatientCaseVersion(currentVersion.id);
        setPatientCaseVersion(caseVersion);
      }
    };

    getLegacyPlan();
  }, [currentVersion, patientCase]);

  return (
    <div className="tads-tab">
      {patientCaseVersion ? (
        <>
          {patientCaseVersion?.data.legacyPlan?.tads?.map((tad) => (
            <div className="tads-tab__item">
              <div className="tads-tab__title">
                {tad?.number} - {tad?.title}
              </div>
              {tad?.description.split('\r\n').map((line) => (
                <div> {line}</div>
              ))}
            </div>
          ))}
        </>
      ) : (
        ''
      )}
    </div>
  );
}
