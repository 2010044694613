import './mouseDownButton.scss';

type toggleButtonProps = {
  title: string;
  onMouseDown: () => void;
  onMouseUp: () => void;
};

export function MouseDownButton({ title, onMouseDown, onMouseUp }: toggleButtonProps) {
  return (
    <button onPointerDown={onMouseDown} onPointerUp={onMouseUp} type="button" className="toggle-button">
      {title}
    </button>
  );
}
