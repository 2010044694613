import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseRoutes, UrlsByCaseState } from '../../AppRouter';
import { INJECTED_TYPES, container } from '../../common/ioc';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { OrthodonticsPagesUrl } from '../../orthodontics/OrthodonticsRoutes';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseVersion, PROFILES_TYPE } from '../../shared';
import { CaseStatus, ICaseStatusService } from '../../shared/caseStatus';
import { ModalTypes } from '../../surgeries/components/layout';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCustomNavigate } from './useCustomNavigate';
import { useUtils } from './useUtils';

export function useCaseStatus(caseId: string) {
  const caseStatusService = container.get<ICaseStatusService>(INJECTED_TYPES.ICaseStatusService);
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);
  const updateCaseStatus = useOrthBoundStore((state) => state.updateCaseStatus);
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const canUndo = useOrthBoundStore((state) => state.canUndo);
  const [caseStatus, setCaseStatus] = useState<CaseStatus>(caseStatusFromStore);
  const { getRouteKeepingParameters } = useCustomNavigate();
  const currentVersionRef = useRef<CaseVersion>();
  const navigate = useNavigate();
  const setProccessUrlByEntryPoint = useOrthBoundStore((state) => state.setProccessUrlByEntryPoint);
  const proccessUrlByEntryPoint = useOrthBoundStore((state) => state.proccessUrlByEntryPoint);
  const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const openModal = useCommonBoundStore((state) => state.openModal);
  const { getCurrentPhaseStageByRoute } = useUtils();
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);

  useEffect(() => {
    if (!currentVersion) {
      return;
    }
    fetchCaseStatus();
  }, [currentVersion, isCaseReseting]);

  const fetchCaseStatus = useCallback(async () => {
    if (!currentVersion) {
      return;
    }

    if (!caseStatusFromStore || currentVersionRef.current !== currentVersion) {
      const fetchedCaseStatus = await caseStatusService.getCaseStatusPhases(caseId, currentVersion.id);
      updateCaseStatus(fetchedCaseStatus);
      setCaseStatus(fetchedCaseStatus);

      if (proccessUrlByEntryPoint) {
        navigateByPhase(fetchedCaseStatus);
      }
      currentVersionRef.current = currentVersion;
    }
  }, [currentVersion, caseStatusFromStore, caseId]);

  const navigateByPhase = useCallback(
    (fetchedCaseStatus: CaseStatus) => {
      setProccessUrlByEntryPoint(false);
      const nextPhase = getNextPhaseName(fetchedCaseStatus);

      let route = '';
      if (!nextPhase) {
        route = `/${BaseRoutes.Orthodontics}${OrthodonticsPagesUrl.Publish}/${caseId}`;
      } else {
        route = `/${BaseRoutes.Orthodontics}${UrlsByCaseState[nextPhase]}/${caseId}`;
      }

      if (isClient) {
        route = `/${BaseRoutes.Orthodontics}${OrthodonticsPagesUrl.DentalMovements}/${caseId}`;
      }

      navigate(getRouteKeepingParameters(route));
      setWebBusy(false);
    },
    [caseId]
  );

  const getNextPhaseName = (fetchedCaseStatus: CaseStatus) => {
    return fetchedCaseStatus?.phases.find((p) => p.completionDate === null)?.name;
  };

  const isActualPhaseCompleted = () => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (!currentPhase) {
      return false;
    }

    const currentPhaseIndex = caseStatusFromStore?.phases.findIndex((p) => p.name === currentPhase);
    if (currentPhaseIndex === -1) {
      return false;
    }
    return caseStatusFromStore?.phases[currentPhaseIndex].completionDate !== null;
  };

  const ifIsNecesaryShowChangesModal = () => {
    if (!caseStatusFromStore) {
      return;
    }

    if (isActualPhaseCompleted() === true && canUndo === true && isCaseReseting === false) {
      openModal(ModalTypes.ModalPreviousCasePhaseModified);
    }
  };

  useEffect(() => {
    if (isCaseReseting === true) {
      return;
    }

    ifIsNecesaryShowChangesModal();
  }, [canUndo, isCaseReseting]);

  return {
    caseStatus,
    navigateByPhase,
    fetchCaseStatus,
    ifIsNecesaryShowChangesModal,
    isActualPhaseCompleted
  } as const;
}
