import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonBoundStore } from '../../../../common/stores/useStore';

import { MouseDownButton } from '../../../../common/components/buttons/mouseDownButton';
import './footerDental.scss';
import { useStepsManager } from '../../../../hooks/orthodontics';

type FooterDentalProps = {
  stepsLenght: number;
};
export function FooterDental({ stepsLenght }: FooterDentalProps) {
  const [t] = useTranslation();
  const { setIsEditStep, setActiveStep } = useCommonBoundStore();
  const { goToStep } = useStepsManager();

  const updateTimelineCurrentStep = useCallback(
    (stepToGo: number) => {
      /* setIsEditStep(false); */
      goToStep(stepToGo, false);
    },
    [setIsEditStep]
  );

  const returnToModificationStep = useCallback(() => {
    /* setIsEditStep(true); */
    goToStep(stepsLenght, false);
  }, [stepsLenght]);

  return (
    <div className="footer-dental">
      <MouseDownButton
        title={t('clientValidations.first')}
        onMouseDown={() => updateTimelineCurrentStep(0)}
        onMouseUp={returnToModificationStep}
      />
      <MouseDownButton
        title={t('clientValidations.last')}
        onMouseDown={() => updateTimelineCurrentStep(stepsLenght - 1)}
        onMouseUp={returnToModificationStep}
      />
    </div>
  );
}
