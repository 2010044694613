import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseType } from '../../../models';
import { PatientCase } from '../../../shared';
import { DataCardBase, LabelValue } from '../../../surgeries/components';
import { getArchKey, getNumberofPiecesToReplace } from './generalInfoUtils';

type PatientDataCardProps = {
  patientCase: PatientCase;
  noCard?: boolean;
};

export function PatientDataCard({ patientCase, noCard }: PatientDataCardProps) {
  const [t] = useTranslation();
  const isSurgeryCase = useMemo(() => patientCase?.type === CaseType.Surgery, [patientCase]);

  return (
    <DataCardBase noCard={noCard} title={t('generalInfo.patientData.title')}>
      <LabelValue label={t('generalInfo.patientData.name')} value={patientCase.patient.name} />
      <LabelValue
        label={t('generalInfo.patientData.gender')}
        value={t(`generalInfo.patientData.${patientCase.patient.gender === 'F' ? 'female' : 'male'}`)}
      />
      <LabelValue
        label={t('generalInfo.patientData.birthDate')}
        value={new Date(patientCase.patient.dateOfBirth).toLocaleDateString('es-es')}
      />
      {isSurgeryCase ? (
        <LabelValue
          label={t('generalInfo.patientData.piecesToReplace')}
          value={getNumberofPiecesToReplace(patientCase.patient.pieces)}
        />
      ) : (
        <>
          <LabelValue
            label={t('generalInfo.patientData.archToTreat')}
            value={t(getArchKey(patientCase.patient.archToTreat))}
          />
          <LabelValue
            label={t('generalInfo.patientData.piecesNotToMove')}
            value={getNumberofPiecesToReplace(patientCase.patient.piecesNotToMove)}
          />
        </>
      )}
    </DataCardBase>
  );
}
