/*
  ---- Description ----
  Utility function to debounce a function call outside of a component
  Useful for event listeners that are attached to the window, document or evergine events to prevent memory leaks

  ---- Example usage: ----

  1. Create set store function:
    const debouncedSetToothHoverData = (dataToParse: string) => {
      const data: ToothHoverData = JSON.parse(dataToParse) as ToothHoverData;
      const storeData = useOrthBoundStore.getState().toothHoverData;
      if (storeData?.fdi === data?.fdi) {
        return;
      }

      useOrthBoundStore.setState({ toothHoverData: data });
    };

  2. Create debounced function:
    const debouncedSetChanges = debounceOutsideComponent(debouncedSetToothHoverData, 200);

  3. Call debounced function inside event listener:
    window.App.appEventsListener.onToothHoverChanged = (toothHoverChanged: string): void => {
      const data: ToothHoverData = JSON.parse(toothHoverChanged) as ToothHoverData;
      debouncedSetChanges(data);
    };

    ---- Improvements ----

    Call debounced function directly without step 2:

    window.App.appEventsListener.onToothHoverChanged = debounceOutsideComponent(debouncedSetToothHoverData, 200);

    --- or without step 1 & 2: ---

    window.App.appEventsListener.onToothHoverChanged = debounceOutsideComponent((dataToParse: string) => {
      const data: ToothHoverData = JSON.parse(dataToParse) as ToothHoverData;
      const storeData = useOrthBoundStore.getState().toothHoverData;
      if (storeData?.fdi === data?.fdi) {
        return;
      }

      useOrthBoundStore.setState({ toothHoverData: data });
    }, 200);
*/

export const debounceOutsideComponentForMultiple = (
  func: (...args: any[]) => void,
  propForUniqueIndex: string,
  delay: number
) => {
  const timeoutIds: Record<string, NodeJS.Timeout> = {};

  return (...args: any[]) => {
    const uniqueId = args[0][propForUniqueIndex];
    if (timeoutIds[uniqueId]) {
      clearTimeout(timeoutIds[uniqueId]);
    }

    timeoutIds[uniqueId] = setTimeout(() => {
      func(...args);
      delete timeoutIds[uniqueId];
    }, delay);
  };
};

export const debounceOutsideComponent = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
