import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaseType } from '../../models';
import * as clinic from '../clinic';
import type { IHttpService } from '../http';
import type { IImplantsService } from '../implants';
import {
  CaseVersion,
  CaseVersionDTO,
  CaseVersionsListDTO,
  MinorVersion,
  PatientCase,
  PatientCaseDTO,
  PatientCaseDTOData
} from './case';
import { CaseBuilder } from './case.builder';
import { ICasesService } from './icases.service';

function PatientCaseDTOToPatientCase(
  patientCaseDTO: PatientCaseDTOData,
  clinic: clinic.Clinic,
  type?: CaseType
): PatientCase {
  return new CaseBuilder()
    .withGeneralData(patientCaseDTO, clinic, type)
    .withSpecificInfo(patientCaseDTO)
    .withCapturesData(patientCaseDTO)
    .withPhotographsData(patientCaseDTO)
    .build();
}

@injectable()
export class CasesService implements ICasesService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  public versionIdUrlParamIndex = 'versionId';
  private readonly apiEndpoint = '/api/product-cases';

  public async getById(id: string): Promise<PatientCase> {
    const url = `${this.apiEndpoint}/${id}`;
    const caseDTO = (await this._httpService.get<PatientCaseDTO>(url)).data;

    const clinicDTO = caseDTO.data?.generalInfo?.clinic;
    let clinic: clinic.Clinic = null;

    if (!!clinicDTO) {
      clinic = {
        address: `${clinicDTO.address}, ${clinicDTO.addressNumber} ${clinicDTO.addressExtra} - ${clinicDTO.postalCode} ${clinicDTO.city.name} (${clinicDTO.city.province.name})`,
        name: clinicDTO.name
      };
    }

    const type = CaseType.Orthodontics;
    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);

    return PatientCaseDTOToPatientCase(caseDTO.data, clinic, type);
  }

  public async update(id: string, data: string): Promise<PatientCase> {
    const url = `${this.apiEndpoint}/${id}`;

    const toSend: PatientCaseDTOData = {
      meta: [data]
    };

    const result = (await this._httpService.patch<PatientCaseDTO>(url, toSend)).data.data;

    const clinicDTO = result.generalInfo?.clinic;
    const clinic: clinic.Clinic = {
      address: `${clinicDTO.address}, ${clinicDTO.addressNumber} ${clinicDTO.addressExtra} - ${clinicDTO.postalCode} ${clinicDTO.city.name} (${clinicDTO.city.province.name})`,
      name: clinicDTO.name
    };

    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);
    return PatientCaseDTOToPatientCase(result, clinic);
  }

  public async getVersionsByCaseId(caseId: string): Promise<CaseVersion[]> {
    const url = `/api/product-cases/${caseId}/treatment-plans`;
    return (await this._httpService.get<CaseVersionsListDTO>(url)).data.data;
  }

  public async getCaseVersion(caseId: string, versionId: string): Promise<CaseVersionDTO> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${versionId}`;
    return (await this._httpService.get<CaseVersionDTO>(url)).data;
  }

  public async editCaseVersion(caseId: string, version: CaseVersion): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${version.id}`;

    const toSend: CaseVersion = version;
    await this._httpService.patch(url, toSend);
  }

  public async createCaseVersion(caseId: string, version: CaseVersion): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans`;
    await this._httpService.post(url, version);
  }

  public async deleteCaseVersion(caseId: string, versionId: string): Promise<void> {
    const url = `/proposal/product-cases/${caseId}/versions/${versionId}`;
    await this._httpService.delete(url);
  }

  public async cloneCaseVersion(caseId: string, versionId: string): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${versionId}/clone`;
    await this._httpService.post(url, {});
  }

  // TODO: numberOfSteps is only used for mock purposes. Delete it when real endpoint is done
  public async getMinorVersions(caseId: string, versionId: string, numberOfSteps: number): Promise<MinorVersion[]> {
    const url = `/mock/product-case/${caseId}/versions/${versionId}/minor-versions`;
    return (await this._httpService.get<MinorVersion[]>(url, { steps: numberOfSteps.toString() })).data;
  }
}
