import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDropdown } from '../../../../assets/icons/dropdown-toggle.svg';
import ToggleCompareTreatments from './ToggleCompareTreatments';

import { useLocation, useNavigate } from 'react-router-dom';
import { useCaseData, useCaseId, useCustomNavigate } from '../../../../hooks';
import { PathLevels } from '../../../../models';
import { OrthodonticsPagesUrl } from '../../../../orthodontics/OrthodonticsRoutes';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { CaseVersion } from '../../../../shared';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';
import './caseVersionSelector.scss';

export default function CaseVersionSelector() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { versions } = useBoundStore();
  const { pathname } = useLocation();
  const { isVersionsDisabled } = useCommonBoundStore();
  const { currentVersion } = useOrthBoundStore();
  const shortedPathName = `/${pathname.split('/')[PathLevels.RouteView]}`;
  const isDentalMovementsPage = shortedPathName === OrthodonticsPagesUrl.DentalMovements;
  const isDisabled = !isDentalMovementsPage || isVersionsDisabled;
  const { getRouteWithNewVersionId } = useCustomNavigate();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchPatientCaseVersions } = useCaseData(caseId);

  const loadSelectedVersion = (version: CaseVersion) => {
    navigate(getRouteWithNewVersionId(location.pathname, version.id));
    window.location.reload();
  };

  return (
    <div className="cvselector">
      <Dropdown autoClose="outside" align="end" className=" version-dropdown">
        <Dropdown.Toggle variant="select-case" disabled={isDisabled} id="dropdown-basic">
          <div className="navbar-case d-flex align-items-center">
            <span className="navbar-case-text">{`${t('common.case')} ${caseId}`}</span>
            {isDentalMovementsPage && <ArrowDropdown className="icon ms-3" />}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isDentalMovementsPage && currentVersion && (
            <ToggleCompareTreatments
              caseId={caseId}
              isCompareDisabled={isDisabled}
              versions={versions}
              selectedVersionId={currentVersion?.id}
              setSelectedVersion={(version) => loadSelectedVersion(version)}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
