import { Route } from 'react-router-dom';
import { BaseRoutes, CommonPagesUrl } from '../AppRouter';
import { GeneralInfo } from '../common';
import { DICOMCaptures, PhotoCaptures, STLCaptures } from '../surgeries/components';
import {
  Attaches,
  AxisAndRoots,
  DentalMovements,
  FinalPosition,
  IPR,
  ImportMaster3d,
  IntermediateSteps,
  MeshCorrection,
  OrthoSpecificInfo,
  TeethSegmentation
} from './components';
import { DesignerEntryPoint } from './components/designerEntryPoint';

export enum OrthodonticsPagesUrl {
  DesignerEntryPoint = '/designer',
  MeshCorrection = '/mesh-correction',
  ImportMaster3D = '/import-master-3d',
  DentalMovements = '/dental-movements',
  TeethSegmentation = '/teeth-segmentation',
  AxisAndRoots = '/axis-roots',
  FinalPosition = '/final-position',
  IntermediateSteps = '/intermediate-steps',
  IPR = '/ipr',
  Attaches = '/attaches',
  Publish = '/publish'
}

export const OrthodonticsRoutes = () => {
  function buildOrthodonticsRoute(path: string) {
    return `${BaseRoutes.Orthodontics}${path}/:caseId`;
  }

  return (
    <>
      <Route path={buildOrthodonticsRoute(CommonPagesUrl.GeneralInfo)} element={<GeneralInfo />} />
      <Route path={buildOrthodonticsRoute(CommonPagesUrl.SpecificInfo)} element={<OrthoSpecificInfo />} />
      <Route path={buildOrthodonticsRoute(CommonPagesUrl.DicomCaptures)} element={<DICOMCaptures />} />
      <Route path={buildOrthodonticsRoute(CommonPagesUrl.StlCaptures)} element={<STLCaptures />} />
      <Route path={buildOrthodonticsRoute(CommonPagesUrl.PhotoCaptures)} element={<PhotoCaptures />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.MeshCorrection)} element={<MeshCorrection />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.ImportMaster3D)} element={<ImportMaster3d />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.DentalMovements)} element={<DentalMovements />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.TeethSegmentation)} element={<TeethSegmentation />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.IntermediateSteps)} element={<IntermediateSteps />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.AxisAndRoots)} element={<AxisAndRoots />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.FinalPosition)} element={<FinalPosition />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.IPR)} element={<IPR />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.Attaches)} element={<Attaches />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.Publish)} element={<DentalMovements />} />
      <Route path={buildOrthodonticsRoute(OrthodonticsPagesUrl.DesignerEntryPoint)} element={<DesignerEntryPoint />} />
    </>
  );
};
