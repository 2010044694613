import { useCallback } from 'react';
import { CaptureType } from '../common/evergine';
import { useCommonBoundStore } from '../common/stores/useStore';
import { editCasePhase } from '../common/utils';
import { OrthodonticsPhasesKeys } from '../orthodontics/components/layout';
import { useOrthBoundStore } from '../orthodontics/stores/useStore';
import { CaptureToUpload, CaseVersionState } from '../shared';
import { CasePhase } from '../shared/caseStatus';
import { useBoundStore } from '../surgeries/stores/useStore';
import { useCaseId, useGetDentalMovements, useRemoveFile, useUploadFile, useUtils } from './shared';
import { SaveChangesHook } from './shared/useModalSaveChanges';

export function useSTLLoad(): SaveChangesHook {
  /* Modal tests fail if we group all useCommonBoundStore props, with the following structure not */
  const lowerFilesToUpload = useCommonBoundStore((state) => state.lowerFilesToUpload);
  const upperFilesToUpload = useCommonBoundStore((state) => state.upperFilesToUpload);
  const captureType = useCommonBoundStore((state) => state.captureType);
  const updateLowerModel3DId = useCommonBoundStore((state) => state.updateLowerModel3DId);
  const updateUpperModel3DId = useCommonBoundStore((state) => state.updateUpperModel3DId);
  const keysFilesToDelete = useCommonBoundStore((state) => state.keysFilesToDelete);
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const [caseId] = useCaseId();
  const { uploadFileFromFS } = useUploadFile(caseId);
  const { removeCaptureFile } = useRemoveFile(caseId);
  const { updateIsCaseReseting, isCaseReseting } = useCommonBoundStore();
  const setIsCasePublished = useOrthBoundStore((state) => state.setIsCasePublished);
  const { updateTreatmentState } = useGetDentalMovements();
  const { uncompleteNextPhases } = useUtils();
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);

  const updateFileInfo = useCallback(
    (file: CaptureToUpload) => {
      const fileinfo = { ...file };
      if (captureType == CaptureType.MODEL3D) {
        fileinfo.path = fileinfo.path.substring(0, fileinfo.path.lastIndexOf('.')) + '.wepmd';
        fileinfo.name = fileinfo.name.substring(0, fileinfo.name.lastIndexOf('.')) + '.wepmd';
        fileinfo.size = Module.FS.open(fileinfo.path).node.usedBytes;
      }

      return fileinfo;
    },
    [captureType]
  );

  const saveFile = useCallback(
    async (fileToUpload: CaptureToUpload, fnUpdateModel3dId: (newUpperModel3DId: string) => void) => {
      const currentVersionId = currentVersion?.id;
      try {
        if (fileToUpload && currentVersionId) {
          const file = updateFileInfo(fileToUpload);
          await uploadFileFromFS(file, currentVersionId);
          if (file.name.includes('wepmd')) {
            fnUpdateModel3dId(file.name.replace('.wepmd', ''));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [currentVersion, uploadFileFromFS, updateFileInfo]
  );

  const saveInBackend = async () => {
    const buildPhase: CasePhase = {
      id: OrthodonticsPhasesKeys.STLLoad,
      name: OrthodonticsPhasesKeys.STLLoad,
      completionDate: new Date(),
      editionDate: new Date()
    };
    const versionId: string = currentVersion.id.toString();
    await editCasePhase(caseId, versionId, buildPhase);

    if (isCaseReseting) {
      setCanUndo(false);
      updateIsCaseReseting(false);
      await uncompleteNextPhases();
      await updateTreatmentState(currentVersion.caseId, currentVersion, CaseVersionState.designing, true);
      setIsCasePublished(false);
    }
  };

  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusy(true);
    let response;
    try {
      const deletionPromises =
        keysFilesToDelete?.map(async (key) => {
          await removeCaptureFile(key);
        }) || [];

      await Promise.all(deletionPromises);

      const upperPromises =
        upperFilesToUpload?.map(async (upperFileToUpload) => {
          await saveFile(upperFileToUpload, updateUpperModel3DId);
        }) || [];

      const lowerPromises =
        lowerFilesToUpload?.map(async (lowerFileToUpload) => {
          await saveFile(lowerFileToUpload, updateLowerModel3DId);
        }) || [];

      await Promise.all([...upperPromises, ...lowerPromises]);

      await saveInBackend();

      response = true;
    } catch (error) {
      response = true;
    } finally {
      setWebBusy(false);
      return Promise.resolve(response);
    }
  }, [saveFile, updateUpperModel3DId, currentVersion, keysFilesToDelete]);

  return { saveChanges };
}
