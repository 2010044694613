import { StateCreator } from 'zustand';

export type PointerPosition = {
  clientX: number;
  clientY: number;
};

export interface OrthContextMenuSlice {
  isOrthContextMenuVisible: boolean;
  setIsOrthContextMenuVisible: (isOrthContextMenuVisible: boolean) => void;
  pointerPosition: PointerPosition;
  setPointerPosition: (pointerPosition: PointerPosition) => void;
}

export const createOrthContextMenuSlice: StateCreator<OrthContextMenuSlice, [], [], OrthContextMenuSlice> = (set) => ({
  isOrthContextMenuVisible: false,
  setIsOrthContextMenuVisible: (isOrthContextMenuVisible: boolean) => set((_) => ({ isOrthContextMenuVisible })),
  pointerPosition: { clientX: 0, clientY: 0 },
  setPointerPosition: (pointerPosition: PointerPosition) => set((_) => ({ pointerPosition }))
});
