/* eslint-disable prettier/prettier */
import { Container } from 'inversify';
import {
  AIMovementsService,
  AuthService,
  AxisRootsOrientationService,
  CalculationAttachesService,
  CasesService,
  ClientPlanValidationService,
  ClinicService,
  DentalMovementsService,
  ErrorHandlerService,
  FileService,
  HttpService,
  IAIMovementsService,
  IAuthService,
  IAxisRootsOrientationService,
  ICalculationAttachesService,
  ICasesService,
  IClientPlanValidationService,
  IClinicService,
  IDentalMovementsService,
  IErrorHandlerService,
  IFileService,
  IHttpService,
  ILoginService,
  ITeethSegmentationService,
  IUserProfileService,
  LoginService,
  TeethSegmentationService,
  UserProfileService
} from '../../shared';
import { CaseStatusService, ICaseStatusService } from '../../shared/caseStatus';
import { ChatService, IChatService } from '../../shared/chat';
import CommandInvokerService, { ICommandInvoker } from '../../shared/commands/commandInvoker.service';
import { HistoryTreatmentChangeService } from '../../shared/historyTreatmentChange';
import { IHistoryTreatmentChangeService } from '../../shared/historyTreatmentChange/ihistoryTreatmentChange.service';
import { IImplantsService, ImplantsService } from '../../shared/implants';
import { IManufacturersService, ManufacturersService } from '../../shared/manufacturers';
import { INJECTED_TYPES } from './ioc.types';

const container = new Container();
container.bind<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService).to(ErrorHandlerService).inSingletonScope();
container.bind<IHttpService>(INJECTED_TYPES.IHttpService).to(HttpService).inSingletonScope();
container.bind<ICasesService>(INJECTED_TYPES.ICasesService).to(CasesService);
container
  .bind<IClientPlanValidationService>(INJECTED_TYPES.IClientPlanValidationService)
  .to(ClientPlanValidationService);
container.bind<IDentalMovementsService>(INJECTED_TYPES.IDentalMovementsService).to(DentalMovementsService);
container
  .bind<IAxisRootsOrientationService>(INJECTED_TYPES.IAxisRootsOrientationService)
  .to(AxisRootsOrientationService);
container.bind<IAuthService>(INJECTED_TYPES.IAuthService).to(AuthService);
container.bind<IUserProfileService>(INJECTED_TYPES.IUserProfileService).to(UserProfileService);
container.bind<ILoginService>(INJECTED_TYPES.ILoginService).to(LoginService);
container.bind<IClinicService>(INJECTED_TYPES.IClinicService).to(ClinicService);
container.bind<IFileService>(INJECTED_TYPES.IFileService).to(FileService);
container.bind<IImplantsService>(INJECTED_TYPES.IImplantsService).to(ImplantsService);
container.bind<IManufacturersService>(INJECTED_TYPES.IManufacturersService).to(ManufacturersService);
container.bind<IChatService>(INJECTED_TYPES.IChatService).to(ChatService);
container
  .bind<IHistoryTreatmentChangeService>(INJECTED_TYPES.IHistoryTreatmentChangeService)
  .to(HistoryTreatmentChangeService);
container.bind<ICaseStatusService>(INJECTED_TYPES.ICaseStatusService).to(CaseStatusService);
container.bind<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService).to(CommandInvokerService).inSingletonScope();
container.bind<ITeethSegmentationService>(INJECTED_TYPES.ITeethSegmentationService).to(TeethSegmentationService);
container.bind<ICalculationAttachesService>(INJECTED_TYPES.ICalculationAttachesService).to(CalculationAttachesService);
container.bind<IAIMovementsService>(INJECTED_TYPES.IAIMovementsService).to(AIMovementsService);

export { container };
