import { useEffect, useState } from 'react';
import { Color } from '../../../../common/evergine/types';
import { TeethArchPosition } from '../../../../models';
import './matchingButtons.scss';

type MatchingButtonsTypes = {
  onClickMatchingButton: (selectedColor: number) => void;
  setIsResetColors: (isColorsReset: boolean) => void;
  isColorsReset: boolean;
  teethArchPosition: TeethArchPosition;
  colorKeyActive: Record<string, number>;
  setColorKeyActive: (colorKeyActive: Record<string, number>) => void;
};

export function MatchingButtons({
  onClickMatchingButton,
  isColorsReset,
  setIsResetColors,
  teethArchPosition,
  colorKeyActive
}: MatchingButtonsTypes) {
  const [colorsKeys, setColorsKeys] = useState<Record<string, string[]>>(null);

  const onCLickColorButton = (selectedColor: number): void => {
    onClickMatchingButton(selectedColor);
  };

  useEffect(() => {
    const mappedColors = Object.keys(Color)
      .map((key: string | number) => {
        if (typeof parseInt(key as string) === 'number' && isNaN(parseInt(key as string))) {
          return Color[key as number];
        }
      })
      .slice(4, 8);
    const initialColorKeys = {
      [teethArchPosition]: mappedColors
    };

    setColorsKeys(initialColorKeys);
  }, [teethArchPosition]);

  useEffect(() => {
    if (isColorsReset) {
      setColorsKeys(colorsKeys);
    }

    setIsResetColors(false);
  }, [isColorsReset]);

  return (
    <>
      {colorsKeys && colorsKeys[teethArchPosition] && colorsKeys[teethArchPosition].length > 0 && (
        <div className="d-flex justify-content-center">
          <div className="matching-buttons">
            {colorsKeys[teethArchPosition].map((color: any, index) => {
              return (
                <button
                  key={index}
                  onClick={() => onCLickColorButton(color as number)}
                  className={`matching-button matching-button-${color} ${
                    colorKeyActive !== null &&
                    colorKeyActive[teethArchPosition] !== null &&
                    colorKeyActive[teethArchPosition] === parseInt(colorsKeys[teethArchPosition][color])
                      ? 'matching-button-active'
                      : ''
                  }`}
                ></button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
