import { StateCreator } from 'zustand';

export interface ConfirmDataSlice {
  canBeConfirmed: boolean;
  isConfirmed: boolean;
  setCanBeConfirmed: (canBeConfirmed: boolean) => void;
  setIsConfirmed: (isConfirmed: boolean) => void;
}
export const createConfirmDataSlice: StateCreator<ConfirmDataSlice, [], [], ConfirmDataSlice> = (set) => ({
  canBeConfirmed: false,
  isConfirmed: false,
  setCanBeConfirmed: (canBeConfirmed: boolean) => set((_) => ({ canBeConfirmed })),
  setIsConfirmed: (isConfirmed: boolean) => set((_) => ({ isConfirmed }))
});
