import { DentalMovements } from '../../common';

export enum MovementsValueSelector {
  Accumulated = 'accumulated',
  Step = 'step'
}

export type DentalMovementDataDTO = {
  data: DentalMovementDTO[];
};

export type DentalMovementDTO = {
  fdi: number;
  extrusionIntrusion: number;
  translationVL: number;
  translationMD: number;
  pureRotation: number;
  torque: number;
  tip: number;
};

export type MovementsTableDto = {
  stepIndex: number;
  movements: DentalMovementDTO[];
};

export type TreatmentPlanificationMovementsDTO = DentalMovements & {
  id: number;
};

export type TreatmentPlanificationMovementsAddDTO = DentalMovements & {
  versionId: string;
};

export type TreatmentPlanificationMovementsUpdateDTO = DentalMovements;
