import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as UploadFileIcon } from '../../../../../assets/icons/upload_file.svg';

interface UploadFileProps {
  fileId: string;
  typesAllowed: string;
  allowMultiple?: boolean;
  onChange: (e: React.ChangeEvent, fileId: string) => void;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
}

export function UploadFile({ fileId, typesAllowed, allowMultiple, onChange, disabled, onClick }: UploadFileProps) {
  const [t] = useTranslation();
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e: React.ChangeEvent) => {
    onChange(e, fileId);
    inputRef.current.value = '';
  };

  return (
    <>
      <input
        id={fileId}
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleChange}
        onClick={onClick}
        accept={typesAllowed}
        multiple={allowMultiple}
      />
      <button
        className="capture-action"
        disabled={disabled}
        data-for={fileId ? fileId : 'uploadFile'}
        data-tip
        onClick={handleClick}
      >
        <UploadFileIcon />
        <ReactTooltip
          id={fileId ? fileId : 'uploadFile'}
          place="bottom"
          effect="solid"
          className="tooltip d-flex flex-column"
          type="dark"
        >
          <span>{t(`imageManagement.actions.uploadFile`)}</span>
        </ReactTooltip>
      </button>
    </>
  );
}
