import { ReactComponent as Attach } from '../../../assets/icons/evergineTools/attach.svg';
import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { ToolbarConfigProps } from '../../../common';
import { debouncedSetState } from '../../../common/utils';
import { useOrthBoundStore } from '../../stores/useStore';

export const attachesToolbarElement: ToolbarConfigProps = {
  icon: Attach,
  tooltipKey: 'evergineTools.attaches',
  customId: 'orthAttachesButton',
  handleClick: () => {
    const attachesPanelCurrentValue = useOrthBoundStore.getState().showAttachesPanel;

    if (!attachesPanelCurrentValue) {
      window.App.webEventsProxy.layers.showAttachments(true);
    }

    debouncedSetState(useOrthBoundStore, { showAttachesPanel: !attachesPanelCurrentValue });
  }
};

export const calcAIAttachesToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.automaticCalc',
  labelKey: 'evergineTools.automaticCalc',
  handleClick: () => {
    useOrthBoundStore.setState({ areAttachesCalculating: true });
  }
};
