import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DeleteImplantIcon } from '../../../assets/icons/delete-implant.svg';

export function DeleteImplantControl() {
  const [t] = useTranslation();

  return (
    <div className="controls-item">
      <ReactTooltip id="deleteImplant" place="right" effect="solid" className="tooltip" type="dark">
        <span>{t(`implants.deleteImplant`)}</span>
      </ReactTooltip>
      <DeleteImplantIcon data-for="deleteImplant" data-tip></DeleteImplantIcon>
    </div>
  );
}
