import { useCallback } from 'react';
import { ReactComponent as ConfirmDisabledIcon } from '../../../../assets/icons/confirm-disabled.svg';
import { ReactComponent as ConfirmEnabledIcon } from '../../../../assets/icons/confirm-enabled.svg';
import { ModalTypes } from '../../../../surgeries/components/layout';
import { useBoundStore } from '../../../../surgeries/stores/useStore';

import { useCommonBoundStore } from '../../../stores/useStore';
import { NavBarAction } from './NavBarAction';

export default (actionProps: any) => {
  const { canBeConfirmed, isConfirmed, setIsConfirmed } = useBoundStore();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);

  const doAction = useCallback(() => {
    setIsConfirmed(true);

    if (actionProps.block.hasNextPath && canBeConfirmed) {
      toggleModalIsOpened(ModalTypes.ModalNextStep);
    }
  }, [isConfirmed, canBeConfirmed]);

  return (
    <NavBarAction
      IconComponent={canBeConfirmed ? ConfirmEnabledIcon : ConfirmDisabledIcon}
      altText={'navbar.actions.confirm'}
      action={() => doAction()}
      disabled={canBeConfirmed == false}
      isEndOfBlock={actionProps.block.isEndOfBlock}
    />
  );
};
