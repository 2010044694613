import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/images/logo.png';
import React from 'react';

import './secretalignerlogo.scss';

export function SecretAlignerLogo() {
  const [t] = useTranslation();
  return (
    <div className="secretalignerlogo">
      <img className="w-100" src={Logo} alt={t('common.company')} />
    </div>
  );
}
