import { StateCreator } from 'zustand';

export interface TimelineSlice {
  activeStep: number;
  isNewStepClientModification: boolean;
  isEditStep: boolean;
  setActiveStep: (newActiveStep: number) => void;
  setIsNewStepClientModification: (newValue: boolean) => void;
  setIsEditStep: (newValue: boolean) => void;
}
export const createTimelineSlice: StateCreator<TimelineSlice, [], [], TimelineSlice> = (set) => ({
  activeStep: 0,
  isNewStepClientModification: false,
  isEditStep: true,
  setActiveStep: (newActiveStep: number) => set(() => ({ activeStep: newActiveStep })),
  setIsNewStepClientModification: (newValue: boolean) => set(() => ({ isNewStepClientModification: newValue })),
  setIsEditStep: (newValue: boolean) => set(() => ({ isEditStep: newValue }))
});
