import { StateCreator } from 'zustand';
import { CaptureToUpload } from '../../../shared/file';
import { CaptureType } from '../../evergine/types';

export interface BaseCaptureSlice {
  lowerFilesToUpload: CaptureToUpload[];
  setLowerFilesToUpload: (files: CaptureToUpload[]) => void;
  upperFilesToUpload: CaptureToUpload[];
  setUpperFilesToUpload: (files: CaptureToUpload[]) => void;
  captureType: CaptureType;
  setCaptureType: (captureType: CaptureType) => void;
  hasModelsLoaded: boolean;
  setHasModelsLoaded: (hasModelsLoaded: boolean) => void;
  someCapture: boolean;
  setSomeCapture: (hasModelsLoaded: boolean) => void;
  hasArchModelsLoaded: boolean;
  setHasArchModelsLoaded: (hasArchModelsLoaded: boolean) => void;
  upperModel3DId?: string;
  setUpperModel3DId: (upperModel3DId: string) => void;
  lowerModel3DId?: string;
  setLowerModel3DId: (lowerModel3DId: string) => void;
  keysFilesToDelete: string[];
  setKeysFilesToDelete: (keysFilesToDelete: string[]) => void;
}
export const createBaseCaptureSlice: StateCreator<BaseCaptureSlice, [], [], BaseCaptureSlice> = (set) => ({
  lowerFilesToUpload: null,
  setLowerFilesToUpload: (files: CaptureToUpload[]) => set({ lowerFilesToUpload: files }),
  upperFilesToUpload: null,
  setUpperFilesToUpload: (files: CaptureToUpload[]) => set({ upperFilesToUpload: files }),
  captureType: null,
  setCaptureType: (captureType: CaptureType) => set({ captureType }),
  hasModelsLoaded: false,
  setHasModelsLoaded: (hasModelsLoaded: boolean) => set({ hasModelsLoaded }),
  someCapture: false,
  setSomeCapture: (someCapture: boolean) => set({ someCapture }),
  hasArchModelsLoaded: false,
  setHasArchModelsLoaded: (hasArchModelsLoaded: boolean) => set({ hasArchModelsLoaded }),
  upperModel3DId: undefined,
  setUpperModel3DId: (upperModel3DId: string) => set({ upperModel3DId }),
  lowerModel3DId: undefined,
  setLowerModel3DId: (lowerModel3DId: string) => set({ lowerModel3DId }),
  keysFilesToDelete: [],
  setKeysFilesToDelete: (keysFilesToDelete: string[]) => set({ keysFilesToDelete })
});
