import { Backdrop, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../common/components';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useCaseData, useCaseId, useGateKeeper, useUploadFile } from '../../../../hooks';
import { useRemoveFile } from '../../../../hooks/shared/useRemoveFile';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { AbilityAction, CommonAbilitySubject, PhotoCaptureType, isBlobURL, isStringVoid } from '../../../../shared';
import { useBoundStore } from '../../../stores/useStore';
import { ModalTypes } from '../../layout';
import { PhotoCapture, PhotoCaptureMultiple } from './photoCapture/PhotoCapture';
import './photoCaptures.scss';
import { PhotoCarousel } from './photoCarousel';

type PhotoCaptureLocalUrl = {
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: ImageToShow;
  [PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY]: ImageToShow;
  [PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OTHERS]: ImageToShow;
};

export type ImageToShow = {
  key: string;
  title: string;
  url: string;
  name: string;
  size: number;
  isUploaded?: boolean; // TODO revisar si se usa
};

export function PhotoCaptures() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.Manage, CommonAbilitySubject.UploadPhotos, () => navigate('/forbidden'));

  const [t] = useTranslation();
  const allowedExtensions = ['jpg', 'jpeg', 'png'];
  const [showCarousel, setShowCarousel] = useState<boolean>();
  const [indexSelectedSlide, setIndexSelectedSlide] = useState<number>(0);
  const [imagesForCarousel, setImagesForCarousel] = useState<ImageToShow[]>([]);
  const [typeOtherImages, setTypeOtherImages] = useState<ImageToShow[]>([]);
  const [images, setImages] = useState<PhotoCaptureLocalUrl>({
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT,
      title: t('photographs.leftOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT,
      title: t('photographs.rightOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER,
      title: t('photographs.upperOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER,
      title: t('photographs.lowerOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: {
      key: PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE,
      title: t('photographs.frontSmile'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY]: {
      key: PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY,
      title: t('photographs.orthoPantomography'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY]: {
      key: PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY,
      title: t('photographs.teleRadiography'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    },
    [PhotoCaptureType.PHOTOGRAPHS_OTHERS]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OTHERS,
      title: t('photographs.others'),
      url: undefined,
      name: undefined,
      size: undefined,
      isUploaded: false
    }
  });
  const [appIsBusy, setAppIsBusy] = useState<boolean>();
  const { isConfirmed, setIsConfirmed, setCanBeConfirmed } = useBoundStore();
  const { toggleModalIsOpened, setModalMessage, setModalTitle } = useCommonBoundStore();

  const [caseId] = useCaseId();
  const { currentVersion } = useOrthBoundStore();
  const { uploadFileFromBlobURL } = useUploadFile(caseId);
  const { removeFile } = useRemoveFile(caseId);
  const { patientCase } = useCaseData(caseId);

  const isImageUploadable = (image: ImageToShow) => !isStringVoid(image.url) && isBlobURL(image.url);
  const isImageDownloadable = (image: ImageToShow) => !isStringVoid(image.url) && !isBlobURL(image.url);

  useEffect(() => {
    setIsConfirmed(false);
    setCanBeConfirmed(false);
  }, []);

  useEffect(() => {
    if (!patientCase) {
      return;
    }

    const receivedPhotographs = patientCase.photographs;
    let newImages = { ...images };
    if (!isStringVoid(receivedPhotographs?.frontSmile.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE],
          url: receivedPhotographs?.frontSmile.url,
          name: receivedPhotographs?.frontSmile.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.leftOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT],
          url: receivedPhotographs?.leftOcclusion.url,
          name: receivedPhotographs?.leftOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.rightOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT],
          url: receivedPhotographs?.rightOcclusion.url,
          name: receivedPhotographs?.rightOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.upperOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER],
          url: receivedPhotographs?.upperOcclusion.url,
          name: receivedPhotographs?.upperOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.lowerOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER],
          url: receivedPhotographs?.lowerOcclusion.url,
          name: receivedPhotographs?.lowerOcclusion.name
        }
      };
    }

    // TODO: Endpoint not implemented
    /*
    const otherImages = receivedPhotographs?.others
      .filter((p) => !isStringVoid(p.url))
      .map((p) => ({
        ...p,
        title: t('photographs.others'),
        size: undefined,
        isUploaded: true
      }));
    setTypeOtherImages(otherImages);*/
    const otherImages: any = [];

    setImages(newImages as PhotoCaptureLocalUrl);
    setImagesForCarousel([...Object.values(newImages), ...otherImages]);
  }, [patientCase]);

  useEffect(() => {
    if (!currentVersion) {
      return;
    }

    const uploadFiles = async () => {
      if (isConfirmed) {
        setAppIsBusy(true);

        try {
          let imagesWithUpdatedData = images;
          const imagesToUpload = Object.values(images).filter((img) => isImageUploadable(img));
          for (const img of imagesToUpload) {
            await uploadFileFromBlobURL(
              {
                type: img.key,
                name: img.name,
                path: img.url,
                size: img.size
              },
              currentVersion.id
            );
            imagesWithUpdatedData = {
              ...imagesWithUpdatedData,
              [img.key]: { ...images[img.key as PhotoCaptureType], isUploaded: true }
            };
          }

          setImages(imagesWithUpdatedData);

          let otherImagesWithUpdatedData = typeOtherImages;
          const otherImagesToUpload = typeOtherImages.filter((img) => isImageUploadable(img));

          otherImagesToUpload.forEach(async (img) => {
            setTypeOtherImages({
              ...typeOtherImages,
              [img.key]: { ...images[img.key as PhotoCaptureType], isUploaded: true }
            });

            await uploadFileFromBlobURL(
              {
                type: img.key,
                name: img.name,
                path: img.url,
                size: img.size
              },
              currentVersion.id
            );
            otherImagesWithUpdatedData = {
              ...otherImagesWithUpdatedData,
              [img.key]: { ...images[img.key as PhotoCaptureType], isUploaded: true }
            };
          });

          setTypeOtherImages(otherImagesWithUpdatedData);
        } catch (error) {
          console.error(error);
        } finally {
          setAppIsBusy(false);
          setIsConfirmed(false);
        }
      }
    };

    uploadFiles();
  }, [isConfirmed, currentVersion]);

  const removePhotoFile = async (type: string) => {
    const imageToRemove = type.includes('others')
      ? typeOtherImages.find((img) => img.key === type)
      : images[type as PhotoCaptureType];

    if (isBlobURL(imageToRemove.url) && !imageToRemove.isUploaded) {
      if (type.includes('others')) {
        const newOtherImages = typeOtherImages.filter((img) => img.key !== type);
        setTypeOtherImages(newOtherImages);
        const newImagesForCarousel = imagesForCarousel.filter((img) => img.key !== type);
        setImagesForCarousel(newImagesForCarousel);
      } else {
        const newImages: any = {
          ...images,
          [type]: { ...images[type as PhotoCaptureType], url: undefined, name: undefined, size: undefined }
        };
        setImages(newImages);
        setImagesForCarousel(Object.values(newImages));
      }

      return;
    }

    setAppIsBusy(true);
    try {
      await removeFile(imageToRemove.key);
      if (type.includes('others')) {
        const newOtherImages = typeOtherImages.filter((img) => img.key !== type);
        setTypeOtherImages(newOtherImages);
        const newImagesForCarousel = imagesForCarousel.filter((img) => img.key !== type);
        setImagesForCarousel(newImagesForCarousel);
      } else {
        const newImages: any = {
          ...images,
          [type]: { ...images[type as PhotoCaptureType], url: undefined, name: undefined, size: undefined }
        };
        setImages(newImages);
        setImagesForCarousel(Object.values(newImages));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAppIsBusy(false);
    }
  };

  const downloadPhotoFile = (imageToDownload: ImageToShow) => {
    const link = document.createElement('a');
    link.href = imageToDownload.url;
    link.setAttribute('download', imageToDownload.name);
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const uploadPhotoFile = (event: any, type: PhotoCaptureType) => {
    const target = event.target as HTMLInputElement;
    const file = event.target.files[0];
    target.value = null;

    if (!file) {
      return;
    }

    const extension = file.name.split('.').slice(-1)[0];
    if (!allowedExtensions.includes(extension)) {
      setModalTitle(t(`modal.error.badFileType.title`));
      setModalMessage(t('modal.error.badFileType.photosScreen'));
      toggleModalIsOpened(ModalTypes.ModalError);
      return;
    }

    if (type === PhotoCaptureType.PHOTOGRAPHS_OTHERS) {
      if (typeOtherImages.length === 10) {
        setModalTitle(t(`modal.error.badFileType.title`));
        setModalMessage(t('modal.error.maxOtherPhotosLength'));
        toggleModalIsOpened(ModalTypes.ModalError);
        return;
      }

      const firstEmptyIndex = patientCase.photographs.others.findIndex((o) => isStringVoid(o.url));
      const id = `${PhotoCaptureType.PHOTOGRAPHS_OTHERS}-${firstEmptyIndex}`;
      const newOtherImages: ImageToShow[] = [
        ...typeOtherImages,
        {
          key: id,
          title: PhotoCaptureType.PHOTOGRAPHS_OTHERS,
          url: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          isUploaded: false
        }
      ];

      setTypeOtherImages(newOtherImages);
      setImagesForCarousel([
        ...imagesForCarousel,
        {
          key: id,
          title: PhotoCaptureType.PHOTOGRAPHS_OTHERS,
          url: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          isUploaded: false
        }
      ]);
    } else {
      const newImages = {
        ...images,
        [type]: {
          ...images[type as PhotoCaptureType],
          url: URL.createObjectURL(file),
          name: file.name,
          size: file.size
        }
      };
      setImages(newImages);
      setImagesForCarousel(Object.values(newImages));
    }

    setCanBeConfirmed(true);
  };

  const onClickBackdrop = (identifier: string) => {
    const index = imagesForCarousel.filter((i) => i.url !== undefined).findIndex((img) => img.key === identifier);
    setIndexSelectedSlide(index);

    setShowCarousel(true);
  };

  return (
    <>
      {showCarousel ? (
        <PhotoCarousel
          slides={imagesForCarousel.filter((i) => i.url !== undefined)}
          onClickRemoveFile={removePhotoFile}
          onClickClose={() => setShowCarousel(false)}
          selectedSlide={indexSelectedSlide}
        />
      ) : (
        <div className="photo-captures">
          <Backdrop style={{ zIndex: 1000 }} open={appIsBusy}>
            <Box sx={{ display: 'block', textAlign: 'center' }}>
              <Spinner />
            </Box>
          </Backdrop>
          <div className="row g-0">
            <div className="col">
              <PhotoCapture
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT}_${uuidv4()}`}
                title={t('photographs.leftOcclusion')}
                imageUrl={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].url}
                isDownloadable={isImageDownloadable(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT])}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT)}
                onClickDownloadFile={(_) => downloadPhotoFile(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT])}
              />
            </div>
            <div className="col">
              <PhotoCapture
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER}_${uuidv4()}`}
                title={t('photographs.upperOcclusion')}
                imageUrl={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].url}
                isDownloadable={isImageDownloadable(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER])}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER)}
                onClickDownloadFile={(_) => downloadPhotoFile(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER])}
              />
            </div>
            <div className="col">
              <PhotoCapture
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE}_${uuidv4()}`}
                title={t('photographs.frontSmile')}
                imageUrl={images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].url}
                isDownloadable={isImageDownloadable(images[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE])}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE)}
                onClickDownloadFile={(_) => downloadPhotoFile(images[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE])}
              />
            </div>
          </div>

          <div className="row g-0">
            <div className="col">
              <PhotoCapture
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT}_${uuidv4()}`}
                title={t('photographs.rightOcclusion')}
                imageUrl={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].url}
                isDownloadable={isImageDownloadable(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT])}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT)}
                onClickDownloadFile={(_) => downloadPhotoFile(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT])}
              />
            </div>
            <div className="col">
              <PhotoCapture
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER}_${uuidv4()}`}
                title={t('photographs.lowerOcclusion')}
                imageUrl={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].url}
                isDownloadable={isImageDownloadable(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER])}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER)}
                onClickDownloadFile={(_) => downloadPhotoFile(images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER])}
              />
            </div>
            <div className="col">
              <PhotoCaptureMultiple
                onClickBackdrop={() => onClickBackdrop(typeOtherImages[0].key)}
                fileId={`photo_${PhotoCaptureType.PHOTOGRAPHS_OTHERS}_${uuidv4()}`}
                title={t('photographs.others')}
                imageUrls={typeOtherImages?.map((o) => o.url)}
                onClickUploadFile={(e) => uploadPhotoFile(e, PhotoCaptureType.PHOTOGRAPHS_OTHERS)}
                onClickRemoveFile={(_) => removePhotoFile(PhotoCaptureType.PHOTOGRAPHS_OTHERS)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
