export enum ActionComponent {
  nextStepAction = 'nextStepAction',
  informationAction = 'informationAction',
  previousStepAction = 'previousStepAction',
  splitScreenAction = 'splitScreenAction',
  orthoTeethArch = 'orthoTeethArch',
  surgeryTeethArch = 'surgeryTeethArch',
  confirmAction = 'confirmAction',
  nextStepDirectAction = 'nextStepDirectAction',
  camViewAction = 'camViewAction',
  showGridAction = 'showGridAction',
  showAxisAction = 'showAxisAction',
  undoAction = 'undoAction',
  redoAction = 'redoAction',
  uploadMockDentalMovementsFileAction = 'uploadMockDentalMovementsFileAction',
  uploadUpperMovementsMockFileAction = 'uploadUpperMovementsMockFileAction',
  uploadLowerMovementsMockFileAction = 'uploadLowerMovementsMockFileAction',
  groupedActions = 'groupedActions',
  uploadNewModelFileAction = 'uploadNewModelFileAction'
}
