import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ResetFileIcon } from '../../../../../assets/icons/reset.svg';

export function ResetFileData({ resetFileData }: any) {
  const [t] = useTranslation();

  return (
    <>
      <button className="capture-action" data-for="resetFileData" data-tip onClick={resetFileData}>
        <ResetFileIcon />
        <ReactTooltip
          id="resetFileData"
          place="bottom"
          effect="solid"
          className="tooltip d-flex flex-column"
          type="dark"
        >
          <span>{t(`imageManagement.actions.resetFileData`)}</span>
        </ReactTooltip>
      </button>
    </>
  );
}
