import { useTranslation } from 'react-i18next';
import { ReactComponent as Warning } from '../../../../../assets/icons/modal/warning.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';

export function ModalError() {
  const [t] = useTranslation();
  const { toggleModalIsOpened, modalMessage, modalTitle } = useCommonBoundStore();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  return (
    <div className="modal-content" data-testid={'modal-previousStep'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Warning className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{modalTitle}</h3>
        <p className="modal-text text-center mb-0">{modalMessage}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.close`)}
        </button>
      </div>
    </div>
  );
}
