import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { MinorVersion } from '../../../shared';
import { Attachment, CapturePosition, IprLabel, Step, Tooth } from '../../evergine';
import { useCommonBoundStore } from '../../stores/useStore';
import { PlayerActions } from './PlayerActions';
import { TimelineOverposition } from './TimelineOverposition';
import { TimelineSteps } from './TimelineSteps';
import './timeline.scss';
import { useStepsManager } from '../../../hooks/orthodontics';

type TimelineProps = {
  upperSteps?: Step[];
  lowerSteps?: Step[];
  upperTeeth?: Tooth[];
  lowerTeeth?: Tooth[];
  upperAttachments?: Attachment[];
  lowerAttachments?: Attachment[];
  upperIprLabels?: IprLabel[];
  lowerIprLabels?: IprLabel[];
  comparerActivated: boolean;
  secondaryTimeline?: boolean;
  vertical: boolean;
  enableFollowUpOverposition: boolean;
  storeCurrentStep?: number;
  versionName?: string;
  minorVersions?: MinorVersion[];
  selectedSteps?: number[];
  isDesigner?: boolean;
  isFinalPositionStage?: boolean;
  keyStepsIndexes?: number[];
  preventNavigation?: boolean;
  updateStoreCurrentStep?: (newValue: number, isCtrlKeyPressed?: boolean) => void;
  onStepContextMenu?: (stepIndex: number, stepElement: HTMLElement, isCtrlKeyPressed: boolean) => void;
  handlePreventedNavigation?: () => void;
};

export enum OverpositionMode {
  MajorVersions = 'majorVersions',
  MinorVersions = 'minorVersions'
}

export const Timeline = ({
  upperSteps,
  lowerSteps,
  upperTeeth,
  lowerTeeth,
  upperAttachments,
  lowerAttachments,
  upperIprLabels,
  lowerIprLabels,
  comparerActivated,
  secondaryTimeline,
  vertical,
  enableFollowUpOverposition,
  storeCurrentStep,
  versionName,
  minorVersions,
  selectedSteps,
  isDesigner,
  isFinalPositionStage,
  keyStepsIndexes,
  preventNavigation,
  updateStoreCurrentStep,
  onStepContextMenu,
  handlePreventedNavigation
}: TimelineProps) => {
  const { isNewStepClientModification } = useCommonBoundStore();
  const [t] = useTranslation();

  const { goToStep } = useStepsManager();

  const [currentStep, setCurrentStep] = useState(secondaryTimeline ? 0 : storeCurrentStep);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOverpositionActivated, setIsOverpositionActivated] = useState(false);
  const [stepToCompare, setStepToCompare] = useState<number>(0);
  const [upperStepList, setUpperStepList] = useState<Step[]>();
  const [lowerStepList, setLowerStepList] = useState<Step[]>();
  const [overpositionMode, setOverpositionMode] = useState<OverpositionMode>();
  const { updateIsVersionDisabled } = useCommonBoundStore();

  const maxStepIndex = useMemo(() => {
    return [...(upperSteps ?? []), ...(lowerSteps ?? [])].reduce(
      (max, step) => (step.stepIndex > max ? step.stepIndex : max),
      -Infinity
    );
  }, [upperSteps, lowerSteps]);

  const maxStepsLength = useMemo(
    () => Math.max(upperStepList?.length || 0, lowerStepList?.length || 0),
    [upperStepList, lowerStepList]
  );

  const TimelineSeparator = () => {
    return <div className="timeline-separator" />;
  };

  const onUpdateStep = useCallback(
    (newValue: number, isCtrlKeyPressed?: boolean) => {
      if (preventNavigation && handlePreventedNavigation) {
        handlePreventedNavigation();
        return;
      }

      if (newValue > maxStepIndex) {
        return;
      }

      if (isFinalPositionStage && newValue !== 0) {
        goToStep(maxStepIndex, !!secondaryTimeline);
      } else {
        goToStep(newValue, !!secondaryTimeline);
      }

      if (secondaryTimeline) {
        setCurrentStep(newValue);
        return;
      }

      if (!updateStoreCurrentStep) {
        return;
      }

      if (isCtrlKeyPressed !== undefined) {
        updateStoreCurrentStep(newValue, isCtrlKeyPressed);
      } else {
        updateStoreCurrentStep(newValue);
      }
    },
    [secondaryTimeline, preventNavigation, handlePreventedNavigation, maxStepIndex]
  );

  useEffect(() => {
    if (!upperSteps && !lowerSteps) {
      return;
    }
    setUpperStepList(upperSteps);
    setLowerStepList(lowerSteps);
  }, [upperSteps, lowerSteps]);

  useEffect(() => {
    if (secondaryTimeline) {
      return;
    }

    setCurrentStep(storeCurrentStep);
  }, [storeCurrentStep, secondaryTimeline, onUpdateStep]);

  useEffect(() => {
    // TODO: revisar en algún momento por qué se hace un goToStep tanto en este useEffect como en la función onUpdateStep
    if (isFinalPositionStage && currentStep !== 0) {
      goToStep(maxStepIndex, !!secondaryTimeline);
    } else {
      goToStep(currentStep, !!secondaryTimeline);
    }
  }, [currentStep]);

  useEffect(() => {
    if (!isOverpositionActivated) {
      setOverpositionMode(undefined);
    }
    window.App.webEventsProxy.movements.showStepOverlap(
      CapturePosition.BOTH,
      isOverpositionActivated,
      !!secondaryTimeline
    );
  }, [isOverpositionActivated]);

  useEffect(() => {
    if (stepToCompare === undefined) {
      return;
    }
    if (overpositionMode === OverpositionMode.MajorVersions) {
      window.App.webEventsProxy.movements.overlapMovements(CapturePosition.BOTH, stepToCompare, !!secondaryTimeline);
    } else {
      // TODO: send event to compare with minor version when it's ready (follow-up version)
      // window.App.webEventsProxy.overlapDentalMovements(CapturePosition.BOTH, stepToCompare, !!secondaryTimeline);
    }
  }, [stepToCompare]);

  const onChangeOverpositionMode = useCallback(
    (mode: OverpositionMode) => {
      setOverpositionMode(mode);
      setIsOverpositionActivated(true);
    },
    [setOverpositionMode, setIsOverpositionActivated]
  );

  useEffect(() => {
    updateIsVersionDisabled(isOverpositionActivated && overpositionMode === OverpositionMode.MinorVersions);
  }, [overpositionMode, isOverpositionActivated]);

  const toggleOverposition = useCallback(() => {
    setIsOverpositionActivated(!isOverpositionActivated);
  }, [setIsOverpositionActivated, isOverpositionActivated]);

  return (
    <div
      className={`timeline ${
        isOverpositionActivated && overpositionMode === OverpositionMode.MajorVersions ? 'is-overposition' : ''
      } ${comparerActivated ? 'comparer-activated' : ''} ${
        secondaryTimeline ? 'secondary-timeline' : 'main-timeline'
      } ${vertical ? 'vertical' : 'horizontal'}`}
    >
      {comparerActivated && (
        <div className="timeline-version">
          <Info />
          <span>{versionName}</span>
        </div>
      )}
      {overpositionMode !== undefined && (
        <div className="timeline-version">
          <Info />
          <span>
            {t(
              `dentalMovements.timeline.${
                overpositionMode === OverpositionMode.MajorVersions
                  ? 'overlappingCurrentPlan'
                  : 'overlappingFollowUpPlan'
              }`
            )}
          </span>
        </div>
      )}
      <TimelineOverposition
        isOverpositionActivated={isOverpositionActivated}
        onClick={toggleOverposition}
        enableFollowUpOverposition={enableFollowUpOverposition}
        onChangeOverpositionMode={onChangeOverpositionMode}
        overpositionMode={overpositionMode}
        isDisabled={!secondaryTimeline && isNewStepClientModification}
      />
      <TimelineSeparator />
      <PlayerActions
        isPlaying={isPlaying}
        onHandlePlayAction={(newValue) => setIsPlaying(newValue)}
        maxStepsLength={maxStepsLength}
        currentStep={currentStep}
        onClickPlayerAction={onUpdateStep}
        isDisabled={!secondaryTimeline && isNewStepClientModification}
      />
      <TimelineSeparator />
      <TimelineSteps
        isDesigner={isDesigner}
        upperSteps={upperStepList}
        lowerSteps={lowerStepList}
        upperTeeth={upperTeeth}
        lowerTeeth={lowerTeeth}
        upperAttachments={upperAttachments}
        lowerAttachments={lowerAttachments}
        upperIprLables={upperIprLabels}
        lowerIprLables={lowerIprLabels}
        isPlaying={isPlaying}
        onEndPlay={() => setIsPlaying(false)}
        maxStepsLength={maxStepsLength}
        maxStepIndex={maxStepIndex}
        isOverpositionActivated={isOverpositionActivated}
        comparerActivated={comparerActivated}
        vertical={vertical}
        stepToCompare={stepToCompare}
        onSelectStepToCompare={(selection) => setStepToCompare(selection)}
        currentStep={currentStep}
        onClickPlayerAction={onUpdateStep}
        isDisabled={!secondaryTimeline && isNewStepClientModification}
        minorVersions={minorVersions}
        showMinorVersions={
          !!minorVersions && minorVersions.length > 0 && overpositionMode === OverpositionMode.MinorVersions
        }
        onStepContextMenu={onStepContextMenu}
        selectedSteps={selectedSteps}
        isFinalPositionStage={isFinalPositionStage}
        keyStepsIndexes={keyStepsIndexes}
      />
      <TimelineSeparator />
    </div>
  );
};
