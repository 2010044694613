import { DentalMovements, Segmentation } from '../../common';
import { CaseType } from '../../models';

type Dentist = {
  id: string;
  clinics: string[];
  collegiateNumber: string;
};

export type PatientCaseDTO = {
  data: PatientCaseDTOData;
};

export type PatientCaseDTOData = {
  id?: string;
  type?: string;
  generalInfo?: PatientCaseDTOGeneralInfo;
  specificInfo?: PatientCaseDTOSpecificInfo;
  files?: PatientCaseDTOFile[];
  meta?: string[];
  createdAt?: string;
  updatedAt?: string;
};

// General info
type PatientCaseDTOGeneralInfo = {
  patient?: PatientCaseDTOGeneralInfoPatient;
  clinic?: PatientCaseDTOGeneralInfoClinic;
  comments?: string;
};

type PatientCaseDTOGeneralInfoPatient = {
  name?: string;
  gender?: string;
  birthdate?: string;
  archTreat?: ArchsToTreat;
  teethNotToMove?: PieceData[];
};

type PatientCaseDTOGeneralInfoClinic = {
  id?: number;
  name?: string;
  address?: string;
  addressNumber?: number;
  addressExtra?: string;
  phone?: string;
  postalCode?: string;
  city?: PatientCaseDTOGeneralInfoClinicCity;
};

type PatientCaseDTOGeneralInfoClinicCity = {
  id?: number;
  name?: string;
  province?: PatientCaseDTOGeneralInfoClinicCityProvince;
};

type PatientCaseDTOGeneralInfoClinicCityProvince = {
  id?: number;
  name?: string;
  country?: PatientCaseDTOGeneralInfoClinicCityProvinceCountry;
};

type PatientCaseDTOGeneralInfoClinicCityProvinceCountry = {
  id?: number;
  name?: string;
  code?: string;
};

// Specific info

type PatientCaseDTOSpecificInfo = {
  details?: PatientCaseDTOSpecificInfoDetails;
  treatmentTarget?: PatientCaseDTOSpecificInfoTreatmentTarget;
  indications?: string;
};

type PatientCaseDTOSpecificInfoDetails = {
  cutType?: string;
  secretRetainers?: boolean;
  passiveAligners?: boolean;
  useElastics?: boolean;
  distalize?: boolean;
  thirdMolarExtraction?: boolean;
};

type PatientCaseDTOSpecificInfoTreatmentTarget = {
  target?: TreatmentTargetEnum;
  patientProblems?: string[];
};

// Files
type PatientCaseDTOFile = {
  identifier?: string;
  legacyKey?: string;
  fileName?: string;
  fileChecksum?: string;
  fileOriginalName?: string;
  fileMime?: string;
  fileTotalSize?: number;
  filePath?: string;
  url?: string;
};

export enum CaseVersionState {
  designing = 'designing',
  published = 'published',
  accepted = 'accepted',
  requestedChanges = 'requestedChanges',
  rejected = 'rejected',
  pending = 'pending',
  readyForProduction = 'readyForProduction'
}

export type CaseVersionsListDTO = {
  data: CaseVersion[];
};

export type CaseVersionDTO = {
  data: CaseVersion;
};

export type CaseVersionPermissions = {
  acceptance: boolean;
};

export type CaseVersion = {
  id: string;
  caseId: string;
  name: string;
  result: CaseVersionState;
  plan: DentalMovements;
  segmentation: Segmentation;
  legacyPlan: LegacyPlan;
  state: CaseVersionState;
  createdAt: Date;
  updatedAt: Date;
  permissions: CaseVersionPermissions;
};

export type MinorVersion = {
  minorVersionId: string;
  versionId: string;
  caseId: string;
  stepIndex: number;
  createdAt: Date;
  upperJawToothMovements: ToothMovement[];
  lowerJawToothMovements: ToothMovement[];
};

type ToothMovement = {
  toothId: string;
  transform?: number[];
};

export type ClinicData = {
  name: string;
  address: string;
};

type PatientData = {
  name: string;
  gender: string;
  dateOfBirth: string;
  pieces?: PieceData[];
  piecesNotToMove?: PieceData[];
  archToTreat?: ArchsToTreat;
};

type Scannings = {
  upperModel3D: CaseFile;
  lowerModel3D: CaseFile;
  guide: CaseFile;
};

type Captures3D = {
  upperDICOM: CaseFile;
  lowerDICOM: CaseFile;
};

type Photographs = {
  leftOcclusion: CaseFile;
  rightOcclusion: CaseFile;
  upperOcclusion: CaseFile;
  lowerOcclusion: CaseFile;
  frontSmile: CaseFile;
  orthopantomography: CaseFile;
  teleradiography: CaseFile;
  others?: CaseFile[];
};

type PieceSize = {
  width: number;
  height: number;
};

export enum ArchsToTreat {
  Both = 'both',
  Lower = 'lower',
  Upper = 'upper',
  ConditioningVeneers = 'conditioning-veneers'
}

export type CaseFileInfoDto = {
  data: CaseFileInfo;
};

export type CaseFileInfo = {
  collectionName: string;
  identifier: string;
  fileName: string;
  url: string;
};

export type CaseFile = {
  key: string;
  name: string;
  url: string;
};

export type PieceData = {
  number?: string; // maybe number
  implantBrand?: string;
  size?: PieceSize;
  shape?: string; // possibly enum
  comments?: string;
};

export enum YesNoMeasureUpEnum {
  Yes = 'yes',
  No = 'no',
  MeasureUp = 'measure-up'
}

export enum TreatmentTargetEnum {
  Esthethic = 'esthetic',
  Occlusion = 'occlusion',
  EsthethicOcclusion = 'esthethic_occlusion'
}

export enum MovementsFileEnum {
  Upper = 'upper-jaw-file',
  Lower = 'lower-jaw-file'
}

export type SpecificInfo = {
  treatmentPrecut: string;
  secretRetainers: boolean;
  passiveAligners: YesNoMeasureUpEnum;
  useElastics: YesNoMeasureUpEnum;
  distalize: YesNoMeasureUpEnum;
  extractThirdMolar: YesNoMeasureUpEnum;
  treatmentTarget: TreatmentTargetEnum;
  indications: string;
  patientProblems: string;
};

export type PatientCase = {
  id: string;
  patient: PatientData;
  anamnesis: string[];
  type: CaseType;
  clinic: ClinicData;
  comments: string;
  meta: string;
  captures3D?: Captures3D;
  scannings?: Scannings;
  photographs?: Photographs;
  specificInfo?: SpecificInfo;
};

export type LegacyPlan = {
  tads: Tad[];
  stripping: Stripping[];
};

export type Tad = {
  id: number;
  description: string;
  title: string;
  number: number;
};

export type Stripping = {
  id: number;
  description: string;
  title: string;
  number: number;
};
