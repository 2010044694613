import { Ability, AbilityBuilder, AbilityTuple, MongoQuery } from '@casl/ability';
import { createContext } from 'react';
import { UserProfileType } from '../profile';
import {
  AbilityAction,
  AbilityRole,
  CommonAbilitySubject,
  OrthoAbilitySubject,
  SurgeryAbilitySubject
} from './ability.enum';

const buildEmployeePermissions = (): Ability<AbilityTuple, MongoQuery> => {
  const { can, cannot, build } = new AbilityBuilder<Ability>();

  can(AbilityAction.View, [
    CommonAbilitySubject.GeneralInfoScreen,
    CommonAbilitySubject.SpecificInfoScreen,
    CommonAbilitySubject.CaseStatePanel,
    CommonAbilitySubject.Versions,
    OrthoAbilitySubject.InfoPanel,
    OrthoAbilitySubject.Labels
  ]);
  can(AbilityAction.Manage, [
    CommonAbilitySubject.LoadCase,
    CommonAbilitySubject.Upload3DModels,
    CommonAbilitySubject.UploadDICOMs,
    CommonAbilitySubject.UploadPhotos,
    CommonAbilitySubject.Versions,
    SurgeryAbilitySubject.Export,
    SurgeryAbilitySubject.ImplantationDesign,
    OrthoAbilitySubject.Import3DModelsScreen,
    OrthoAbilitySubject.TreatmentViewerScreen,
    OrthoAbilitySubject.DownloadDentalMovementHistoric,
    OrthoAbilitySubject.TeethSegmentationScreen,
    OrthoAbilitySubject.IntermediateStepsScreen
  ]);

  cannot(AbilityAction.Manage, [SurgeryAbilitySubject.CheckDesign, OrthoAbilitySubject.TreatmentValidation]);
  return build();
};

const buildClientPermissions = (): Ability<AbilityTuple, MongoQuery> => {
  const { can, cannot, build } = new AbilityBuilder<Ability>();

  can(AbilityAction.View, [OrthoAbilitySubject.InfoPanel, OrthoAbilitySubject.TreatmentViewerScreen]);

  can(AbilityAction.Manage, [
    SurgeryAbilitySubject.CheckDesign,
    SurgeryAbilitySubject.ImplantationDesign,
    OrthoAbilitySubject.TreatmentValidation
  ]);

  cannot(AbilityAction.View, [
    CommonAbilitySubject.LoadCase,
    CommonAbilitySubject.GeneralInfoScreen,
    CommonAbilitySubject.SpecificInfoScreen,
    CommonAbilitySubject.Upload3DModels,
    CommonAbilitySubject.UploadDICOMs,
    CommonAbilitySubject.UploadPhotos,
    CommonAbilitySubject.CaseStatePanel,
    CommonAbilitySubject.Versions,
    SurgeryAbilitySubject.Export,
    OrthoAbilitySubject.Import3DModelsScreen,
    OrthoAbilitySubject.TeethSegmentationScreen,
    OrthoAbilitySubject.IntermediateStepsScreen
  ]);

  return build();
};

export const AbilityContext = createContext<Ability | null>(null);

export type AppAbility = Ability;

type RolePermissions = {
  [key: string]: Ability<AbilityTuple, MongoQuery>;
};

export function defineAbilitiesFor(profileType: UserProfileType): Ability {
  const { rules, cannot } = new AbilityBuilder<Ability>();

  // By default, disable everything
  cannot(AbilityAction.Manage, [CommonAbilitySubject.All]);

  const rolePermissions: RolePermissions = {
    [AbilityRole.Employee]: buildEmployeePermissions(),
    [AbilityRole.Client]: buildClientPermissions()
  };

  const currentPermissions = rolePermissions[profileType];

  if (!currentPermissions) {
    throw new Error(`There are no permissions for role ${profileType}`);
  }

  rules.push(...currentPermissions.rules);

  return new Ability(rules);
}
