import { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { Odontogram } from '../../../../surgeries/components';
import { findArchPositionByFdi } from '../../../../surgeries/components/odontogramTeethArch/odontogramTeethArchUtils';
import { SegmentationNumerationStrategy } from '../../../evergine/types';

import './modalChangeEnumeration.scss';

interface ModalChangeEnumerationProps {
  title: string;
  yesText: string;
  noText: string;
  distalText: string;
  mesialText: string;
  from: string;
  disableMesialDistalRenumber: boolean;
  onClickClose: () => void;
  onClickContinue: (param: SegmentationNumerationStrategy) => void;
}

export function ModalChangeEnumeration({
  title,
  yesText,
  noText,
  distalText,
  mesialText,
  from,
  disableMesialDistalRenumber,
  onClickClose,
  onClickContinue
}: ModalChangeEnumerationProps) {
  const { selectedPieces, setFromToPieces, fromToPieces } = useOrthBoundStore();

  const calculatePieceTo = useCallback(
    (pieceId: string) => {
      if (selectedPieces.includes(pieceId)) {
        setFromToPieces([]);
        return;
      } else {
        setFromToPieces([...selectedPieces, pieceId]);
        return pieceId;
      }
    },
    [selectedPieces]
  );

  const getFromAndToPieces = useMemo(() => {
    return {
      fromPiece: fromToPieces[0],
      toPiece: fromToPieces[1]
    };
  }, [fromToPieces]);

  return (
    <div data-testid="modal" className="modal modal-opened modal-change-numeration">
      <div data-testid="modal-container" className={`modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-body d-flex flex-column align-items-center text-center">
            <h3 className="modal-title mb-4">{title}</h3>
            <span className="text-modal">
              <Trans i18nKey="pageInfoMessages.teethSegmentation.selectNumeration" values={{ teethId: from }} />
            </span>

            <Odontogram
              archPosition={findArchPositionByFdi(from)}
              showToggleNumbers={true}
              selectedPieces={[from]}
              toRenumeration={getFromAndToPieces.toPiece}
              onPieceClick={(pieceId) => calculatePieceTo(pieceId)}
            />
          </div>

          <div className="modal-footer">
            <button
              onClick={onClickClose}
              type="button"
              className="btn btn-outline-primary modal-button"
              data-bs-dismiss="modal"
            >
              {noText}
            </button>
            <button
              onClick={() => onClickContinue(SegmentationNumerationStrategy.RenumberToDistal)}
              type="button"
              className="btn btn-outline-primary modal-button centered"
              data-bs-dismiss="modal"
              disabled={fromToPieces.length === 0 || disableMesialDistalRenumber}
            >
              {distalText}
            </button>
            <button
              onClick={() => onClickContinue(SegmentationNumerationStrategy.RenumberToMesial)}
              type="button"
              className="btn btn-outline-primary modal-button centered"
              data-bs-dismiss="modal"
              disabled={fromToPieces.length === 0 || disableMesialDistalRenumber}
            >
              {mesialText}
            </button>
            <button
              onClick={() => onClickContinue(SegmentationNumerationStrategy.SwapNumbers)}
              type="button"
              className="btn btn-dark modal-button"
              disabled={fromToPieces.length === 0}
            >
              {yesText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
