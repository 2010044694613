import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ITeethSegmentationService } from './iteethSegmentation.service';
import { SegmentationFileDTO, SegmentationResponseDTO } from './teethSegmentation';

@injectable()
export class TeethSegmentationService implements ITeethSegmentationService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  private readonly apiEndpoint = '/ai/segmentation';

  public async getAITeethSegmentation(requestDto: SegmentationFileDTO): Promise<SegmentationResponseDTO> {
    const result = await this._httpService.post<SegmentationResponseDTO>(this.apiEndpoint, requestDto);
    return result.data;
  }
}
