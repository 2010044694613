import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { IImplantsService } from '../../shared/implants/iimplants.service';
import { ImplantPlan } from '../../shared/implants/implant';
import { IManufacturersService } from '../../shared/manufacturers';
import { Manufacturer } from '../../shared/manufacturers/manufacturer';

export function useImplantsData(caseId: string) {
  const getAllManufacturers = async () => {
    const manufacturersService = container.get<IManufacturersService>(INJECTED_TYPES.IManufacturersService);
    const fetchedManufacturers: Manufacturer[] = await manufacturersService.getAll(caseId);
    return fetchedManufacturers;
  };

  const getAllImplants = async () => {
    const implantsService = container.get<IImplantsService>(INJECTED_TYPES.IImplantsService);
    const fetchedImplants: ImplantPlan[] = await implantsService.getAll(caseId);
    return fetchedImplants;
  };

  return { getAllImplants, getAllManufacturers } as const;
}
