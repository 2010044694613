import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as EvolutionIcon } from '../../../assets/icons/evolution.svg';

import { useTranslation } from 'react-i18next';
import { AttachmentState } from '../../../common';
import { ApplyIprState, IprLabel } from '../../../common/evergine';
import { Shift } from '../dentalMovements';
import { EvolutionPanelInfo } from './EvolutionPanelInfo';
import './evolutionPanel.scss';
import { EvolutionTable } from './evolutionTable/EvolutionTable';

interface EvolutionPanelProps {
  opened: boolean;
  shift: Shift;
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (list: IprLabel[]) => void;
  onClickClose: () => void;
  getWidthFunction: (getWidth: () => number) => void;
}

export type EvolutionTooth = {
  fdi: number;
  isExtracted: boolean;
  isMoved: boolean;
  isPassiveAligner: boolean;
};

export type EvolutionInterdentalDistance = {
  leftToothFdi: number;
  rightToothFdi: number;
  distance: number;
  applyIprState: ApplyIprState;
  isSpaceVisible: boolean;
};

export type EvolutionAttachment = {
  toothFdi: number;
  state: AttachmentState;
};

export type EvolutionStep = {
  index: number;
  teeth: EvolutionTooth[];
  attachments: EvolutionAttachment[];
  interdentalDistances: EvolutionInterdentalDistance[];
};

export function EvolutionPanel({
  opened,
  shift,
  steps,
  applyIPRList,
  onClickClose,
  getWidthFunction,
  updateIPRList
}: EvolutionPanelProps) {
  const [t] = useTranslation();
  const evolutionPanelRef = useRef(null);

  const getWidth = useCallback(() => {
    if (evolutionPanelRef.current) {
      const width = evolutionPanelRef.current.getBoundingClientRect().width;
      return width;
    }
    return 0;
  }, [evolutionPanelRef.current, steps]);

  const classes = classNames('evolutionpanel', {
    opened,
    ['shift-1']: shift === Shift.InfoPanelOpened
  });

  useEffect(() => {
    if (!evolutionPanelRef?.current) {
      return;
    }

    getWidthFunction(getWidth);
  }, [getWidth, evolutionPanelRef?.current]);

  return (
    <div className={classes} ref={evolutionPanelRef}>
      <div className="evolutionpanel-container">
        <div className="evolutionpanel-header">
          <div className="evolutionpanel-tools">
            <div className="evolutionpanel-top">
              <div>
                <EvolutionIcon /> <span className="evolutionpanel-top-title">{t('evolutionPanel.title')}</span>
              </div>
              <div>
                <EvolutionPanelInfo />
                <CloseIcon className="clickable" onClick={onClickClose} />
              </div>
            </div>
          </div>
        </div>
        <EvolutionTable steps={steps} updateIPRList={updateIPRList} applyIPRList={applyIPRList} opened={opened} />
      </div>
    </div>
  );
}
