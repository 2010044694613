import { useTranslation } from 'react-i18next';
import { ItemChanges } from './ItemChanges/ItemChanges';

import { ItemModifications } from '../../../../models/HistoryChanges';

import './listChanges.scss';

type ListChangesProps = {
  items: ItemModifications[];
};

export function ListChanges({ items }: ListChangesProps) {
  const [t] = useTranslation();
  return (
    <div className="list-group list-group-flush list-changes">
      <li className="list-group-item">
        <span className="head-item">{t('changes.modify')}</span>
        <span className="head-item">{t('changes.piece')}</span>
      </li>
      {items.map((item, index) => (
        <ItemChanges description={item.description} piece={item.piece} key={`key-${index}`} />
      ))}
    </div>
  );
}
