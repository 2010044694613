import { useEffect, useState } from 'react';
import { useCaseData } from './useCaseData';

type Affectation = {
  hasUpperAffectation: boolean;
  hasLowerAffectation: boolean;
};

export function useTeetharchAffectation(caseId: string) {
  const { patientCase } = useCaseData(caseId);
  const pieces = patientCase?.patient.pieces || [];
  const [affectations, setAffectations] = useState<Affectation>({
    hasUpperAffectation: false,
    hasLowerAffectation: false
  });

  useEffect(() => {
    const calculateTeetharchAffectation = () => {
      if (pieces.length > 0) {
        setAffectations({
          hasUpperAffectation: pieces.some((piece) => parseInt(piece.number) <= 28),
          hasLowerAffectation: pieces.some((piece) => parseInt(piece.number) > 28)
        });
      }
    };

    calculateTeetharchAffectation();
  }, [pieces]);

  return { affectations } as const;
}
