export enum ModalTypes {
  ModalNextStep = 'ModalNextStep',
  ModalPreviousStep = 'ModalPreviousStep',
  ModalError = 'ModalError',
  ModalImplant = 'ModalImplant',
  ModalNextStepDirect = 'ModalNextStepDirect',
  ModalClientValidationsValidate = 'ModalClientValidationsValidate',
  ModalClientValidationsEdit = 'ModalClientValidationsEdit',
  ModalClientValidationsReject = 'ModalClientValidationsReject',
  ModalClientValidationsSendChanges = 'ModalClientValidationsSendChanges',
  ModalClientValidationsCancelChanges = 'ModalClientValidationsCancelChanges',
  ModalConfirmDeleteVersion = 'ModalConfirmDeleteVersion',
  ModalAutoClosed = 'ModalAutoClosed',
  ModalOnlyOneFileInformation = 'ModalOnlyOneFileInformation',
  ModalPreviousCasePhaseModified = 'ModalPreviousCasePhaseModified',
  ModalNoMissingTeethInformation = 'ModalNoMissingTeethInformation',
  ModalPublishCase = 'ModalPublishCase',
  ModalDownloadCase = 'ModalDownloadCase',
  ModalDeleteAttach = 'ModalDeleteAttach',
  ModalModificationsMade = 'ModalModificationsMade'
}
