import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export function ModalNoMissingTeethInformation() {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { setIsTeethSegmentationConfirmed } = useOrthBoundStore();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = () => {
    setIsTeethSegmentationConfirmed(true);
  };

  return (
    <div className="modal-content" data-testid={'modal-noMissingTeethInformation'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.noMissingTeethInformation.title`)}</h3>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.return`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.continue`)}
        </button>
      </div>
    </div>
  );
}
