import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Redo from '../../../../../assets/icons/redo.svg';
import { INJECTED_TYPES, NavBarAction, container } from '../../../../../common';
import { ICommandInvoker } from '../../../../../shared';
import { useOrthBoundStore } from '../../../../stores/useStore';

export default (actionProps: any) => {
  const [t] = useTranslation();
  const { canRedo } = useOrthBoundStore();
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);

  const handleRedoClick = useCallback(() => {
    invokeRedo();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === 'y' || event.key === 'Y')) {
        if (canRedo) {
          invokeRedo();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [canRedo]);

  const invokeRedo = useCallback(() => {
    commandInvokerService.redo();
  }, [commandInvokerService]);

  const buttonId = 'redo';

  return (
    <>
      <NavBarAction
        icon={Redo}
        dataFor={buttonId}
        altText={t('navbar.actions.redo')}
        action={handleRedoClick}
        disabled={!canRedo}
        isEndOfBlock={actionProps.block.isEndOfBlock}
      />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.redo')}
      </ReactTooltip>
    </>
  );
};
