import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export function ModalModificationsMade() {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { setIsRecalculateStepsNeeded, setIsRecalculateCancelled } = useOrthBoundStore();

  const onCloseButtonClick = () => {
    setIsRecalculateCancelled(true);
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = () => {
    toggleModalIsOpened('');
    setIsRecalculateStepsNeeded(true);
  };

  return (
    <div className="modal-content" data-testid={'modal-modificationsMade'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.modificationsMade.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.modificationsMade.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.modificationsMade.confirmRecalculate`)}
        </button>
      </div>
    </div>
  );
}
