import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { useCaseId, useChat } from '../../../hooks';
import { useUserProfileData } from '../../../hooks/shared/useUserProfileData';
import { ChatMessage } from '../../../shared/chat';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { ChatComment } from './ChatComment';
import './chatTab.scss';

const MAX_INPUT_CHARACTERS = 500;

export function ChatTab() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { getMessages, addMessage, markMessageAsRead } = useChat(caseId);
  const { messages, setMessages } = useBoundStore();
  const [isLatestToday, setIsLatestToday] = useState<boolean>(false);
  const [userProfile] = useUserProfileData();
  const [input, setInput] = useState('');
  const myRef = useRef(null);

  const executeScroll = () => {
    setTimeout(() => {
      const lastChildElement = myRef.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const isToday = (someDate: Date) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  useEffect(() => {
    const loadServerMessages = async () => {
      const serverComments = await getMessages();
      const serverMessages: ChatMessage[] = [];
      let isFirstToday = false;
      let isLatestToday = false;

      serverComments.forEach((serverComment: ChatMessage) => {
        if (!isLatestToday) {
          isFirstToday = isToday(new Date(serverComment.timestamp));
        }

        serverMessages.push({
          ...serverComment,
          author: serverComment.userId === userProfile.id ? t('chat.me') : serverComment.senderName,
          isMe: serverComment.userId === userProfile.id,
          timestamp: new Date(serverComment.timestamp),
          isFirstMessageToday: isLatestToday ? false : isFirstToday
        } as ChatMessage);

        if (isFirstToday) {
          isLatestToday = true;
          setIsLatestToday(true);
        }
      });

      const lastReceivedMessage = serverMessages
        .filter((message) => !message.isRead)
        .sort(
          (a, b) =>
            Math.abs(new Date(a.timestamp).getTime() - new Date().getTime()) -
            Math.abs(new Date(b.timestamp).getTime() - new Date().getTime())
        )
        .shift();
      if (lastReceivedMessage) {
        //TODO: future implementation of mark message as read
        //markMessageAsRead(lastReceivedMessage.id);
      }

      setMessages(serverMessages);
    };
    loadServerMessages();
  }, [caseId]);

  useEffect(() => {
    executeScroll();
    setInput('');
  }, [messages]);

  const generateMessageToState = () => {
    if (!input) {
      return;
    }

    const message = {
      senderName: `${userProfile?.firstName} ${userProfile?.lastNames}`,
      userId: userProfile?.id,
      author: t('chat.me'),
      body: input.trim(),
      timestamp: new Date(),
      isMe: true,
      caseId: caseId,
      isFirstMessageToday: isLatestToday ? false : true
    } as ChatMessage;
    setMessages([...messages, message]);
    addMessage(message);
    setIsLatestToday(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Enter) {
      generateMessageToState();
    }
  };

  return (
    <div className="chat">
      <div className="chat-messages" ref={myRef}>
        {messages.map((message, index) => (
          <ChatComment
            key={`${message.caseId}-${index}`}
            author={message.author}
            timestamp={message.timestamp}
            body={message.body}
            isMe={message.isMe}
            isFirstMessageToday={message.isFirstMessageToday}
          />
        ))}
      </div>
      <div className="chat-new_message">
        <input
          type="text"
          className="chat-input"
          placeholder={t('chat.writeHere')}
          aria-label={t('chat.writeHere')}
          value={input}
          onInput={(e) => setInput(e.currentTarget.value)}
          onKeyPress={handleKeyPress}
          maxLength={MAX_INPUT_CHARACTERS}
        />
        <span className="chat-icon" onClick={generateMessageToState}>
          <SendIcon className="icon" />
        </span>
      </div>
    </div>
  );
}
