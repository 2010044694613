import React from 'react';
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropdownToggleIcon } from '../../../../assets/icons/arrow-dropdown.svg';
import { ReactComponent as SplitSingleIcon } from '../../../../assets/icons/split-one.svg';
import { ReactComponent as SplitTwoIcon } from '../../../../assets/icons/split-two.svg';
import TickIcon from '../../../../assets/icons/tick.svg';
import { Split } from '../../../../common/evergine/types';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import './splitScreenAction.scss';

export default () => {
  const [t] = useTranslation();
  const { splitMode, setSplitMode } = useBoundStore();
  const [iconToShow, setIconToShow] = useState<number>();

  useEffect(() => {
    const icon = splitMode === Split.Single ? Split.Single : Split.Split2;
    setIconToShow(icon);
  }, [splitMode]);

  return (
    <>
      <Dropdown className="splitscreenaction-dropdown">
        <Dropdown.Toggle variant="language" id="dropdown-basic">
          {Split.Single === iconToShow ? <SplitSingleIcon /> : <SplitTwoIcon />}
          <DropdownToggleIcon className="icon rotate-90 ms-3" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSplitMode(Split.Single)} className="splitscreenaction-dropdown-item">
            <SplitSingleIcon />
            {t('navbar.actions.splitScreen.oneView')}
            {splitMode === Split.Single && (
              <img className="splitscreenaction-dropdown-item--check position-absolute" src={TickIcon} />
            )}
          </Dropdown.Item>
          <Dropdown.Item
            data-testid="lang-button-english"
            onClick={() => setSplitMode(Split.Split2)}
            className="splitscreenaction-dropdown-item"
          >
            <SplitTwoIcon />
            {t('navbar.actions.splitScreen.twoView')}
            {splitMode === Split.Split2 && (
              <img className="splitscreenaction-dropdown-item--check position-absolute" src={TickIcon} />
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
