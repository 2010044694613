import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaptureToUpload, IFileService } from '../../shared/file';
import { useBase64Converter } from '../useBase64Converter';

export function useUploadFile(caseId: string) {
  const { getBase64FromArrayBuffer } = useBase64Converter();
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);

  const sendFile = async (file: CaptureToUpload, content: string, versionId: string) => {
    await fileService.uploadFile(caseId, versionId, file.type, {
      key: file.type,
      originalName: file.name,
      totalSize: file.size,
      mimeType: 'application/octet-stream',
      file: content
    });
  };

  const uploadFileFromBlobURL = async (file: CaptureToUpload, versionId: string): Promise<void> => {
    const res = await fetch(file.path);
    const blobData = await res.blob();
    const reader = new FileReader();
    const sendOnLoad = new Promise((resolve, reject) => {
      reader.onload = async (_) => {
        const response = await sendFile(file, (reader.result as string).split(',')[1], versionId);
        resolve(response);
      };
    });

    reader.readAsDataURL(blobData);
    await sendOnLoad;
  };

  const uploadFileFromFS = async (file: CaptureToUpload, versionId: string): Promise<void> => {
    const arrayBuffer = Module.FS.readFile(file.path);
    const base64content = getBase64FromArrayBuffer(arrayBuffer);

    await sendFile(file, base64content, versionId);
  };

  return {
    uploadFileFromFS,
    uploadFileFromBlobURL
  } as const;
}
