import { useTranslation } from 'react-i18next';
import { PatientCase } from '../../../shared';
import { DataCardBase, LabelValue } from '../../../surgeries/components';

type PatientDataCardProps = {
  patientCase: PatientCase;
  noCard?: boolean;
};

export function SpecificInfoIndicationsCard({ patientCase, noCard }: PatientDataCardProps) {
  const [t] = useTranslation();

  return (
    <DataCardBase noCard={noCard} title={t('specificInfo.indications')}>
      <LabelValue value={patientCase.specificInfo?.indications} />
    </DataCardBase>
  );
}
