import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ClientPlanValidationDTO } from './clientPlanValidation';
import { IClientPlanValidationService } from './iclientPlanValidation.service';
import { useCommonBoundStore } from '../../common/stores/useStore';

@injectable()
export class ClientPlanValidationService implements IClientPlanValidationService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  private readonly apiEndpoint = '/api/product-cases';

  public async clientPlanValidation(
    caseId: string,
    versionId: string,
    clientPlanValidation: ClientPlanValidationDTO
  ): Promise<void> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}/acceptance`;
    await this._httpService.post<ClientPlanValidationDTO>(url, clientPlanValidation);
    useCommonBoundStore.setState({ isThereFeedbackForTreatment: true });
  }
}
