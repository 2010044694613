import JSZip from 'jszip';
import { ChangeEvent, useCallback, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import Upload3DFileIcon from '../../../../../assets/icons/3D.svg';
import { CapturePosition, DentalMovementGroup, Model3dType, NavBarAction } from '../../../../../common';
import { Model } from '../../../../../common/evergine/types';
import { useFiles } from '../../../../../hooks/useFiles';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../../../stores/useStore';

export default function UploadNewModelFileAction(actionProps: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isDir, isFile } = useFiles();
  const { setLowerDentalMovements, setUpperDentalMovements } = useOrthBoundStore();
  const { setWebBusy } = useBoundStore();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setWebBusy(true);
    const file = event.target.files[0];
    const casePath = 'DynamicModels';
    const caseInputFullPath = `/Content/${casePath}`;
    const upperMovementsFileName = 'upperMovements.json';
    const lowerMovementsFileName = 'lowerMovements.json';

    if (!isDir(caseInputFullPath)) {
      Module.FS.mkdir(caseInputFullPath);
    }

    const unzipper = new JSZip();
    const zipData = await unzipper.loadAsync(file);
    console.log(`Extracting ${Object.keys(zipData.files).length} files`);

    const promises: Promise<void>[] = [];
    zipData.forEach((_, zipEntry) => {
      const fullPath = `${caseInputFullPath}/${zipEntry.name}`;
      promises.push(
        zipEntry.async('uint8array').then((fileData) => {
          const binData = new Int8Array(fileData);
          try {
            if (isDirZip(zipEntry.name)) {
              const dirName = `${caseInputFullPath}/${getDirName(zipEntry.name)}`;
              if (!isDir(dirName)) {
                Module.FS.mkdir(dirName);
              }
            }
            if (!isDir(fullPath)) {
              Module.FS.writeFile(fullPath, binData);
            }
          } catch (error) {
            console.error(`Error writing to the file "${fullPath}":`, error);
          }
        })
      );
    });

    await Promise.all(promises);

    const filesObject = (await Module.FS.open(caseInputFullPath)).node.contents;
    const files = Object.values(filesObject) as File[];
    const modelsToLoad: Model[] = [];

    files.forEach((file) => {
      if (!file.name.endsWith('json')) {
        const filePath = `${caseInputFullPath}/${file.name}`;
        modelsToLoad.push({
          id: file.name.replace('.wepmd', ''),
          uri: filePath,
          teethArch: CapturePosition.LOWER,
          model3dType: file.name.startsWith('ctooth') ? Model3dType.Tooth : Model3dType.Gum
        });
      }
    });

    await window.App.webEventsProxy.common.loadModels(modelsToLoad);

    const upperMovementsFullPath = `${caseInputFullPath}/${upperMovementsFileName}`;
    if (isFile(upperMovementsFullPath)) {
      loadMovements(upperMovementsFullPath, true);
      setWebBusy(false);
    }

    const lowerMovementsFullPath = `${caseInputFullPath}/${lowerMovementsFileName}`;
    if (isFile(lowerMovementsFullPath)) {
      setTimeout(() => {
        loadMovements(lowerMovementsFullPath, false);
        setWebBusy(false);
      });
    }
  };

  const loadMovements = useCallback(
    async (filePath: string, isUpper: boolean) => {
      const movementsFile = Module.FS.readFile(filePath);
      const movementsFileString = getStringFrom(movementsFile);
      const movements = JSON.parse(movementsFileString) as DentalMovementGroup;
      if (isUpper) {
        await window.App.webEventsProxy.movements.setMovements(movements, false);
        setUpperDentalMovements(movements);
      } else {
        await window.App.webEventsProxy.movements.setMovements(movements, false);
        setLowerDentalMovements(movements);
      }
    },
    [setLowerDentalMovements, setUpperDentalMovements]
  );

  const getStringFrom = (data: ArrayBuffer) => {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(data);
  };

  const isDirZip = (path: string) => {
    return path.split('/').length > 1;
  };

  const getDirName = (path: string) => {
    return path.split('/')[0];
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const isLocalEnvironment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  if (!isLocalEnvironment) {
    return <></>;
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={async (event: ChangeEvent<HTMLInputElement>) => await handleFileChange(event)}
        accept=".zip"
        style={{ display: 'none' }}
      />
      <NavBarAction
        dataFor="New model"
        icon={Upload3DFileIcon}
        altText="Upload mock file"
        action={handleButtonClick}
        isEndOfBlock={actionProps.block.isEndOfBlock}
      />
      <ReactTooltip id="New model" place="bottom" effect="solid" className="tooltip" type="dark">
        Provisional upload of new model
      </ReactTooltip>
    </>
  );
}
