import React from 'react';

import './remarkedValueLabel.scss';

interface RemarkedValueLabelProps {
  value: string;
  label: string;
}

export function RemarkedValueLabel({ value, label }: RemarkedValueLabelProps) {
  return (
    <div className="remarkedvaluelabel">
      <div className="remarkedvaluelabel-value">{value}</div>
      <div className="remarkedvaluelabel-label">{label}</div>
    </div>
  );
}
