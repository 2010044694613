import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { AttachmentType, IconTextInfo } from '../../../../../../common';

import { useMemo } from 'react';
import { useUtils } from '../../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import './attacheModel.scss';

interface AttacheModelProps {
  model: IconTextInfo;
}

export function AttacheModel({ model }: AttacheModelProps) {
  const [t] = useTranslation();
  const { updateMovements, noTeethSelected } = useUtils();
  const selectedTeethId = useOrthBoundStore((state) => state.selectedTeethId);
  const attachActive = useOrthBoundStore((state) => state.attachActive);
  const setAttachActive = useOrthBoundStore((state) => state.setAttachActive);

  const handleAttach = (teeth: number, type: AttachmentType) => {
    setAttachActive(type);
    window.App.webEventsProxy.attachments.addAttachment(teeth, type);
    updateMovements();
  };

  const isActive = useMemo(() => {
    if (attachActive === model.typeAtt) {
      return true;
    }
    return false;
  }, [attachActive]);

  return (
    <div className="attachemodel" key={model.id}>
      <button
        data-for={model.id}
        data-tip
        id={model.id}
        disabled={noTeethSelected}
        className={`etoolbar-button clickable ${isActive ? 'is-active' : ''}`}
        onClick={() => handleAttach(selectedTeethId, model.typeAtt)}
      >
        <div>{<model.icon />}</div>
        <ReactTooltip id={model.id} place="top" effect="solid" className="tooltip" type="dark">
          {t(model.text)}
        </ReactTooltip>
      </button>
    </div>
  );
}
