import React from 'react';
import { useTranslation } from 'react-i18next';
import './navBarAction.scss';

interface ActionProps {
  icon?: string;
  IconComponent?: React.FC<any>;
  altText: string;
  dataFor?: string;
  disabled?: boolean;
  isEndOfBlock?: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  action?: (param?: any) => void;
}

export function NavBarAction({ icon, altText, action, dataFor, IconComponent, disabled, isEndOfBlock }: ActionProps) {
  const [t] = useTranslation();

  return (
    <button
      onClick={action ? () => action() : null}
      className={`btn btn-action navbar-actions-item ${isEndOfBlock ? 'blockend' : ''}`}
      data-tip
      data-for={dataFor}
      disabled={disabled}
    >
      {icon && <img alt={t(altText)} src={icon} />}
      {IconComponent && <IconComponent />}
    </button>
  );
}
