import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentType } from '../../common';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

export function useAttaches() {
  const [t] = useTranslation();
  const positionAttachTooltip = useOrthBoundStore((state) => state.positionAttachTooltip);
  const typeTooltip = useMemo(() => {
    if (!positionAttachTooltip) {
      return;
    }
    switch (positionAttachTooltip.type) {
      case AttachmentType.RotationRight:
        return t('attachesPanel.rotationOpRight');
      case AttachmentType.RotationLeft:
        return t('attachesPanel.rotationOpLeft');
      case AttachmentType.ExtrusionAnteriorConventional:
        return t('attachesPanel.extrusionConAnterior');
      case AttachmentType.ExtrusionAnteriorOptimized:
        return t('attachesPanel.extrusionOpAnterior');
      case AttachmentType.ExtrusionPosteriorOptimized:
        return t('attachesPanel.extrusionOpPosterior');
      case AttachmentType.RectangularHorizontalOptimized:
        return t('attachesPanel.rectangularCon');
      case AttachmentType.RectangularVerticalOptimized:
        return t('attachesPanel.rectangularOp');
      case AttachmentType.RectangularLeftBeveled:
        return t('attachesPanel.rectangularOpBevLeft');
      case AttachmentType.RectangularRightBeveled:
        return t('attachesPanel.rectangularOpBevRight');
      case AttachmentType.RadicularControlDoubleOne:
        return t('attachesPanel.rootControlDoubleLeft');
      case AttachmentType.RadicularControlDoubleTwo:
        return t('attachesPanel.rootControlDoubleRight');
      case AttachmentType.RadicularControlSimple:
        return t('attachesPanel.rootControlSimple');
      case AttachmentType.ControlRetentionOptimized:
        return t('attachesPanel.controlOpRetention');
      case AttachmentType.ControlSupportOptimized:
        return t('attachesPanel.controlOpSupport');
      case AttachmentType.ControlExpansionOptimized:
        return t('attachesPanel.controlOpExpansion');
      case AttachmentType.BiteLiftAnterior:
        return t('attachesPanel.biteRampAnterior');
      case AttachmentType.BiteLiftPosterior:
        return t('attachesPanel.biteRampPosterior');
      default:
        return '';
    }
  }, [positionAttachTooltip]);

  const positionTooltip = useMemo(() => {
    if (!positionAttachTooltip) {
      return;
    }
    return {
      y: positionAttachTooltip.y,
      x: positionAttachTooltip.x
    };
  }, [positionAttachTooltip]);

  return { typeTooltip, positionTooltip };
}
