import { useEvergineStore } from 'evergine-react';
import JSZip from 'jszip';
import { useCallback, useEffect } from 'react';
import {
  CapturePosition,
  DentalMovementGroup,
  INJECTED_TYPES,
  Model,
  Model3dType,
  Stage,
  container
} from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { CaseFile, ICommandInvoker, PatientCase } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useFiles } from '../useFiles';
import { useCaseData } from './useCaseData';
import { useGetDentalMovements } from './useGetDentalMovements';
import { useGetFiles } from './useGetFiles';
import { useLoadFilesIntoFS } from './useLoadFiles';

export function useRenderModels(caseId: string, stage: Stage) {
  const { loadUpperModel3DInFS, loadLowerModel3DInFS } = useLoadFilesIntoFS();
  const { isFile } = useFiles();
  const {
    lowerModel3DId,
    upperModel3DId,
    hasArchModelsLoaded,
    setHasArchModelsLoaded,
    setLowerModel3DId,
    setUpperModel3DId
  } = useCommonBoundStore();
  const { evergineReady } = useEvergineStore();
  const { setWebBusy } = useBoundStore();
  const {
    currentVersion,
    upperDentalMovements,
    lowerDentalMovements,
    areSegmentationModelsInFS,
    areTeethModelsLoaded,
    previousStage,
    setLowerDentalMovements,
    setUpperDentalMovements,
    setAreMovementsLoaded,
    setStageIsLoaded,
    setIsInFinalPositionStage,
    setAreTeethModelsLoaded,
    setAreChangesMadeOnTeeth,
    setPreviousStage,
    setShowMovements,
    setShowAttachesPanel,
    setAxixRootsEditMode
  } = useOrthBoundStore();
  const { patientCase } = useCaseData(caseId);
  const { getFileInfo } = useGetFiles(caseId);
  const { isDir } = useFiles();
  const { getDentalMovementsFromEvergine, getDentalMovementsFromBackend } = useGetDentalMovements();
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);

  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;

  const stagesWithArchModels = [Stage.LoadSTL, Stage.TeethSegmentation];
  const stagesWithDentalMovements = [
    Stage.AxisAndRoots,
    Stage.FinalPosition,
    Stage.IntermediateSteps,
    Stage.IPR,
    Stage.Attaches,
    Stage.Publish
  ];

  const isStageWithArchModels = (stage: Stage) => stagesWithArchModels.includes(stage);
  const isStageWithDentalMovements = (stage: Stage) => stagesWithDentalMovements.includes(stage);

  useEffect(() => {
    commandInvokerService.clear();
  }, [stage]);

  useEffect(() => {
    setWebBusy(true);

    const handleLoadStage = async (): Promise<void> => {
      setIsInFinalPositionStage(false);
      if (!evergineReady || !patientCase || currentVersion === null) {
        return;
      }
      setStageIsLoaded(false);
      setAreMovementsLoaded(false);
      let areDentalMovementsLoadedInEvergine = false;

      let lowerMovements = lowerDentalMovements;
      let upperMovements = upperDentalMovements;
      if (isStageWithDentalMovements(stage)) {
        // TODO: revisar para casos en los que haya tratamiento de una sóla arcada
        if (!lowerMovements || !upperMovements) {
          let movements = getDentalMovementsFromEvergine();

          if (movements.lowerMovements || movements.upperMovements) {
            areDentalMovementsLoadedInEvergine = true;
            setAreMovementsLoaded(true);
          }

          if (!movements.upperMovements && !movements.lowerMovements) {
            movements = await getDentalMovementsFromBackend(currentVersion);
          }

          if (movements.upperMovements) {
            upperMovements = movements.upperMovements;
            setUpperDentalMovements(upperMovements);
          }

          if (movements.lowerMovements) {
            lowerMovements = movements.lowerMovements;
            setLowerDentalMovements(lowerMovements);
          }
        } else {
          if (lowerMovements || upperMovements) {
            // ToDo: optimize to avoid load models and set dental movements when already in Evergine
            areDentalMovementsLoadedInEvergine = true;
            setAreMovementsLoaded(true);
          }
        }
      }

      const forceSetMovements =
        (previousStage === Stage.TeethSegmentation || previousStage === undefined) && stage === Stage.AxisAndRoots;

      // 1 loadModels
      // 1a Models as one wepmd per jaw (stl-captures and teeth-segmentation screens)
      if (isStageWithArchModels(stage) && !hasArchModelsLoaded) {
        await renderBothModels3D(patientCase);
        setHasArchModelsLoaded(true);
      }

      // 1b Models as one wepmd per teeth/gum
      if (isStageWithDentalMovements(stage) && !areTeethModelsLoaded) {
        await loadModels(upperMovements, lowerMovements);
        setAreTeethModelsLoaded(true);
      }

      // 2 setMovements
      if ((isStageWithDentalMovements(stage) && !areDentalMovementsLoadedInEvergine) || forceSetMovements) {
        await loadDentalMovements(upperMovements, lowerMovements);
        setAreMovementsLoaded(true);
      }

      // 3 setstage
      window.App.webEventsProxy.common.setStage(stage);

      setStageIsLoaded(true);
      setWebBusy(false);

      // Workaround
      setIsInFinalPositionStage(stage === Stage.FinalPosition);
      // Reset states
      //window.App.webEventsProxy.axisAndRoots.enableEditionForToothRotationAxis(false);
      setAxixRootsEditMode(false);
      setAreChangesMadeOnTeeth(false);
      setPreviousStage(stage);
      setShowMovements(false);
      setShowAttachesPanel(false);
    };

    handleLoadStage().catch(console.error);
  }, [evergineReady, patientCase, stage, currentVersion]);

  const getModelInfo = useCallback(
    async (teethArch: CapturePosition): Promise<CaseFile | null> => {
      const modelId = teethArch === CapturePosition.UPPER ? upperModel3DId : lowerModel3DId;

      if (modelId) {
        return {
          name: modelId
        } as CaseFile;
      }

      const modelInfo = await getFileInfo(
        teethArch == CapturePosition.UPPER ? 'upper-wepmd' : 'lower-wepmd',
        currentVersion.id
      );

      return modelInfo;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const loadModel3D = useCallback(
    async (patientCase: PatientCase, teethArch: CapturePosition): Promise<Model | undefined> => {
      if (!patientCase) {
        return undefined;
      }

      if (!patientCase.scannings) {
        return undefined;
      }

      const modelInfo = await getModelInfo(teethArch);

      if (modelInfo?.name) {
        const fileName = `${modelInfo.name}`;
        const fileFSFullPath = `${dynamicModelsFullPath}/${fileName}`;
        const isFileInFS = isFile(fileFSFullPath);

        if (!isFileInFS) {
          const loadModelInFS = teethArch === CapturePosition.UPPER ? loadUpperModel3DInFS : loadLowerModel3DInFS;
          await loadModelInFS(patientCase, modelInfo);
        }

        const model = {
          id: fileName.replace('.wepmd', ''),
          uri: `${dynamicModels}/${fileName}`,
          teethArch: teethArch,
          model3dType: Model3dType.Scan
        };

        return model;
      }
    },
    [currentVersion]
  );

  const renderBothModels3D = useCallback(
    async (patientCase: PatientCase): Promise<void> => {
      const upperModel = await loadModel3D(patientCase, CapturePosition.UPPER);
      const lowerModel = await loadModel3D(patientCase, CapturePosition.LOWER);

      if (upperModel === undefined && lowerModel === undefined) {
        return;
      }

      const modelsToLoad: Model[] = [];
      const modelInstances = [];

      if (upperModel) {
        modelsToLoad.push(upperModel);
        modelInstances.push({ modelId: upperModel.id });
        setUpperModel3DId(upperModel.id);
      }

      if (lowerModel) {
        modelsToLoad.push(lowerModel);
        modelInstances.push({ modelId: lowerModel.id });
        setLowerModel3DId(lowerModel.id);
      }

      await window.App.webEventsProxy.common.loadModels(modelsToLoad);
    },
    [loadModel3D, currentVersion, setLowerModel3DId, setUpperModel3DId]
  );

  const saveTeethModelsFromBackendInFS = useCallback(async (): Promise<void> => {
    if (currentVersion === null) {
      return;
    }

    const fileInfo = await getFileInfo('teeth-zip', currentVersion.id);

    if (!fileInfo) {
      return;
    }

    const resFile = await fetch(fileInfo.url);
    const arrayBufferData = await resFile.arrayBuffer();

    const unzipper = new JSZip();
    const zipData = await unzipper.loadAsync(arrayBufferData);

    const promises: Promise<void>[] = [];

    zipData.forEach(async (_, zipEntry) => {
      if (!zipEntry.dir) {
        promises.push(
          (async (): Promise<void> => {
            const fileData = await zipEntry.async('uint8array');
            const binData = new Int8Array(fileData);
            const fileFullPath = `${dynamicModelsFullPath}/${zipEntry.name}`;
            Module.FS.writeFile(fileFullPath, binData);
          })()
        );
      }
    });

    await Promise.all(promises);
  }, [currentVersion]);

  const getModelFiles = (dentalMovements: DentalMovementGroup): Model[] => {
    if (!dentalMovements) {
      return [];
    }

    const modelFiles: Model[] = [];

    dentalMovements.teeth.forEach((t) => {
      const filePath = `${dynamicModels}/${t.id}.wepmd`;
      modelFiles.push({
        id: t.id.replace('.wepmd', ''),
        uri: filePath,
        teethArch: dentalMovements.teethArch,
        model3dType: Model3dType.Tooth
      });
    });

    const gum = dentalMovements.gum;
    modelFiles.push({
      id: gum.id,
      uri: `${dynamicModels}/${gum.id}.wepmd`,
      teethArch: dentalMovements.teethArch,
      model3dType: Model3dType.Tooth
    });

    return modelFiles;
  };

  const loadDentalMovements = useCallback(
    async (upperMovements: DentalMovementGroup, lowerMovements: DentalMovementGroup): Promise<void> => {
      let movementsAreLoaded = false;
      if (upperMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(upperMovements, false);
        movementsAreLoaded = true;
      }

      if (lowerMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(lowerMovements, false);
        movementsAreLoaded = true;
      }

      if (movementsAreLoaded) {
        setAreMovementsLoaded(true);
      }
    },
    [setAreMovementsLoaded]
  );

  const loadModels = useCallback(
    async (upperDentalMovements: DentalMovementGroup, lowerDentalMovements: DentalMovementGroup): Promise<void> => {
      const dynamicModelsFullPath = '/Content/DynamicModels';

      if (!isDir(dynamicModelsFullPath)) {
        Module.FS.mkdir(dynamicModelsFullPath);
      }

      if (!areSegmentationModelsInFS) {
        await saveTeethModelsFromBackendInFS();
      }

      const modelFiles = [...getModelFiles(upperDentalMovements), ...getModelFiles(lowerDentalMovements)];
      await window.App.webEventsProxy.common.loadModels(modelFiles);
    },
    [areSegmentationModelsInFS, currentVersion]
  );
}
