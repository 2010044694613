import { StateCreator } from 'zustand';
import { DentalMovementGroup, ToothHoverData, ToothTransformInfo } from '../../../common';

export interface DentalMovementsSlice {
  upperDentalMovements: DentalMovementGroup;
  setUpperDentalMovements: (data: DentalMovementGroup) => void;
  lowerDentalMovements: DentalMovementGroup;
  setLowerDentalMovements: (data: DentalMovementGroup) => void;
  showMiniOcclusogram: boolean;
  setShowMiniOcclusogram: (newValue: boolean) => void;
  selectedTeethTransformData: ToothTransformInfo;
  setSelectedTeethTransformData: (newValue: ToothTransformInfo) => void;
  teethTransformDataList: ToothTransformInfo[];
  setTeethTransformDataList: (newValue: ToothTransformInfo[]) => void;
  selectedTeethId: number;
  setSelectedTeethId: (newValue: number) => void;
  toothHoverData: ToothHoverData;
  setToothHoverData: (newValue: ToothHoverData) => void;
  isCasePublished: boolean;
  setIsCasePublished: (newValue: boolean) => void;
}

export const createDentalMovementsSlice: StateCreator<DentalMovementsSlice, [], [], DentalMovementsSlice> = (set) => ({
  upperDentalMovements: null,
  lowerDentalMovements: null,
  showMiniOcclusogram: false,
  selectedTeethTransformData: null,
  selectedTeethId: null,
  toothHoverData: null,
  teethTransformDataList: [],
  setUpperDentalMovements: (upperDentalMovements: DentalMovementGroup) => set((_) => ({ upperDentalMovements })),
  setLowerDentalMovements: (lowerDentalMovements: DentalMovementGroup) => set((_) => ({ lowerDentalMovements })),
  setShowMiniOcclusogram: (showMiniOcclusogram: boolean) => set((_) => ({ showMiniOcclusogram })),
  setSelectedTeethTransformData: (selectedTeethTransformData: ToothTransformInfo) =>
    set((_) => ({ selectedTeethTransformData })),
  setSelectedTeethId: (selectedTeethId: number) => set((_) => ({ selectedTeethId })),
  setToothHoverData: (toothHoverData: ToothHoverData) => set((_) => ({ toothHoverData })),
  setTeethTransformDataList: (teethTransformDataList: ToothTransformInfo[]) => set((_) => ({ teethTransformDataList })),
  isCasePublished: false,
  setIsCasePublished: (isCasePublished: boolean) => set((_) => ({ isCasePublished }))
});
