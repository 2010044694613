import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reflect-metadata';
import App from './App';
import './app.i18n';
import { initializeEvergine } from './common/evergine/evergine-initialize';

// Initialize Evergine
Blazor.start().then(() => initializeEvergine());

(async () => {
  ReactDOM.render(
    <Suspense fallback={<>Loading...</>}>
      <App />
      <ToastContainer
        position="bottom-right"
        limit={5}
        autoClose={8000}
        style={{
          width: 'auto',
          minWidth: '320px',
          maxWidth: '500px'
        }}
      />
    </Suspense>,
    document.getElementById('main')
  );
})();
