import { useCallback } from 'react';
import { OrthodonticsPhasesKeys } from '../../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';

export function useSaveAxisAndRoots(): SaveChangesHook {
  const setMesioDistalLength = useOrthBoundStore((state) => state.setMesioDistalLength);
  const { saveProgressInBackend } = useSaveProgress();
  const { setIsAutoAxisRootsConfirmed, setUpperDentalMovements, setLowerDentalMovements } = useOrthBoundStore();

  const saveChanges = useCallback(async (): Promise<boolean> => {
    let response;
    try {
      const res = await window.App.webEventsProxy.axisAndRoots.calculateMesioDistalLengthForTeeth();
      if (res) {
        setMesioDistalLength(res);
        response = true;
      } else {
        response = false;
      }

      const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

      setUpperDentalMovements(upperMovements);
      setLowerDentalMovements(lowerMovements);

      await saveProgressInBackend(
        { lower: lowerMovements, upper: upperMovements },
        OrthodonticsPhasesKeys.AxisAndRoots
      );
    } catch (error) {
      response = false;
    } finally {
      setIsAutoAxisRootsConfirmed(false);
      return Promise.resolve(response);
    }
  }, [setMesioDistalLength, saveProgressInBackend]);

  return { saveChanges };
}
