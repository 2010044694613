import { useCallback } from 'react';
import { DentalMovementGroup, INJECTED_TYPES, container } from '../../common';
import { CaseVersion, CaseVersionState, IDentalMovementsService } from '../../shared';

export function useGetDentalMovements() {
  const dentalMovementsService = container.get<IDentalMovementsService>(INJECTED_TYPES.IDentalMovementsService);

  const getDentalMovementsFromEvergine = useCallback((): {
    upperMovements: DentalMovementGroup | null;
    lowerMovements: DentalMovementGroup | null;
  } => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    return {
      upperMovements,
      lowerMovements
    };
  }, []);

  const getDentalMovementsFromBackend = useCallback(
    async (
      version: CaseVersion
    ): Promise<{ upperMovements: DentalMovementGroup | null; lowerMovements: DentalMovementGroup | null }> => {
      const movements = await dentalMovementsService.getTreatmentPlanificationMovements(version.caseId, version.id);

      if (movements.upper !== null || movements.lower !== null) {
        return Promise.resolve({
          upperMovements: movements.upper,
          lowerMovements: movements.lower
        });
      }

      return Promise.resolve({
        upperMovements: null,
        lowerMovements: null
      });
    },
    []
  );

  const getDentalMovements = useCallback(
    async (
      version: CaseVersion
    ): Promise<{ upperMovements: DentalMovementGroup | null; lowerMovements: DentalMovementGroup | null }> => {
      const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

      if (upperMovements !== null && lowerMovements !== null) {
        return {
          upperMovements,
          lowerMovements
        };
      }

      const movements = await dentalMovementsService.getTreatmentPlanificationMovements(version.caseId, version.id);

      if (movements.upper !== null || movements.lower !== null) {
        return {
          upperMovements: movements.upper,
          lowerMovements: movements.lower
        };
      }

      return {
        upperMovements: null,
        lowerMovements: null
      };
    },
    []
  );

  const updateTreatmentState = useCallback(
    async (caseId: string, version: CaseVersion, state: CaseVersionState, isResetingPlan = false) => {
      dentalMovementsService.updateTreatmentState(caseId, version, state, isResetingPlan);
    },
    []
  );

  return { getDentalMovements, updateTreatmentState, getDentalMovementsFromEvergine, getDentalMovementsFromBackend };
}
