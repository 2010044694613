import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as DropdownToggleIcon } from '../../../../assets/icons/arrow-dropdown.svg';
import TickIcon from '../../../../assets/icons/tick.svg';
import './custom-dropdown.scss';
import classNames from 'classnames';
import { useState } from 'react';

export interface DropdownItem {
  id: string;
  text: string;
  isSelected: boolean;
}

interface DropdownProps {
  items: DropdownItem[];
  withBorder?: boolean;
  stretch?: boolean;
  onClick: (selectedItem: string) => void;
  disabled?: boolean;
  customClass?: string;
}

export function CustomDropdown(props: DropdownProps) {
  const { items, withBorder, stretch, onClick, disabled, customClass } = props;
  const [selected, setSelected] = useState(items?.find((i) => i.isSelected).id);

  const classes = classNames('customdropdown', {
    bordered: withBorder,
    stretch: stretch
  });

  const onSelectOption = (id: string) => {
    onClick(id);
    setSelected(id);
  };

  return (
    <Dropdown className={`${classes} ${customClass}`}>
      <Dropdown.Toggle variant="customdropdown" id="customdropdown-basic" disabled={disabled}>
        <span>{items.find((i) => i.id === selected).text}</span>
        <DropdownToggleIcon className="icon rotate-90 ms-5" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {items?.map((item, i) => {
          const id = item.id;
          return (
            <Dropdown.Item key={id} onClick={() => onSelectOption(item.id)} className="customdropdown-item">
              <span>{item.text}</span>
              {item.id === selected && <img className="customdropdown-item--check" src={TickIcon} />}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
