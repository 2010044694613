import { useEvergineStore } from 'evergine-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalDivider } from '../../../common/components';
import { CapturePosition, MatchingInfo, Model3dType, Stage } from '../../../common/evergine/types';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useCaseData, useCaseId, useLoadFilesIntoFS } from '../../../hooks';
import { useFiles } from '../../../hooks/useFiles';
import { TeethArchPosition } from '../../../models';
import { useBoundStore } from '../../stores/useStore';
import { AddImplantControl, Controls, DeleteImplantControl } from '../controls';
import { ImageHeader, ModalTypes } from '../layout';
import './implants.scss';

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;

export function Implants() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { evergineReady } = useEvergineStore();
  const { patientCase, updatePatientCase, getPatientCase } = useCaseData(caseId);
  const {
    loadUpperDicomInFS,
    loadUpperModel3DInFS,
    loadLowerDicomInFS,
    loadLowerModel3DInFS,
    patientCaseFilesToMatchingInfo
  } = useLoadFilesIntoFS();
  const [isFilesLoaded, setIsFilesLoaded] = useState<boolean>(false);
  const { teethArch, matchingModelPoints, setCanBeConfirmed, showCanvas, setShowCanvas } = useBoundStore();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { isDir } = useFiles();

  useEffect(() => {
    if (evergineReady && patientCase) {
      window.App.webEventsProxy.common.setStage(Stage.Implants);

      const loadFiles = async () => {
        if (!isDir(dynamicModelsFullPath)) {
          console.log('Creating directory ', dynamicModelsFullPath);
          Module.FS.mkdir(dynamicModelsFullPath);
          await loadUpperDicomInFS(patientCase);
          await loadUpperModel3DInFS(patientCase);
          await loadLowerDicomInFS(patientCase);
          await loadLowerModel3DInFS(patientCase);
        }

        setIsFilesLoaded(true);
      };

      if (isFilesLoaded) {
        const loadCanvasInfo = async () => {
          setShowCanvas(true);
          const patientCase = await getPatientCase();
          const matchingInfo: Record<string, MatchingInfo> = patientCaseFilesToMatchingInfo(patientCase);

          if (!matchingInfo[teethArch].dicom || !matchingInfo[teethArch].model3D) {
            setShowCanvas(false);
            return;
          }

          window.App.webEventsProxy.common.loadModels([
            {
              id: matchingInfo[teethArch].dicom,
              uri: `DynamicModels/${matchingInfo[teethArch].dicom}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER
            },
            {
              id: matchingInfo[teethArch].model3D,
              uri: `DynamicModels/${matchingInfo[teethArch].model3D}`,
              teethArch: teethArch === TeethArchPosition.UPPER ? CapturePosition.UPPER : CapturePosition.LOWER,
              model3dType: Model3dType.Scan
            }
          ]);

          window.App.webEventsProxy.surgeries.loadMatchingInfo(matchingInfo[teethArch]);
        };

        loadCanvasInfo();
      }

      loadFiles();
    }
  }, [evergineReady, patientCase, isFilesLoaded, teethArch]);

  const onClickAddImplant = (): void => {
    toggleModalIsOpened(ModalTypes.ModalImplant);
  };

  return (
    <div className="implants container-fluid g-0 d-flex flex-column">
      <Controls>
        <AddImplantControl onClickAddImplant={() => onClickAddImplant()} />
        <DeleteImplantControl />
      </Controls>
      <div className="d-flex implants-row">
        <ImageHeader className={'w-50'} title={'Corona (Y)'}>
          {(!evergineReady || !showCanvas) && (
            <div className="capture-message position-absolute top-25 w-50 start-0">
              <div className="section-message">
                <span>{t(`imageManagement.common.noFiles`)}</span>
              </div>
            </div>
          )}
        </ImageHeader>
        <ImageHeader className={'w-50'} title={'Sagital(X)'}>
          {(!evergineReady || !showCanvas) && (
            <div className="capture-message position-absolute top-25 w-50 end-0">
              <div className="section-message">
                <span>{t(`imageManagement.common.noFiles`)}</span>
              </div>
            </div>
          )}
        </ImageHeader>
      </div>
      <div className="d-flex implants-row">
        <ImageHeader className={'w-50'} title={'Axial (Z)'}>
          {(!evergineReady || !showCanvas) && (
            <div className="capture-message position-absolute top-25 w-50 start-0">
              <div className="section-message">
                <span>{t(`imageManagement.common.noFiles`)}</span>
              </div>
            </div>
          )}
        </ImageHeader>
        <ImageHeader className={'w-50'} title={'3D'}>
          {(!evergineReady || !showCanvas) && (
            <div className="capture-message position-absolute top-25 w-50 end-0">
              <div className="section-message">
                <span>{t(`imageManagement.common.noFiles`)}</span>
              </div>
            </div>
          )}
        </ImageHeader>
      </div>
      <VerticalDivider />
    </div>
  );
}
