import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BoltonIcon } from '../../../assets/icons/bolton.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { LabelSwitch, RemarkedValueLabel, SimpleTable, Tooth } from '../../../common';

import { useBolton } from '../../../hooks/orthodontics/useBolton';
import './boltonPanel.scss';
interface BoltonPanelProps {
  opened: boolean;
  mandibularTeeth: Tooth[];
  maxillaryTeeth: Tooth[];
  onClickClose: () => void;
}

const upperRightTeetharchIndexes = [11, 12, 13, 14, 15, 16];
const upperLeftTeetharchIndexes = [21, 22, 23, 24, 25, 26];
const lowerRightTeetharchIndexes = [41, 42, 43, 44, 45, 46];
const lowerLeftTeetharchIndexes = [31, 32, 33, 34, 35, 36];

export function BoltonPanel({ opened, mandibularTeeth, maxillaryTeeth, onClickClose }: BoltonPanelProps) {
  const [t] = useTranslation();

  const [anteriorValue, setAnteriorValue] = useState('N/A');
  const [totalValue, setTotalValue] = useState('N/A');
  const [showPercentage, setShowPercentage] = useState<boolean>(true);
  const [anteriorBoltonIndex, setAnteriorBoltonIndex] = useState('');
  const [totalBoltonIndex, setTotalBoltonIndex] = useState('');
  const [anteriorBoltonPercentage, setAnteriorBoltonPercentage] = useState('');
  const [totalBoltonPercentage, setTotalBoltonIPercentage] = useState('');
  const [isBoltonInitialized, setIsBoltonInitialized] = useState<boolean>();

  const classes = classNames('boltonpanel', {
    opened
  });

  useEffect(() => {
    if (!mandibularTeeth || !maxillaryTeeth) {
      return;
    }

    const anteriorBoltonIndexValue = useBolton(mandibularTeeth, maxillaryTeeth).getAnteriorBoltonIndex().toString();
    const totalBoltonIndexValue = useBolton(mandibularTeeth, maxillaryTeeth).getTotalBoltonIndex().toString();

    setAnteriorBoltonIndex(anteriorBoltonIndexValue);
    setTotalBoltonIndex(totalBoltonIndexValue);

    const calculatedAnteriorBoltonPercentage = useBolton(mandibularTeeth, maxillaryTeeth).getAnteriorBoltonPercentage();
    const calculatedTotalBoltonPercentage = useBolton(mandibularTeeth, maxillaryTeeth).getTotalBoltonPercentage();
    setAnteriorBoltonPercentage(
      calculatedAnteriorBoltonPercentage === 'NaN' ? 'N/A' : `${calculatedAnteriorBoltonPercentage}%`
    );
    setTotalBoltonIPercentage(
      calculatedTotalBoltonPercentage === 'NaN' ? 'N/A' : `${calculatedTotalBoltonPercentage}%`
    );

    setIsBoltonInitialized(true);
  }, [mandibularTeeth, maxillaryTeeth]);

  useEffect(() => {
    if (!isBoltonInitialized) {
      return;
    }

    if (showPercentage) {
      setAnteriorValue(anteriorBoltonPercentage);
      setTotalValue(totalBoltonPercentage);
    } else {
      setAnteriorValue(anteriorBoltonIndex);
      setTotalValue(totalBoltonIndex);
    }
  }, [showPercentage, isBoltonInitialized]);

  const getMaxyllaryTeethInfo = useCallback(
    (teethIndexes: number[]) => {
      return maxillaryTeeth
        ?.filter((t: Tooth) => teethIndexes.includes(t.fdi))
        .sort((left: Tooth, right: Tooth) => left.fdi - right.fdi)
        .map((t: Tooth) => ({
          key: t.fdi.toString(),
          value: t.mesioDistalLength.toFixed(2).toString()
        }));
    },
    [maxillaryTeeth]
  );

  const getMandibularTeethInfo = useCallback(
    (teethIndexes: number[]) => {
      return mandibularTeeth
        ?.filter((t: Tooth) => teethIndexes.includes(t.fdi))
        .sort((left: Tooth, right: Tooth) => left.fdi - right.fdi)
        .map((t: Tooth) => ({
          key: t.fdi.toString(),
          value: t.mesioDistalLength.toFixed(2).toString()
        }));
    },
    [mandibularTeeth]
  );

  return (
    <Draggable bounds="body" handle=".handle">
      <div className={classes}>
        <div className="boltonpanel-header">
          <div className="boltonpanel-header--title handle">
            <BoltonIcon />
            <div>{t('boltonPanel.title')}</div>
          </div>
          <button type="button" className="boltonpanel-header--close" onClick={onClickClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="boltonpanel-indexes">
          <RemarkedValueLabel value={anteriorValue} label={t('boltonPanel.anteriorIndex')} key={`bolton-ratio-3_3`} />
          <RemarkedValueLabel value={totalValue} label={t('boltonPanel.totalIndex')} key={`bolton-ratio-6_6`} />
          <LabelSwitch
            defaultValue={showPercentage}
            handleToggle={() => setShowPercentage(!showPercentage)}
            label={t('boltonPanel.percentageMode')}
          />
        </div>
        <div className="boltonpanel-data">
          <div className="boltonpanel-data--row">
            <SimpleTable
              title={t('boltonPanel.upperRightQuadrant')}
              rows={getMaxyllaryTeethInfo(upperRightTeetharchIndexes)}
            />
            <SimpleTable
              title={t('boltonPanel.upperLeftQuadrant')}
              rows={getMaxyllaryTeethInfo(upperLeftTeetharchIndexes)}
            />
          </div>
          <div className="boltonpanel-data--row">
            <SimpleTable
              title={t('boltonPanel.lowerRightQuadrant')}
              rows={getMandibularTeethInfo(lowerRightTeetharchIndexes)}
            />
            <SimpleTable
              title={t('boltonPanel.lowerLeftQuadrant')}
              rows={getMandibularTeethInfo(lowerLeftTeetharchIndexes)}
            />
          </div>
        </div>
      </div>
    </Draggable>
  );
}
