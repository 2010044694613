import { t } from 'i18next';
import { useRef } from 'react';
import Carousel from 'react-bootstrap/esm/Carousel';
import { ReactComponent as ArrowLeftIcon } from '../../../../../assets/icons/arrow-left-white.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right-white.svg';
import CloseCarouselIcon from '../../../../../assets/icons/close_slider.svg';
import DeleteIcon from '../../../../../assets/icons/delete.svg';
import DownloadIcon from '../../../../../assets/icons/download.svg';
import { IconRoundButton } from '../../../../../common/components/iconRoundButton';
import { useCarousel } from '../../../../../hooks/shared/useCarousel';
import { ImageToShow } from '../PhotoCaptures';
import './photoCarousel.scss';

interface CarouselProps {
  slides: ImageToShow[];
  selectedSlide: number;
  onClickClose: () => void;
  onClickRemoveFile: (identifier: string) => void;
}

export function PhotoCarousel(props: CarouselProps) {
  const { slides, selectedSlide, onClickClose, onClickRemoveFile } = props;
  const ref = useRef(null);

  const {
    imagesForCarousel: slidesToShow,
    indexSelectedSlide: currentIndex,
    onNextClick,
    onPrevClick,
    setImagesForCarousel: setSlidesToShow,
    setIndexSelectedSlide: setCurrentIndex
  } = useCarousel(ref, slides, selectedSlide);

  const handleRemoveFile = () => {
    const slideToRemove = slidesToShow[currentIndex];
    onClickRemoveFile(slideToRemove.key);
    setSlidesToShow(slidesToShow.filter((slide) => slide.key !== slideToRemove.key));
    onClickClose();
  };

  return (
    <div className="photocarousel">
      <div className="photocarousel-header">
        <div className="photocarousel-header_title">{slidesToShow[currentIndex]?.title}</div>
        <div className="photocarousel-header_counter">{`${currentIndex + 1}/${slidesToShow.length}`}</div>
        <div className="photocarousel-header_controls">
          <img src={DownloadIcon} alt={t('photographs.download')} />
          <img src={DeleteIcon} alt={t('photographs.delete')} onClick={() => handleRemoveFile()} />
          <img src={CloseCarouselIcon} alt={t('photographs.closeSlider')} onClick={onClickClose} />
        </div>
      </div>
      <div className="photocarousel-container">
        {slidesToShow.length > 1 && <IconRoundButton icon={ArrowLeftIcon} size="m" onClick={onPrevClick} />}

        <Carousel
          ref={ref}
          activeIndex={currentIndex}
          controls={false}
          interval={null}
          indicators={false}
          onSelect={(index: number) => setCurrentIndex(index)}
        >
          {slidesToShow?.map((slide, i) => (
            <Carousel.Item key={i}>
              <img src={slide.url} />
            </Carousel.Item>
          ))}
        </Carousel>
        {slidesToShow.length > 1 && <IconRoundButton icon={ArrowRightIcon} size="m" onClick={onNextClick} />}
      </div>
    </div>
  );
}
