import { useEvergineStore } from 'evergine-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { EvergineToolbar, PagesWithTools, Stage, Timeline, toolbarConfig } from '../../../common';
import { LateralPanel } from '../../../common/components/lateralPanel';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useCaseId, useRenderModels } from '../../../hooks';
import { useDentalMovements, useDentalMovementsStepsManager } from '../../../hooks/orthodontics';
import { useUtils } from '../../../hooks/shared/useUtils';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../shared';
import { useOrthBoundStore } from '../../stores/useStore';
import { BoltonPanel } from '../boltonPanel';
import { Shift } from '../dentalMovements';
import { EvolutionPanel } from '../evolutionPanel';
import { MovementsPanel } from '../movementsPanel/MovementsPanel';
import { OcclusogramLegend } from '../occlusogramLegend';
import { IPRButtons } from '../buttonsIPR';

export function IPR() {
  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.IPR);
  const ability = useContext(AbilityContext);
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);

  const { evergineReady } = useEvergineStore();
  const {
    lowerDentalMovements,
    upperDentalMovements,
    stageIsLoaded,
    showMovementsTable,
    showMiniOcclusogram,
    showBoltonPanel,
    showEvolutionPanel,
    showIPR,
    setShowBoltonPanel,
    setShowEvolutionPanel,
    setShowMovementsTable,
    setShowIPR
  } = useOrthBoundStore();
  const { activeStep, setActiveStep } = useCommonBoundStore();
  const { dentalMovementsSteps, dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList } =
    useDentalMovementsStepsManager(lowerDentalMovements, upperDentalMovements);
  const { stepSorter, getWidthFromEvolutionPanel, evolutionPanelWidth } = useUtils();

  const pageTools = toolbarConfig[PagesWithTools.IPR];

  const [canShowTimeline, setCanShowTimeline] = useState<boolean>();

  useEffect(() => {
    setShowIPR(true);
  }, []);

  useEffect(() => {
    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady;
    setCanShowTimeline(result);
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  const showLateralPanelIfAllowed = useCallback(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />;
    }
  }, [showInfoPanel, ability, handleInfoPanelToggle]);

  // TODO: intentar sacar a un hook o componente base
  const shiftMovementsPanel = useCallback(() => {
    if (showMovementsTable) {
      if (showEvolutionPanel && !showInfoPanel) {
        return Shift.EvolutionPanelOpened;
      }

      if (showInfoPanel && !showEvolutionPanel) {
        return Shift.InfoPanelOpened;
      }

      if (showInfoPanel && showEvolutionPanel) {
        return Shift.EvolutionAndInfoPanelsOpened;
      }
    }

    return Shift.None;
  }, [showMovementsTable, showEvolutionPanel, showInfoPanel]);

  return (
    <>
      {showLateralPanelIfAllowed()}
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      {showIPR && <IPRButtons />}
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showBoltonPanel && (
        <BoltonPanel
          opened={showBoltonPanel}
          mandibularTeeth={lowerDentalMovements?.teeth}
          maxillaryTeeth={upperDentalMovements?.teeth}
          onClickClose={() => setShowBoltonPanel(false)}
        />
      )}
      {dentalMovementsSteps.length > 0 && (
        <EvolutionPanel
          opened={showEvolutionPanel}
          onClickClose={() => setShowEvolutionPanel(false)}
          shift={showEvolutionPanel && showInfoPanel ? Shift.InfoPanelOpened : Shift.None}
          getWidthFunction={(width) => getWidthFromEvolutionPanel(width)}
          steps={dentalMovementsSteps}
          updateIPRList={setDentalMovementsApplyIPRList}
          applyIPRList={dentalMovementsApplyIPRList}
        />
      )}
      <MovementsPanel
        opened={showMovementsTable}
        onClickClose={() => setShowMovementsTable(false)}
        shiftMode={shiftMovementsPanel()}
        evolutionPanelWidth={evolutionPanelWidth}
      />

      {canShowTimeline && (
        <>
          <Timeline
            upperTeeth={upperDentalMovements?.teeth}
            lowerTeeth={lowerDentalMovements?.teeth}
            upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
            lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
            upperAttachments={upperDentalMovements?.attachments}
            lowerAttachments={lowerDentalMovements?.attachments}
            upperIprLabels={upperDentalMovements?.iprLabels}
            lowerIprLabels={lowerDentalMovements?.iprLabels}
            comparerActivated={false}
            vertical={false}
            enableFollowUpOverposition={false}
            updateStoreCurrentStep={(newValue: number) => setActiveStep(newValue)}
            storeCurrentStep={activeStep}
            isDesigner={true}
          />
        </>
      )}
    </>
  );
}
