import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { HistoryTreatmentChange, HistoryTreatmentChangeDTO } from './historyTreatmentChange';
import { IHistoryTreatmentChangeService } from './ihistoryTreatmentChange.service';

@injectable()
export class HistoryTreatmentChangeService implements IHistoryTreatmentChangeService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  private readonly apiEndpoint = '/api/product-cases';

  public async getTreatmentChange(caseId: string, treatmentChangeId: number): Promise<HistoryTreatmentChange> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-changes/${treatmentChangeId}`;
    const treatmentChange = (await this._httpService.get<HistoryTreatmentChange>(url)).data;
    return treatmentChange;
  }

  public async getTreatmentChanges(caseId: string): Promise<HistoryTreatmentChange[]> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-changes?containsChangeItems=1`;
    const treatmentChanges = (await this._httpService.get<HistoryTreatmentChangeDTO>(url)).data.data;
    return treatmentChanges;
  }
}
