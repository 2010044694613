import { ReactComponent as IconAlertError } from '../../../assets/icons/alert-error.svg';
import { ReactComponent as IconAlertWarning } from '../../../assets/icons/alert-warning.svg';
import './alertMessage.scss';

export enum AlertMessageType {
  Warning,
  Error
}

type AlertMessageProps = {
  message: string;
  type: AlertMessageType;
};

export function AlertMessage(props: AlertMessageProps) {
  const { message, type } = props;

  return (
    <div className="alertmessage">
      <span className="alertmessage-icon">
        {type === AlertMessageType.Warning ? <IconAlertWarning /> : <IconAlertError />}
      </span>
      <span
        data-testid="alertmessage-text"
        className={`alertmessage-text alertmessage-text--${type === AlertMessageType.Warning ? 'warning' : 'error'}`}
      >
        {message}
      </span>
    </div>
  );
}
