import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PathLevels } from '../../models';
import { useBoundStore } from '../../surgeries/stores/useStore';

export function useCaseId() {
  const { pathname } = useLocation();
  const caseIdFromRoute = pathname.split('/')[PathLevels.CaseId];
  const caseIdFromStore = useBoundStore((state) => state.caseId);
  const updateCaseId = useBoundStore((state) => state.updateCaseId);
  const [caseId, setCaseId] = useState<string>(caseIdFromStore || caseIdFromRoute);

  useEffect(() => {
    if (!caseId || caseId !== caseIdFromRoute) {
      updateCaseId(caseIdFromRoute);
      setCaseId(caseIdFromRoute);
    }
  }, []);

  return [caseId] as const;
}
