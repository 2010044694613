import { MutableRefObject, useCallback, useState } from 'react';
import { ImageToShow } from '../../surgeries/components';

export function useCarousel(carouselRef: MutableRefObject<any>, images: ImageToShow[] = [], activeSlide = 0) {
  const [imagesForCarousel, setImagesForCarousel] = useState<ImageToShow[]>(images);
  const [indexSelectedSlide, setIndexSelectedSlide] = useState<number>(activeSlide);

  const onPrevClick = useCallback(() => {
    carouselRef.current.prev();
    indexSelectedSlide > 0
      ? setIndexSelectedSlide(indexSelectedSlide - 1)
      : setIndexSelectedSlide(imagesForCarousel.length - 1);
  }, [indexSelectedSlide, imagesForCarousel, setIndexSelectedSlide]);

  const onNextClick = useCallback(() => {
    carouselRef.current.next();
    indexSelectedSlide === imagesForCarousel.length - 1
      ? setIndexSelectedSlide(0)
      : setIndexSelectedSlide(indexSelectedSlide + 1);
  }, [indexSelectedSlide, imagesForCarousel, setIndexSelectedSlide]);

  const onFirstClick = useCallback(() => {
    carouselRef.current.prev(0);
    setIndexSelectedSlide(0);
  }, [indexSelectedSlide, imagesForCarousel, setIndexSelectedSlide]);

  const onLastClick = useCallback(() => {
    const last = imagesForCarousel.length - 1;
    carouselRef.current.next(last);
    setIndexSelectedSlide(last);
  }, [indexSelectedSlide, imagesForCarousel, setIndexSelectedSlide]);

  return {
    imagesForCarousel,
    setImagesForCarousel,
    indexSelectedSlide,
    setIndexSelectedSlide,
    onPrevClick,
    onNextClick,
    onFirstClick,
    onLastClick
  };
}
