import { useCallback } from 'react';
import { CaptureType, MatchingPoint } from '../../common/evergine/types';

export function useCalculateMatchingPoints() {
  const calculateMatchingPoints = useCallback((fileType, matchingModelPoint, matchingModelPoints, teethArch) => {
    const typePoints = fileType === CaptureType.MODEL3D ? 'model3dPoints' : 'dicomPoints';

    const foundModel3dPoint = matchingModelPoints[teethArch][typePoints].find(
      (item: MatchingPoint) => item.color === matchingModelPoint.point.color
    );

    if (foundModel3dPoint) {
      foundModel3dPoint.position = matchingModelPoint.point.position;
      return [...matchingModelPoints[teethArch][typePoints]];
    }

    return [...matchingModelPoints[teethArch][typePoints], matchingModelPoint.point];
  }, []);

  return [calculateMatchingPoints] as const;
}
