import { TeethArchPosition } from '../../../models/TeethArchModel';

export type OdontogramTeethArchProps = {
  highlightPieces?: string[];
  selectedPieces?: string[];
  missingPieces?: string[];
  toRenumeration?: string;
  showToggleNumbers?: boolean;
  colorVersion?: boolean;
  onPieceClick?: (pieceId: string) => void;
};

interface teethsColorsProps {
  fdi: string;
  color: string;
  archPosition?: TeethArchPosition;
}

interface ColorsEvergine {
  [fdi: string]: string;
}

const teethsColors: teethsColorsProps[] = [
  { fdi: '18', color: '#6E8B4B', archPosition: TeethArchPosition.UPPER },
  { fdi: '17', color: '#8469C9', archPosition: TeethArchPosition.UPPER },
  { fdi: '16', color: '#323D58', archPosition: TeethArchPosition.UPPER },
  { fdi: '15', color: '#B2578C', archPosition: TeethArchPosition.UPPER },
  { fdi: '14', color: '#286FCC', archPosition: TeethArchPosition.UPPER },
  { fdi: '13', color: '#27A586', archPosition: TeethArchPosition.UPPER },
  { fdi: '12', color: '#DB970A', archPosition: TeethArchPosition.UPPER },
  { fdi: '11', color: '#C83746', archPosition: TeethArchPosition.UPPER },
  { fdi: '21', color: '#EF6C79', archPosition: TeethArchPosition.UPPER },
  { fdi: '22', color: '#F8CC5D', archPosition: TeethArchPosition.UPPER },
  { fdi: '23', color: '#61D1B5', archPosition: TeethArchPosition.UPPER },
  { fdi: '24', color: '#6AA3EC', archPosition: TeethArchPosition.UPPER },
  { fdi: '25', color: '#E48BBD', archPosition: TeethArchPosition.UPPER },
  { fdi: '26', color: '#596E9B', archPosition: TeethArchPosition.UPPER },
  { fdi: '27', color: '#B3A0E3', archPosition: TeethArchPosition.UPPER },
  { fdi: '28', color: '#90BC62', archPosition: TeethArchPosition.UPPER },
  { fdi: '48', color: '#6E8B4B', archPosition: TeethArchPosition.LOWER },
  { fdi: '47', color: '#8469C9', archPosition: TeethArchPosition.LOWER },
  { fdi: '46', color: '#323D58', archPosition: TeethArchPosition.LOWER },
  { fdi: '45', color: '#B2578C', archPosition: TeethArchPosition.LOWER },
  { fdi: '44', color: '#286FCC', archPosition: TeethArchPosition.LOWER },
  { fdi: '43', color: '#27A586', archPosition: TeethArchPosition.LOWER },
  { fdi: '42', color: '#DB970A', archPosition: TeethArchPosition.LOWER },
  { fdi: '41', color: '#C83746', archPosition: TeethArchPosition.LOWER },
  { fdi: '31', color: '#EF6C79', archPosition: TeethArchPosition.LOWER },
  { fdi: '32', color: '#F8CC5D', archPosition: TeethArchPosition.LOWER },
  { fdi: '33', color: '#61D1B5', archPosition: TeethArchPosition.LOWER },
  { fdi: '34', color: '#6AA3EC', archPosition: TeethArchPosition.LOWER },
  { fdi: '35', color: '#E48BBD', archPosition: TeethArchPosition.LOWER },
  { fdi: '36', color: '#596E9B', archPosition: TeethArchPosition.LOWER },
  { fdi: '37', color: '#B3A0E3', archPosition: TeethArchPosition.LOWER },
  { fdi: '38', color: '#90BC62', archPosition: TeethArchPosition.LOWER }
];
export const colorsToEvergine: ColorsEvergine = teethsColors.reduce(
  (acc: ColorsEvergine, currentValue: teethsColorsProps) => {
    acc[currentValue.fdi] = currentValue.color;
    return acc;
  },
  {}
);

export const findArchPositionByFdi = (fdi: string): TeethArchPosition | undefined => {
  const tooth = teethsColors.find((item) => item.fdi === fdi);
  return tooth ? tooth.archPosition : undefined;
};

const getColorByFdi = (id: string): string => {
  const foundColor = teethsColors.find((item) => item.fdi === id);
  return foundColor ? foundColor.color : selectedToothColor;
};

export const isPieceHighlighted = (pieceId: string, highlightPieces: string[]) =>
  highlightPieces.some((p: string) => p === pieceId);

export const isPieceSelected = (pieceId: string, selectedPieces: string[]) =>
  selectedPieces.some((p: string) => p === pieceId);

export const isPieceMissing = (pieceId: string, missingPieces: string[]) =>
  missingPieces.some((p: string) => p === pieceId);

export const isPieceTo = (pieceId: string, toPiece: string) => pieceId === toPiece;

export const getPieceColor = (pieceId: string, props: OdontogramTeethArchProps, alternativeColor = false) => {
  if (isPieceMissing(pieceId, props.missingPieces)) {
    return alternativeColor ? missingToothColorAlternative : missingToothColor;
  }

  if (isPieceSelected(pieceId, props.selectedPieces)) {
    //return alternativeColor ? selectedToothColorAlternative : selectedToothColor;
    return getColorByFdi(pieceId);
  }

  if (isPieceHighlighted(pieceId, props.highlightPieces)) {
    return alternativeColor ? highlightedToothColorAlternative : highlightedToothColor;
  }
  if (isPieceTo(pieceId, props.toRenumeration)) {
    return selectedToothColorText;
  }

  //return alternativeColor ? normalToothColorAlternative : normalToothColor;
  return props.colorVersion ? getColorByFdi(pieceId) : normalToothColor;
};

export const showToothBorder = (pieceId: string, props: OdontogramTeethArchProps) => {
  if (isPieceMissing(pieceId, props.missingPieces)) {
    return {
      stroke: missingToothColorBorder,
      strokeWidth: '1',
      strokeDasharray: '5,3',
      strokeDashoffset: '0'
    };
  }

  return null;
};

export const getPieceTextColor = (pieceId: string, props: OdontogramTeethArchProps) => {
  /* if (isPieceSelected(pieceId, props.selectedPieces)) {
    return selectedToothColorText;
  } */

  return generalToothColorText;
};

export const missingToothColor = '#6C7076';
export const missingToothColorAlternative = '#36383C';
export const missingToothColorBorder = '#A8B6C2';
export const highlightedToothColor = '#007BFF';
export const highlightedToothColorAlternative = '#0b6dd8';
export const normalToothColor = '#7B818A';
export const normalToothColorAlternative = '#36383C';
export const normalToothColorAlternativeOdontogramMini = '#FFFFFF';
export const selectedToothColor = '#80E8A9';
export const selectedToothColorAlternative = '#67b386';
export const generalToothColorText = '#F0F0F0';
export const selectedToothColorText = '#000000';
