import React from 'react';
import { ReactComponent as NoMovementIcon } from '../../../assets/icons/evolutionPanel/info/no-movement.svg';
import { ReactComponent as MissingToothIcon } from '../../../assets/icons/evolutionPanel/info/missing-tooth.svg';
import { ReactComponent as ActiveAlignerIcon } from '../../../assets/icons/evolutionPanel/info/active-aligner.svg';
import { ReactComponent as PassiveAlignerIcon } from '../../../assets/icons/evolutionPanel/info/passive-aligner.svg';
import { ReactComponent as StartAttachIcon } from '../../../assets/icons/evolutionPanel/info/start-attach.svg';
import { ReactComponent as EndAttachIcon } from '../../../assets/icons/evolutionPanel/info/end-attach.svg';
import { ReactComponent as SpaceIcon } from '../../../assets/icons/evolutionPanel/info/space.svg';
import { ReactComponent as IPRIcon } from '../../../assets/icons/evolutionPanel/info/ipr.svg';

import { useTranslation } from 'react-i18next';
import { IconTextInfo, Info } from '../../../common';

export function EvolutionPanelInfo() {
  const [t] = useTranslation();

  const infoPairings: IconTextInfo[] = [
    {
      text: t('evolutionPanel.info.noMovement'),
      icon: NoMovementIcon
    },
    {
      text: t('evolutionPanel.info.missingTooth'),
      icon: MissingToothIcon
    },
    {
      text: t('evolutionPanel.info.activeAligner'),
      icon: ActiveAlignerIcon
    },
    {
      text: t('evolutionPanel.info.passiveAligner'),
      icon: PassiveAlignerIcon
    },
    {
      text: t('evolutionPanel.info.startAtache'),
      icon: StartAttachIcon
    },
    {
      text: t('evolutionPanel.info.endAtache'),
      icon: EndAttachIcon
    },
    {
      text: t('evolutionPanel.info.space'),
      icon: SpaceIcon
    },
    {
      text: t('evolutionPanel.info.IPR'),
      icon: IPRIcon
    }
  ];

  return (
    <div>
      <Info iconTextInfos={infoPairings} darkStyle={true} />
    </div>
  );
}
