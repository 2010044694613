import { StateCreator } from 'zustand';

export interface ValidateTreatmentSlice {
  isThereFeedbackForTreatment: boolean;
  setIsThereFeedbackForTreatment: (isThereFeedbackForTreatment: boolean) => void;
}
export const createValidateTreatmentSlice: StateCreator<ValidateTreatmentSlice, [], [], ValidateTreatmentSlice> = (
  set
) => ({
  isThereFeedbackForTreatment: false,
  setIsThereFeedbackForTreatment: (isThereFeedbackForTreatment: boolean) => set(() => ({ isThereFeedbackForTreatment }))
});
