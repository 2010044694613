import { MovementType, SourceOfMovement } from '../../common';

export type TreatmentChange = Record<string, TreatmentChangeValue>;

export type TreatmentChangeKey = {
  kind: string;
  source: string;
  pieceIdentifier: string;
};

export type TreatmentChangeValue = {
  value: number;
  measureUnit: string;
};

export enum MeasureUnit {
  Millimeter = 'mm',
  Degrees = 'º'
}

const SPLIT_CHAR = '|';

export const buildTreatmentChangeKey = (kind: MovementType, source: SourceOfMovement, toothFdi: number): string => {
  return `${kind}${SPLIT_CHAR}${source}${SPLIT_CHAR}${toothFdi}`;
};

export const decodeTreatmentChangeKey = (changeKey: string): TreatmentChangeKey => {
  const parse = changeKey.split(SPLIT_CHAR);
  return {
    kind: parse[0],
    source: parse[1],
    pieceIdentifier: parse[2]
  };
};
