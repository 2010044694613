import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { AttachesInputDTO, AttachesResponseDTO } from './calculationAttaches';
import { ICalculationAttachesService } from './iCalculationAttaches.service';

@injectable()
export class CalculationAttachesService implements ICalculationAttachesService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  private readonly apiEndpoint = '/ai/attaches';

  public async getAIAutomaticAttaches(requestDto: AttachesInputDTO): Promise<AttachesResponseDTO> {
    const result = await this._httpService.post<AttachesResponseDTO>(this.apiEndpoint, requestDto);
    return result.data;
  }
}
