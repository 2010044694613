import { useTranslation } from 'react-i18next';
import { TeethArchPosition } from '../../../models';
import OdontogramLowerTeethArch from '../odontogramTeethArch/OdontogramLowerTeethArch';
import OdontogramUpperTeethArch from '../odontogramTeethArch/OdontogramUpperTeethArch';
import './odontogram.scss';

type OdontogramProps = {
  highlightPieces?: string[];
  selectedPieces?: string[];
  missingPieces?: string[];
  toRenumeration?: string;
  showToggleNumbers?: boolean;
  showTitle?: boolean;
  colorVersion?: boolean;
  archPosition?: TeethArchPosition;
  onPieceClick?: (piece: string) => void;
};

/* enum ArchsIds {
  Lower = 'lower',
  Upper = 'upper'
} */

export function Odontogram(props: OdontogramProps) {
  const [t] = useTranslation();
  const {
    missingPieces = [],
    highlightPieces = [],
    selectedPieces = [],
    archPosition = TeethArchPosition.BOTH,
    onPieceClick
  } = props;

  const defaultOnPieceClick = () => {
    // No actions by default
  };

  const onPieceClickHandler = onPieceClick || defaultOnPieceClick;

  //const sendShowNumbersToEvergine = useCallback((value: boolean, id: string) => {
  // TODO: Send event to evergine for show/hide numbers
  //console.log('Send event to evergine for show numbers: ', value, id);
  //}, []);

  /*  const showToggleNumbersOrTextLabel = useCallback(
    (arch: ArchsIds) => {
      if (props.showToggleNumbers) {
        return (
          <div className="odontogram-show-numbers">
            <Switch id={arch} handleToggle={sendShowNumbersToEvergine} defaultValue={true} />
            <br />
            <div className="label">{t(`generalInfo.odontogram.displayNumbers`)}</div>
          </div>
        );
      } else {
        return <div className="odontogram-label">{t(`generalInfo.odontogram.${arch}`)}</div>;
      }
    },
    [props.showToggleNumbers]
  ); */

  return (
    <div className="odontogram">
      <div className="d-flex align-items-center justify-content-center">
        {archPosition !== TeethArchPosition.LOWER && (
          <>
            {/* {showToggleNumbersOrTextLabel(ArchsIds.Upper)} */}
            <OdontogramUpperTeethArch
              highlightPieces={highlightPieces}
              selectedPieces={selectedPieces}
              missingPieces={missingPieces}
              toRenumeration={props.toRenumeration}
              onPieceClick={onPieceClickHandler}
              //onPieceClick={defaultOnPieceClick}
              colorVersion={props.colorVersion}
            />
          </>
        )}
      </div>
      {props.showTitle ? <div className="odontogram-title">{t('generalInfo.odontogram.title')}</div> : ''}
      <div className="d-flex align-items-center justify-content-center">
        {archPosition !== TeethArchPosition.UPPER && (
          <>
            {/* {showToggleNumbersOrTextLabel(ArchsIds.Lower)} */}
            <OdontogramLowerTeethArch
              highlightPieces={highlightPieces}
              selectedPieces={selectedPieces}
              missingPieces={missingPieces}
              toRenumeration={props.toRenumeration}
              onPieceClick={onPieceClickHandler}
              //onPieceClick={defaultOnPieceClick}
              colorVersion={props.colorVersion}
            />
          </>
        )}
      </div>
    </div>
  );
}
