import { useTranslation } from 'react-i18next';
import { PatientCase } from '../../../shared';
import { DataCardBase, LabelValue } from '../../../surgeries/components';

type PatientDataCardProps = {
  patientCase: PatientCase;
  noCard?: boolean;
};

export function ClinicDataCard({ patientCase, noCard }: PatientDataCardProps) {
  const [t] = useTranslation();

  return (
    <DataCardBase noCard={noCard} title={t('generalInfo.clinicData.title')}>
      <LabelValue label={t('generalInfo.clinicData.name')} value={patientCase.clinic.name} />
      <LabelValue label={t('generalInfo.clinicData.address')} value={patientCase.clinic.address} />
    </DataCardBase>
  );
}
