import classNames from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AbilityAction, AbilityContext, CaseVersion, CaseVersionState, CommonAbilitySubject } from '../../../../shared';
import Radio from '../../radio/Radio';
import { ManageVersionButton } from './ManageVersionButton';
import { ActionsButton } from './ToggleCompareTreatments';
import './caseVersionItem.scss';

type CaseVersionSelectorItemProps = {
  version: CaseVersion;
  editVersionId: string;
  selectedVersionId: string;
  compareWithVersionId: string;
  actions: ActionsButton[];
  isEditingVersion: boolean;
  isComparingTreatmentActive?: boolean;
  handleSelectVersion: (version: CaseVersion) => void;
};

export function CaseVersionItem({
  version,
  editVersionId,
  selectedVersionId,
  compareWithVersionId,
  actions,
  isEditingVersion,
  isComparingTreatmentActive,
  handleSelectVersion
}: CaseVersionSelectorItemProps) {
  const [inputValue, setInputValue] = useState('');
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);

  const handleInputChange = useCallback((event: any) => {
    event.preventDefault();
    setInputValue(event.target.value);
  }, []);

  const getDate = useCallback(() => {
    const hasUpdateDate = !!version.updatedAt && new Date(version.updatedAt).valueOf() !== -62135595916000;
    const date = hasUpdateDate ? version.updatedAt : version.createdAt;
    return new Date(date).toLocaleDateString('es-es');
  }, [version]);

  const isActive = useCallback(() => {
    return selectedVersionId === version.id || compareWithVersionId === version.id;
  }, [version, selectedVersionId, compareWithVersionId]);

  const classes = classNames('caseversioninput-version_button', {
    'is-active': isActive()
  });

  const isManageVersionsVisible = useMemo(() => {
    const canManageVersions = !ability || ability.can(AbilityAction.Manage, CommonAbilitySubject.Versions);

    return isComparingTreatmentActive !== true && canManageVersions;
  }, [isComparingTreatmentActive]);

  const showRadio = useCallback(() => {
    return selectedVersionId !== version.id;
  }, [version, selectedVersionId]);

  const handleSelected = useCallback(() => {
    handleSelectVersion(version);
  }, [version, handleSelectVersion]);

  return (
    <>
      {isComparingTreatmentActive && (
        <span className="caseversioninput-radio">
          {showRadio() && <Radio active={isActive()} callBack={handleSelected} />}
        </span>
      )}
      <button
        type="button"
        onClick={handleSelected}
        disabled={!!editVersionId && editVersionId !== version.id}
        className={classes}
      >
        {isEditingVersion ? (
          <input
            type="text"
            className="caseversioninput-title"
            value={inputValue || version.name}
            disabled={!isEditingVersion}
            onChange={handleInputChange}
          />
        ) : (
          <div className="caseversioninput-title">{version.name}</div>
        )}
        <span>
          {t(`navbar.versions.${CaseVersionState[version.result]}`)}
          {isComparingTreatmentActive && <span> - {getDate()}</span>}
        </span>
      </button>
      <div className="d-flex">
        {isManageVersionsVisible &&
          actions.map((a) => (
            <ManageVersionButton
              key={`${version.id}-${a.labelKey}`}
              buttonProps={a}
              buttonAction={() => a.action(version.id, inputValue)}
              versionId={version.id}
              currentEditingVersion={editVersionId}
            />
          ))}
      </div>
    </>
  );
}
