import React from 'react';

import './simpleTable.scss';

interface SimpleTableProps {
  title: string;
  rows: Record<string, string>[];
}

export function SimpleTable({ title, rows }: SimpleTableProps) {
  return (
    <div className="simpletable">
      <div className="simpletable-title">{title}</div>
      {rows?.map((row, i) => (
        <div className="simpletable-row" key={`table-${title}-row-${i}`}>
          <div className="simpletable-row--key">{row.key}</div>
          <div className="simpletable-row--value">{row.value}</div>
        </div>
      ))}
    </div>
  );
}
