import { AbilityAction, AbilityContext, SecretAlignerAbilitySubject } from '../shared';
import { useContext } from 'react';

export function useGateKeeper(
  abilityAction: AbilityAction,
  abilitySubject: SecretAlignerAbilitySubject,
  callback: Function
) {
  const ability = useContext(AbilityContext);

  if (!ability || ability.cannot(abilityAction, abilitySubject)) {
    callback();
  }
}
