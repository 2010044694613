import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { ReactComponent as Minus } from '../../../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import { ReactComponent as Reset } from '../../../../../assets/icons/reset.svg';
import { MovementType, SourceOfMovement, ToothChange } from '../../../../../common/evergine/types';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../../../shared';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { TeethMovementsToolsType, teethMovementsTools } from './ToolsIcons';

import { MeasureUnit, TreatmentChangeValue, buildTreatmentChangeKey } from '../../../../models';
import { OrthToolButtonReset } from './orthToolButtonReset/';
import { OrthToolButtonSmall } from './orthToolButtonSmall/OrthToolButtonSmall';

import './orthTeethTools.scss';

type TeethMovementsValue = {
  movementType: MovementType;
  value: number;
  measureUnitType?: MeasureUnit;
};

const initialMovementValues: { [key: string]: TeethMovementsValue[] } = {};

const defaultMovementsValues: TeethMovementsValue[] = [
  { movementType: MovementType.ExtrusionIntrusion, value: null },
  { movementType: MovementType.TranslationVL, value: null },
  { movementType: MovementType.TranslationMD, value: null },
  { movementType: MovementType.PureRotation, value: null },
  { movementType: MovementType.Torque, value: null },
  { movementType: MovementType.TIP, value: null }
];

const unitTypeForMovement: { [key: string]: MeasureUnit } = {
  [MovementType.ExtrusionIntrusion]: MeasureUnit.Millimeter,
  [MovementType.TranslationVL]: MeasureUnit.Millimeter,
  [MovementType.TranslationMD]: MeasureUnit.Millimeter,
  [MovementType.PureRotation]: MeasureUnit.Degrees,
  [MovementType.TIP]: MeasureUnit.Degrees,
  [MovementType.Torque]: MeasureUnit.Degrees
};

const MM_STEP_NUMBER = 0.1;
const DEGREES_STEP_NUMBER = 1;
const VALID_NUMBER_REGEX = /^[+-]?\d+(\.\d+)?$/;
const MOVEMENTS_DECIMALS = 1;
const EPSILON = 0.05;

type OrthTeethToolsType = {
  onCaseModification?: (isModified: boolean) => void;
};

export function OrthTeethTools({ onCaseModification }: OrthTeethToolsType) {
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);
  const [selectedMovement] = useState<SourceOfMovement>(SourceOfMovement.Crown);
  const [movementsValues, setMovementsValue] = useState<TeethMovementsValue[]>([...defaultMovementsValues]);
  const [lastMovementType, setLastMovementType] = useState<MovementType>();
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const { treatmentSuggestedChanges, setTreatmentSuggestedChanges, selectedTeethTransformData, selectedTeethId } =
    useOrthBoundStore();

  const onSaveMovements = useCallback(
    (newValues: TeethMovementsValue[]) => {
      setMovementsValue(newValues);

      //onCaseModification(true);
    },
    [setMovementsValue]
  );

  useEffect(() => {
    if (!selectedTeethTransformData) {
      return;
    }

    function updateMovementsValues() {
      const newValues: { [key: string]: number } = {
        [MovementType.ExtrusionIntrusion]: selectedTeethTransformData?.extrusionIntrusion,
        [MovementType.TranslationVL]: selectedTeethTransformData?.translationVL,
        [MovementType.TranslationMD]: selectedTeethTransformData?.translationMD,
        [MovementType.PureRotation]: selectedTeethTransformData?.pureRotation,
        [MovementType.TIP]: selectedTeethTransformData?.tip,
        [MovementType.Torque]: selectedTeethTransformData?.torque
      };

      const list = cloneDeep(defaultMovementsValues);
      const treatmentChangesCloned = cloneDeep(treatmentSuggestedChanges);

      const initialMovement = initialMovementValues[selectedTeethTransformData.toothFdi];
      let isInitialMovement = false;
      if (initialMovement === undefined) {
        initialMovementValues[selectedTeethTransformData.toothFdi] = [];
        isInitialMovement = true;
      }

      list.forEach((movement) => {
        const newValue = newValues[movement.movementType] || 0;
        movement.value = newValue;

        if (isInitialMovement) {
          initialMovementValues[selectedTeethTransformData.toothFdi].push({ ...movement });
        }
        const initialValue =
          initialMovementValues[selectedTeethTransformData.toothFdi].find(
            (m) => m.movementType === movement.movementType
          )?.value || 0;

        const key = buildTreatmentChangeKey(
          movement.movementType,
          selectedMovement,
          parseInt(selectedTeethTransformData?.toothFdi)
        );

        if (Math.abs(newValue - initialValue) > EPSILON) {
          treatmentChangesCloned[key] = {
            value: parseFloat(newValue.toFixed(MOVEMENTS_DECIMALS)),
            measureUnit: unitTypeForMovement[movement.movementType]
          } as TreatmentChangeValue;
        } else {
          delete treatmentChangesCloned[key];
        }
      });

      setTreatmentSuggestedChanges(treatmentChangesCloned);
      onSaveMovements(list);
    }

    updateMovementsValues();
  }, [selectedTeethTransformData]);

  const onChangeInput = useCallback(
    (value: any, movementType: MovementType, movementsList: TeethMovementsValue[], measureUnit?: MeasureUnit) => {
      const movementIndex = movementsList.findIndex((m) => m.movementType === movementType);
      const movement = movementsList[movementIndex];
      if (movement) {
        movementsList[movementIndex].value = value;
      } else {
        movementsList.push({
          movementType,
          value,
          measureUnitType: measureUnit
        });
      }

      onSaveMovements([...movementsList]);
    },
    []
  );

  const onSendDataToEvergine = useCallback(
    (list: TeethMovementsValue[], movement: MovementType) => {
      if (!selectedTeethId) {
        return;
      }
      const lastMovementValue = list.find((mov) => mov.movementType === movement);
      const valueToSend = parseFloat(lastMovementValue?.value as any);

      if (lastMovementValue) {
        window.App.webEventsProxy.movements.setToothChange({
          kind: lastMovementValue?.movementType,
          value: valueToSend,
          source: selectedMovement,
          toothFdi: selectedTeethId
        } as ToothChange);
      }
    },
    [selectedTeethId, selectedMovement]
  );

  const sumOrRemoveValue = useCallback(
    (movementType: MovementType, isSum: boolean, measureUnit: MeasureUnit, list: TeethMovementsValue[]) => {
      const movementIndex = list.findIndex((m) => m.movementType === movementType);
      const movement = list[movementIndex];
      const step = measureUnit === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER;

      let value = movement?.value || 0;
      if (isSum) {
        value += step;
      } else {
        value -= step;
      }

      const parsedNumber = Math.round(value * 1e12) / 1e12;

      if (movement) {
        list[movementIndex].value = parsedNumber;
      } else {
        list.push({
          movementType,
          value: parsedNumber,
          measureUnitType: measureUnit
        });
      }

      setMovementsValue([...list]);
      onSendDataToEvergine(list, movementType);
    },
    [onSendDataToEvergine]
  );

  const onAddValueToInput = useCallback(
    (movementType: MovementType, measureUnit?: MeasureUnit) => {
      sumOrRemoveValue(movementType, true, measureUnit, movementsValues);
    },
    [sumOrRemoveValue, movementsValues]
  );

  const onRemoveValueToInput = useCallback(
    (movementType: MovementType, measureUnit?: MeasureUnit) => {
      sumOrRemoveValue(movementType, false, measureUnit, movementsValues);
    },
    [sumOrRemoveValue, movementsValues]
  );

  const getMaxValue = (unitType: MeasureUnit): number => {
    return unitType === MeasureUnit.Millimeter ? 1000 : 360;
  };

  const validateInput = (value: string, teethMovementType: MovementType, measureUnitType: MeasureUnit): boolean => {
    const isNegativeSign = value.startsWith('-');

    if ((value.length === 1 && isNegativeSign) || value.length === 0 || !isNaN(Number(value))) {
      const dotPosition = value.indexOf('.');
      const valueToSave =
        value.length > 1 && dotPosition > 0 && VALID_NUMBER_REGEX.test(value)
          ? Number(value).toFixed(MOVEMENTS_DECIMALS)
          : value;
      onChangeInput(valueToSave, teethMovementType, movementsValues, measureUnitType);
      return;
    }

    if (!VALID_NUMBER_REGEX.test(value)) {
      return;
    }

    onChangeInput(value, teethMovementType, movementsValues, measureUnitType);
  };

  const onClickResetTooth = useCallback(() => {
    if (!selectedTeethId) {
      return;
    }
    setLastMovementType(undefined);
    const changeKey: ToothChange = {
      kind: MovementType.Reset,
      toothFdi: selectedTeethId
    };
    window.App.webEventsProxy.movements.setToothChange(changeKey);
  }, [selectedMovement, selectedTeethId]);

  const getInputValue = useCallback(
    (movementType: MovementType): any => {
      const movement = movementsValues.find((m) => m.movementType === movementType);
      let valueToShow;
      if (selectedTeethId > 0) {
        valueToShow =
          typeof movement?.value === 'number' ? movement?.value.toFixed(MOVEMENTS_DECIMALS) : movement?.value;
      } else {
        valueToShow = 0;
      }

      return valueToShow || '';
    },
    [movementsValues, selectedTeethId]
  );

  const onSelectTool = (movementType: MovementType) => {
    setLastMovementType(movementType);
    window.App.webEventsProxy.movements.selectTool(movementType);
  };

  useEffect(() => {
    if (selectedMovement === SourceOfMovement.Root) {
      if (!ability || ability.can(AbilityAction.Manage, OrthoAbilitySubject.TreatmentValidation)) {
        return setDisabledInput(true);
      }
    }
    return setDisabledInput(false);
  }, [selectedMovement, ability]);

  const isButtonDisabled = useCallback(
    (isToolDisabled: boolean) => {
      return !selectedTeethId || selectedTeethId < 0 || isToolDisabled || disabledInput;
    },
    [selectedTeethId, disabledInput]
  );

  const movementToolComponent = useCallback(
    (tool: TeethMovementsToolsType, index: number) => {
      return (
        <div
          className={`orth-movements__content ${tool.teethMovementType === lastMovementType ? 'is-active' : ''}`}
          key={`s-${index}`}
        >
          <div className="orth-movements__row" onClick={() => onSelectTool(tool.teethMovementType)}>
            <div className="orth-movements__icon">{<tool.icon />}</div>
            <div className="orth-movements__label">{t(tool.titleKey)}</div>
            {!tool.hideButtons && (
              <>
                <input
                  className="orth-movements__input"
                  disabled={isButtonDisabled(tool.disabled)}
                  value={getInputValue(tool.teethMovementType)}
                  onChange={(e) => validateInput(e.target.value, tool.teethMovementType, tool.measureUnitType)}
                  onKeyPress={(e: any) => {
                    if (e.key !== Key.Enter || !VALID_NUMBER_REGEX.test(e.target.value)) {
                      return;
                    }
                    onSendDataToEvergine(movementsValues, tool.teethMovementType);
                  }}
                  placeholder={`0.0 ${tool.measureUnitType}`}
                  max={getMaxValue(tool.measureUnitType)}
                  min={-getMaxValue(tool.measureUnitType)}
                  step={tool.measureUnitType === MeasureUnit.Millimeter ? MM_STEP_NUMBER : DEGREES_STEP_NUMBER}
                  data-testid={tool.teethMovementType}
                />

                <div className="orth-movements__row">
                  <OrthToolButtonSmall
                    Icon={Plus}
                    isDisabled={isButtonDisabled(tool.disabled)}
                    onClick={() => onAddValueToInput(tool.teethMovementType, tool.measureUnitType)}
                  />
                  <OrthToolButtonSmall
                    Icon={Minus}
                    isDisabled={isButtonDisabled(tool.disabled)}
                    onClick={() => onRemoveValueToInput(tool.teethMovementType, tool.measureUnitType)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      );
    },
    [
      disabledInput,
      lastMovementType,
      getInputValue,
      isButtonDisabled,
      onAddValueToInput,
      onRemoveValueToInput,
      onSendDataToEvergine
    ]
  );

  return (
    <div className="orth-movements__teeth-tool">
      <div className="orth-movements__header">
        {/* <Radio
          active={selectedMovement === SourceOfMovement.Crown}
          label={t('dentalMovements.movementsTool.teeth.crown')}
          callBack={() => setSelectedMovement(SourceOfMovement.Crown)}
        />
        <div>
          <Radio
          active={selectedMovement === SourceOfMovement.Root}
          label={t('dentalMovements.movementsTool.teeth.root')}
          callBack={() => setSelectedMovement(SourceOfMovement.Root)}
        />
        </div> */}
        <OrthToolButtonReset Icon={Reset} isDisabled={!selectedTeethId || disabledInput} onClick={onClickResetTooth} />
      </div>
      <div className="orth-movements__separator" />
      <div className="separator" />
      <div className="scroll">
        {teethMovementsTools.map((tool: TeethMovementsToolsType, index: number) => movementToolComponent(tool, index))}
      </div>
    </div>
  );
}
