import { ReactComponent as Warning } from '../../../../assets/icons/modal/warning.svg';

interface ModalRejectProps {
  title: string;
  message: string;
  yesText: string;
  noText: string;
  onClickClose: () => void;
  onClickContinue: () => void;
}

export function ModalReject({ title, message, yesText, noText, onClickClose, onClickContinue }: ModalRejectProps) {
  return (
    <div className="modal-content" data-testid={'modal-client-reject'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Warning className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{title}</h3>
        <p className="modal-text text-center mb-0">{message}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onClickClose}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {noText}
        </button>
        <button onClick={onClickContinue} type="button" className="btn btn-dark flex-fill modal-button">
          {yesText}
        </button>
      </div>
    </div>
  );
}
