export enum StepState {
  InProgress,
  Validated,
  Pending
}

export type AsideConfig = {
  title: string;
  items: AsideConfigItem[];
  tooltip?: string;
};

export type AsideConfigItem = {
  title: string;
  defaultState: StepState;
  key: string;
  route?: string;
};
