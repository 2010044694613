import { inject, injectable } from 'inversify';
import { DentalMovements, Segmentation, SourceOfMovement } from '../../common';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaseVersion, CaseVersionDTO, CaseVersionState } from '../cases';
import type { IHttpService } from '../http';
import {
  DentalMovementDTO,
  DentalMovementDataDTO,
  MovementsValueSelector,
  TreatmentPlanificationMovementsUpdateDTO
} from './dentalMovements';
import { IDentalMovementsService } from './identalMovements.service';

@injectable()
export class DentalMovementsService implements IDentalMovementsService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  private readonly apiEndpoint = '/api/product-cases';

  public async getMovementsTable(
    caseId: string,
    versionId: string,
    source: SourceOfMovement,
    selector: MovementsValueSelector
  ): Promise<DentalMovementDTO[]> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}/dental-movements?source=${source}&selector=${selector}`;

    try {
      const dentalMovementsDTO = (await this._httpService.get<DentalMovementDataDTO>(url)).data.data;
      return dentalMovementsDTO;
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async getTreatmentPlanificationMovements(caseId: string, versionId: string): Promise<DentalMovements> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}`;

    try {
      const treatmentPlanificationMovements = (await this._httpService.get<CaseVersionDTO>(url)).data;
      return treatmentPlanificationMovements.data.plan;
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async updateTreatmentPlanificationMovements(
    caseId: string,
    version: CaseVersion,
    treatmentPlanificationMovements: TreatmentPlanificationMovementsUpdateDTO,
    segmentation: Segmentation
  ): Promise<void> {
    const versionId = version.id.toString();
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}`;
    version.plan = treatmentPlanificationMovements;
    if (!!segmentation) {
      version.segmentation = segmentation;
    }

    try {
      await this._httpService.patch<CaseVersion>(url, version);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async updateTreatmentState(
    caseId: string,
    version: CaseVersion,
    state: CaseVersionState,
    isResetingPlan = false
  ): Promise<void> {
    const versionId = version.id.toString();
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}`;
    version.state = state;

    if (isResetingPlan) {
      version.plan = null;
      version.segmentation = null;
    }

    try {
      await this._httpService.patch<CaseVersion>(url, version);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }
}
