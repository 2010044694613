import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Vector2 } from '../../evergine';
import './customTooltip.scss';

type CustomTooltipProps = {
  text: string;
  position: Vector2;
};

export const CustomTooltip = ({ position, text }: CustomTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<CSSProperties | null>(null);
  useEffect(() => {
    const calculateWidth = () => {
      if (tooltipRef.current) {
        const width = tooltipRef.current.getBoundingClientRect().width;
        const middleWidth = width / 2;
        const calculateY = position.y + 60;
        const calculateX = position.x - middleWidth;
        const dynamicStyles = {
          bottom: `${calculateY}px`,
          left: `${calculateX}px`,
          position: 'absolute'
        } as CSSProperties;
        setStyles(dynamicStyles);
      }
    };

    calculateWidth();
  }, []);

  return (
    <div ref={tooltipRef} className="tooltip-custom" style={styles}>
      <span>{text}</span>
    </div>
  );
};
