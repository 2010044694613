import { StateCreator } from 'zustand';

export interface MessageInfoSlice {
  messageInfo: string;
  setMessageInfo: (messageInfo: string) => void;
}
export const createMessageInfoSlice: StateCreator<MessageInfoSlice, [], [], MessageInfoSlice> = (set) => ({
  messageInfo: '',
  setMessageInfo: (messageInfo: string) => set(() => ({ messageInfo: messageInfo }))
});
