import { Backdrop, Box } from '@mui/material';
import { useEvergineStore } from 'evergine-react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import {
  CapturePosition,
  CaptureType,
  Model,
  Model3dType,
  PagesWithTools,
  Split,
  Stage
} from '../../../common/evergine/types';
import {
  useBaseCaptureActionManager,
  useCHeckValidExtension,
  useCalculateRoutes,
  useCaseChanges,
  useCaseData,
  useCaseId,
  useCaseStatus,
  useCaseType,
  useCustomNavigate,
  useDownloadFiles,
  useGetFiles,
  useModalSaveChanges,
  useModelHelper,
  useRemoveFile,
  useTeetharchAffectation
} from '../../../hooks';
import { CaseType, TeethArchPosition } from '../../../models';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { FileCaptureType, isStringVoid } from '../../../shared';
import { DownloadFile, ImageHeader, RemoveFile, UploadFile } from '../../../surgeries/components/layout';
import { ModalInformation } from '../../../surgeries/components/layout/modal/modalTypes/ModalInformation';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../stores/useStore';
import { EvergineToolbar, toolbarConfig } from '../evergineToolbar';
import { VerticalDivider } from '../layout/vertical-divider';
import { Spinner } from '../spinner';
import './basecaptures.scss';

interface BaseCapturesProps {
  type: CaptureType;
  allowedFileExtension: string;
  pageWithTools?: PagesWithTools;
}

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;

export function BaseCaptures(props: BaseCapturesProps) {
  const { type, allowedFileExtension, pageWithTools } = props;
  const stringType = type === 0 ? 'DICOM' : 'MODEL3D';
  const [t] = useTranslation();
  const [checkValidExtension] = useCHeckValidExtension();
  const [caseId] = useCaseId();
  const { affectations } = useTeetharchAffectation(caseId);
  const { setCanBeConfirmed, isConfirmed, setIsConfirmed, splitMode } = useBoundStore();
  const { patientCase } = useCaseData(caseId);
  const { evergineReady } = useEvergineStore();
  const { getRouteKeepingParameters } = useCustomNavigate();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  const [downloadCaptureFile, isDownloadable] = useDownloadFiles(caseId);
  const {
    toggleModalIsOpened,
    setHasArchModelsLoaded,
    hasArchModelsLoaded,
    setLowerModel3DId,
    setUpperModel3DId,
    lowerModel3DId,
    upperModel3DId
  } = useCommonBoundStore();
  const [modelsId, setModelsId] = useState<Record<string, Record<string, string>>>({
    ['UPPER']: { ['DICOM']: null, ['MODEL3D']: upperModel3DId },
    ['LOWER']: { ['DICOM']: null, ['MODEL3D']: lowerModel3DId }
  });
  const { lowerFilesToUpload, upperFilesToUpload, setLowerFilesToUpload, setUpperFilesToUpload, setSomeCapture } =
    useCommonBoundStore();
  const { currentVersion } = useOrthBoundStore();
  const { removeFileUpper, removeFileLower } = useRemoveFile(caseId, stringType);
  const caseType = useCaseType();
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const pageTools = pageWithTools ? toolbarConfig[pageWithTools] : null;
  const { loadCapture, createDirectory } = useModelHelper();
  const { showLowerCapture, showUpperCapture } = useBaseCaptureActionManager(modelsId, stringType, caseType);

  const [areModelsLoaded, setAreModelsLoaded] = useState<boolean>(hasArchModelsLoaded);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [calculateNextRoute] = useCalculateRoutes(pathname, caseId, caseType);
  const { isCaseModified, onChangeRoute, updateRouteToGo } = useCaseChanges();
  const { getPhaseSaveChange } = useModalSaveChanges();
  const { saveChanges } = getPhaseSaveChange();
  const { getFileInfo } = useGetFiles(caseId);
  const [showModalInfo, setShowModalInfo] = useState<boolean>(false);
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const { isCaseReseting } = useCommonBoundStore();
  const { isActualPhaseCompleted } = useCaseStatus(caseId);
  const isThereCapture = useCallback(
    (position: TeethArchPosition) => {
      return !isStringVoid(modelsId[position][stringType]);
    },
    [modelsId]
  );

  const onlyOneCapture = useMemo(() => {
    if (
      (isThereCapture(TeethArchPosition.UPPER) && !isThereCapture(TeethArchPosition.LOWER)) ||
      (!isThereCapture(TeethArchPosition.UPPER) && isThereCapture(TeethArchPosition.LOWER))
    ) {
      return true;
    }
    return false;
  }, [isThereCapture(TeethArchPosition.UPPER), isThereCapture(TeethArchPosition.LOWER)]);

  // Start onChange block
  const handleFileChange = async (event: ChangeEvent<Element>, modelId: string, teethArch: CapturePosition) => {
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files && eventTarget.files[0];

    if (!file) {
      return;
    }

    const isValidExtension = checkValidExtension(type, file, allowedFileExtension);
    if (!isValidExtension) {
      return;
    }
    eventTarget.value = null;

    createDirectory();

    loadEvergineFiles(file, modelId, teethArch);
  };

  const fileOnClick = async (event: React.MouseEvent) => {
    if (isActualPhaseCompleted() && isCaseReseting === false) {
      event.preventDefault();
      setCanUndo(true);
    }
  };

  const setFileToUpload = useCallback(
    (teethArch: CapturePosition, file: File, path: string, modelId: string) => {
      const isUpper = teethArch === CapturePosition.UPPER;
      const setterFilesToUpload = isUpper ? setUpperFilesToUpload : setLowerFilesToUpload;
      const extension = type === CaptureType.MODEL3D ? 'wepmd' : 'zip';

      if (type === CaptureType.DICOM) {
        setterFilesToUpload([
          {
            name: `${modelId}.${extension}`,
            modelId: modelId,
            path: path,
            size: file.size,
            type: isUpper ? FileCaptureType.UPPER_DICOM : FileCaptureType.LOWER_DICOM
          }
        ]);

        return;
      }

      setterFilesToUpload([
        {
          name: `${modelId}.stl`,
          modelId: modelId,
          path: path,
          size: file.size,
          type: isUpper ? FileCaptureType.UPPER_STL : FileCaptureType.LOWER_STL
        },
        {
          name: `${modelId}.wepmd`,
          modelId: modelId,
          path: path.replace('.stl', '.wepmd'),
          size: file.size,
          type: isUpper ? FileCaptureType.UPPER_MODEL3D : FileCaptureType.LOWER_MODEL3D
        }
      ]);
    },
    [lowerFilesToUpload, upperFilesToUpload, setLowerFilesToUpload, setUpperFilesToUpload]
  );

  const loadEvergineFiles = useCallback(
    (file, modelId, teethArch) => {
      const filepath = `${dynamicModels}/${modelId}.${allowedFileExtension}`;
      const fileFullPath = `${dynamicModelsFullPath}/${modelId}.${allowedFileExtension}`;

      const reader = new FileReader();

      reader.onload = () => {
        let stream: ArrayBuffer = reader.result as ArrayBuffer;
        let data = new Int8Array(stream);

        setFileToUpload(teethArch, file, fileFullPath, modelId);

        if (type === CaptureType.MODEL3D) {
          // upload extra file for stl (we have setted above the wepmd)
          setFileToUpload(teethArch, file, fileFullPath.replace(allowedFileExtension, 'stl'), modelId);
        }

        Module.FS.writeFile(fileFullPath, data);

        window.App.webEventsProxy.common.loadModels([
          {
            id: modelId,
            uri: filepath,
            teethArch: teethArch,
            model3dType: type === CaptureType.DICOM ? null : Model3dType.Scan
          }
        ]);
        setAreModelsLoaded(true);
        setHasArchModelsLoaded(true);

        if (teethArch === CapturePosition.UPPER) {
          setModelsId({
            ...modelsId,
            ['UPPER']: {
              ...modelsId['UPPER'],
              [stringType]: modelId && modelId ? modelId : null
            },
            ['LOWER']: {
              ...modelsId['LOWER']
            }
          });
          setUpperModel3DId(modelId);
        }

        if (teethArch === CapturePosition.LOWER) {
          setModelsId({
            ...modelsId,
            ['LOWER']: {
              ...modelsId['LOWER'],
              [stringType]: modelId && modelId ? modelId : null
            },
            ['UPPER']: {
              ...modelsId['UPPER']
            }
          });
          setLowerModel3DId(modelId);
        }

        data = null;
        stream = null;
      };
      reader.readAsArrayBuffer(file);
    },
    [patientCase, modelsId]
  );
  // End onChange block

  // Start onLoad block
  useEffect(() => {
    setShowBackdrop(true); // this will happen only once

    if (evergineReady) {
      setTimeout(() => {
        window.App.webEventsProxy.common.setStage(Stage.LoadSTL);
      }, 0);
    }
  }, [evergineReady]);

  /* const hideLoader = useMemo(() => {
    console.log('showBackdrop', showBackdrop);
    if (!isModalOpened) {
      return true;
    }
    return false;
  }, [isModalOpened, showBackdrop]); */

  useEffect(() => {
    setUpperModel3DId(modelsId['UPPER']['MODEL3D']);
    setLowerModel3DId(modelsId['LOWER']['MODEL3D']);
  }, [modelsId]);

  useEffect(() => {
    if (areModelsLoaded) {
      setShowBackdrop(false);
      return;
    }

    if (currentVersion === null) {
      return;
    }

    if (evergineReady) {
      const loadFiles = async () => {
        let models: Model[] = [];
        createDirectory();

        // TODO: cuando se recupere el proyecto Cirugías, recoger los DICOMs de una manera similar a los wepmd-stl
        const upperCaseFile = await getFileInfo('upper-wepmd', currentVersion.id);
        const upperCapture = {
          key: FileCaptureType.UPPER_MODEL3D,
          name: upperCaseFile.name,
          url: upperCaseFile.url
        };
        const lowerCaseFile = await getFileInfo('lower-wepmd', currentVersion.id);
        const lowerCapture = {
          key: FileCaptureType.LOWER_MODEL3D,
          name: lowerCaseFile.name,
          url: lowerCaseFile.url
        };

        let upperModel: Model | undefined;

        if (!!upperCapture.url) {
          upperModel = await loadCapture(CapturePosition.UPPER, upperCapture.name, upperCapture.url);
          if (upperModel) {
            upperModel.model3dType = CaptureType.DICOM ? null : Model3dType.Scan;
            models = [...models, upperModel];
          }
        }

        let lowerModel: Model | undefined;
        if (!!lowerCapture.url) {
          lowerModel = await loadCapture(CapturePosition.LOWER, lowerCapture.name, lowerCapture.url);

          if (lowerModel) {
            lowerModel.model3dType = CaptureType.DICOM ? null : Model3dType.Scan;
            models = [...models, lowerModel];
          }
        }

        setModelsId({
          ...modelsId,
          ['UPPER']: {
            ...modelsId['UPPER'],
            [stringType]: upperModel && upperModel.id ? upperModel.id : null
          },
          ['LOWER']: {
            ...modelsId['LOWER'],
            [stringType]: lowerModel && lowerModel.id ? lowerModel.id : null
          }
        });

        setTimeout(() => {
          window.App.webEventsProxy.common.loadModels(models);
          setAreModelsLoaded(true);
          setHasArchModelsLoaded(true);

          setTimeout(() => {
            setShowBackdrop(false);
          }, 2000);
        }, 2000);
      };

      loadFiles();
    }
  }, [evergineReady, areModelsLoaded, currentVersion]);
  // End onLoad block

  // Activate or not save header icon files

  useEffect(() => {
    if (caseType === CaseType.Orthodontics) {
      setCanBeConfirmed(!!modelsId['UPPER'][stringType] || !!modelsId['LOWER'][stringType]);
      return;
    }

    if (affectations.hasUpperAffectation && !affectations.hasLowerAffectation) {
      setCanBeConfirmed(!!modelsId['UPPER'][stringType]);
      return;
    }

    if (affectations.hasLowerAffectation && !affectations.hasUpperAffectation) {
      setCanBeConfirmed(!!modelsId['LOWER'][stringType]);
      return;
    }

    if (affectations.hasLowerAffectation && affectations.hasUpperAffectation) {
      setCanBeConfirmed(!!modelsId['UPPER'][stringType] || !!modelsId['LOWER'][stringType]);
    }
  }, [modelsId, hasArchModelsLoaded]);

  // Trigger confirmation to false in base captures page

  useEffect(() => {
    if (!isConfirmed) {
      return;
    }

    if (currentVersion === null) {
      return;
    }

    const saveBaseCapturesChanges = async () => {
      const nextRoute = calculateNextRoute();
      if (isCaseModified) {
        updateRouteToGo(nextRoute);
        onChangeRoute();
        return;
      }

      const result = await saveChanges();
      if (!result) {
        return;
      }

      navigate(getRouteKeepingParameters(nextRoute));
    };
    if (onlyOneCapture) {
      setWebBusy(false);
      setShowModalInfo(true);
    } else {
      saveBaseCapturesChanges();
    }
    setIsConfirmed(false);
  }, [isConfirmed, upperFilesToUpload, lowerFilesToUpload, areModelsLoaded]);

  useEffect(() => {
    if (isThereCapture(TeethArchPosition.UPPER) || isThereCapture(TeethArchPosition.LOWER)) {
      return setSomeCapture(true);
    }
    return setSomeCapture(false);
  }, [isThereCapture(TeethArchPosition.UPPER), isThereCapture(TeethArchPosition.LOWER)]);

  const saveBaseCaptures = async () => {
    setShowModalInfo(false);
    setWebBusy(true);
    const nextRoute = calculateNextRoute();
    if (isCaseModified) {
      updateRouteToGo(nextRoute);
      onChangeRoute();
      setWebBusy(false);
      return;
    }

    const result = await saveChanges();
    if (!result) {
      setWebBusy(false);
      return;
    }
    setWebBusy(false);
    navigate(nextRoute);
  };

  return (
    <>
      <div className="captures container-fluid g-0 d-flex">
        <Backdrop style={{ zIndex: 9999 }} open={showBackdrop}>
          <Box sx={{ display: 'block', textAlign: 'center' }}>
            <Spinner />
          </Box>
        </Backdrop>
        <div className={`flex-fill captures-item captures-item-border`}>
          <div className="d-flex">
            {showUpperCapture && (
              <ImageHeader title={type === CaptureType.DICOM ? t(`dicomCaptures.topTitle`) : t(`stlCaptures.topTitle`)}>
                <UploadFile
                  fileId={`${type}_${CapturePosition.UPPER}_${uuidv4()}`}
                  onChange={(event, modelId) => handleFileChange(event, modelId, CapturePosition.UPPER)}
                  onClick={(event) => fileOnClick(event)}
                  typesAllowed={`.${allowedFileExtension}`}
                />
                {isThereCapture(TeethArchPosition.UPPER) && (
                  <RemoveFile onClick={() => removeFileUpper(type, modelsId, setModelsId)} />
                )}

                {isDownloadable(CapturePosition.UPPER, type) && (
                  <DownloadFile onClick={() => downloadCaptureFile(CapturePosition.UPPER, type, modelsId)} />
                )}
              </ImageHeader>
            )}
            {showLowerCapture && (
              <ImageHeader
                title={type === CaptureType.DICOM ? t(`dicomCaptures.bottomTitle`) : t(`stlCaptures.bottomTitle`)}
              >
                <UploadFile
                  fileId={`${type}_${CapturePosition.LOWER}_${uuidv4()}`}
                  onChange={(event, modelId) => handleFileChange(event, modelId, CapturePosition.LOWER)}
                  onClick={(event) => fileOnClick(event)}
                  typesAllowed={`.${allowedFileExtension}`}
                />
                {isThereCapture(TeethArchPosition.LOWER) && (
                  <RemoveFile onClick={() => removeFileLower(type, modelsId, setModelsId)} />
                )}
                {isDownloadable(CapturePosition.LOWER, type) && (
                  <DownloadFile onClick={() => downloadCaptureFile(CapturePosition.LOWER, type, modelsId)} />
                )}
              </ImageHeader>
            )}
          </div>
        </div>
        {splitMode !== Split.Single && <VerticalDivider />}
        {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      </div>
      {showModalInfo && (
        <ModalInformation cancelAction={() => setShowModalInfo(false)} continueAction={saveBaseCaptures} />
      )}
    </>
  );
}
