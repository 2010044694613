import { useNavigate } from 'react-router-dom';
import { useCaseData, useCaseId, useGateKeeper } from '../../../hooks';
import { AbilityAction, CommonAbilitySubject } from '../../../shared';
import { Odontogram } from '../odontogram';
import { Piece } from '../piece';

export function SpecificInfo() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.View, CommonAbilitySubject.SpecificInfoScreen, () => navigate('/forbidden'));

  const [caseId] = useCaseId();
  const { patientCase } = useCaseData(caseId);

  return (
    <div className="generalinfo container-md">
      {patientCase && (
        <div className="row ">
          <div className="generalinfo-odontogram col-lg-4  col-md-12">
            <Odontogram
              showTitle={true}
              selectedPieces={[]}
              highlightPieces={patientCase.patient.pieces?.map((piece) => piece.number) ?? []}
            />
          </div>
          <div className="generalinfo-data col-lg-8 col-md-12">
            <div className="row gy-4">
              {patientCase.patient.pieces?.map((piece, index) => (
                <div key={index} className="col-md-12 col-lg-6">
                  <Piece
                    key={piece.number}
                    number={piece.number}
                    implantBrand={piece.implantBrand}
                    size={piece.size}
                    shape={piece.shape}
                    comments={piece.comments}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
