import { StateCreator } from 'zustand';

export interface OrthTeethSegmentationSlice {
  highlightedPieces: string[];
  setHighlightedPieces: (highlightedPieces: string[]) => void;
  selectedPieces: string[];
  setSelectedPieces: (selectedPieces: string[]) => void;
  missingPieces: string[];
  setMissingPieces: (missingPieces: string[]) => void;
  fromToPieces: string[];
  setFromToPieces: (missingPieces: string[]) => void;
  isTeethSegmentationConfirmed: boolean;
  setIsTeethSegmentationConfirmed: (isTeethSegmentationConfirmed: boolean) => void;
  showPaintPanel: boolean;
  setShowPaintPanel: (showPaintPanel: boolean) => void;
  modeBrush: boolean;
  setModeBrush: (param: boolean) => void;
  modeEraser: boolean;
  setModeEraser: (param: boolean) => void;
  showModalEnumeration: boolean;
  setShowModalEnumeration: (param: boolean) => void;
  areSegmentationModelsInFS: boolean;
  setAreSegmentationModelsInFS: (areSegmentationModelsInFS: boolean) => void;
}
/* eslint-disable */
export const createOrthTeethSegmentationSlice: StateCreator<
  OrthTeethSegmentationSlice,
  [],
  [],
  OrthTeethSegmentationSlice
> = (set) => ({
  /* eslint-enable */
  highlightedPieces: [],
  setHighlightedPieces: (highlightedPieces: string[]) => set((_) => ({ highlightedPieces })),
  showPaintPanel: false,
  setShowPaintPanel: (showPaintPanel: boolean) => set((_) => ({ showPaintPanel })),
  selectedPieces: [],
  setSelectedPieces: (selectedPieces: string[]) => set((_) => ({ selectedPieces })),
  missingPieces: [],
  setMissingPieces: (missingPieces: string[]) => set((_) => ({ missingPieces })),
  fromToPieces: [],
  setFromToPieces: (fromToPieces: string[]) => set((_) => ({ fromToPieces })),
  isTeethSegmentationConfirmed: false,
  setIsTeethSegmentationConfirmed: (isTeethSegmentationConfirmed: boolean) =>
    set((_) => ({ isTeethSegmentationConfirmed })),
  modeBrush: false,
  setModeBrush: (param: boolean) => set((_) => ({ modeBrush: param })),
  modeEraser: false,
  setModeEraser: (param: boolean) => set((_) => ({ modeEraser: param })),
  showModalEnumeration: false,
  setShowModalEnumeration: (param: boolean) => set((_) => ({ showModalEnumeration: param })),
  areSegmentationModelsInFS: false,
  setAreSegmentationModelsInFS: (areSegmentationModelsInFS: boolean) => set((_) => ({ areSegmentationModelsInFS }))
});
