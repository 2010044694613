import { useEffect } from 'react';
import { useCaseData, useCaseId, useCaseStatus } from '../../../hooks';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';

export function DesignerEntryPoint() {
  const setProccessUrlByEntryPoint = useOrthBoundStore((state) => state.setProccessUrlByEntryPoint);
  const [caseId] = useCaseId();
  const { fetchCaseStatus } = useCaseStatus(caseId);
  const { fetchPatientCaseVersions } = useCaseData(caseId);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  useEffect(() => {
    setWebBusy(true);
    const fetchAndNavigate = async () => {
      setProccessUrlByEntryPoint(true);
      await fetchPatientCaseVersions();
      await fetchCaseStatus();
    };

    fetchAndNavigate();
  }, []);

  return <></>;
}
