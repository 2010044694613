import { injectable } from 'inversify';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { ICommand } from './command';

export interface ICommandInvoker {
  execute(command: ICommand): Promise<void>;
  undo(): Promise<void>;
  redo(): Promise<void>;
  addEvergineCommand(command: ICommand): void;
  clear(): void;
}

@injectable()
export default class CommandInvokerService implements ICommandInvoker {
  private undoStack: ICommand[];
  private redoStack: ICommand[];

  // this is a weak way of limit single command execution at the time, maybe use some kind of mutex?
  private isExecuting: boolean;

  constructor() {
    this.undoStack = [];
    this.redoStack = [];
  }

  execute(command: ICommand): Promise<void> {
    if (this.isExecuting) {
      return;
    }

    this.isExecuting = true;
    return new Promise<void>(async (resolve) => {
      await command.execute();

      this.undoStack.push(command);
      this.redoStack = [];
      useOrthBoundStore.setState({ canUndo: true });
      useOrthBoundStore.setState({ canRedo: false });
      resolve();
    }).finally(() => (this.isExecuting = false));
  }

  undo(): Promise<void> {
    if (this.isExecuting) {
      return;
    }

    this.isExecuting = true;
    return new Promise<void>(async (resolve) => {
      if (this.undoStack.length <= 0) {
        return;
      }

      const command = this.undoStack.pop();

      if (command) {
        await command.undo();
        useOrthBoundStore.setState({ canUndo: this.undoStack.length > 0 });

        this.redoStack.push(command);
        useOrthBoundStore.setState({ canRedo: true });
      }

      resolve();
    }).finally(() => (this.isExecuting = false));
  }

  redo(): Promise<void> {
    if (this.isExecuting) {
      return;
    }

    this.isExecuting = true;
    return new Promise<void>(async (resolve) => {
      if (this.redoStack.length <= 0) {
        return;
      }

      const command = this.redoStack.pop();

      if (command) {
        await command.execute();
        this.undoStack.push(command);
        useOrthBoundStore.setState({ canUndo: true });
        useOrthBoundStore.setState({ canRedo: this.redoStack.length > 0 });
      }

      resolve();
    }).finally(() => (this.isExecuting = false));
  }

  addEvergineCommand(command: ICommand): void {
    if (command.isEvergineCommand) {
      this.undoStack.push(command);

      if (this.redoStack.length > 0) {
        window.App.webEventsProxy.common.removeActionCommands(this.redoStack);
      }

      this.redoStack.splice(0, this.redoStack.length);
      useOrthBoundStore.setState({ canUndo: true });
      useOrthBoundStore.setState({ canRedo: false });
    }
  }

  clear(): void {
    if (this.undoStack.length > 0 || this.redoStack.length > 0) {
      this.undoStack.splice(0, this.undoStack.length);
      this.redoStack.splice(0, this.redoStack.length);
      useOrthBoundStore.setState({ canUndo: false });
      useOrthBoundStore.setState({ canRedo: false });

      window.App.webEventsProxy.common.clearActionCommands();
    }
  }
}
