import { FunctionComponent } from 'react';
import './orthToolButtonReset.scss';

type OrthToolButtonResetType = {
  Icon: FunctionComponent;
  isDisabled: boolean;
  onClick: () => void;
};

export function OrthToolButtonReset({ Icon, isDisabled, onClick }: OrthToolButtonResetType) {
  return (
    <button className="orth-tool-button-reset" onClick={onClick} disabled={isDisabled}>
      <Icon />
    </button>
  );
}
