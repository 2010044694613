import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientCase } from '../../../shared';
import { DataCardBase, LabelValue } from '../../../surgeries/components';
import { getSpecificInfoTemplateValue } from './OrthoSpecificInfoUtils';

type PatientDataCardProps = {
  patientCase: PatientCase;
  noCard?: boolean;
};

export function SpecificInfoDetailCard({ patientCase, noCard }: PatientDataCardProps) {
  const [t] = useTranslation();

  const getValue = useCallback(getSpecificInfoTemplateValue, [patientCase, t]);

  return (
    <DataCardBase noCard={noCard} title={t('specificInfo.details')}>
      <LabelValue label={t('specificInfo.precutType')} value={t(getValue(patientCase.specificInfo?.treatmentPrecut))} />
      <LabelValue
        label={t('specificInfo.secretRetainer')}
        value={patientCase.specificInfo?.secretRetainers ? t('common.yes') : t('common.no')}
      />
      <LabelValue
        label={t('specificInfo.passiveAligners')}
        value={patientCase.specificInfo?.passiveAligners ? t('common.yes') : t('common.no')}
      />
      <LabelValue
        label={t('specificInfo.useElastics')}
        value={patientCase.specificInfo?.useElastics ? t('common.yes') : t('common.no')}
      />
      <LabelValue
        label={t('specificInfo.distalize')}
        value={patientCase.specificInfo?.distalize ? t('common.yes') : t('common.no')}
      />
      <LabelValue
        label={t('specificInfo.thirdExtraction')}
        value={patientCase.specificInfo?.extractThirdMolar ? t('common.yes') : t('common.no')}
      />
    </DataCardBase>
  );
}
