import './itemChanges.scss';

interface ItemChangesProps {
  description: string;
  piece: number;
}

export function ItemChanges({ description, piece }: ItemChangesProps) {
  return (
    <li className="item-changes list-group-item d-flex justify-content-between align-items-center">
      <span>{description}</span>
      <span>{piece}</span>
    </li>
  );
}
