import { ReactComponent as AutoCalcAxis } from '../../../assets/icons/auto-calc.svg';
import { ReactComponent as ManualFix } from '../../../assets/icons/manual-fix.svg';
import { ToolbarConfigProps } from '../../../common';
import { debouncedSetState } from '../../../common/utils';
import { useOrthBoundStore } from '../../stores/useStore';

export const manualFixToolbarElement: ToolbarConfigProps = {
  icon: ManualFix,
  tooltipKey: 'evergineTools.manualFix',
  keepOpen: true,
  handleClick: () => {
    const axisRootsEditModeCurrentValue = useOrthBoundStore.getState().axixRootsEditMode;
    window.App.webEventsProxy.axisAndRoots.enableEditionForToothRotationAxis(!axisRootsEditModeCurrentValue);
    debouncedSetState(useOrthBoundStore, { axixRootsEditMode: !axisRootsEditModeCurrentValue });
  }
};

export const calcAIAxisRootsToolbarElement: ToolbarConfigProps = {
  icon: AutoCalcAxis,
  tooltipKey: 'evergineTools.autoAxisRoots',
  labelKey: 'evergineTools.autoAxisRoots',
  handleClick: () => {
    useOrthBoundStore.setState({ isAutoAxisRootsConfirmed: true });
  }
};
