import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  grid: {
    background: 'whitesmoke'
  },
  item: {
    padding: '10px'
  }
}));

const Panel = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.grid}
      xs={3}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid className={classes.item} item>
        <Typography variant="h5">Action Panel</Typography>
      </Grid>
      <Grid className={classes.item} item></Grid>
    </Grid>
  );
};

export { Panel };
