export enum CustomErrorEnum {
  NotFound,
  NotOk
}

export class CustomError extends Error {
  private readonly error: CustomErrorEnum;
  constructor(error: CustomErrorEnum) {
    super();
    this.error = error;
  }

  public errorType() {
    return this.error;
  }
}
