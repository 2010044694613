import { StateCreator } from 'zustand';
import { Settings } from '../../../shared/settings';

export interface SettingsSlice {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}
export const createSettingsSlice: StateCreator<SettingsSlice, [], [], SettingsSlice> = (set) => ({
  settings: null,
  setSettings: (settings: Settings) => set(() => ({ settings }))
});
