import React from 'react';
import './filePane.scss';

export type FilePaneHeadProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
};

export type FilePaneContentProps = {
  children: JSX.Element;
};

export interface FilePaneSubComponents {
  Header: React.FC<FilePaneHeadProps>;
  Content: React.FC<FilePaneContentProps>;
}

const Header: React.FC<FilePaneHeadProps> = ({ title, children }) => (
  <div className="file-pane-header">
    <p className="file-pane-header-title">{title}</p>
    <span className="file-pane-header-actions">{children}</span>
  </div>
);
const Content: React.FC<FilePaneContentProps> = ({ children }) => <div className="file-pane-content">{children}</div>;

const FilePane: React.FC<{}> & FilePaneSubComponents = (props) => (
  <div className="file-pane" {...props}>
    {props.children}
  </div>
);

FilePane.Header = Header;
FilePane.Content = Content;

export { FilePane };
