import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';

type ModalInformationTypes = {
  cancelAction: () => void;
  continueAction: () => void;
};

export function ModalInformation({ cancelAction, continueAction }: ModalInformationTypes) {
  const [t] = useTranslation();
  return (
    <div data-testid="modal" className="modal modal-opened">
      <div data-testid="modal-container" className={`modal-dialog modal-dialog-centered `}>
        <div className="modal-content" data-testid={'modal-onlyOneFileInformation'}>
          <div className="modal-body d-flex flex-column align-items-center">
            <Info className="modal-icon mb-3" />
            <h3 className="modal-title mb-4">{t(`modal.onlyOneFile.title`)}</h3>
            <p className="modal-text text-center mb-0">{t(`modal.onlyOneFile.description`)}</p>
          </div>

          <div className="modal-footer d-flex flex-grow-1">
            <button
              onClick={cancelAction}
              type="button"
              className="btn btn-outline-primary flex-fill modal-button"
              data-bs-dismiss="modal"
            >
              {t(`modal.actions.uploadMissingFile`)}
            </button>
            <button onClick={continueAction} type="button" className="btn btn-primary flex-fill modal-button">
              {t(`modal.actions.nextWithOneArch`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
