import { useMemo, useState } from 'react';
import { ToothState } from '../../../../../common';
import { useOrthBoundStore } from '../../../../stores/useStore';

export type ContextMenuOptions = {
  labelKey: string;
  action?: () => void;
  children?: ContextMenuOptions[];
  disabled?: boolean;
  isHidden?: boolean;
  checked?: boolean;
};

export function useOrthContextMenu() {
  const { toothHoverData } = useOrthBoundStore();
  const [isTeethBlocked, setIsTeethBlocked] = useState(toothHoverData?.toothState === ToothState.Lock);
  const [isTeethInmovable, setIsTeethInmovable] = useState(toothHoverData?.toothState === ToothState.Inmovable);

  const options: ContextMenuOptions[] = useMemo(() => {
    return [
      {
        labelKey: 'contextMenuOptions.addAttache',
        action: () => {
          console.log('On add Attache selected');
        },
        disabled: true,
        isHidden: false
      },
      {
        labelKey: 'contextMenuOptions.addAttachement',
        action: () => {
          console.log('On add Attachement selected');
        },
        disabled: true,
        isHidden: false
      },
      {
        labelKey: 'contextMenuOptions.addIPR',
        action: () => {
          console.log('On add IPR selected');
        },
        disabled: true,
        isHidden: false
      },
      {
        labelKey: 'contextMenuOptions.addSpace',
        action: () => {
          console.log('On add space selected');
        },
        disabled: true,
        isHidden: false
      },
      {
        labelKey: 'contextMenuOptions.blockTeethMenu.label',
        isHidden: isTeethInmovable,
        children: [
          {
            labelKey: `contextMenuOptions.blockTeethMenu.${
              isTeethBlocked ? 'unblockTeethPosition' : 'blockTeethPosition'
            }`,
            action: () => {
              setIsTeethBlocked(!isTeethBlocked);
            },
            checked: isTeethBlocked
          },
          {
            labelKey: 'contextMenuOptions.blockTeethMenu.considerImmovable',
            action: () => {
              setIsTeethInmovable(true);
            }
          }
        ]
      },
      {
        labelKey: 'contextMenuOptions.blockTeethMenu.considerMovable',
        action: () => {
          setIsTeethInmovable(false);
        },
        isHidden: !isTeethInmovable
      }
    ].filter((option) => option?.isHidden !== true);
  }, [isTeethBlocked, isTeethInmovable]);

  return { options, toothHoverData };
}
