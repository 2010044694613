import { StateCreator } from 'zustand';
import { Split } from '../../../common/evergine/types';

export interface SplitScreenSlice {
  splitMode: Split | undefined;
  setSplitMode: (splitMode: Split) => void;
}
export const createSplitScreenSlice: StateCreator<SplitScreenSlice, [], [], SplitScreenSlice> = (set) => ({
  splitMode: undefined,
  setSplitMode: (splitMode: Split) => set((_) => ({ splitMode }))
});
