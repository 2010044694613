import { StateCreator } from 'zustand';

export interface TouchDeviceSlice {
  isTouchDevice: boolean | null;
  setIsTouchDevice: (value: boolean) => void;
}
export const createTouchDeviceSlice: StateCreator<TouchDeviceSlice, [], [], TouchDeviceSlice> = (set) => ({
  isTouchDevice: null,
  setIsTouchDevice: (value: boolean) => set(() => ({ isTouchDevice: value }))
});
