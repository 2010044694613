import { useCallback } from 'react';
import { MatchingInfo } from '../../common/evergine/types';
import { CaseFile, PatientCase, isStringVoid as isStringNullOrEmpty } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useFiles } from '../useFiles';

export function useLoadFilesIntoFS() {
  const { isDir, isFile } = useFiles();
  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;
  const { updateMatchingModelPoints } = useBoundStore();

  const createModelsDirectoryIfNotExistent = () => {
    if (!isDir(dynamicModelsFullPath)) {
      console.log('Creating directory ', dynamicModelsFullPath);
      Module.FS.mkdir(dynamicModelsFullPath);
    }
  };

  const isFileInFS = useCallback(
    (fileName: string): boolean => {
      const fileNameFullPath = `${dynamicModelsFullPath}/${fileName}`;
      return isFile(fileNameFullPath);
    },
    [isFile]
  );

  const loadUpperDicomInFS = useCallback(async (patientCase): Promise<void> => {
    if (patientCase.captures3D.upperDICOM.name && patientCase.captures3D.upperDICOM.url) {
      const fileName = `${patientCase.captures3D.upperDICOM.name}`;

      if (isFileInFS(fileName)) {
        return;
      }

      try {
        const res = await fetch(patientCase.captures3D.upperDICOM.url);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        const fileFullPath = `${dynamicModelsFullPath}/${fileName}`;
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(fileFullPath, data);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const loadUpperModel3DInFS = useCallback(async (patientCase: PatientCase, modelInfo?: CaseFile): Promise<void> => {
    if ((patientCase.scannings.upperModel3D.name && patientCase.scannings.upperModel3D.url) || modelInfo) {
      const fileName = `${modelInfo ? modelInfo.name : patientCase.scannings.upperModel3D.name}`;

      if (isFileInFS(fileName)) {
        return;
      }

      try {
        const res = await fetch(modelInfo ? modelInfo.url : patientCase.scannings.upperModel3D.url);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        const fileFullPath = `${dynamicModelsFullPath}/${fileName}`;
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(fileFullPath, data);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const loadLowerDicomInFS = useCallback(async (patientCase: PatientCase): Promise<void> => {
    if (patientCase.captures3D.lowerDICOM.name && patientCase.captures3D.lowerDICOM.url) {
      const fileName = `${patientCase.captures3D.lowerDICOM.name}`;

      if (isFileInFS(fileName)) {
        return;
      }

      try {
        const res = await fetch(patientCase.captures3D.lowerDICOM.url);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        const fileFullPath = `${dynamicModelsFullPath}/${fileName}`;
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(fileFullPath, data);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const loadLowerModel3DInFS = useCallback(async (patientCase: PatientCase, modelInfo?: CaseFile): Promise<void> => {
    if ((patientCase.scannings.lowerModel3D.name && patientCase.scannings.lowerModel3D.url) || modelInfo) {
      const fileName = `${modelInfo ? modelInfo.name : patientCase.scannings.lowerModel3D.name}`;

      if (isFileInFS(fileName)) {
        return;
      }

      try {
        const res = await fetch(modelInfo ? modelInfo.url : patientCase.scannings.upperModel3D.url);
        const arrayBufferData = await res.arrayBuffer();
        const data = new Int8Array(arrayBufferData);
        const fileFullPath = `${dynamicModelsFullPath}/${fileName}`;
        createModelsDirectoryIfNotExistent();
        Module.FS.writeFile(fileFullPath, data);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const patientCaseFilesToMatchingInfo = useCallback((patientCase: PatientCase) => {
    const matchingPoints: Record<string, MatchingInfo> = isStringNullOrEmpty(patientCase.meta)
      ? []
      : JSON.parse(patientCase.meta)['MATCHINGPOINTS'];

    if (!matchingPoints) {
      return;
    }

    const matchingUpperDicomPoints =
      matchingPoints['UPPER'] && matchingPoints['UPPER'].dicomPoints && matchingPoints['UPPER'].dicomPoints.length > 0
        ? matchingPoints['UPPER'].dicomPoints
        : [];
    const matchingUpperModel3dPoints =
      matchingPoints['UPPER'] &&
      matchingPoints['UPPER'].model3dPoints &&
      matchingPoints['UPPER'].model3dPoints.length > 0
        ? matchingPoints['UPPER'].model3dPoints
        : [];
    const matchingLowerDicomPoints =
      matchingPoints['LOWER'] && matchingPoints['LOWER'].dicomPoints && matchingPoints['LOWER']?.dicomPoints.length > 0
        ? matchingPoints['LOWER'].dicomPoints
        : [];
    const matchingLowerModel3dPoints =
      matchingPoints['LOWER'] &&
      matchingPoints['LOWER'].model3dPoints &&
      matchingPoints['LOWER']?.model3dPoints.length > 0
        ? matchingPoints['LOWER'].model3dPoints
        : [];

    const matchingInfo: Record<string, MatchingInfo> = {
      ['UPPER']: {
        dicom: patientCase.captures3D.upperDICOM.name,
        model3D: patientCase.scannings.upperModel3D.name,
        dicomPoints: matchingUpperDicomPoints.length > 0 ? matchingUpperDicomPoints : [],
        model3dPoints: matchingUpperModel3dPoints.length > 0 ? matchingUpperModel3dPoints : []
      },
      ['LOWER']: {
        dicom: patientCase.captures3D.lowerDICOM.name,
        model3D: patientCase.scannings.lowerModel3D.name,
        dicomPoints: matchingLowerDicomPoints.length > 0 ? matchingLowerDicomPoints : [],
        model3dPoints: matchingLowerModel3dPoints.length > 0 ? matchingLowerModel3dPoints : []
      }
    };

    updateMatchingModelPoints(matchingInfo);

    return matchingInfo;
  }, []);

  return {
    loadUpperDicomInFS,
    loadUpperModel3DInFS,
    loadLowerDicomInFS,
    loadLowerModel3DInFS,
    patientCaseFilesToMatchingInfo
  } as const;
}
