import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientCase } from '../../../shared';
import { DataCardBase, LabelValue } from '../../../surgeries/components';
import { getSpecificInfoTemplateValue } from './OrthoSpecificInfoUtils';

type PatientDataCardProps = {
  patientCase: PatientCase;
  noCard?: boolean;
};

export function SpecificInfoTreatmentCard({ patientCase, noCard }: PatientDataCardProps) {
  const [t] = useTranslation();

  const getValue = useCallback(getSpecificInfoTemplateValue, [patientCase, t]);

  return (
    <DataCardBase noCard={noCard} title={t('specificInfo.treatmentTarget')}>
      <LabelValue label="Objetivo" value={t(getValue(patientCase.specificInfo?.treatmentTarget))} />
      <LabelValue label={t('specificInfo.patientProblems')} value={patientCase.specificInfo?.patientProblems} />
    </DataCardBase>
  );
}
