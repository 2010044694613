export interface TabProps {
  id: string;
  label: string;
  children: any;
  disabled?: boolean;
}

export function CustomTab(props: TabProps) {
  return props.children;
}
