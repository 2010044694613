import { StateCreator } from 'zustand';
import { ChatMessage } from '../../../shared/chat';

export interface ChatSlice {
  messages: ChatMessage[];
  setMessages: (messages: ChatMessage[]) => void;
  chatTextToSend: string;
  setChatTextToSend: (text: string) => void;
}

export const createChatSlice: StateCreator<ChatSlice, [], [], ChatSlice> = (set) => ({
  messages: [],
  setMessages: (messages) => set(() => ({ messages })),
  chatTextToSend: '',
  setChatTextToSend: (text) => set(() => ({ chatTextToSend: text }))
});
