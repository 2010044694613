import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaseFile, CaseFileInfoDto } from '../cases';
import type { IHttpService } from '../http';
import { FileToUploadDTO } from './fileToUpload.model';
import { IFileService } from './ifile.service';
import { isStringVoid } from '../functionalities.helper';

@injectable()
export class FileService implements IFileService {
  private readonly caseIdToSubstitute = '{caseIdPlaceholder}';
  private readonly versionIdToSubstitute = '{versionIdPlaceholder}';
  private readonly fileKeyToSubstitute = '{fileKeyPlaceholder}';
  private readonly apiEndpoint = `/api/product-cases/${this.caseIdToSubstitute}/treatment-plans/${this.versionIdToSubstitute}/files/${this.fileKeyToSubstitute}`;
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  getApiPath = (caseId: string, versionId: string, fileId: string) =>
    this.apiEndpoint
      .replace(this.caseIdToSubstitute, caseId)
      .replace(this.versionIdToSubstitute, versionId)
      .replace(this.fileKeyToSubstitute, fileId);

  public async uploadFile(caseId: string, versionId: string, fileKey: string, file: FileToUploadDTO): Promise<void> {
    const apiPath = this.getApiPath(caseId, versionId, fileKey);
    await this._httpService.post(apiPath, file);
  }

  public async removeFile(caseId: string, versionId: string, fileKey: string): Promise<void> {
    const apiPath = this.getApiPath(caseId, versionId, fileKey);
    await this._httpService.delete(apiPath);
  }

  public async getFileInfo(caseId: string, versionId: string, fileKey: string): Promise<CaseFile | null> {
    const apiPath = this.getApiPath(caseId, versionId, fileKey);
    const backendData = (await this._httpService.get<CaseFileInfoDto>(apiPath)).data;
    const fileInfo = Array.isArray(backendData.data) ? backendData.data[0] : backendData.data;

    const file: CaseFile = {
      key: isStringVoid(fileInfo?.identifier) ? fileInfo?.collectionName : fileInfo?.identifier,
      name: fileInfo?.fileName,
      url: fileInfo?.url
    };
    return file;
  }
}
