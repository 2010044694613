import { useEffect, useMemo, useRef, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { useTranslation } from 'react-i18next';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';

import { useHistoryTreatmentChanges, useStepsManager } from '../../../../../hooks/orthodontics';
import { useTreatmentSuggestedChanges } from '../../../../../hooks/orthodontics/useTreatmentSuggestedChanges';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ModalTypes } from './modal-types.enum';
import './modalClientValidationsSendChanges.scss';

export type RequestedChangeToShow = {
  request: string;
  pieceIdentifier: string;
};

export function ModalClientValidationsSendChanges() {
  const [t] = useTranslation();
  const { setShowMovements, upperDentalMovements, lowerDentalMovements } = useOrthBoundStore();
  const {
    toggleModalIsOpened,
    setIsModalXL,
    setModalMessage,
    setModalTitle,
    setIsNewStepClientModification,
    setActiveStep
  } = useCommonBoundStore();
  const [isChangesScrollVisible, setIsChangesScrollVisible] = useState<boolean>();
  const [comments, setComments] = useState<string>();
  const { getTreatmentChangesToShow, cancelTreatmentChanges } = useTreatmentSuggestedChanges();
  const { sendClientPlanValidation } = useHistoryTreatmentChanges();
  const { goToStep } = useStepsManager();
  const changesToShow = getTreatmentChangesToShow(); // ToDo: Refactor this, performance problem.

  const maxStoreStepsLength = useMemo(
    () => Math.max(upperDentalMovements?.steps?.length || 0, lowerDentalMovements?.steps?.length || 0),
    [upperDentalMovements, lowerDentalMovements]
  );

  useEffect(() => {
    setIsModalXL(true);

    return () => {
      setIsModalXL(false);
    };
  }, []);

  const formRef = useRef<HTMLFormElement>();
  const changesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!changesRef.current) {
      return;
    }

    const isScrollVisible = changesRef.current?.clientHeight < changesRef.current?.scrollHeight;
    setIsChangesScrollVisible(isScrollVisible);
  }, [changesRef, changesToShow]);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onSaveAndSendClick = async () => {
    try {
      await sendClientPlanValidation(comments);
      setModalTitle(t('modal.clientValidation.send.requestedChangesOKTitle'));
      setModalMessage(t('modal.clientValidation.send.requestedChangesOKMessage'));
      toggleModalIsOpened('');
      toggleModalIsOpened(ModalTypes.ModalAutoClosed);
      cancelTreatmentChanges(); // ToDo: fix, do not clear changesToShow
      const actualStep = maxStoreStepsLength - 2;
      goToStep(actualStep, false);
    } catch (error) {
      setModalTitle(t(`modal.clientValidation.send.requestedChangesKOTitle`));
      setModalMessage(t('modal.clientValidation.send.requestedChangesKOMessage'));
      toggleModalIsOpened(ModalTypes.ModalError);
    }
  };

  return (
    <div className="modal-content" data-testid={'modal-client-validations-send'}>
      <div className="modal-body">
        <h3 className="modal-title mb-4">{t('modal.clientValidation.send.title')}</h3>
        <div className="sendchanges">
          <div className="sendchanges-column">
            <p className="modal-text mb-3 sendchanges-title">{t('modal.clientValidation.send.description')}</p>

            <Form ref={formRef}>
              <Form.Group>
                <Form.Label className="modal-label">{t('modal.clientValidation.send.textAreaTitle')}</Form.Label>
                <Form.Control as="textarea" rows={10} value={comments} onChange={(e) => setComments(e.target.value)} />
              </Form.Group>
            </Form>

            <div className="sendchanges-actions">
              <button
                onClick={onCloseButtonClick}
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {t('modal.actions.cancel')}
              </button>
              <button
                onClick={onSaveAndSendClick}
                disabled={changesToShow.length === 0}
                type="button"
                className="btn btn-primary"
              >
                {t('modal.actions.saveAndSend')}
              </button>
            </div>
          </div>

          <div className={`sendchanges-column sendchanges-changes ${isChangesScrollVisible && 'is-scroll'}`}>
            <div
              className={`sendchanges-changes__change sendchanges-changes__header ${
                isChangesScrollVisible && 'is-scroll'
              }`}
            >
              <div>{t('modal.clientValidation.send.requestedModification')}</div>
              <div>{t('modal.clientValidation.send.piece')}</div>
            </div>
            <div className="sendchanges-changes__body" ref={changesRef}>
              {changesToShow &&
                changesToShow.map((change, i) => (
                  <div className="sendchanges-changes__change" key={`sendchanges-change${i}${change.pieceIdentifier}`}>
                    <div>{change.request}</div>
                    <div>{change.pieceIdentifier}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
