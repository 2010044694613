import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import ShowCarouselIcon from '../../../../../assets/icons/show-carousel.svg';
import './slideShowBackdrop.scss';

export function SlideShowBackdrop({ open, onClick, children }: any) {
  const [t] = useTranslation();
  return (
    <>
      <Backdrop
        sx={{ color: '#FFFFFF', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}
        open={open}
        onClick={onClick}
      >
        <div className="slideshowbackdrop" onClick={onClick}>
          <img src={ShowCarouselIcon} alt={t('photographs.showSlides')} />
          <span>{t('photographs.showSlides')}</span>
        </div>
      </Backdrop>
      {children}
    </>
  );
}
