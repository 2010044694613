import { injectable } from 'inversify';
import { CaseType } from '../../models';
import { CustomError, CustomErrorEnum } from '../custom-error';
import { CaseVersion, CaseVersionDTO, CaseVersionState, MinorVersion, PatientCase } from './case';
import { ICasesService } from './icases.service';

const piecesUpperTeetharch = [
  {
    number: '11',
    implantBrand: 'MPI',
    size: {
      height: 3.5,
      width: 8
    },
    shape: 'Cónico',
    comments: 'Colocar ambos implantes con la misma angulación'
  },
  {
    number: '27',
    implantBrand: 'MPI',
    size: {
      height: 3.5,
      width: 8
    },
    shape: 'Cónico',
    comments: 'Colocar ambos implantes con la misma angulación'
  }
];

const piecesLowerTeetharch = [
  {
    number: '38',
    implantBrand: 'MPI',
    size: {
      height: 3.5,
      width: 8
    },
    shape: 'Cónico',
    comments: 'Colocar ambos implantes con la misma angulación'
  }
];

const mockedPatientCase: PatientCase = {
  id: new Date().valueOf().toString(),
  patient: {
    name: 'Carmen González Fernández',
    dateOfBirth: '01/01/90',
    gender: 'Femenino',
    pieces: []
  },
  type: CaseType.Surgery,
  // anamnesis: {
  //   checks: [
  //     'El paciente no está bajo tratamiento (...)',
  //     'El paciente no está ni ha estado durante los últimos meses (...)'
  //   ]
  // },
  anamnesis: [],
  clinic: {
    name: 'Contoso Dental',
    address: 'Av. Principal, 63 - 28000 Madrid'
  },
  comments: 'Paciente embarazada. Refiere dolores de cabeza frecuentes y alergia a la penicilina',
  captures3D: undefined,
  photographs: undefined,
  scannings: undefined,
  meta: undefined
};

const mockedVersionsCase: CaseVersion[] = [
  {
    caseId: 's-123456',
    createdAt: new Date(),
    updatedAt: new Date(),
    name: 'Version 1',
    result: CaseVersionState.readyForProduction,
    plan: null,
    segmentation: null,
    legacyPlan: null,
    state: CaseVersionState.published,
    id: '1',
    permissions: {
      acceptance: true
    }
  }
];

const mockedMinorVersions: MinorVersion[] = [
  {
    caseId: 's-123456',
    createdAt: new Date(),
    stepIndex: 1,
    versionId: '1',
    minorVersionId: '1',
    upperJawToothMovements: [
      {
        toothId: '1',
        transform: [1, 2, 4]
      }
    ],
    lowerJawToothMovements: [
      {
        toothId: '1',
        transform: [1, 2, 4]
      }
    ]
  }
];

const notFoundMockId = 'NOT_FOUND';
const errorMockId = 'ERROR';

@injectable()
export class CasesServiceMock implements ICasesService {
  public versionIdUrlParamIndex = 'versionId';

  getCaseVersion(caseId: string, versionId: string): Promise<CaseVersionDTO> {
    return Promise.resolve({ data: mockedVersionsCase[0] });
  }
  public getById(id: string): Promise<PatientCase> {
    if (id === notFoundMockId) {
      throw new CustomError(CustomErrorEnum.NotFound);
    } else if (id === errorMockId) {
      throw new CustomError(CustomErrorEnum.NotOk);
    }

    if (id.includes('up')) {
      return Promise.resolve({
        ...mockedPatientCase,
        patient: { ...mockedPatientCase.patient, pieces: piecesUpperTeetharch }
      });
    }

    if (id.includes('down')) {
      return Promise.resolve({
        ...mockedPatientCase,
        patient: { ...mockedPatientCase.patient, pieces: piecesLowerTeetharch }
      });
    }

    return Promise.resolve({
      ...mockedPatientCase,
      patient: { ...mockedPatientCase.patient, pieces: [...piecesUpperTeetharch, ...piecesLowerTeetharch] }
    });
  }

  public update(id: string, data: string): Promise<PatientCase> {
    return Promise.resolve({
      ...mockedPatientCase,
      patient: { ...mockedPatientCase.patient, pieces: piecesUpperTeetharch }
    });
  }

  public getVersionsByCaseId(id: string): Promise<CaseVersion[]> {
    if (id === notFoundMockId) {
      throw new CustomError(CustomErrorEnum.NotFound);
    } else if (id === errorMockId) {
      throw new CustomError(CustomErrorEnum.NotOk);
    }

    return Promise.resolve({
      ...mockedVersionsCase
    });
  }

  public editCaseVersion(id: string, version: CaseVersion): Promise<void> {
    return Promise.resolve();
  }

  public createCaseVersion(id: string, version: CaseVersion): Promise<void> {
    if (id === notFoundMockId) {
      throw new CustomError(CustomErrorEnum.NotFound);
    } else if (id === errorMockId) {
      throw new CustomError(CustomErrorEnum.NotOk);
    }
    return Promise.resolve();
  }

  public cloneCaseVersion(id: string, versionId: string): Promise<void> {
    return Promise.resolve();
  }

  public deleteCaseVersion(id: string, versionId: string): Promise<void> {
    return Promise.resolve();
  }

  public getMinorVersions(caseId: string, versionId: string, numberOfSteps: number): Promise<MinorVersion[]> {
    return Promise.resolve(mockedMinorVersions);
  }
}
