import { StateCreator } from 'zustand';
import { MesioDistalLength } from '../../../common';

export interface OrthAxisRootsSlice {
  isAutoAxisRootsConfirmed: boolean;
  setIsAutoAxisRootsConfirmed: (isAutoAxisRootsConfirmed: boolean) => void;
  mesioDistalLength: MesioDistalLength | null;
  setMesioDistalLength: (mesioDistalLength: MesioDistalLength) => void;
}

export const createOrthAxisRootsSlice: StateCreator<OrthAxisRootsSlice, [], [], OrthAxisRootsSlice> = (set) => ({
  isAutoAxisRootsConfirmed: false,
  setIsAutoAxisRootsConfirmed: (isAutoAxisRootsConfirmed: boolean) => set((_) => ({ isAutoAxisRootsConfirmed })),
  mesioDistalLength: null,
  setMesioDistalLength: (mesioDistalLength: MesioDistalLength) => set((_) => ({ mesioDistalLength }))
});
