import { useTranslation } from 'react-i18next';
import { PieceData } from '../../../shared';
import { DataCardBase } from '../dataCard';
import { LabelValue } from '../labelValue';

export function Piece(props: PieceData) {
  const [t] = useTranslation();
  const { number, size, shape, comments, implantBrand } = props;

  return (
    <>
      <DataCardBase isToothCard={true} title={`${t('specificInfo.piece')} ${number ?? 'N/A'}`}>
        <LabelValue label={t('specificInfo.brand')} value={implantBrand ?? 'N/A'} />
        <LabelValue
          label={t('specificInfo.lengthAndWidth')}
          value={`${size?.width ?? 'N/A'} x ${size?.height ?? 'N/A'}`}
        />
        <LabelValue label={t('specificInfo.shape')} value={shape ?? 'N/A'} />
        <LabelValue label={t('specificInfo.comments')} value={comments ?? '--'} />
      </DataCardBase>
    </>
  );
}
