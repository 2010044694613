import { StateCreator } from 'zustand';
import { IprLabelPosition } from '../../../common/evergine';

export interface OrthIPRSlice {
  iprLabelSelected?: IprLabelPosition;
  setIprLabelSelected: (iprLabelSelected: IprLabelPosition) => void;
}

export const createOrthIPRSlice: StateCreator<OrthIPRSlice, [], [], OrthIPRSlice> = (set) => ({
  iprLabelSelected: undefined,
  setIprLabelSelected: (iprLabelSelected: IprLabelPosition) => set((_) => ({ iprLabelSelected }))
});
