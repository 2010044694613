const specificInfoTemplates: { [key: string]: string } = {
  trim_leaving_of_gum: 'specificInfo.precutAlignerOption1',
  trim_at_neckline: 'specificInfo.precutAlignerOption2',
  esthetic: 'specificInfo.esthetic',
  occlusion: 'specificInfo.occlusion',
  esthetic_occlusion: 'specificInfo.estheticOcclusion'
};

export const getSpecificInfoTemplateValue = (value?: string) => {
  return value ? specificInfoTemplates[value] : '-';
};
