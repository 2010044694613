import { useState } from 'react';
import { SlideShowBackdrop } from '../../../surgeries/components/captures/photoCaptures/slideShowBackdrop';

interface FileItemProps {
  label: string;
  src: string;
  onClickBackdrop: () => void;
}

export function FileItem(props: FileItemProps) {
  const { label, src, onClickBackdrop } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <p>{label}</p>
      <div
        className="file-item"
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        onClick={onClickBackdrop}
      >
        <SlideShowBackdrop open={open}>
          <img className="file-item-image" src={src} alt={src} />
        </SlideShowBackdrop>
      </div>
    </>
  );
}
