import { ActionComponent } from '../../../../../models';

export const navBarActionsConfigSurgery = {
  content: {
    body: [
      {
        '/general-information': [
          { component: ActionComponent.nextStepAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/specific-information': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/dicom-captures': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.confirmAction, hasNextPath: true },
          { component: ActionComponent.informationAction },
          { component: ActionComponent.splitScreenAction }
        ]
      },
      {
        '/stl-captures': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.confirmAction, hasNextPath: true },
          { component: ActionComponent.informationAction },
          { component: ActionComponent.splitScreenAction }
        ]
      },
      {
        '/photo-captures': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.nextStepAction },
          { component: ActionComponent.confirmAction },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/matching': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.confirmAction, hasNextPath: true },
          { component: ActionComponent.surgeryTeethArch },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/result-matching': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.surgeryTeethArch },
          { component: ActionComponent.confirmAction, hasNextPath: true },
          { component: ActionComponent.informationAction }
        ]
      },
      {
        '/implants': [
          { component: ActionComponent.previousStepAction },
          { component: ActionComponent.surgeryTeethArch },
          { component: ActionComponent.confirmAction, hasNextPath: true },
          { component: ActionComponent.informationAction }
        ]
      }
    ]
  }
};
