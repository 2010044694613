import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';

import { EvolutionStep } from '..';
import { IprLabel } from '../../../../common/evergine';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { QuadrantRows } from './QuadrantRows';
import './evolutionTable.scss';
import { useStepsManager } from '../../../../hooks/orthodontics';

interface EvolutionTableProps {
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (applyIPRList: IprLabel[]) => void;
  opened: boolean;
}

export function EvolutionTable({ steps, applyIPRList, updateIPRList, opened }: EvolutionTableProps) {
  const { showEvolutionPanel } = useOrthBoundStore();
  const { goToStep } = useStepsManager();
  const [isBodyScrollVisible, setIsBodyScrollVisible] = useState<boolean>();
  const { activeStep, setActiveStep, isNewStepClientModification } = useCommonBoundStore();
  const headerStepId = 'evolutiontable-step-';
  const piecesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!piecesRef.current) {
      return;
    }

    const isScrollVisible = piecesRef.current?.clientHeight < piecesRef.current?.scrollHeight;
    setIsBodyScrollVisible(isScrollVisible);
  }, [piecesRef, showEvolutionPanel]);

  const bodyActiveStyle = useMemo(() => {
    const selectedStepIndexEl = document.getElementById(`${headerStepId}${activeStep}`);
    const positionX = selectedStepIndexEl?.offsetLeft || 8;
    const styles = {
      '--before-height': `${piecesRef.current?.scrollHeight}px`,
      '--position-x': `${positionX}px`,
      '--visibility-before': selectedStepIndexEl ? 'block' : 'none'
    } as React.CSSProperties;
    return styles;
  }, [activeStep, piecesRef]);

  const highlightedButtons = useMemo(() => {
    return (
      <>
        {steps.map((step: EvolutionStep) => {
          return (
            <button
              className={`evolutiontable-header--step ${step.index === activeStep ? 'is-active' : ''}`}
              key={`evolutiontable-step-${step.index}`}
              id={`${headerStepId}${step.index}`}
              onClick={() => {
                goToStep(step.index, false);
              }}
              disabled={isNewStepClientModification}
            >
              {step.index}
            </button>
          );
        })}
      </>
    );
  }, [activeStep, steps, isNewStepClientModification]);

  return (
    <div className="evolutiontable">
      <div className={`evolutiontable-header ${isBodyScrollVisible ? 'is-scroll' : ''}`}>{highlightedButtons}</div>
      <div className="evolutiontable-body" style={bodyActiveStyle} ref={piecesRef}>
        {opened && <QuadrantRows steps={steps} updateIPRList={updateIPRList} applyIPRList={applyIPRList} />}
      </div>
    </div>
  );
}
