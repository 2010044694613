import { ReactComponent as IconChecked } from '../../../assets/icons/checked.svg';
import './checkedInfo.scss';

type CheckedInfoProps = {
  info: string;
};

export function CheckedInfo({ info }: CheckedInfoProps) {
  return (
    <div className="checkedinfo">
      <div className="checkedinfo-tick">
        <IconChecked />
      </div>
      <div className="checkedinfo-info">{info}</div>
    </div>
  );
}
