import { useEvergineStore } from 'evergine-react';
import { useCallback } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DropdownToggleIcon } from '../../../../../assets/icons/arrow-dropdown.svg';
import CamBackIcon from '../../../../../assets/icons/evergineTools/cam-back.svg';
import CamFrontIcon from '../../../../../assets/icons/evergineTools/cam-front.svg';
import CamLeftIcon from '../../../../../assets/icons/evergineTools/cam-left.svg';
import CamLowerIcon from '../../../../../assets/icons/evergineTools/cam-lower.svg';
import CamRightIcon from '../../../../../assets/icons/evergineTools/cam-rigth.svg';
import CamUpperIcon from '../../../../../assets/icons/evergineTools/cam-upper.svg';
import { ReactComponent as Eye } from '../../../../../assets/icons/eye.svg';
import { NavBarAction } from '../../../../../common';
import { CameraPosition } from '../../../../../common/evergine/types';
import { Constants } from '../../../../../shared';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import './camViewAction.scss';
import { useWindowSize } from '../../../../../hooks';

export default () => {
  const [t] = useTranslation();
  const { camView, splitMode, setCamView } = useBoundStore();
  const { evergineReady } = useEvergineStore();
  const size = useWindowSize();

  const handleClick = useCallback(
    async (cameraPosition: CameraPosition) => {
      if (!evergineReady) {
        return;
      }

      window.App.webEventsProxy.common.setCameraPosition(cameraPosition);
      setCamView(cameraPosition);
    },
    [evergineReady, camView, splitMode]
  );

  const dropdownButtonProps: any = {
    'data-for': 'camviewaction',
    'data-tip': true
  };

  const getItemsOnARow = () => (
    <>
      <NavBarAction
        icon={CamFrontIcon}
        dataFor="showFrontCam"
        altText={t('navbar.actions.camView.front')}
        action={() => handleClick(CameraPosition.Front)}
      />
      <ReactTooltip id="showFrontCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.front')}
      </ReactTooltip>
      <NavBarAction
        icon={CamUpperIcon}
        dataFor="showTopCam"
        altText={t('navbar.actions.camView.top')}
        action={() => handleClick(CameraPosition.Top)}
      />
      <ReactTooltip id="showTopCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.top')}
      </ReactTooltip>

      <NavBarAction
        icon={CamLowerIcon}
        dataFor="showBottomCam"
        altText={t('navbar.actions.camView.bottom')}
        action={() => handleClick(CameraPosition.Bottom)}
      />
      <ReactTooltip id="showBottomCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.bottom')}
      </ReactTooltip>

      <NavBarAction
        icon={CamLeftIcon}
        dataFor="showLeftCam"
        altText={t('navbar.actions.camView.leftSide')}
        action={() => handleClick(CameraPosition.Left)}
      />
      <ReactTooltip id="showLeftCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.leftSide')}
      </ReactTooltip>

      <NavBarAction
        icon={CamRightIcon}
        dataFor="showRightCam"
        altText={t('navbar.actions.camView.rightSide')}
        action={() => handleClick(CameraPosition.Right)}
      />
      <ReactTooltip id="showRightCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.rightSide')}
      </ReactTooltip>

      <NavBarAction
        icon={CamBackIcon}
        dataFor="showBackCam"
        altText={t('navbar.actions.camView.rear')}
        action={() => handleClick(CameraPosition.Back)}
      />
      <ReactTooltip id="showBackCam" place="bottom" effect="solid" className="tooltip" type="dark">
        {t('navbar.actions.camView.rear')}
      </ReactTooltip>
    </>
  );

  const getItemsOnDropdown = () => (
    <>
      <Dropdown className="camviewaction-dropdown" as={ButtonGroup} autoClose="outside">
        <Dropdown.Toggle variant="language" {...dropdownButtonProps} id="dropdown-basic">
          <Eye />
          <DropdownToggleIcon className="icon rotate-90 ms-3" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Front)}
            className={`camviewaction-dropdown-item `}
            active={camView === CameraPosition.Front}
          >
            <CamFrontIcon />
            {t('navbar.actions.camView.front')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Back)}
            className={`camviewaction-dropdown-item `}
            active={camView === CameraPosition.Back}
          >
            <CamBackIcon />
            {t('navbar.actions.camView.rear')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Left)}
            className={`camviewaction-dropdown-item`}
            active={camView === CameraPosition.Left}
          >
            <CamLeftIcon />
            {t('navbar.actions.camView.leftSide')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Right)}
            className={`camviewaction-dropdown-item `}
            active={camView === CameraPosition.Right}
          >
            <CamRightIcon />
            {t('navbar.actions.camView.rightSide')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Top)}
            className={`camviewaction-dropdown-item `}
            active={camView === CameraPosition.Top}
          >
            <CamUpperIcon />
            {t('navbar.actions.camView.top')}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleClick(CameraPosition.Bottom)}
            className={`camviewaction-dropdown-item `}
            active={camView === CameraPosition.Bottom}
          >
            <CamLowerIcon />
            {t('navbar.actions.camView.bottom')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ReactTooltip id={'camviewaction'} place="bottom" effect="solid" className="tooltip">
        {t(`navbar.actions.information.camera`)}
      </ReactTooltip>
    </>
  );

  return size.width <= Constants.widthMSizePixels ? getItemsOnDropdown() : getItemsOnARow();
};
