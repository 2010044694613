import { useCallback } from 'react';
import { CaptureType } from '../../common/evergine/types';
import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { FileCaptureType, IFileService } from '../../shared/file';
import { useFiles } from '../useFiles';
import { useCaseStatus } from './useCaseStatus';

export function useRemoveFile(caseId: string, stringType?: string) {
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);
  const { removeResourcesByName } = useFiles();
  const { currentVersion } = useOrthBoundStore();
  const keysFilesToDelete = useCommonBoundStore((state) => state.keysFilesToDelete);
  const setKeysFilesToDelete = useCommonBoundStore((state) => state.setKeysFilesToDelete);
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const { isCaseReseting } = useCommonBoundStore();
  const { isActualPhaseCompleted } = useCaseStatus(caseId);

  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;

  const removeFile = async (fileId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await fileService.removeFile(caseId, currentVersion!.id, fileId);
  };

  const deleteLocalFiles = (modelIdDICOM: string) => {
    removeResourcesByName(modelIdDICOM, dynamicModelsFullPath);
  };

  const removeFileUpper = useCallback(
    async (
      type: CaptureType,
      modelsId: Record<string, Record<string, string>>,
      setModelsId: (modelsId: any) => void
    ) => {
      if (isActualPhaseCompleted() && isCaseReseting === false) {
        setCanUndo(true);
        return;
      }
      const identifier = type === CaptureType.DICOM ? FileCaptureType.UPPER_DICOM : FileCaptureType.UPPER_MODEL3D;

      if (identifier === FileCaptureType.UPPER_MODEL3D) {
        setKeysFilesToDelete([...keysFilesToDelete, FileCaptureType.UPPER_STL, FileCaptureType.UPPER_MODEL3D]);
      } else {
        setKeysFilesToDelete([...keysFilesToDelete, identifier]);
      }

      const model = modelsId['UPPER'][CaptureType[type]];
      deleteLocalFiles(model);

      setModelsId({
        ...modelsId,
        ['UPPER']: {
          ...modelsId['UPPER'],
          [stringType]: null
        },
        ['LOWER']: {
          ...modelsId['LOWER']
        }
      });

      window.App.webEventsProxy.common.unloadModels([model]);
    },
    [keysFilesToDelete, isCaseReseting]
  );

  const removeFileLower = useCallback(
    async (
      type: CaptureType,
      modelsId: Record<string, Record<string, string>>,
      setModelsId: (modelsId: any) => void
    ) => {
      if (isActualPhaseCompleted() && isCaseReseting === false) {
        setCanUndo(true);
        return;
      }
      const identifier = type === CaptureType.DICOM ? FileCaptureType.LOWER_DICOM : FileCaptureType.LOWER_MODEL3D;

      if (identifier === FileCaptureType.LOWER_MODEL3D) {
        setKeysFilesToDelete([...keysFilesToDelete, FileCaptureType.LOWER_STL, FileCaptureType.LOWER_MODEL3D]);
      } else {
        setKeysFilesToDelete([...keysFilesToDelete, identifier]);
      }

      const model = modelsId['LOWER'][CaptureType[type]];
      deleteLocalFiles(model);

      setModelsId({
        ...modelsId,
        ['LOWER']: {
          ...modelsId['LOWER'],
          [stringType]: null
        },
        ['UPPER']: {
          ...modelsId['UPPER']
        }
      });

      window.App.webEventsProxy.common.unloadModels([model]);
    },

    [keysFilesToDelete, isCaseReseting]
  );

  const removeCaptureFile = async (identifier: string) => {
    try {
      await removeFile(identifier);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    removeFile,
    removeFileUpper,
    removeFileLower,
    removeCaptureFile
  } as const;
}
