import { useEvergineStore } from 'evergine-react';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { EvergineToolbar, INJECTED_TYPES, PagesWithTools, Stage, container, toolbarConfig } from '../../../common';
import { useBase64Converter, useCaseId, useRenderModels } from '../../../hooks';
import { AxisRootsOrientationFileDTO, IAxisRootsOrientationService } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import './axisAndRoots.scss';

export function AxisAndRoots() {
  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.AxisAndRoots);
  const pageTools = toolbarConfig[PagesWithTools.Roots];
  const { evergineReady } = useEvergineStore();
  const {
    isAutoAxisRootsConfirmed,
    upperDentalMovements,
    lowerDentalMovements,
    axixRootsEditMode,
    setIsAutoAxisRootsConfirmed
  } = useOrthBoundStore();
  const { setWebBusy, setMessageInfo } = useBoundStore();
  const { blobToBase64 } = useBase64Converter();
  const axisRootsOrientationService = container.get<IAxisRootsOrientationService>(
    INJECTED_TYPES.IAxisRootsOrientationService
  );
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);

  useEffect(() => {
    setMessageInfo(axixRootsEditMode ? 'pageInfoMessages.axisRoots.manualFix' : null);
  }, [axixRootsEditMode]);

  useEffect(() => {
    if (isAutoAxisRootsConfirmed && isAIEnabled) {
      setIsAIEnabled(false);
      setWebBusy(true);

      const receiveAxisRootsDataFromAI = async () => {
        try {
          const zip = new JSZip();

          if (lowerDentalMovements) {
            lowerDentalMovements.teeth.forEach((tooth) => {
              const toothFile = Module.FS.readFile(`/Content/DynamicModels/${tooth.id}.wepmd`);
              zip.file(`${tooth.fdi}.wepmd`, toothFile);
            });
          }

          if (upperDentalMovements) {
            upperDentalMovements.teeth.forEach((tooth) => {
              const toothFile = Module.FS.readFile(`/Content/DynamicModels/${tooth.id}.wepmd`);
              zip.file(`${tooth.fdi}.wepmd`, toothFile);
            });
          }

          const blob = await zip.generateAsync({ type: 'blob' });
          const base64 = await blobToBase64(blob);
          const base64Content = base64.replace('data:application/zip;base64,', '');

          const teethFileDto: AxisRootsOrientationFileDTO = { file: base64Content };
          const response = await axisRootsOrientationService.getAIAxisRootsOrientation(teethFileDto);

          window.App.webEventsProxy.axisAndRoots.setTeethRotationAxisPrediction(response);
        } finally {
          setWebBusy(false);
          setIsAIEnabled(true);
          setIsAutoAxisRootsConfirmed(false);
        }
      };

      receiveAxisRootsDataFromAI();
    }
  }, [isAutoAxisRootsConfirmed]);

  return (
    <div className="captures container-fluid g-0 d-flex axis-roots">
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
    </div>
  );
}
