import React from 'react';
import { ActionComponent } from '../../../../models';
import { OrthoTeethArch, ShowGridAction } from '../../../../orthodontics/components/layout';
import CamViewAction from '../../../../orthodontics/components/layout/navBar/navBarActions/CamViewAction';
import GroupedActions from '../../../../orthodontics/components/layout/navBar/navBarActions/GroupedActions';
import RedoAction from '../../../../orthodontics/components/layout/navBar/navBarActions/RedoAction';
import ShowAxisAction from '../../../../orthodontics/components/layout/navBar/navBarActions/ShowAxisAction';
import UndoAction from '../../../../orthodontics/components/layout/navBar/navBarActions/UndoAction';
import UploadLowerMovementsMockFileAction from '../../../../orthodontics/components/layout/navBar/navBarActions/UploadLowerMovementsMockFileAction';
import UploadMockDentalMovementsFileAction from '../../../../orthodontics/components/layout/navBar/navBarActions/UploadMockDentalMovementsFileAction';
import UploadUpperMovementsMockFileAction from '../../../../orthodontics/components/layout/navBar/navBarActions/UploadUpperMovementsMockFileAction';
import { SurgeryTeethArch } from '../../../../surgeries/components/layout';
import ConfirmAction from './ConfirmAction';
import InformationAction from './InformationAction';
import NextStepAction from './NextStepAction';
import NextStepDirectAction from './NextStepDirectAction';
import PreviousStepAction from './PreviousStepAction';
import SplitScreenAction from './SplitScreenAction';
import UploadNewModelFileAction from '../../../../orthodontics/components/layout/navBar/navBarActions/UploadNewModelFileAction';

const Actions: any = {
  [ActionComponent.nextStepAction]: NextStepAction,
  [ActionComponent.informationAction]: InformationAction,
  [ActionComponent.previousStepAction]: PreviousStepAction,
  [ActionComponent.splitScreenAction]: SplitScreenAction,
  [ActionComponent.orthoTeethArch]: OrthoTeethArch,
  [ActionComponent.surgeryTeethArch]: SurgeryTeethArch,
  [ActionComponent.confirmAction]: ConfirmAction,
  [ActionComponent.nextStepDirectAction]: NextStepDirectAction,
  [ActionComponent.camViewAction]: CamViewAction,
  [ActionComponent.showAxisAction]: ShowAxisAction,
  [ActionComponent.showGridAction]: ShowGridAction,
  [ActionComponent.undoAction]: UndoAction,
  [ActionComponent.redoAction]: RedoAction,
  [ActionComponent.uploadMockDentalMovementsFileAction]: UploadMockDentalMovementsFileAction,
  [ActionComponent.uploadUpperMovementsMockFileAction]: UploadUpperMovementsMockFileAction,
  [ActionComponent.uploadLowerMovementsMockFileAction]: UploadLowerMovementsMockFileAction,
  [ActionComponent.groupedActions]: GroupedActions,
  [ActionComponent.uploadNewModelFileAction]: UploadNewModelFileAction
};

export default (block: any) => {
  if (typeof Actions[block.component] !== 'undefined') {
    return React.createElement(Actions[block.component], {
      key: block.component,
      block: block
    });
  }

  return React.createElement(() => <div>{block.component}</div>, {
    key: block.component
  });
};
