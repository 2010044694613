import { ReactComponent as BiteRampAnterior } from '../../../../../assets/icons/evergineTools/attaches/bite-ramp-anterior.svg';
import { ReactComponent as BiteRampPosterior } from '../../../../../assets/icons/evergineTools/attaches/bite-ramp-posterior.svg';
import { ReactComponent as ControlOpExpansion } from '../../../../../assets/icons/evergineTools/attaches/control-op-expansion.svg';
import { ReactComponent as ControlOpRetention } from '../../../../../assets/icons/evergineTools/attaches/control-op-retention.svg';
import { ReactComponent as ControlOpSupport } from '../../../../../assets/icons/evergineTools/attaches/control-op-support.svg';
import { ReactComponent as ExtrusionConAnterior } from '../../../../../assets/icons/evergineTools/attaches/extrusion-con-anterior.svg';
import { ReactComponent as ExtrusionOpAnterior } from '../../../../../assets/icons/evergineTools/attaches/extrusion-op-anterior.svg';
import { ReactComponent as ExtrusionOpPosterior } from '../../../../../assets/icons/evergineTools/attaches/extrusion-op-posterior.svg';
import { ReactComponent as RectangularCon } from '../../../../../assets/icons/evergineTools/attaches/rectangular-con.svg';
import { ReactComponent as RectangularOpBevLeft } from '../../../../../assets/icons/evergineTools/attaches/rectangular-op-beveled-left.svg';
import { ReactComponent as RectangularOpBevRight } from '../../../../../assets/icons/evergineTools/attaches/rectangular-op-beveled-right.svg';
import { ReactComponent as RectangularOp } from '../../../../../assets/icons/evergineTools/attaches/rectangular-op.svg';
import { ReactComponent as RootControlDoubleLeft } from '../../../../../assets/icons/evergineTools/attaches/root-control-double-left.svg';
import { ReactComponent as RootControlDoubleRight } from '../../../../../assets/icons/evergineTools/attaches/root-control-double-right.svg';
import { ReactComponent as RootControlSimple } from '../../../../../assets/icons/evergineTools/attaches/root-control-simple.svg';
import { ReactComponent as RotationLeft } from '../../../../../assets/icons/evergineTools/attaches/rotation-left.svg';
import { ReactComponent as RotationRight } from '../../../../../assets/icons/evergineTools/attaches/rotation-right.svg';
import { AttachmentType } from '../../../../../common';

export const attacheTypes = [
  {
    name: 'attachesPanel.rotation',
    models: [
      {
        id: 'rotationOpRight',
        typeAtt: AttachmentType.RotationRight,
        icon: RotationRight,
        text: 'attachesPanel.rotationOpRight'
      },
      {
        id: 'rotationOpLeft',
        typeAtt: AttachmentType.RotationLeft,
        icon: RotationLeft,
        text: 'attachesPanel.rotationOpLeft'
      }
    ]
  },
  {
    name: 'attachesPanel.extrusion',
    models: [
      {
        id: 'extrusionConAnterior',
        typeAtt: AttachmentType.ExtrusionAnteriorConventional,
        icon: ExtrusionConAnterior,
        text: 'attachesPanel.extrusionConAnterior'
      },
      {
        id: 'extrusionOpAnterior',
        typeAtt: AttachmentType.ExtrusionAnteriorOptimized,
        icon: ExtrusionOpAnterior,
        text: 'attachesPanel.extrusionOpAnterior'
      },
      {
        id: 'extrusionOpPosterior',
        typeAtt: AttachmentType.ExtrusionPosteriorOptimized,
        icon: ExtrusionOpPosterior,
        text: 'attachesPanel.extrusionOpPosterior'
      }
    ]
  },
  {
    name: 'attachesPanel.rectangular',
    models: [
      {
        id: 'rectangularCon',
        typeAtt: AttachmentType.RectangularHorizontalOptimized,
        icon: RectangularCon,
        text: 'attachesPanel.rectangularCon'
      },
      {
        id: 'rectangularOp',
        typeAtt: AttachmentType.RectangularVerticalOptimized,
        icon: RectangularOp,
        text: 'attachesPanel.rectangularOp'
      },
      {
        id: 'rectangularOpBevLeft',
        typeAtt: AttachmentType.RectangularLeftBeveled,
        icon: RectangularOpBevLeft,
        text: 'attachesPanel.rectangularOpBevLeft'
      },
      {
        id: 'rectangularOpBevRight',
        typeAtt: AttachmentType.RectangularRightBeveled,
        icon: RectangularOpBevRight,
        text: 'attachesPanel.rectangularOpBevRight'
      }
    ]
  },
  {
    name: 'attachesPanel.rootControl',
    models: [
      {
        id: 'rootControlDoubleLeft',
        typeAtt: AttachmentType.RadicularControlDoubleOne,
        icon: RootControlDoubleLeft,
        text: 'attachesPanel.rootControlDoubleLeft'
      },
      {
        id: 'rootControlDoubleRight',
        typeAtt: AttachmentType.RadicularControlDoubleTwo,
        icon: RootControlDoubleRight,
        text: 'attachesPanel.rootControlDoubleRight'
      },
      {
        id: 'rootControlSimple',
        typeAtt: AttachmentType.RadicularControlSimple,
        icon: RootControlSimple,
        text: 'attachesPanel.rootControlSimple'
      }
    ]
  },
  {
    name: 'attachesPanel.control',
    models: [
      {
        id: 'controlOpRetention',
        typeAtt: AttachmentType.ControlRetentionOptimized,
        icon: ControlOpRetention,
        text: 'attachesPanel.controlOpRetention'
      },
      {
        id: 'controlOpSupport',
        typeAtt: AttachmentType.ControlSupportOptimized,
        icon: ControlOpSupport,
        text: 'attachesPanel.controlOpSupport'
      },
      {
        id: 'controlOpExpansion',
        typeAtt: AttachmentType.ControlExpansionOptimized,
        icon: ControlOpExpansion,
        text: 'attachesPanel.controlOpExpansion'
      }
    ]
  },
  {
    name: 'attachesPanel.biteRamp',
    models: [
      {
        id: 'biteRampAnterior',
        typeAtt: AttachmentType.BiteLiftAnterior,
        icon: BiteRampAnterior,
        text: 'attachesPanel.biteRampAnterior'
      },
      {
        id: 'biteRampPosterior',
        typeAtt: AttachmentType.BiteLiftPosterior,
        icon: BiteRampPosterior,
        text: 'attachesPanel.biteRampPosterior'
      }
    ]
  }
];
