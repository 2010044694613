import { useCallback, useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { ReactComponent as ToothIcon } from '../../../../../assets/icons/evergineTools/tooth-alt.svg';
import { ReactComponent as JawsIcon } from '../../../../../assets/icons/jaws.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseChanges } from '../../../../../hooks';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { OrthArchsTools } from './OrthArchsTools';
import { OrthTeethTools } from './OrthTeethTools';
import './orthTeethMovements.scss';

enum MovementType {
  Jaws = 1,
  Tooth = 2
}

export function OrthTeethMovements() {
  const [t] = useTranslation();
  const { setShowMovements } = useOrthBoundStore();
  const { isEditStep, updateIsCaseModified } = useCommonBoundStore();
  const { checkIfPreviousCompletedPhaseHasChanged } = useCaseChanges();

  const [selectedMovementType, setSelectedMovementType] = useState<MovementType>(MovementType.Tooth);

  const PANEL_SIZE = 312;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthTeethMovementsButton')?.getBoundingClientRect();
  }, []);

  const MovementButton = (buttonType: MovementType) => {
    return (
      <button
        className={`orth-movements__button ${buttonType === selectedMovementType ? 'is-active' : ''}`}
        onClick={() => setSelectedMovementType(buttonType)}
      >
        {buttonType === MovementType.Tooth ? <ToothIcon /> : <JawsIcon />}
      </button>
    );
  };

  const caseHasBeenModified = useCallback(
    (isModified: boolean) => {
      if (isModified && checkIfPreviousCompletedPhaseHasChanged()) {
        updateIsCaseModified(true);
        return;
      }
      updateIsCaseModified(false);
    },
    [checkIfPreviousCompletedPhaseHasChanged]
  );

  useEffect(() => {
    if (!isEditStep) {
      setShowMovements(false);
    }
  }, [isEditStep]);

  return (
    <>
      <Draggable
        bounds="body"
        handle=".handleOrthTeethMovements"
        defaultPosition={{ x: (toolPosition?.x || PANEL_SIZE) - PANEL_SIZE, y: toolPosition?.top || 0 }}
      >
        <div className="orth-movements">
          <div className="orth-movements__header">
            <div className="orth-movements__title handleOrthTeethMovements">
              <div>{t('evergineTools.movements')}</div>
            </div>

            <button type="button" className="orth-movements__close" onClick={() => setShowMovements(false)}>
              <CloseIcon className="clickable" />
            </button>
          </div>
          <div className="orth-movements__separator" />
          {/* <div className="orth-movements__buttons-container">
            {MovementButton(MovementType.Tooth)}
            <div>{MovementButton(MovementType.Jaws)}</div>
          </div> */}
          <div>
            {selectedMovementType === MovementType.Tooth ? (
              <OrthTeethTools /*onCaseModification={caseHasBeenModified}*/ />
            ) : (
              <OrthArchsTools />
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
}
