import { ReactComponent as Movements } from '../../../assets/icons/evergineTools/movements.svg';
import { ReactComponent as VirtualJump } from '../../../assets/icons/evergineTools/virtual-jump.svg';
import { ToolbarConfigProps } from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { debouncedSetState } from '../../../common/utils';
import { PROFILES_TYPE } from '../../../shared';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';

export const teethMovementsToolbarElement: ToolbarConfigProps = {
  icon: Movements,
  tooltipKey: 'evergineTools.teethMovements',
  customId: 'orthTeethMovementsButton',
  handleClick: () => {
    const currentValue = useOrthBoundStore.getState().showMovements;
    debouncedSetState(useOrthBoundStore, { showMovements: !currentValue });
  },
  isHidden: () => {
    const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
    const isModificationMode = useCommonBoundStore.getState().isNewStepClientModification;
    const isHiddenForClient = isClient && !isModificationMode;

    return (
      isHiddenForClient ||
      (!useOrthBoundStore.getState().lowerDentalMovements && !useOrthBoundStore.getState().upperDentalMovements)
    );
  }
};

export const virtualJumpToolbarElement: ToolbarConfigProps = {
  icon: VirtualJump,
  tooltipKey: 'evergineTools.virtualJump',
  handleClick: () => undefined
};
