import { StateCreator } from 'zustand';
import { HistoryItem } from '../../models';

export interface HistoryModificationsSlice {
  historyModifications: HistoryItem[];
  setHistoryModifications: (historyModifications: HistoryItem[]) => void;
}

// eslint-disable-next-line prettier/prettier
export const createHistoryModificationsSlice: StateCreator<HistoryModificationsSlice, [], [], HistoryModificationsSlice> = (set) => ({
  historyModifications: [],
  setHistoryModifications: (historyModifications: HistoryItem[]) => set((_) => ({ historyModifications }))
});
