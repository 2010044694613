import { Stage } from '../../../../common';
import { AsideConfig, StepState } from '../../../../surgeries/components/layout/aside';

export enum OrthodonticsOrderedRoutes {
  STLLoad = '/orthodontics/stl-captures',
  TeethSegmentation = '/orthodontics/teeth-segmentation',
  AxisAndRoots = '/orthodontics/axis-roots',
  FinalPosition = '/orthodontics/final-position',
  IntermediateSteps = '/orthodontics/intermediate-steps',
  IPR = '/orthodontics/ipr',
  Attaches = '/orthodontics/attaches',
  Publish = '/orthodontics/publish'
}

export enum OrthodonticsPhasesKeys {
  STLLoad = 'stl_load',
  TeethSegmentation = 'teeth_segmentation',
  AxisAndRoots = 'axis_and_roots',
  FinalPosition = 'final_position',
  IntermediateSteps = 'intermediate_steps',
  IPR = 'ipr',
  Attaches = 'attaches',
  Publish = 'publish'
}

export const PhaseByUrl: Record<string, OrthodonticsPhasesKeys> = {
  'stl-captures': OrthodonticsPhasesKeys.STLLoad,
  'teeth-segmentation': OrthodonticsPhasesKeys.TeethSegmentation,
  'axis-roots': OrthodonticsPhasesKeys.AxisAndRoots,
  'final-position': OrthodonticsPhasesKeys.FinalPosition,
  'intermediate-steps': OrthodonticsPhasesKeys.IntermediateSteps,
  ipr: OrthodonticsPhasesKeys.IPR,
  attaches: OrthodonticsPhasesKeys.Attaches,
  publish: OrthodonticsPhasesKeys.Publish
};

export const EvergineStageByPhase: Record<string, Stage> = {
  [OrthodonticsPhasesKeys.STLLoad]: Stage.LoadSTL,
  [OrthodonticsPhasesKeys.TeethSegmentation]: Stage.TeethSegmentation,
  [OrthodonticsPhasesKeys.AxisAndRoots]: Stage.AxisAndRoots,
  [OrthodonticsPhasesKeys.FinalPosition]: Stage.FinalPosition,
  [OrthodonticsPhasesKeys.IntermediateSteps]: Stage.IntermediateSteps,
  [OrthodonticsPhasesKeys.IPR]: Stage.IPR,
  [OrthodonticsPhasesKeys.Attaches]: Stage.Attaches,
  [OrthodonticsPhasesKeys.Publish]: Stage.Publish
};

export const orthodonticsAsideConfig: AsideConfig[] = [
  {
    title: 'aside.orthodontics.images.title',
    items: [
      {
        title: 'aside.orthodontics.images.items.STLLoad',
        route: OrthodonticsOrderedRoutes.STLLoad,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.STLLoad
      }
    ]
  },
  {
    title: 'aside.orthodontics.preparation.title',
    items: [
      {
        title: 'aside.orthodontics.preparation.items.teethSegmentation',
        route: OrthodonticsOrderedRoutes.TeethSegmentation,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.TeethSegmentation
      },
      {
        title: 'aside.orthodontics.preparation.items.axisAndRoots',
        route: OrthodonticsOrderedRoutes.AxisAndRoots,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.AxisAndRoots
      }
    ]
  },
  {
    title: 'aside.orthodontics.treatment.title',
    items: [
      {
        title: 'aside.orthodontics.treatment.items.finalPosition',
        route: OrthodonticsOrderedRoutes.FinalPosition,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.FinalPosition
      },
      {
        title: 'aside.orthodontics.treatment.items.intermediateSteps',
        route: OrthodonticsOrderedRoutes.IntermediateSteps,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.IntermediateSteps
      },
      {
        title: 'aside.orthodontics.treatment.items.IPR',
        route: OrthodonticsOrderedRoutes.IPR,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.IPR
      },
      {
        title: 'aside.orthodontics.treatment.items.attaches',
        route: OrthodonticsOrderedRoutes.Attaches,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.Attaches
      }
    ]
  },
  {
    title: 'aside.orthodontics.production.title',
    items: [
      {
        title: 'aside.orthodontics.production.items.publish',
        route: OrthodonticsOrderedRoutes.Publish,
        defaultState: StepState.Pending,
        key: OrthodonticsPhasesKeys.Publish
      }
    ]
  }
];
