import './labelvalue.scss';

type LavelValueProps = {
  label?: string;
  value?: string;
};

export function LabelValue(props: LavelValueProps) {
  const { label, value } = props;

  return (
    <div className="labelvalue">
      <div className="labelvalue-label">{label}</div>
      <div className="labelvalue-value">{value}</div>
    </div>
  );
}
