import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/esm/Button';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { Language, STORE_LANG_KEY, i18n } from '../../../app.i18n';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as ConfigurationIcon } from '../../../assets/icons/configuration.svg';
import InformationIcon from '../../../assets/icons/question.svg';
import { ReactComponent as RadioOffIcon } from '../../../assets/icons/radio-off.svg';
import { ReactComponent as RadioOnIcon } from '../../../assets/icons/radio-on.svg';
import './navBarConfiguration.scss';

export default () => {
  const [t] = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [localCurrentLanguage, setLocalCurrentLanguage] = useState<string>(i18n.language);
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showLanguageDrowdown, setShowLanguageDrowdown] = useState<boolean>(true);

  const changeLocalLanguage = (language: string) => () => {
    setLocalCurrentLanguage(language);
  };

  const saveConfiguration = () => () => {
    setShowPanel(!showPanel);
    i18n.changeLanguage(localCurrentLanguage);
    setCurrentLanguage(localCurrentLanguage);
    localStorage.setItem(STORE_LANG_KEY, localCurrentLanguage);
  };

  const toggleShowPanel = () => () => {
    setShowPanel(!showPanel);
  };

  useEffect(() => {
    const language = i18n.language;
    setCurrentLanguage(language);
  }, []);

  return (
    <div className="configuration">
      <button onClick={toggleShowPanel()} className={`btn btn-action configuration-btn`} data-tip>
        <ConfigurationIcon className="ml-3" />
      </button>
      {showPanel && (
        <div className="configuration-panel">
          <div>
            <div className="configuration-title">
              {t('navbar.actions.configuration.title')}
              <span>
                <img
                  data-for="configuration"
                  data-tip
                  alt={t('navbar.actions.configuration.title')}
                  src={InformationIcon}
                  className="ms-3"
                />
                <ReactTooltip id="configuration" place="right" effect="solid" className="tooltip" type="dark">
                  <span>{t('navbar.actions.configuration.title')}</span>
                </ReactTooltip>
              </span>
            </div>
            <Dropdown
              className="lang-dropdown"
              autoClose={false}
              show={showLanguageDrowdown}
              onToggle={(isOpen) => setShowLanguageDrowdown(isOpen)}
            >
              <Dropdown.Toggle data-testid="lang-button" variant="language" id="dropdown-basic">
                {t('navbar.actions.configuration.language')}
                {showLanguageDrowdown ? <ArrowUpIcon className="icon ms-3" /> : <ArrowDownIcon className="icon ms-3" />}
              </Dropdown.Toggle>
              <Dropdown.Menu show>
                <Dropdown.Item
                  as="button"
                  data-testid="lang-button-english"
                  onClick={changeLocalLanguage(Language.English)}
                  className="lang-dropdown-item"
                >
                  <span>{t('languages.english')}</span>
                  {localCurrentLanguage === Language.English ? (
                    <RadioOnIcon className="configuration-radio_on" />
                  ) : (
                    <RadioOffIcon />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  data-testid="lang-button-spanish"
                  onClick={changeLocalLanguage(Language.Spanish)}
                  className="lang-dropdown-item"
                >
                  <span>{t('languages.spanish')}</span>
                  {localCurrentLanguage === Language.Spanish ? (
                    <RadioOnIcon className="configuration-radio_on" />
                  ) : (
                    <RadioOffIcon />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  data-testid="lang-button-portuguese"
                  onClick={changeLocalLanguage(Language.Portuguese)}
                  className="lang-dropdown-item"
                >
                  <span>{t('languages.portuguese')}</span>
                  {localCurrentLanguage === Language.Portuguese ? (
                    <RadioOnIcon className="configuration-radio_on" />
                  ) : (
                    <RadioOffIcon />
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  data-testid="lang-button-french"
                  onClick={changeLocalLanguage(Language.French)}
                  className="lang-dropdown-item"
                >
                  <span>{t('languages.french')}</span>
                  {localCurrentLanguage === Language.French ? (
                    <RadioOnIcon className="configuration-radio_on" />
                  ) : (
                    <RadioOffIcon />
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="configuration-btn_container">
            <Button onClick={saveConfiguration()} className={`btn configuration-btn`}>
              {t('navbar.actions.configuration.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
