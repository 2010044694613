import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import './iconRoundButton.scss';

type IconRoundButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  size?: 's' | 'm';
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon: FunctionComponent;
  modifier?: 'default' | 'secondary';
};

export function IconRoundButton(props: IconRoundButtonProps) {
  let styles;
  if (props.size) {
    styles = {
      width: props.size,
      height: props.size
    };
  }

  const classes = classNames('icon-round-button', {
    [`icon-round-button--${props.modifier}`]: props.modifier,
    [`icon-round-button--size-${props.size}`]: props.size
  });

  return (
    <button type={props.type || 'button'} className={classes} style={styles} onClick={props.onClick}>
      <props.icon />
    </button>
  );
}
