import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import './tabs.scss';

type TabsProps = {
  defaultActiveTabId?: string;
  children: JSX.Element[];
};

export function CustomTabs(props: TabsProps) {
  const getActiveTabId = () => {
    if (!props.children.length) {
      return;
    }

    if (props.defaultActiveTabId !== undefined) {
      return props.defaultActiveTabId;
    }

    const firstNotDisabledTab = props.children.find((t) => !t.props.disabled);
    if (firstNotDisabledTab) {
      return firstNotDisabledTab.props.id as string;
    }

    return props.children[0].props.id as string;
  };

  return (
    <div className="tabs">
      <Tab.Container defaultActiveKey={getActiveTabId()}>
        <Nav className="tabs-nav">
          {props.children.map((child) => (
            <Nav.Item key={`tab-nav-${child.props.id}`} className="tabs-item">
              <Nav.Link eventKey={child.props.id} className="tabs-tab" disabled={child.props.disabled}>
                {child.props.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content className="tabs-content">
          {props.children.map((child) => (
            <Tab.Pane
              key={`tab-content-${child.props.id}`}
              className="tabs-panel"
              eventKey={child.props.id}
              mountOnEnter={true}
            >
              {child}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
