import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { INJECTED_TYPES, container } from '../../../../../common';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';
import { ICommandInvoker } from '../../../../../shared';
import { useBoundStore } from '../../../../stores/useStore';

export function ModalPreviousCasePhaseModified() {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { updateMovements, getStageEvergineByCurrentPhase } = useUtils();
  const { updateIsCaseReseting } = useCommonBoundStore();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);

  const onCancel = () => {
    commandInvokerService.undo();
    setCanUndo(false);
    toggleModalIsOpened('');
  };

  const handleSubmit = () => {
    toggleModalIsOpened('');
    setWebBusy(true);
    const stage = getStageEvergineByCurrentPhase();
    window.App.webEventsProxy.common.resetToStage(stage);
    updateMovements();
    updateIsCaseReseting(true);
    setWebBusy(false);
  };

  return (
    <div className="modal-content" data-testid={'modal-previousCaseStateModified'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.previousCaseStateModified.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.previousCaseStateModified.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={handleSubmit} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.previousCaseStateModified.confirm`)}
        </button>
      </div>
    </div>
  );
}
