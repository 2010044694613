import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PreviousStepIcon from '../../../../assets/icons/previous.svg';
import { useCalculateRoutes, useCaseId, useCaseType, useCustomNavigate } from '../../../../hooks';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';
import { NavBarAction } from './NavBarAction';

export default () => {
  const [t] = useTranslation();
  const { setShowPaintPanel } = useOrthBoundStore();
  const navigate = useNavigate();
  const { getRouteKeepingParameters } = useCustomNavigate();
  const { pathname } = useLocation();
  const [caseId] = useCaseId();
  const caseType = useCaseType();
  const [calculateNextRoute, calculatePreviousRoute] = useCalculateRoutes(pathname, caseId, caseType);
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const { updateIsCaseReseting } = useCommonBoundStore();

  const navigateToPreviousStep = () => {
    setCanUndo(false);
    updateIsCaseReseting(false);
    setShowPaintPanel(false);
    const previousRoute = calculatePreviousRoute();

    navigate(getRouteKeepingParameters(previousRoute));
  };

  const buttonId = 'previousStep';

  return (
    <>
      <NavBarAction
        icon={PreviousStepIcon}
        dataFor={buttonId}
        altText={t('navbar.actions.previousStep')}
        action={navigateToPreviousStep}
      />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.previousStep`)}
      </ReactTooltip>
    </>
  );
};
