import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { IManufacturersService } from './imanufacturers.service';
import { Manufacturer } from './manufacturer';

@injectable()
export class ManufacturersService implements IManufacturersService {
  private readonly caseIdToSubstitute = '{reference}';
  private readonly apiEndpoint = `/api/manufacturers`;
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  getApiPath = (caseId: string) => this.apiEndpoint.replace(this.caseIdToSubstitute, caseId);

  public async getAll(caseId: string): Promise<Manufacturer[] | null> {
    const apiPath = this.getApiPath(caseId);
    const response = await this._httpService.get(apiPath);
    const manufacturers = response.data as Manufacturer[];
    return manufacturers;
  }
}
