import { StateCreator } from 'zustand';
import { MatchingInfo } from '../../../common/evergine/types';

export type Slice = {
  name: string;
  modelId: string;
  uri?: string;
};

export interface MatchingSlice {
  matchingImages: Record<string, Record<string, Slice>>;
  updateMatchingImages: (matching: Record<string, Record<string, Slice>>) => void;
  matchingModelPoints: Record<string, MatchingInfo>;
  updateMatchingModelPoints: (matchingModelPoints: Record<string, MatchingInfo>) => void;
}

export const createMatchingSlice: StateCreator<MatchingSlice, [], [], MatchingSlice> = (set) => ({
  matchingImages: {},
  updateMatchingImages: (matchingImages) => set(() => ({ matchingImages: matchingImages })),
  matchingModelPoints: {
    ['UPPER']: {
      dicom: '',
      model3D: '',
      dicomPoints: [],
      model3dPoints: []
    },
    ['LOWER']: {
      dicom: '',
      model3D: '',
      dicomPoints: [],
      model3dPoints: []
    }
  },
  updateMatchingModelPoints: (matchingModelPoints) => set(() => ({ matchingModelPoints: matchingModelPoints }))
});
