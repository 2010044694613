import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import rotateIcon from '../../../../assets/icons/information/rotate.svg';
import moveIcon from '../../../../assets/icons/information/move.svg';
import zoomIcon from '../../../../assets/icons/information/zoom.svg';
import informationIcon from '../../../../assets/icons/question.svg';
import { NavBarAction } from './NavBarAction';

export default () => {
  const [t] = useTranslation();

  return (
    <div>
      <ReactTooltip id="information" place="bottom" effect="solid" className="tooltip d-flex flex-column" type="dark">
        <span>
          <img className="me-2" alt={'navbar.actions.information.rotate'} src={rotateIcon} />
          {t('navbar.actions.information.rotate')}
        </span>
        <span>
          <img className="me-2" alt={'navbar.actions.information.move'} src={moveIcon} />
          {t('navbar.actions.information.move')}
        </span>
        <span>
          <img className="me-2" alt={t('navbar.actions.information.zoom')} src={zoomIcon} />
          {t('navbar.actions.information.zoom')}
        </span>
      </ReactTooltip>
      <NavBarAction icon={informationIcon} altText={t('navbar.actions.information.title')} dataFor="information" />
    </div>
  );
};
