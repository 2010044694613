import { StateCreator } from 'zustand';
import { TeethArchPosition } from '../../../models';

export interface UndoRedoSlice {
  canUndo: boolean;
  canRedo: boolean;
  setCanUndo: (newCanUndo: boolean) => void;
  setCanRedo: (newCanRedo: boolean) => void;
}

export const createUndoRedoSlice: StateCreator<UndoRedoSlice, [], [], UndoRedoSlice> = (set) => ({
  canUndo: false,
  canRedo: false,
  setCanUndo: (newCanUndo: boolean) => set(() => ({ canUndo: newCanUndo })),
  setCanRedo: (newCanRedo: boolean) => set(() => ({ canRedo: newCanRedo }))
});
