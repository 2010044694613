import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CaptureType } from '../../common/evergine/types';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { ModalTypes } from '../../surgeries/components/layout';

export function useCHeckValidExtension() {
  const { toggleModalIsOpened, setModalMessage, setModalTitle } = useCommonBoundStore();
  const [t] = useTranslation();

  const checkValidExtension = useCallback((type, file, allowedFileExtension) => {
    const extension = file.name.split('.').slice(-1)[0];
    if (extension !== allowedFileExtension) {
      setModalTitle(t(`modal.error.badFileType.title`));
      setModalMessage(
        type === CaptureType.DICOM ? t('modal.error.badFileType.DICOMScreen') : t('modal.error.badFileType.STLScreen')
      );
      toggleModalIsOpened(ModalTypes.ModalError);
      return false;
    }
    return true;
  }, []);

  return [checkValidExtension] as const;
}
