import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import InformationIcon from '../../../../assets/icons/question.svg';
import InProgress from '../../../../assets/icons/state-0.svg';
import Validated from '../../../../assets/icons/state-1.svg';
import Pending from '../../../../assets/icons/state-2.svg';
import './asideSurgery.scss';
import { StepState } from './asideConfig';
import { surgeryAsideConfig } from './surgeryAsideConfig';

export function AsideStepsSurgery() {
  const [t] = useTranslation();

  const getStateIcon = (defaultState: StepState) => {
    if (defaultState == 0) return InProgress;
    if (defaultState == 1) return Validated;
    return Pending;
  };

  return (
    <div className="step">
      {surgeryAsideConfig.map((asideStep, index) => (
        <div key={index}>
          <div className="step-title">
            {t(asideStep.title)}
            {asideStep.tooltip && (
              <span>
                <img
                  data-for={t(asideStep.title)}
                  data-tip
                  alt="Información del caso"
                  src={InformationIcon}
                  className="ms-3"
                />
                <ReactTooltip id={t(asideStep.title)} place="right" effect="solid" className="tooltip" type="dark">
                  <span>{t(asideStep.tooltip)}</span>
                </ReactTooltip>
              </span>
            )}
          </div>
          <div>
            {asideStep.items.map((asideStepItem, index) => (
              <div key={index} className="step-item d-flex align-items-center">
                <img className="me-3" src={getStateIcon(asideStepItem.defaultState)} />
                <div>
                  <p className="step-subtitle">{t(asideStepItem.title)}</p>
                  <span className="step-info">ID-0001</span>
                  <span className="step-info me-1 ms-1">·</span>
                  <span className="step-info">21/01/2022</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
