import { StateCreator } from 'zustand';
export interface IntermediateStepsSlice {
  areMadeChangesOnTeeth: boolean;
  isRecalculateStepsNeeded: boolean;
  isRecalculateCancelled: boolean;
  areInitialMovementsLoaded: boolean;
  setAreChangesMadeOnTeeth: (areMadeChangesOnTeeth: boolean) => void;
  setIsRecalculateStepsNeeded: (isRecalculateStepsNeeded: boolean) => void;
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => void;
  setAreInitialMovementsLoaded: (areInitialMovementsLoaded: boolean) => void;
}

export const createIntermediateStepsSlice: StateCreator<IntermediateStepsSlice, [], [], IntermediateStepsSlice> = (
  set
) => ({
  areMadeChangesOnTeeth: false,
  isRecalculateStepsNeeded: false,
  isRecalculateCancelled: false,
  areInitialMovementsLoaded: false,
  setAreChangesMadeOnTeeth: (areMadeChangesOnTeeth: boolean) => set((_) => ({ areMadeChangesOnTeeth })),
  setIsRecalculateStepsNeeded: (isRecalculateStepsNeeded: boolean) => set((_) => ({ isRecalculateStepsNeeded })),
  setIsRecalculateCancelled: (isRecalculateCancelled: boolean) => set((_) => ({ isRecalculateCancelled })),
  setAreInitialMovementsLoaded: (areInitialMovementsLoaded: boolean) => set((_) => ({ areInitialMovementsLoaded }))
});
