import { BaseTeethArchDropdown } from '../../../../../common/components/navBar/navBarActions/BaseTeethArchDropdown';
import { TeethArchPosition } from '../../../../../models';
import { useBoundStore } from '../../../../stores/useStore';

export const SurgeryTeethArch = () => {
  const { teethArch, setTeethArch } = useBoundStore();

  const options = [
    { translationKey: 'upper', type: TeethArchPosition.UPPER },
    { translationKey: 'lower', type: TeethArchPosition.LOWER }
  ];

  return <BaseTeethArchDropdown dropdownOptions={options} handleSelectOption={setTeethArch} defaultType={teethArch} />;
};
