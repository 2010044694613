import { StateCreator } from 'zustand';
import { MovementsTableDto } from '../../../shared';

export interface OrthToolsSlice {
  showMovementsTable: boolean;
  setShowMovementsTable: (showMovementsTable: boolean) => void;
  movementsTable: MovementsTableDto;
  setMovementsTable: (movementsTable: MovementsTableDto) => void;
  showBoltonPanel: boolean;
  setShowBoltonPanel: (showBoltonPanel: boolean) => void;
  showEvolutionPanel: boolean;
  setShowEvolutionPanel: (showEvolutionPanel: boolean) => void;
  showIPR: boolean;
  setShowIPR: (showIPR: boolean) => void;
  showMovements: boolean;
  setShowMovements: (showMovements: boolean) => void;
  showAttachesPanel: boolean;
  setShowAttachesPanel: (showAttachesPanel: boolean) => void;
  axixRootsEditMode: boolean;
  setAxixRootsEditMode: (showAttachesPanel: boolean) => void;
}

export const createOrthToolsSlice: StateCreator<OrthToolsSlice, [], [], OrthToolsSlice> = (set) => ({
  axixRootsEditMode: false,
  showMovementsTable: false,
  movementsTable: null,
  showBoltonPanel: false,
  showEvolutionPanel: false,
  showIPR: false,
  showMovements: false,
  showAttachesPanel: false,
  setAxixRootsEditMode: (axixRootsEditMode: boolean) => set((_) => ({ axixRootsEditMode })),
  setShowMovementsTable: (showMovementsTable: boolean) => set((_) => ({ showMovementsTable })),
  setMovementsTable: (movementsTable: MovementsTableDto) => set((_) => ({ movementsTable })),
  setShowBoltonPanel: (showBoltonPanel: boolean) => set((_) => ({ showBoltonPanel })),
  setShowEvolutionPanel: (showEvolutionPanel: boolean) => set((_) => ({ showEvolutionPanel })),
  setShowIPR: (showIPR: boolean) => set((_) => ({ showIPR })),
  setShowMovements: (showMovements: boolean) => set((_) => ({ showMovements })),
  setShowAttachesPanel: (showAttachesPanel: boolean) => set((_) => ({ showAttachesPanel }))
});
