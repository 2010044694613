import { useCallback, useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import InformationIcon from '../../../../assets/icons/question.svg';
import InProgress from '../../../../assets/icons/state-0.svg';
import Validated from '../../../../assets/icons/state-1.svg';
import Pending from '../../../../assets/icons/state-2.svg';
import { orthodonticsAsideConfig } from './orthodonticsAsideConfig';

import { useCaseId, useCaseStatus } from '../../../../hooks';
import { PathLevels } from '../../../../models';
import { AsideConfigItem, StepState } from '../../../../surgeries/components/layout';
import { useOrthBoundStore } from '../../../stores/useStore';
import './asideOrtho.scss';

export function AsideStepsOrtho() {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const currentPath = useMemo(() => {
    const splittedPath = pathname.split('/');
    return `/${splittedPath[PathLevels.CaseType]}/${splittedPath[PathLevels.RouteView]}`;
  }, [pathname]);
  const [caseId] = useCaseId();
  useCaseStatus(caseId);
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);

  const getStateIcon = (defaultState: StepState) => {
    if (defaultState === StepState.InProgress) return InProgress;
    if (defaultState == StepState.Validated) return Validated;
    return Pending;
  };

  const getPhaseIcon = useCallback(
    (phaseItem: AsideConfigItem) => {
      if (!caseStatusFromStore) {
        return;
      }

      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseItem.key);
      if (phase) {
        if (phase.completionDate) return Validated;
        if (phaseItem.route === currentPath) return InProgress;
        return Pending;
      }

      return null;
    },
    [caseStatusFromStore]
  );

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const getDefaultActiveKey = useCallback(() => {
    if (!orthodonticsAsideConfig) {
      return;
    }

    const currentArea = orthodonticsAsideConfig.find((area) => area.items.some((item) => item.route === currentPath));

    if (currentArea) {
      return currentArea.title;
    }

    return null;
  }, [orthodonticsAsideConfig, currentPath]);

  return (
    <div className="step">
      <Accordion defaultActiveKey={getDefaultActiveKey()}>
        {orthodonticsAsideConfig.map((asideStep, index) => (
          <Accordion.Item eventKey={asideStep.title} key={`aside-steps-ortho${index}`}>
            <div>
              <div className="step-title">
                <Accordion.Header>
                  <div className="step-title_text">
                    {t(asideStep.title)}
                    {asideStep.tooltip && (
                      <span>
                        <img
                          data-for={t(asideStep.title)}
                          data-tip
                          alt={t(asideStep.tooltip)}
                          src={InformationIcon}
                          className="ms-3"
                        />
                        <ReactTooltip
                          id={t(asideStep.title)}
                          place="right"
                          effect="solid"
                          className="tooltip"
                          type="dark"
                        >
                          <span>{t(asideStep.tooltip)}</span>
                        </ReactTooltip>
                      </span>
                    )}
                  </div>
                </Accordion.Header>
              </div>

              <Accordion.Body>
                <div>
                  {asideStep.items.map((asideStepItem, index) => (
                    <div key={index} className="step-item">
                      <img className="step-icon" src={getPhaseIcon(asideStepItem)} />
                      <div>
                        <p className="step-subtitle">{t(asideStepItem.title)}</p>
                        <span className="step-info">{getPhaseCompletionDate(asideStepItem.key)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
