import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { AttachmentRemoveMode } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export function ModalDeleteAttach() {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { selectedTeethId } = useOrthBoundStore();
  const { updateMovements } = useUtils();
  const [selectedOption, setSelectedOption] = useState<number>(null);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const handleContinue = () => {
    window.App.webEventsProxy.attachments.removeAttachment(selectedTeethId, selectedOption);
    updateMovements();
    toggleModalIsOpened('');
  };

  const handleSelected = (selectedOption: number) => {
    setSelectedOption(selectedOption);
  };

  const isActive = (option: number) => {
    return selectedOption === option;
  };

  return (
    <div className="modal-content" data-testid={'modal-deleteAttach'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.deleteAttach.title`)}</h3>
        <p className="modal-options modal-text text-left mb-0">
          <Radio
            label={t(`modal.deleteAttach.options.deleteInAllSteps`)}
            active={isActive(AttachmentRemoveMode.RemoveInAllSteps)}
            callBack={() => handleSelected(AttachmentRemoveMode.RemoveInAllSteps)}
          />
          <Radio
            label={t(`modal.deleteAttach.options.deleteFromActualStepToLast`)}
            active={isActive(AttachmentRemoveMode.RemoveFromCurrentStepToEnd)}
            callBack={() => handleSelected(AttachmentRemoveMode.RemoveFromCurrentStepToEnd)}
          />
          <Radio
            label={t(`modal.deleteAttach.options.deleteFromActualStepToInitial`)}
            active={isActive(AttachmentRemoveMode.RemoveFromCurrentStepToStart)}
            callBack={() => handleSelected(AttachmentRemoveMode.RemoveFromCurrentStepToStart)}
          />
        </p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button
          onClick={handleContinue}
          disabled={selectedOption === null}
          type="button"
          className="btn btn-primary flex-fill modal-button"
        >
          {t(`modal.actions.delete`)}
        </button>
      </div>
    </div>
  );
}
