import { CSSProperties, ReactNode, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';

import './intermediateStepsContextMenu.scss';

type StepsContextMenuItem = {
  labelKey: string;
  action: () => void;
  icon: ReactNode;
  hide?: boolean;
};

type IntermediateStepsContextMenuProps = {
  position: PointerPosition;
  items: StepsContextMenuItem[];
  onToggleMenu: () => void;
};

export function IntermediateStepsContextMenu(props: IntermediateStepsContextMenuProps) {
  const { position, items, onToggleMenu } = props;
  const [t] = useTranslation();
  const styles: CSSProperties = useMemo(() => {
    const visibleItemsCount = items.filter((i) => !i.hide).length;
    const height = visibleItemsCount === 1 ? 3.9 : visibleItemsCount * 2.9;
    return {
      left: position.clientX,
      top: position.clientY - height * parseFloat(getComputedStyle(document.documentElement).fontSize)
    };
  }, [position, items]);
  const menuItems = useMemo(() => items, [items]);

  return (
    <>
      <Dropdown className="steps-context-menu" style={styles} show={true} onToggle={onToggleMenu}>
        <Dropdown.Menu className="steps-context-menu">
          {menuItems.map((option, index) => {
            const id = `steps-context-menu__item-${index}`;
            return (
              !option.hide && (
                <Dropdown.Item key={id} className="steps-context-menu" onClick={option.action}>
                  <div>{option.icon}</div>
                  <div>{t(option.labelKey)}</div>
                </Dropdown.Item>
              )
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
