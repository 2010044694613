import { useEvergineStore } from 'evergine-react';
import { useEffect } from 'react';
import { PatientCase } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCaseId } from './useCaseId';
import { useTeetharchAffectation } from './useTeetharchAffectation';

export function useConfirmMatchingPoints(patientCase: PatientCase) {
  const [caseId] = useCaseId();
  const { affectations } = useTeetharchAffectation(caseId);
  const { teethArch, matchingModelPoints, setCanBeConfirmed } = useBoundStore();
  const { evergineReady } = useEvergineStore();

  useEffect(() => {
    const { hasLowerAffectation, hasUpperAffectation } = affectations;
    const minimunCountMatchingPoints = 3;

    if (evergineReady) {
      if (hasUpperAffectation && hasLowerAffectation) {
        if (
          matchingModelPoints['UPPER'].dicomPoints.length >= minimunCountMatchingPoints &&
          matchingModelPoints['UPPER'].model3dPoints.length >= minimunCountMatchingPoints &&
          matchingModelPoints['LOWER'].dicomPoints.length >= minimunCountMatchingPoints &&
          matchingModelPoints['LOWER'].model3dPoints.length >= minimunCountMatchingPoints
        ) {
          setCanBeConfirmed(true);
        }
        return;
      }

      if (hasLowerAffectation) {
        if (
          matchingModelPoints[teethArch].dicomPoints.length >= minimunCountMatchingPoints &&
          matchingModelPoints[teethArch].model3dPoints.length >= minimunCountMatchingPoints
        ) {
          setCanBeConfirmed(true);
        }
      }

      if (hasUpperAffectation) {
        if (
          matchingModelPoints[teethArch].dicomPoints.length >= minimunCountMatchingPoints &&
          matchingModelPoints[teethArch].model3dPoints.length >= minimunCountMatchingPoints
        ) {
          setCanBeConfirmed(true);
        }
      }
    }
  }, [evergineReady, matchingModelPoints]);
}
