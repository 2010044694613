import { CameraPosition, ToolbarConfigProps } from '../../../common';
import { ReactComponent as ResetCam } from '../../../assets/icons/evergineTools/view-selection.svg';
import { Key } from 'ts-key-enum';

export const testToolbarElement: ToolbarConfigProps = {
  icon: ResetCam,
  tooltipKey: 'evergineTools.test',
  handleClick: () => {
    window.App.webEventsProxy.common.setCameraPosition(CameraPosition.Front);
  },
  shortcut: `${Key.Alt}+${Key.ArrowUp}`
};
