import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ChatMessage, ChatMessageDTO } from './chat';
import { IChatService } from './ichat.service';

@injectable()
export class ChatService implements IChatService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  // private readonly apiEndpoint = '/saas-api/chat';
  private readonly apiEndpoint = '/api/product-cases';

  private buildURL = (caseId: string) => `${this.apiEndpoint}/${caseId}/internal-chat`;

  public async getByCaseId(caseId: string): Promise<ChatMessage[]> {
    const chatMessageList: ChatMessage[] = (await this._httpService.get<ChatMessageDTO>(this.buildURL(caseId))).data
      .data;
    return chatMessageList;
  }

  public async addMessage(caseId: string, chatMessage: ChatMessage): Promise<boolean> {
    const result = await this._httpService.post<boolean>(this.buildURL(caseId), chatMessage);
    return result.data;
  }

  public async markMessageAsRead(caseId: string, messageId: string): Promise<void> {
    await this._httpService.patch<boolean>(`${this.buildURL(caseId)}/${messageId}/mark-as-read`);
  }
}
