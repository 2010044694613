import { useCallback, useEffect, useMemo, useState } from 'react';
import { INJECTED_TYPES, OutputIntermediateSteps, Step, container } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { IAIMovementsService } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useStepsManager } from './useStepsManager';

const getStepKeyIndexesFromSteps = (steps: Step[]) =>
  steps.filter((s) => s.isKeyStep === true).map((ks) => ks.stepIndex);

const firstStepIndex = 1;

export function useIntermediateStepsManager() {
  const {
    upperDentalMovements,
    lowerDentalMovements,
    areMadeChangesOnTeeth,
    isRecalculateStepsNeeded,
    isRecalculateCancelled,
    stageIsLoaded,
    areMovementsLoaded,
    setUpperDentalMovements,
    setLowerDentalMovements,
    setAreChangesMadeOnTeeth,
    setIsRecalculateStepsNeeded
  } = useOrthBoundStore();
  const { setWebBusy } = useBoundStore();
  const { setIsModalOpened } = useCommonBoundStore();
  const { goToStep } = useStepsManager();
  const [upperSteps, setUpperSteps] = useState<Step[]>([]);
  const [lowerSteps, setLowerSteps] = useState<Step[]>([]);
  const [selectedStepIndexes, setSelectedStepIndexes] = useState<number[]>([firstStepIndex]);
  const [activeStep, setActiveStep] = useState<number>(firstStepIndex);
  const movementsAIService = container.get<IAIMovementsService>(INJECTED_TYPES.IAIMovementsService);
  const lastStepIndex = useMemo(() => Math.max(upperSteps.length, lowerSteps.length) - 1, [upperSteps, lowerSteps]);
  const keyStepIndexes = useMemo(() => {
    // WORKAROUND debido a que si hay cambios en una sóla arcada, en la otra siguen viniendo como keysteps los stepindex 0 y 1
    const upperStepsKeyIndexes =
      upperDentalMovements?.steps?.length > 2 ? getStepKeyIndexesFromSteps(upperDentalMovements?.steps) : [];
    const lowerStepsKeyIndexes =
      lowerDentalMovements?.steps?.length > 2 ? getStepKeyIndexesFromSteps(lowerDentalMovements?.steps) : [];

    if (upperStepsKeyIndexes.length >= 2 || lowerStepsKeyIndexes.length >= 2) {
      return Array.from(new Set([...upperStepsKeyIndexes, ...lowerStepsKeyIndexes]));
    }

    return [0, 1];
  }, [lowerDentalMovements, upperDentalMovements]);

  useEffect(() => {
    if (!stageIsLoaded || !areMovementsLoaded) {
      return;
    }

    if (upperDentalMovements) {
      if (upperDentalMovements.steps) {
        setUpperSteps(upperDentalMovements.steps);
      }
    }

    if (lowerDentalMovements) {
      if (lowerDentalMovements.steps) {
        setLowerSteps(lowerDentalMovements.steps);
      }
    }
  }, [lowerDentalMovements, upperDentalMovements, stageIsLoaded]);

  useEffect(() => {
    if (selectedStepIndexes.length === 1) {
      setActiveStep(selectedStepIndexes[0]);
    } else {
      setActiveStep(selectedStepIndexes[selectedStepIndexes.length - 1]);
    }
  }, [selectedStepIndexes]);

  useEffect(() => {
    if (!isRecalculateStepsNeeded) {
      return;
    }

    const recalculateSteps = async () => {
      setWebBusy(true);
      await recalculateStepsAfterEdition();
      setIsRecalculateStepsNeeded(false);
      setAreChangesMadeOnTeeth(false);
      setWebBusy(false);
    };

    recalculateSteps();
  }, [isRecalculateStepsNeeded]);

  const hasToAskForRecalculate = useCallback(() => {
    return areMadeChangesOnTeeth;
  }, [areMadeChangesOnTeeth]);

  const getMovementsFromEvergine = () => window.App.webEventsProxy.movements.getTreatmentMovements();

  const updateMovements = useCallback(() => {
    const { upperMovements, lowerMovements } = getMovementsFromEvergine();
    setUpperDentalMovements(upperMovements);
    setLowerDentalMovements(lowerMovements);
  }, [setUpperDentalMovements, setLowerDentalMovements]);

  const addStep = useCallback(
    async (selectedStepIndex: number): Promise<void> => {
      if (selectedStepIndex === lastStepIndex - 1) {
        return;
      }

      await window.App.webEventsProxy.movements.addNewStep(selectedStepIndex);
      updateMovements();
      const newStepIndex = selectedStepIndex + 1;
      setSelectedStepIndexes([newStepIndex]);
    },
    [upperSteps, lowerSteps, lastStepIndex, updateMovements]
  );

  const getStepsPrediction = useCallback(async (stepFrom: number, stepTo: number): Promise<OutputIntermediateSteps> => {
    const inputIntermediateSteps = await window.App.webEventsProxy.movements.getInputForIntermediateSteps(
      stepFrom,
      stepTo
    );
    const inputStringified = JSON.stringify(inputIntermediateSteps);
    const result = await movementsAIService.getAIMovementsPrediction({
      movementsDelta: inputStringified
    });

    return result as OutputIntermediateSteps;
  }, []);

  const loadInitialPrediction = useCallback(async (): Promise<boolean> => {
    const outputIntermediateSteps = await getStepsPrediction(0, lastStepIndex);

    if (outputIntermediateSteps && outputIntermediateSteps.steps?.length > 0) {
      await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
        outputIntermediateSteps,
        0,
        lastStepIndex
      );

      updateMovements();
      goToStep(firstStepIndex, false);
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }, [upperSteps, lowerSteps, lastStepIndex, updateMovements]);

  const removeStep = useCallback(
    async (selectedStepIndexes: number[]): Promise<void> => {
      if (!selectedStepIndexes || selectedStepIndexes.length === 0 || selectedStepIndexes.includes(0)) {
        return;
      }

      // caso simple: eliminar un sólo paso
      const { previous, next } = window.App.webEventsProxy.movements.getKeyStepIndicesByStepIndex(
        selectedStepIndexes[0]
      );

      const outputIntermediateSteps = await getStepsPrediction(previous, next);

      if (outputIntermediateSteps && outputIntermediateSteps.steps) {
        await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
          outputIntermediateSteps,
          previous,
          next
        );

        updateMovements();
      }
    },
    [getStepsPrediction]
  );

  const recalculateStepsAfterEdition = useCallback(async (): Promise<void> => {
    const { previous, next } = window.App.webEventsProxy.movements.getKeyStepIndicesByStepIndex(activeStep);

    const outputFromPreviousToCurrentStep = await getStepsPrediction(previous, activeStep);
    const outputFromCurrentToNextKeyStep = await getStepsPrediction(activeStep, next);

    if (
      outputFromPreviousToCurrentStep &&
      outputFromPreviousToCurrentStep.steps &&
      outputFromCurrentToNextKeyStep &&
      outputFromCurrentToNextKeyStep.steps
    ) {
      await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
        outputFromCurrentToNextKeyStep,
        activeStep,
        next
      );

      await window.App.webEventsProxy.movements.updateIntermediateStepsFromAI(
        outputFromPreviousToCurrentStep,
        previous,
        activeStep
      );

      updateMovements();
    }
  }, [activeStep, setIsModalOpened]);

  return {
    upperSteps,
    lowerSteps,
    lastStepIndex,
    selectedStepIndexes,
    keyStepIndexes,
    activeStep,
    addStep,
    removeStep,
    loadInitialPrediction,
    setSelectedStepIndexes,
    hasToAskForRecalculate
  };
}
