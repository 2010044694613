import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Validate } from '../../../../assets/icons/validate-green.svg';
import { ActionsButton } from './ToggleCompareTreatments';
import './manageVersionButton.scss';

type ManageVersionButtonProps = {
  buttonProps: ActionsButton;
  buttonAction: () => void;
  versionId: string;
  currentEditingVersion: string;
};

export function ManageVersionButton(props: ManageVersionButtonProps) {
  const { buttonProps, buttonAction, versionId, currentEditingVersion } = props;
  const [t] = useTranslation();

  const isEditing = useMemo(() => currentEditingVersion === versionId, [currentEditingVersion, versionId]);
  const isIsEditModeButOtherVersion = useMemo(
    () => currentEditingVersion && !isEditing,
    [currentEditingVersion, isEditing]
  );
  const isIsEditModeButNotEditButton = useMemo(
    () => currentEditingVersion && !buttonProps.isEditButton,
    [currentEditingVersion, buttonProps]
  );

  const onActionClick = useCallback(() => buttonAction(), [buttonAction]);

  const isEditButtonEditing = buttonProps.isEditButton && isEditing;

  return (
    <>
      <button
        type="button"
        data-tip
        data-for={buttonProps.labelKey}
        aria-label={t(buttonProps.labelKey)}
        className={`mvbutton-button `}
        onClick={onActionClick}
        disabled={buttonProps.isDisabled || isIsEditModeButOtherVersion || isIsEditModeButNotEditButton}
      >
        {isEditButtonEditing ? <Validate /> : <buttonProps.Icon />}
      </button>
      <ReactTooltip id={buttonProps.labelKey} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(isEditButtonEditing ? 'common.accept' : buttonProps.labelKey)}
      </ReactTooltip>
    </>
  );
}
