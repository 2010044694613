import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCaseId, useImplantsData } from '../../../../../hooks';
import { ImplantPlan } from '../../../../../shared';
import { Manufacturer } from '../../../../../shared/manufacturers/manufacturer';

export function ModalImplant() {
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { getAllImplants, getAllManufacturers } = useImplantsData(caseId);
  const { toggleModalIsOpened } = useCommonBoundStore();
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>(null);
  const [implants, setImplants] = useState<ImplantPlan[]>(null);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  useEffect(() => {
    const getManufacturers = async () => {
      setManufacturers(await getAllManufacturers());
    };

    getManufacturers();
  }, [manufacturers]);

  const getImplants = useCallback(async () => {
    setImplants(await getAllImplants());
  }, [implants]);

  return (
    <div className="modal-content modal-implants" data-testid={'modal-implant'}>
      <div className="modal-body">
        <h3 className="modal-title mb-4 modal-implants-title w-100">Biblioteca de implantes</h3>
        <div className="d-flex">
          <div className="w-50 me-2">
            <p className="modal-implants-subtitle">Fabricante</p>
            <div className="list manufacturers-section">
              {manufacturers &&
                manufacturers.map((manufacturer) => {
                  return (
                    <p className="list-item" onClick={() => getImplants()}>
                      {manufacturer.name}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="w-50 ms-2">
            <p className="modal-implants-subtitle">Implante</p>
            <div className="list implants-section">
              {implants &&
                implants.map(({ implant }) => {
                  return <p className="list-item">{implant.model}</p>;
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.close`)}
        </button>
      </div>
    </div>
  );
}
