import { StateCreator } from 'zustand';
import { CameraPosition } from '../../../common/evergine/types';

// TODO: mover a ortho
export interface CamViewSlice {
  camView: CameraPosition;
  setCamView: (camView: CameraPosition) => void;
}
export const createCamViewSlice: StateCreator<CamViewSlice, [], [], CamViewSlice> = (set) => ({
  camView: CameraPosition.Front,
  setCamView: (camView: CameraPosition) => set((_) => ({ camView: camView }))
});
