import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { IImplantsService } from './iimplants.service';
import { ImplantPlan } from './implant';

const mockedImplants = [
  {
    implant: {
      id: '',
      createdAt: '',
      geometry: {
        id: '',
        name: 'conic'
      },
      implantType: {
        id: '',
        createdAt: '',
        name: 'tipo 1'
      },
      length: '10',
      model: 'model A',
      updatedAt: '',
      width: '12'
    },
    tooth: {
      id: '',
      name: '10'
    },
    comments: 'comentarios A'
  },
  {
    implant: {
      id: '',
      createdAt: '',
      geometry: {
        id: '',
        name: 'conic'
      },
      implantType: {
        id: '',
        createdAt: '',
        name: 'tipo 2'
      },
      length: '10',
      model: 'model B',
      updatedAt: '',
      width: '12'
    },
    tooth: {
      id: '',
      name: '30'
    },
    comments: 'comentarios B'
  }
] as ImplantPlan[];

@injectable()
export class ImplantsService implements IImplantsService {
  private readonly caseIdToSubstitute = '{reference}';
  private readonly apiEndpoint = `/api/product-case/${this.caseIdToSubstitute}/implants`;
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  getApiPath = (caseId: string) => this.apiEndpoint.replace(this.caseIdToSubstitute, caseId);

  public async getAll(caseId: string): Promise<ImplantPlan[] | null> {
    const apiPath = this.getApiPath(caseId);
    const response = await this._httpService.get(apiPath);
    const implants = response.data as ImplantPlan[];
    return implants;
  }
}
