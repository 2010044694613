import { StateCreator } from 'zustand';
import { ActionCommand, EvergineError, MatchingModelPoint } from '../../../common/evergine/types';

export interface WasmSlice {
  evergineBusy: boolean;
  evergineError: EvergineError | undefined;
  matchingModelPoint: MatchingModelPoint | undefined;
  newActionCommand: ActionCommand | undefined;
  updateMatchingModelPoint: (matchingModelPoints: MatchingModelPoint | undefined) => void;
}
export const createWasmSlice: StateCreator<WasmSlice, [], [], WasmSlice> = (set) => ({
  evergineBusy: false,
  evergineError: undefined,
  matchingModelPoint: undefined,
  newActionCommand: undefined,
  updateMatchingModelPoint: (matchingModelPoint) => set(() => ({ matchingModelPoint: matchingModelPoint }))
});
