import { ReactComponent as Warning } from '../../../../assets/icons/modal/warning.svg';

interface ModalAcceptCancelProps {
  title: string;
  message: string;
  yesText: string;
  noText: string;
  subMessage?: string;
  onClickClose: () => void;
  onClickContinue: () => void;
}

export function ModalAcceptCancel({
  title,
  message,
  subMessage,
  yesText,
  noText,
  onClickClose,
  onClickContinue
}: ModalAcceptCancelProps) {
  return (
    <div data-testid="modal" className="modal modal-opened">
      <div data-testid="modal-container" className={`modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-body d-flex flex-column align-items-center">
            <Warning className="modal-icon mb-3" />
            <h3 className="modal-title mb-4">{title}</h3>
            <p className="modal-text text-center mb-0">{message}</p>
            {subMessage && <p className="modal-text text-center mb-0">{subMessage}</p>}
          </div>

          <div className="modal-footer d-flex flex-grow-1">
            <button
              onClick={onClickClose}
              type="button"
              className="btn btn-outline-primary flex-fill modal-button"
              data-bs-dismiss="modal"
            >
              {noText}
            </button>
            <button onClick={onClickContinue} type="button" className="btn btn-dark flex-fill modal-button">
              {yesText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
