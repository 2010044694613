import { FunctionComponent } from 'react';
import './orthToolButtonSmall.scss';

type OrthToolButtonSmallType = {
  Icon: FunctionComponent;
  isDisabled: boolean;
  onClick: () => void;
};

export function OrthToolButtonSmall({ Icon, isDisabled, onClick }: OrthToolButtonSmallType) {
  return (
    <button className="orth-tool-button-small" onClick={onClick} disabled={isDisabled}>
      <Icon />
    </button>
  );
}
