import { useNavigate } from 'react-router-dom';
import { useCaseId, useGateKeeper, useGetFiles, useRenderModels } from '../../../hooks';
import { AbilityAction, ITeethSegmentationService, OrthoAbilitySubject, SegmentationFileDTO } from '../../../shared';

import { useEvergineStore } from 'evergine-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CapturePosition,
  EvergineToolbar,
  INJECTED_TYPES,
  ModalChangeEnumeration,
  ModalConfirmEnumeration,
  PagesWithTools,
  SegmentationNumerationStrategy,
  Stage,
  container,
  toolbarConfig
} from '../../../common';
import { TeethArchPosition } from '../../../models';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';

import { useTranslation } from 'react-i18next';
import { Odontogram } from '../../../surgeries/components';
import { colorsToEvergine } from '../../../surgeries/components/odontogramTeethArch/odontogramTeethArchUtils';
import { OrthTeethPaint } from '../evergineToolbarElements';

import './teethSegmentation.scss';

const upperLeftQuadrantFDIs = ['18', '17', '16', '15', '14', '13', '12', '11'];
const upperRightQuadrantFDIs = ['28', '27', '26', '25', '24', '23', '22', '21'];
const lowerLeftQuadrantFDIs = ['48', '47', '46', '45', '44', '43', '42', '41'];
const lowerRightQuadrantFDIs = ['38', '37', '36', '35', '34', '33', '32', '31'];

const getQuadrantOfPiece = (pieceFDI: string) => {
  if (upperLeftQuadrantFDIs.includes(pieceFDI)) {
    return upperLeftQuadrantFDIs;
  }

  if (upperRightQuadrantFDIs.includes(pieceFDI)) {
    return upperRightQuadrantFDIs;
  }

  if (lowerLeftQuadrantFDIs.includes(pieceFDI)) {
    return lowerLeftQuadrantFDIs;
  }

  if (lowerRightQuadrantFDIs.includes(pieceFDI)) {
    return lowerRightQuadrantFDIs;
  }
};

export function TeethSegmentation() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  useGateKeeper(AbilityAction.View, OrthoAbilitySubject.TeethSegmentationScreen, () => navigate('/forbidden'));
  const [caseId] = useCaseId();
  const { getSTLFile } = useGetFiles(caseId);
  const { setMessageInfo, setWebBusy } = useBoundStore();
  useRenderModels(caseId, Stage.TeethSegmentation);
  const {
    isTeethSegmentationConfirmed,
    setIsTeethSegmentationConfirmed,
    showPaintPanel,
    showModalEnumeration,
    setShowModalEnumeration,
    selectedPieces,
    setFromToPieces,
    fromToPieces,
    showUpperArch,
    showLowerArch,
    currentVersion,
    stageIsLoaded
  } = useOrthBoundStore();
  const { evergineReady } = useEvergineStore();
  const teethSegmentationService = container.get<ITeethSegmentationService>(INJECTED_TYPES.ITeethSegmentationService);
  const [selectedArchPosition, setSelectedArchPosition] = useState<TeethArchPosition>(TeethArchPosition.BOTH);
  const [isAISegmentationReceived, setIsAISegmentationReceived] = useState<boolean>();
  const [typeStrategy, setTypeStrategy] = useState<number | undefined>(undefined);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [isAllowedMesialDistalRenumber, setIsAllowedMesialDistalRenumber] = useState<boolean>(false);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);

  const pageTools = toolbarConfig[PagesWithTools.Segmentation];

  const closeModal = () => {
    setFromToPieces([]);
    setTypeStrategy(undefined);
    showModalEnumeration && setShowModalEnumeration(false);
    showModalConfirmation && setShowModalConfirmation(false);
  };

  const endEnumeration = async () => {
    await endEnumerationAux(typeStrategy);
  };

  const endEnumerationAux = async (param: SegmentationNumerationStrategy) => {
    try {
      await window.App.webEventsProxy.segmentation.applyNumerationChange(
        getFromAndToPieces.fromPiece,
        getFromAndToPieces.toPiece,
        param
      );
    } catch (error) {
      console.error('Error evaluating numeration change:', error);
    }
    setFromToPieces([]);
    setTypeStrategy(undefined);
    showModalEnumeration && setShowModalEnumeration(false);
    showModalConfirmation && setShowModalConfirmation(false);
  };

  const continueNumeration = async (param: SegmentationNumerationStrategy) => {
    setTypeStrategy(param);
    try {
      const result = await window.App.webEventsProxy.segmentation.evaluateNumerationChange(
        getFromAndToPieces.fromPiece,
        getFromAndToPieces.toPiece,
        param
      );
      if (!result.canBeAutomaticallyResolved) {
        setShowModalEnumeration(false);
        return setShowModalConfirmation(true);
      }
      return endEnumerationAux(param);
    } catch (error) {
      console.error('Error evaluating numeration change:', error);
    }
  };

  useEffect(() => {
    if (!currentVersion || !evergineReady || !stageIsLoaded) {
      return;
    }

    if (currentVersion.segmentation && currentVersion.segmentation !== null) {
      const lowerSegmentation = currentVersion.segmentation.lower || null;
      const upperSegmentation = currentVersion.segmentation.upper || null;

      if (lowerSegmentation) {
        window.App.webEventsProxy.segmentation.setInitialSegmentationData({
          faces: lowerSegmentation,
          teethArch: CapturePosition.LOWER
        });
      }

      if (upperSegmentation) {
        window.App.webEventsProxy.segmentation.setInitialSegmentationData({
          faces: upperSegmentation,
          teethArch: CapturePosition.UPPER
        });
      }
      setIsAISegmentationReceived(true);
    }
  }, [currentVersion, evergineReady, stageIsLoaded]);

  useEffect(() => {
    if (isTeethSegmentationConfirmed && isAIEnabled) {
      setIsAIEnabled(false);
      setWebBusy(true);

      const sendTeethDataToAI = async () => {
        try {
          const upperArchBase64 = await getSTLFile(CapturePosition.UPPER);

          if (upperArchBase64) {
            const upperJawFileDto: SegmentationFileDTO = { file: upperArchBase64, teethArch: CapturePosition.UPPER };
            const response = await teethSegmentationService.getAITeethSegmentation(upperJawFileDto);
            window.App.webEventsProxy.segmentation.setSegmentationData({
              faces: response.faces,
              teethArch: CapturePosition.UPPER
            });
          }

          const lowerArchBase64 = await getSTLFile(CapturePosition.LOWER);

          if (lowerArchBase64) {
            const lowerJawFileDto: SegmentationFileDTO = { file: lowerArchBase64, teethArch: CapturePosition.LOWER };
            const response = await teethSegmentationService.getAITeethSegmentation(lowerJawFileDto);
            window.App.webEventsProxy.segmentation.setSegmentationData({
              faces: response.faces,
              teethArch: CapturePosition.LOWER
            });
          }
          setIsAISegmentationReceived(true);
        } finally {
          setIsAIEnabled(true);
          setWebBusy(false);
        }
      };

      sendTeethDataToAI();
    }
    setIsTeethSegmentationConfirmed(false);
  }, [isTeethSegmentationConfirmed]);

  useEffect(() => {
    let position: TeethArchPosition;
    if (showUpperArch === true && showLowerArch === true) {
      position = TeethArchPosition.BOTH;
    } else if (showUpperArch === true) {
      position = TeethArchPosition.UPPER;
    } else if (showLowerArch === true) {
      position = TeethArchPosition.LOWER;
    } else {
      position = TeethArchPosition.BOTH;
    }
    setSelectedArchPosition(position);
  }, [showUpperArch, showLowerArch]);

  useEffect(() => {
    setMessageInfo(showPaintPanel ? 'pageInfoMessages.teethSegmentation.paintInstructions' : undefined);
  }, [showPaintPanel]);

  const handleFromEnumeration = useCallback(() => {
    if (selectedPieces && selectedPieces.length > 0) {
      return selectedPieces[0];
    }
    return;
  }, [selectedPieces]);

  const getFromAndToPieces = useMemo(() => {
    return {
      fromPiece: Number(fromToPieces[0]),
      toPiece: Number(fromToPieces[1])
    };
  }, [fromToPieces]);

  useEffect(() => {
    if (fromToPieces.length === 2) {
      const [from, to] = fromToPieces;

      const fromPieceQuadrant = getQuadrantOfPiece(from);

      if (fromPieceQuadrant) {
        setIsAllowedMesialDistalRenumber(fromPieceQuadrant?.includes(to));
      } else {
        setIsAllowedMesialDistalRenumber(false);
      }
    }
  }, [fromToPieces]);

  useEffect(() => {
    if (!evergineReady) {
      return;
    }

    window.App.webEventsProxy.segmentation.setColorsByFdi(colorsToEvergine);
  }, [evergineReady]);

  return (
    <>
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      {showPaintPanel && <OrthTeethPaint selectedArchPosition={selectedArchPosition} />}
      <div className="teethsegmentation">
        {!showPaintPanel && isAISegmentationReceived && (
          <div className="teethsegmentation-odontogram">
            <Odontogram archPosition={selectedArchPosition} showToggleNumbers={true} colorVersion={true} />
          </div>
        )}
      </div>
      {showModalEnumeration && (
        <ModalChangeEnumeration
          title={t('pageInfoMessages.teethSegmentation.changeNumeration')}
          yesText={t('common.accept')}
          noText={t('modal.actions.cancel')}
          distalText={t('pageInfoMessages.teethSegmentation.distalRenumeration')}
          mesialText={t('pageInfoMessages.teethSegmentation.mesialRenumeration')}
          from={handleFromEnumeration()}
          onClickClose={closeModal}
          onClickContinue={(param) => continueNumeration(param)}
          disableMesialDistalRenumber={!isAllowedMesialDistalRenumber}
        />
      )}
      {showModalConfirmation && (
        <ModalConfirmEnumeration
          title={t('pageInfoMessages.teethSegmentation.conflictElements')}
          yesText={t('pageInfoMessages.teethSegmentation.change')}
          noText={t('modal.actions.cancel')}
          from={handleFromEnumeration()}
          onClickClose={closeModal}
          onClickContinue={endEnumeration}
        />
      )}
    </>
  );
}
