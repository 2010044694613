import { injectable } from 'inversify';
import { INJECTED_TYPES, container } from '../../common';
import type { IHttpService } from '../http';
import { AxisRootsOrientationFileDTO, AxisRootsOrientationResponseDTO } from './axisRootsOrientation';
import { IAxisRootsOrientationService } from './iAxisRootsOrientation.service';

@injectable()
export class AxisRootsOrientationService implements IAxisRootsOrientationService {
  private _httpService = container.get<IHttpService>(INJECTED_TYPES.IHttpService);

  private readonly apiEndpoint = '/ai/orientation';

  public async getAIAxisRootsOrientation(
    requestDto: AxisRootsOrientationFileDTO
  ): Promise<AxisRootsOrientationResponseDTO[]> {
    const result = await this._httpService.post<AxisRootsOrientationResponseDTO[]>(this.apiEndpoint, requestDto);
    return result.data;
  }
}
