import { StateCreator } from 'zustand';
import { Stage } from '../../../common';

export interface RenderModelsSlice {
  areMovementsLoaded: boolean;
  stageIsLoaded: boolean;
  isInFinalPositionStage: boolean;
  areTeethModelsLoaded: boolean;
  previousStage?: Stage;
  setAreMovementsLoaded: (areMovementsLoaded: boolean) => void;
  setStageIsLoaded: (stageIsLoaded: boolean) => void;
  setIsInFinalPositionStage: (isInFinalPositionStage: boolean) => void;
  setAreTeethModelsLoaded: (areTeethModelsLoaded: boolean) => void;
  setPreviousStage: (previousStage: Stage) => void;
}

export const createRenderModelsSlice: StateCreator<RenderModelsSlice, [], [], RenderModelsSlice> = (set) => ({
  areMovementsLoaded: false,
  stageIsLoaded: false,
  isInFinalPositionStage: false,
  areTeethModelsLoaded: false,
  previousStage: undefined,
  setAreMovementsLoaded: (areMovementsLoaded: boolean) => set((_) => ({ areMovementsLoaded })),
  setStageIsLoaded: (stageIsLoaded: boolean) => set((_) => ({ stageIsLoaded })),
  setIsInFinalPositionStage: (isInFinalPositionStage: boolean) => set((_) => ({ isInFinalPositionStage })),
  setAreTeethModelsLoaded: (areTeethModelsLoaded: boolean) => set((_) => ({ areTeethModelsLoaded })),
  setPreviousStage: (previousStage: Stage) => set((_) => ({ previousStage }))
});
