import { StateCreator } from 'zustand';

export interface SpinnerSlice {
  webBusy: boolean;
  setWebBusy: (webBusy: boolean) => void;
}

export const createSpinnerSlice: StateCreator<SpinnerSlice, [], [], SpinnerSlice> = (set) => ({
  webBusy: false,
  setWebBusy: (webBusy) => set(() => ({ webBusy }))
});
