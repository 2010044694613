import { ReactElement } from 'react';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import './modal.scss';

export function Modal(props: { children: ReactElement<{ key: string }>[] }) {
  const { children } = props;
  const isModalOpened = useCommonBoundStore((state) => state.isModalOpened);
  const modalType = useCommonBoundStore((state) => state.modalType);
  const isModalXL = useCommonBoundStore((state) => state.isModalXL);

  if (!isModalOpened) {
    return null;
  }

  return (
    <div data-testid="modal" className="modal modal-opened">
      <div
        data-testid="modal-container"
        className={`modal-dialog modal-dialog-centered ${isModalXL && 'modal-dialog--xl'} `}
      >
        {children.find((child) => child.key === modalType)}
      </div>
    </div>
  );
}
