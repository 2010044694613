import { StateCreator } from 'zustand';
import { AttachmentType } from '../../../common';
import { TooltipAttach } from '../../components';
export interface OrthAttachesSlice {
  areAttachesCalculating: boolean;
  setAreAttachesCalculating: (areAttachesCalculating: boolean) => void;
  attachActive: AttachmentType | null;
  setAttachActive: (attachActive: AttachmentType) => void;
  positionAttachTooltip: TooltipAttach | null;
  setPositionAttach: (positionAttachTooltip: TooltipAttach) => void;
}

export const createOrthAttachesSlice: StateCreator<OrthAttachesSlice, [], [], OrthAttachesSlice> = (set) => ({
  areAttachesCalculating: false,
  setAreAttachesCalculating: (areAttachesCalculating: boolean) => set((_) => ({ areAttachesCalculating })),
  attachActive: null,
  setAttachActive: (attachActive: AttachmentType) => set((_) => ({ attachActive })),
  positionAttachTooltip: null,
  setPositionAttach: (positionAttachTooltip: TooltipAttach) => set((_) => ({ positionAttachTooltip }))
});
