import { useTranslation } from 'react-i18next';
import NextStepIcon from '../../../../assets/icons/next.svg';
import { ModalTypes } from '../../../../surgeries/components/layout';
import { useCommonBoundStore } from '../../../stores/useStore';

import { NavBarAction } from './NavBarAction';

export default () => {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);

  const openModal = () => {
    toggleModalIsOpened(ModalTypes.ModalNextStepDirect);
  };

  return <NavBarAction icon={NextStepIcon} altText={t('navbar.actions.nextStep')} action={openModal} />;
};
