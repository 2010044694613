import { useMemo } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';

import { TeethArchPosition } from '../../../../../models';
import { OrthPaintTools } from './OrthPaintTools';

import './orthTeethPaint.scss';

interface OrthTeethPaintProps {
  selectedArchPosition: TeethArchPosition;
}

export function OrthTeethPaint({ selectedArchPosition }: OrthTeethPaintProps) {
  const [t] = useTranslation();
  const { setShowPaintPanel, setSelectedPieces } = useOrthBoundStore();

  const PANEL_SIZE = 312;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthTeethPaintButton')?.getBoundingClientRect();
  }, []);

  const handleClose = () => {
    setShowPaintPanel(false);
    setSelectedPieces([]);
  };

  return (
    <>
      <Draggable
        bounds="body"
        handle=".handleOrthTeethPaint"
        defaultPosition={{ x: toolPosition.x - PANEL_SIZE, y: toolPosition.top }}
      >
        <div className="orth-paint">
          <div className="orth-paint__header">
            <div className="orth-paint__title handleOrthTeethPaint">
              <div>{t('evergineTools.paintSegmentation')}</div>
            </div>

            <button type="button" className="orth-paint__close" onClick={handleClose}>
              <CloseIcon className="clickable" />
            </button>
          </div>
          <div className="orth-paint__separator" />
          <div>
            <OrthPaintTools selectedArchPosition={selectedArchPosition} />
          </div>
        </div>
      </Draggable>
    </>
  );
}
