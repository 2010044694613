import { ReactComponent as RadioOffIcon } from '../../../assets/icons/radio-off.svg';
import { ReactComponent as RadioOnIcon } from '../../../assets/icons/radio-on.svg';
import './radio.scss';

export type RadioProps = {
  label?: string;
  active?: boolean;
  readOnly?: boolean;
  callBack?: () => void;
};

export default function Radio({ label, active, callBack, readOnly }: RadioProps) {
  return (
    <label className="radio-button">
      <span className="radio-button-icons">
        <input type="radio" checked={active} onChange={callBack} readOnly={readOnly} />
        <span className="radio-button-icon radio-button-on">
          <RadioOnIcon />
        </span>
        <span className="radio-button-icon radio-button-off">
          <RadioOffIcon />
        </span>
      </span>
      {label && <span className="radio-button-label">{label}</span>}
    </label>
  );
}
