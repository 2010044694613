import { useTranslation } from 'react-i18next';
import NextStepIcon from '../../../../assets/icons/next.svg';

import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  useCalculateRoutes,
  useCaseChanges,
  useCaseId,
  useCaseType,
  useCustomNavigate,
  useModalSaveChanges
} from '../../../../hooks/shared';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';
import { NavBarAction } from './NavBarAction';

export default (actionProps: any) => {
  const isDirectNavigation = actionProps?.block?.isDirectNavigation;
  const checkPhaseChanges = actionProps?.block?.checkPhaseChanges;

  const [t] = useTranslation();
  const { setIsConfirmed, canBeConfirmed } = useBoundStore();
  const { isCaseModified, onChangeRoute, updateRouteToGo } = useCaseChanges();
  const { pathname } = useLocation();
  const [caseId] = useCaseId();
  const caseType = useCaseType();
  const { getRouteKeepingParameters } = useCustomNavigate();
  const [calculateNextRoute] = useCalculateRoutes(pathname, caseId, caseType);
  const { getPhaseSaveChange } = useModalSaveChanges();
  const { saveChanges } = getPhaseSaveChange();
  const navigate = useNavigate();
  const { currentVersion } = useOrthBoundStore();
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const someCapture = useCommonBoundStore((state) => state.someCapture);
  const { setStageIsLoaded, setShowPaintPanel } = useOrthBoundStore();
  const { isCaseReseting } = useCommonBoundStore();
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);

  const confirm = useCallback(async () => {
    setCanUndo(false);
    setShowPaintPanel(false);
    if (currentVersion === null) {
      return;
    }

    if (isDirectNavigation) {
      const nextRoute = calculateNextRoute();

      setStageIsLoaded(false);

      if (checkPhaseChanges) {
        if (isCaseModified) {
          updateRouteToGo(nextRoute);
          onChangeRoute();
          return;
        }

        const result = await saveChanges();
        if (!result) {
          return;
        }
      }

      navigate(getRouteKeepingParameters(nextRoute));
      return;
    }
    setIsConfirmed(true);
  }, [
    isDirectNavigation,
    checkPhaseChanges,
    isCaseModified,
    calculateNextRoute,
    currentVersion,
    setStageIsLoaded,
    isCaseReseting
  ]);

  const onButtonClick = () => {
    setWebBusy(true);
    confirm();
  };

  const isDisabled = () => {
    if (!actionProps?.block?.isDirectNavigation && canBeConfirmed == false) {
      return true;
    }
    return false;
  };

  const buttonId = 'nextStep';

  return (
    <>
      <NavBarAction
        icon={NextStepIcon}
        dataFor={buttonId}
        disabled={isDisabled()}
        altText={t('navbar.actions.nextStep')}
        action={onButtonClick}
        isEndOfBlock={actionProps?.block?.isEndOfBlock}
      />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.nextStep`)}
      </ReactTooltip>
    </>
  );
};
