import { useEvergineStore } from 'evergine-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from '../../../assets/icons/delete.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import { EvergineToolbar, ModalAcceptCancel, PagesWithTools, Stage, Timeline, toolbarConfig } from '../../../common';
import { LateralPanel } from '../../../common/components/lateralPanel';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useCaseId, useRenderModels } from '../../../hooks';
import {
  useDentalMovements,
  useDentalMovementsStepsManager,
  useIntermediateStepsManager
} from '../../../hooks/orthodontics';
import { useUtils } from '../../../hooks/shared/useUtils';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../shared';
import { ModalTypes } from '../../../surgeries/components/layout';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';
import { useOrthBoundStore } from '../../stores/useStore';
import { BoltonPanel } from '../boltonPanel';
import { Shift } from '../dentalMovements';
import { OrthTeethMovements } from '../evergineToolbarElements';
import { EvolutionPanel } from '../evolutionPanel';
import { MovementsPanel } from '../movementsPanel/MovementsPanel';
import { OcclusogramLegend } from '../occlusogramLegend';
import { IntermediateStepsContextMenu } from './IntermediateStepsContextMenu';

export function IntermediateSteps() {
  const ability = useContext(AbilityContext);
  const [t] = useTranslation();
  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.IntermediateSteps);
  const { evergineReady } = useEvergineStore();
  const [contextMenuPosition, setContextMenuPosition] = useState<PointerPosition>({ clientX: 0, clientY: 0 });
  const [showContextMenu, setShowContextMenu] = useState<boolean>();
  const [showModal, setShowModal] = useState<boolean>();
  const [hasRemoved, setHasRemoved] = useState<boolean>();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>();
  const { toggleModalIsOpened, setActiveStep } = useCommonBoundStore();
  const {
    lowerDentalMovements,
    upperDentalMovements,
    areMovementsLoaded,
    showMovements,
    areMadeChangesOnTeeth,
    stageIsLoaded,
    showBoltonPanel,
    showEvolutionPanel,
    showMovementsTable,
    showMiniOcclusogram,
    areInitialMovementsLoaded,
    setShowBoltonPanel,
    setShowEvolutionPanel,
    setShowMovementsTable,
    setAreInitialMovementsLoaded
  } = useOrthBoundStore();
  const { setWebBusy } = useBoundStore();
  const pageTools = toolbarConfig[PagesWithTools.IntermediateSteps];

  const [canShowTimeline, setCanShowTimeline] = useState<boolean>(false);

  const {
    upperSteps,
    lowerSteps,
    lastStepIndex,
    selectedStepIndexes,
    keyStepIndexes,
    activeStep,
    addStep,
    removeStep,
    loadInitialPrediction,
    setSelectedStepIndexes,
    hasToAskForRecalculate
  } = useIntermediateStepsManager();

  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);

  const { dentalMovementsSteps, dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList } =
    useDentalMovementsStepsManager(lowerDentalMovements, upperDentalMovements);
  const { stepSorter, getWidthFromEvolutionPanel, evolutionPanelWidth } = useUtils();

  useEffect(() => {
    if (!selectedStepIndexes) {
      return;
    }

    const newCurrentStep = selectedStepIndexes[selectedStepIndexes.length - 1];
    setCurrentStepIndex(newCurrentStep);
    setActiveStep(newCurrentStep);
  }, [selectedStepIndexes]);

  useEffect(() => {
    if (upperSteps.length <= 1 && lowerSteps.length <= 1) {
      return;
    }

    if (!areMovementsLoaded || areInitialMovementsLoaded) {
      return;
    }

    const loadInitialStepsPrediction = async () => {
      setWebBusy(true);
      await loadInitialPrediction();
      setWebBusy(false);
    };

    setAreInitialMovementsLoaded(true);
    loadInitialStepsPrediction();
  }, [areMovementsLoaded, upperSteps, lowerSteps, areInitialMovementsLoaded]);

  const onStepContextMenu = useCallback(
    (stepIndex: number, stepElement: HTMLElement, isCtrlKeyPressed: boolean) => {
      setSelectedStepIndexes((indexes) => {
        if (!isCtrlKeyPressed && indexes.length === 1) {
          return [stepIndex];
        }

        if (!isCtrlKeyPressed && !indexes.includes(stepIndex)) {
          return [stepIndex];
        }

        if (isCtrlKeyPressed && stepIndex !== 0) {
          return [...indexes, stepIndex];
        }

        return indexes;
      });

      const rect = stepElement.getBoundingClientRect();
      const { top, left } = rect;
      setContextMenuPosition({ clientX: left, clientY: top });
      setShowContextMenu(true);
    },
    [setContextMenuPosition, setSelectedStepIndexes, setShowContextMenu, selectedStepIndexes]
  );

  const onToggleMenu = useCallback(() => {
    setShowContextMenu(false);
  }, [setShowContextMenu]);

  const addStepAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0 || selectedStepIndexes[0] === lastStepIndex - 1) {
      return;
    }

    await addStep(selectedStepIndexes[0]);
  }, [selectedStepIndexes, addStep]);

  const removeStepsAction = useCallback(async (): Promise<void> => {
    if (selectedStepIndexes.length === 0) {
      return;
    }

    if (selectedStepIndexes.length > 1) {
      setShowModal(true);
    } else {
      setWebBusy(true);
      await removeSteps(selectedStepIndexes);
      setWebBusy(false);
    }
  }, [selectedStepIndexes]);

  const removeSteps = useCallback(
    async (stepIndexes: number[]): Promise<void> => {
      await removeStep(stepIndexes);
      setShowModal(false);
      setHasRemoved(true);
    },
    [removeStep, setShowModal, setHasRemoved]
  );

  const handlePreventedNavigation = useCallback(() => {
    if (areMadeChangesOnTeeth) {
      toggleModalIsOpened(ModalTypes.ModalModificationsMade);
    }
  }, [areMadeChangesOnTeeth]);

  const addStepToSelection = useCallback(
    (stepIndex: number, isCtrlKeyPressed?: boolean) => {
      setSelectedStepIndexes((indexes) => {
        if (!isCtrlKeyPressed) {
          return [stepIndex];
        }

        const indexesWithoutZero = indexes.filter((i) => i !== 0);

        if (indexesWithoutZero.includes(stepIndex)) {
          return indexesWithoutZero.filter((index) => index !== stepIndex);
        }

        if (stepIndex !== 0) {
          /*TODO - enable this return when activate multiple selection */
          //return [...indexesWithoutZero, stepIndex];
          return [stepIndex];
        }

        return indexesWithoutZero;
      });
    },
    [setSelectedStepIndexes, selectedStepIndexes, hasToAskForRecalculate, areMadeChangesOnTeeth]
  );

  const intermediateStepsContextMenuOptions = [
    {
      labelKey: 'intermediateSteps.contextMenuOptions.addStep',
      action: addStepAction,
      icon: <IconPlus />,
      hide: selectedStepIndexes.length > 1 || selectedStepIndexes[0] === lastStepIndex
    },
    {
      labelKey: 'intermediateSteps.contextMenuOptions.removeStep',
      action: removeStepsAction,
      icon: <IconDelete />,
      hide:
        selectedStepIndexes[0] === 0 ||
        selectedStepIndexes.length > 1 ||
        !keyStepIndexes.includes(selectedStepIndexes[0]) ||
        selectedStepIndexes[0] === lastStepIndex
    },
    {
      labelKey: 'intermediateSteps.contextMenuOptions.removeSteps',
      action: removeStepsAction,
      icon: <IconDelete />,
      hide: selectedStepIndexes.length === 1
    }
  ];

  const onClickClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const showSelectedIndexesHuman = useCallback(() => {
    const stepsReadable =
      selectedStepIndexes.length > 1
        ? `${selectedStepIndexes.slice(0, -1).join(', ')} ${t('common.and')} ${selectedStepIndexes.slice(-1)}`
        : `${selectedStepIndexes[0]}`;

    const message = `${t('intermediateSteps.modalRemoveSteps.message')} ${stepsReadable} ${t(
      'intermediateSteps.modalRemoveSteps.confirmation'
    )}`;

    return message;
  }, [selectedStepIndexes]);

  const showLateralPanelIfAllowed = useCallback(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />;
    }
  }, [showInfoPanel, ability, handleInfoPanelToggle]);

  const shiftMovementsPanel = useCallback(() => {
    if (showMovementsTable) {
      if (showEvolutionPanel && !showInfoPanel) {
        return Shift.EvolutionPanelOpened;
      }

      if (showInfoPanel && !showEvolutionPanel) {
        return Shift.InfoPanelOpened;
      }

      if (showInfoPanel && showEvolutionPanel) {
        return Shift.EvolutionAndInfoPanelsOpened;
      }
    }

    return Shift.None;
  }, [showMovementsTable, showEvolutionPanel, showInfoPanel]);

  useEffect(() => {
    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady &&
      areInitialMovementsLoaded;
    setCanShowTimeline(result);
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded, areInitialMovementsLoaded]);

  return (
    <>
      {showLateralPanelIfAllowed()}
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      {showMovements && <OrthTeethMovements />}
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showBoltonPanel && (
        <BoltonPanel
          opened={showBoltonPanel}
          mandibularTeeth={lowerDentalMovements?.teeth}
          maxillaryTeeth={upperDentalMovements?.teeth}
          onClickClose={() => setShowBoltonPanel(false)}
        />
      )}
      {dentalMovementsSteps.length > 0 && (
        <EvolutionPanel
          opened={showEvolutionPanel}
          onClickClose={() => setShowEvolutionPanel(false)}
          shift={showEvolutionPanel && showInfoPanel ? Shift.InfoPanelOpened : Shift.None}
          getWidthFunction={(width) => getWidthFromEvolutionPanel(width)}
          steps={dentalMovementsSteps}
          updateIPRList={setDentalMovementsApplyIPRList}
          applyIPRList={dentalMovementsApplyIPRList}
        />
      )}
      <MovementsPanel
        opened={showMovementsTable}
        onClickClose={() => setShowMovementsTable(false)}
        shiftMode={shiftMovementsPanel()}
        evolutionPanelWidth={evolutionPanelWidth}
      />
      {canShowTimeline && (
        <>
          <Timeline
            upperTeeth={upperDentalMovements?.teeth}
            lowerTeeth={lowerDentalMovements?.teeth}
            upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
            lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
            upperAttachments={upperDentalMovements?.attachments}
            lowerAttachments={lowerDentalMovements?.attachments}
            comparerActivated={false}
            vertical={false}
            enableFollowUpOverposition={false}
            onStepContextMenu={onStepContextMenu}
            updateStoreCurrentStep={addStepToSelection}
            storeCurrentStep={activeStep}
            /*TODO - enable selectedSteps prop when activate multiple selection */
            //selectedSteps={selectedStepIndexes}
            isDesigner={true}
            keyStepsIndexes={keyStepIndexes}
            preventNavigation={areMadeChangesOnTeeth}
            handlePreventedNavigation={handlePreventedNavigation}
          />
          {showContextMenu && selectedStepIndexes[0] !== lastStepIndex && (
            <IntermediateStepsContextMenu
              position={contextMenuPosition}
              onToggleMenu={onToggleMenu}
              items={intermediateStepsContextMenuOptions}
            />
          )}
          {showModal && (
            <ModalAcceptCancel
              message={showSelectedIndexesHuman()}
              subMessage={t('intermediateSteps.modalRemoveSteps.recalcNeeded')}
              noText={t('modal.actions.cancel')}
              yesText={t('modal.actions.remove')}
              onClickClose={onClickClose}
              onClickContinue={() => removeSteps(selectedStepIndexes)}
              title={t('intermediateSteps.modalRemoveSteps.title')}
            />
          )}
        </>
      )}
    </>
  );
}
