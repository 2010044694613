import { useTranslation } from 'react-i18next';
import { IconTextInfo } from '../../../../../../common';

import { AttacheModel } from '../attacheModel/AttacheModel';
import './attacheType.scss';

export interface AttacheType {
  name: string;
  models: IconTextInfo[];
}

interface AttacheTypeProps {
  attacheType: AttacheType;
}

export function AttacheType({ attacheType }: AttacheTypeProps) {
  const [t] = useTranslation();

  return (
    <div key={attacheType.name} className="attachetype">
      <div className="attachetype-title">{t(attacheType.name)}</div>
      <div className="attachetype-models">
        {attacheType.models.map((model) => (
          <AttacheModel model={model} key={`attachemodel-${model.id}`} />
        ))}
      </div>
    </div>
  );
}
