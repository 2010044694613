export enum PROFILES_TYPE {
  client = 'client',
  employee = 'employee',
  dev = 'dev'
}

export const CLIENT_ROLE_ON_BACKEND = 'ROLE_USER';

export type UserProfileType = PROFILES_TYPE.client | PROFILES_TYPE.employee | PROFILES_TYPE.dev;

export type UserProfileData = {
  id: string;
  type: UserProfileType;
  name: string;
  surname: string;
};

export type UserInfo = {
  id: string;
  email: string;
};

export type UserProfile = {
  id: string;
  firstName: string;
  lastNames: string;
  email: string;
  type: UserProfileType;
};

export type UserProfileDTO = {
  data: UserProfile;
};
