import { useTranslation } from 'react-i18next';
import { ReactComponent as Minus } from '../../../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import { TeethArchPosition } from '../../../../../models';
import { Odontogram } from '../../../../../surgeries/components';

import { Slider } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Radio from '../../../../../common/components/radio/Radio';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { OrthToolButtonSmall } from '../teethMovements/orthToolButtonSmall';

import './orthPaintTools.scss';

interface OrthPaintToolsProps {
  selectedArchPosition: TeethArchPosition;
}

export function OrthPaintTools({ selectedArchPosition }: OrthPaintToolsProps) {
  const [t] = useTranslation();
  const [valueSlider, setValueSlider] = useState<number>(1);
  const { selectedPieces, setSelectedPieces, modeBrush, modeEraser, setShowModalEnumeration } = useOrthBoundStore();

  const toggleSelectedPieces = useCallback(
    (pieceId: string) => {
      if (selectedPieces.includes(pieceId)) {
        setSelectedPieces([]);
      } else {
        setSelectedPieces([pieceId]);
        const pieceIdNumber = Number(pieceId);
        window.App.webEventsProxy.segmentation.setPainterFdi(pieceIdNumber);
        window.App.webEventsProxy.segmentation.setBrushSize(valueSlider);
      }
    },
    [selectedPieces, valueSlider]
  );

  const onClickOdontogramPiece = (pieceId: string) => {
    toggleSelectedPieces(pieceId);
  };

  const onChangeRangeSlider = (e: any) => {
    window.App.webEventsProxy.segmentation.setBrushSize(e.target.value);
    setValueSlider(e.target.value);
  };

  const handleSize = (param: number) => {
    if (param < 0.5 || param > 20) {
      return;
    }
    window.App.webEventsProxy.segmentation.setBrushSize(param);
    setValueSlider(param);
  };

  return (
    <>
      <div className="orth-paint-tools">
        <div className="orth-paint-tools__separator" />
        <div className="orth-paint-tools__header">{t('manualSegmentationPanel.selectTeeth')}</div>
        <div className="orth-paint-tools__content">
          <Odontogram
            archPosition={selectedArchPosition}
            showToggleNumbers={true}
            selectedPieces={selectedPieces}
            onPieceClick={onClickOdontogramPiece}
          />
          <div className="content-radio">
            <Radio active={modeBrush} label={t('manualSegmentationPanel.paintTool')} readOnly={true} />
            <Radio active={modeEraser} label={t('manualSegmentationPanel.eraseTool')} readOnly={true} />
          </div>
        </div>
        <div className="orth-paint-tools__separator" />

        <div className="orth-paint-tools__footer">
          <div className="title">
            <p className="text">{t('manualSegmentationPanel.sizeBrush')}</p>
            <div className="right-side">
              <div className="content-legend">
                <input
                  className="input-size"
                  disabled={true}
                  value={`${valueSlider} mm`}
                  placeholder={`${valueSlider} mm`}
                  max={100}
                  min={0}
                />
              </div>

              <div className="group-buttons">
                <OrthToolButtonSmall Icon={Plus} isDisabled={false} onClick={() => handleSize(valueSlider + 0.5)} />
                <OrthToolButtonSmall Icon={Minus} isDisabled={false} onClick={() => handleSize(valueSlider - 0.5)} />
              </div>
            </div>
          </div>
          <Slider disabled={false} value={valueSlider} onChange={(e) => onChangeRangeSlider(e)} min={0.5} max={20} />
          <button
            disabled={selectedPieces.length === 0 || !selectedPieces}
            type="button"
            className="btn btn-primary calculate"
            onClick={() => setShowModalEnumeration(true)}
          >
            {t('pageInfoMessages.teethSegmentation.changeNumeration')}
          </button>
        </div>
      </div>
    </>
  );
}
