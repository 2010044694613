import { DentalMovementDTO } from '../dentalMovements';
import { HistoryTreatmentChangeItemPost } from '../historyTreatmentChange';

export enum ClientPlanValidationEnum {
  Pending = 'pending',
  Approved = 'accepted',
  Rejected = 'rejected',
  RequestedChanges = 'requestedChanges'
}

export type ClientPlanValidation = {
  caseId: string;
  clientId: string;
  result: ClientPlanValidationEnum;
  requestedChangesText?: string;
};

export type ClientPlanValidationDTO = {
  clientId: string;
  result: ClientPlanValidationEnum;
  requestedChangesText?: string;
  requestedMovements: ToothMovementDTO[];
  isClientModification: boolean;
  treatmentChangeItems: HistoryTreatmentChangeItemPost[];
};

export type ToothMovementDTO = {
  toothId: number;
  crown?: DentalMovementDTO;
  root?: DentalMovementDTO;
};
