import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '../../../../../common/components/radio/Radio';
import './orthTeethMovements.scss';
import { LabelSwitch } from '../../../../../common/components/labelSwitch';

enum SymmetryType {
  Complete = 1,
  Preceding = 2
}

enum CoordinationType {
  Complete = 3,
  Preceding = 4,
  Subsequent = 5
}

type ArchMovementType = SymmetryType | CoordinationType;

function isSymmetryType(type: ArchMovementType): type is SymmetryType {
  return type === SymmetryType.Complete || type === SymmetryType.Preceding;
}

export function OrthArchsTools() {
  const [t] = useTranslation();
  const [isUpperSelected, setIsUpperSelected] = useState<boolean>(false);
  const [isSymetricMove, setIsSymetricMove] = useState<boolean>(false);
  const [symmetryType, setSymmetryType] = useState<SymmetryType>();
  const [coordinationType, setCoordinationType] = useState<CoordinationType>();

  const archMovementTypeSetter = (selectedType: ArchMovementType) => {
    if (isSymmetryType(selectedType)) {
      setSymmetryType(selectedType);
    } else {
      setCoordinationType(selectedType);
    }
  };

  const radioComponent = (
    id: ArchMovementType,
    selection: any,
    translationKey: string,
    callBack: (selectedType: ArchMovementType) => void
  ) => {
    return (
      <div className="orth-movements__content">
        <Radio
          active={id === selection}
          label={t(`dentalMovements.movementsTool.${translationKey}`)}
          callBack={() => callBack(id)}
        />
      </div>
    );
  };

  return (
    <div className="orth-movements__gum">
      <div className="orth-movements__separator" />
      <div className="orth-movements__header">
        <Radio
          active={isUpperSelected}
          label={t('dentalMovements.movementsTool.upper')}
          callBack={() => setIsUpperSelected(true)}
        />
        <Radio
          active={!isUpperSelected}
          label={t('dentalMovements.movementsTool.lower')}
          callBack={() => setIsUpperSelected(false)}
        />
      </div>
      <div className="orth-movements__separator" />
      <div className="orth-movements__content">
        <LabelSwitch
          isReversed
          defaultValue={isSymetricMove}
          handleToggle={() => setIsSymetricMove(!isSymetricMove)}
          label={t('dentalMovements.movementsTool.symmetricMovement')}
        />
      </div>
      <div className="orth-movements__content orth-movements__subtitle">
        {t('dentalMovements.movementsTool.symmetric')}
      </div>

      {radioComponent(SymmetryType.Complete, symmetryType, 'complete', archMovementTypeSetter)}
      {radioComponent(SymmetryType.Preceding, symmetryType, 'preceding', archMovementTypeSetter)}

      <div className="orth-movements__content">
        <span className="orth-movements__subtitle">{t('dentalMovements.movementsTool.coordinate')}</span>
        <span className="orth-movements__small">({t('dentalMovements.movementsTool.lowerWithUpper')})</span>
      </div>

      {radioComponent(CoordinationType.Complete, coordinationType, 'complete', archMovementTypeSetter)}
      {radioComponent(CoordinationType.Preceding, coordinationType, 'preceding', archMovementTypeSetter)}
      {radioComponent(CoordinationType.Subsequent, coordinationType, 'subsequent', archMovementTypeSetter)}
    </div>
  );
}
