import { useTranslation } from 'react-i18next';

import { useEvergineStore } from 'evergine-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CapturePosition, Stage } from '../../../common';
import { FilePane } from '../../../common/components/filePane';
import { ZipFilePane } from '../../../common/components/zipFilePane';
import { useGateKeeper } from '../../../hooks';
import { useMaestro3D } from '../../../hooks/shared/useMaestro3D';
import { AbilityAction, OrthoAbilitySubject } from '../../../shared';
import { DownloadFile, RemoveFile, UploadFile } from '../../../surgeries/components/layout';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import './importMaster3d.scss';

export function ImportMaster3d() {
  const navigate = useNavigate();
  useGateKeeper(AbilityAction.Manage, OrthoAbilitySubject.Import3DModelsScreen, () => navigate('/forbidden'));

  const [t] = useTranslation();

  const { downloadMaestro3DConvertedFile, loadMaestro3DImportFile, removeMaestro3DFiles } = useMaestro3D();

  const fileExtension = '.zip';

  const { setWebBusy, evergineBusy, webBusy } = useBoundStore();

  const [upperFile, setUpperFile] = useState<string>('');
  const [lowerFile, setLowerFile] = useState<string>('');

  const [upperFileWorking, setUpperFileWorking] = useState(false);
  const [lowerFileWorking, setLowerFileWorking] = useState(false);

  const { evergineReady } = useEvergineStore();

  useEffect(() => {
    if (evergineReady === true) {
      window.App.webEventsProxy.common.setStage(Stage.Client);
      window.App.webEventsProxy.layers.showAxis(false);
    }
  }, [evergineReady]);

  useEffect(() => {
    if (webBusy && !evergineBusy) {
      setWebBusy(false);
      setUpperFileWorking(false);
      setLowerFileWorking(false);
    }
  }, [evergineBusy]);

  const removeFileUpper = () => {
    removeMaestro3DFiles(upperFile, CapturePosition.UPPER);
    setUpperFile('');
  };

  const removeFileLower = () => {
    removeMaestro3DFiles(lowerFile, CapturePosition.LOWER);
    setLowerFile('');
  };

  const uploadUpper = (e: React.ChangeEvent) => {
    setUpperFileWorking(true);
    setWebBusy(true);
    // TODO tener en cuenta: Antes de subir un zip eliminar el anterior?
    removeFileUpper();
    const input = e.target as HTMLInputElement;
    loadMaestro3DImportFile(input.files, CapturePosition.UPPER);
    setUpperFile(input.files[0].name);
  };

  const uploadLower = (e: React.ChangeEvent) => {
    setLowerFileWorking(true);
    setWebBusy(true);
    // TODO tener en cuenta: Antes de subir un zip eliminar el anterior?
    removeFileLower();
    const input = e.target as HTMLInputElement;
    loadMaestro3DImportFile(input.files, CapturePosition.LOWER);
    setLowerFile(input.files[0].name);
  };

  const downloadFileUpper = () => {
    downloadMaestro3DConvertedFile(upperFile, CapturePosition.UPPER);
  };

  const downloadFileLower = () => {
    downloadMaestro3DConvertedFile(lowerFile, CapturePosition.LOWER);
  };

  return (
    <div className="import-master-3d-container">
      <FilePane>
        <FilePane.Header title={t('master3DFiles.upper')}>
          <UploadFile
            fileId="importUpperMaestro3D"
            onChange={uploadUpper}
            typesAllowed={fileExtension}
            disabled={!evergineReady}
          />
          <DownloadFile onClick={downloadFileUpper} disabled={!evergineReady || !upperFile} />
          <RemoveFile onClick={removeFileUpper} disabled={!evergineReady || !upperFile} />
        </FilePane.Header>
        <FilePane.Content>
          <ZipFilePane fileName={upperFileWorking ? '' : upperFile} />
        </FilePane.Content>
      </FilePane>
      <FilePane>
        <FilePane.Header title={t('master3DFiles.lower')}>
          <UploadFile
            fileId="importUpperMaestro3D"
            onChange={uploadLower}
            typesAllowed={fileExtension}
            disabled={!evergineReady}
          />
          <DownloadFile onClick={downloadFileLower} disabled={!evergineReady || !lowerFile} />
          <RemoveFile onClick={removeFileLower} disabled={!evergineReady || !lowerFile} />
        </FilePane.Header>
        <FilePane.Content>
          <ZipFilePane fileName={lowerFileWorking ? '' : lowerFile} />
        </FilePane.Content>
      </FilePane>
    </div>
  );
}
