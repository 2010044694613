import { StateCreator } from 'zustand';

export interface ModalSlice {
  isModalOpened: boolean;
  modalType: string;
  isModalXL: boolean;
  modalMessage?: string;
  modalTitle?: string;
  toggleModalIsOpened: (modalType: string) => void;
  openModal: (modalType: string) => void;
  setIsModalOpened: (modalType: string, isModalOpened: boolean) => void;
  setModalMessage: (message: string) => void;
  setModalTitle: (newModalTitle: string) => void;
  setIsModalXL: (newIsModalXL: boolean) => void;
}
export const createModalSlice: StateCreator<ModalSlice, [], [], ModalSlice> = (set) => ({
  isModalOpened: false,
  modalType: '',
  isModalXL: false,
  modalMessage: undefined,
  setIsModalOpened: (modalType: string, isModalOpened: boolean) => set(() => ({ isModalOpened: isModalOpened })),
  toggleModalIsOpened: (modalType: string) =>
    set((state) => ({ isModalOpened: !state.isModalOpened, modalType: modalType })),
  setModalMessage: (message: string) => set(() => ({ modalMessage: message })),
  setModalTitle: (newModalTitle: string) => set(() => ({ modalTitle: newModalTitle })),
  setIsModalXL: (newIsModalXL: boolean) => set(() => ({ isModalXL: newIsModalXL })),
  openModal: (modalType: string) => {
    set(() => ({ isModalOpened: true, modalType: modalType }));
  }
});
