import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';
import { OrthodonticsPhasesKeys } from '../../../orthodontics/components/layout';

export function useSaveIntermediateSteps(): SaveChangesHook {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setLowerDentalMovements = useOrthBoundStore((state) => state.setLowerDentalMovements);
  const setUpperDentalMovements = useOrthBoundStore((state) => state.setUpperDentalMovements);
  const { saveProgressInBackend } = useSaveProgress();

  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusy(true);

    await window.App.webEventsProxy.movements.processIntermediateSteps();

    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    setLowerDentalMovements(lowerMovements);
    setUpperDentalMovements(upperMovements);

    await saveProgressInBackend(
      { lower: lowerMovements, upper: upperMovements },
      OrthodonticsPhasesKeys.IntermediateSteps
    );

    setWebBusy(false);
    return Promise.resolve(true);
  }, [setLowerDentalMovements, setUpperDentalMovements, saveProgressInBackend]);

  return { saveChanges };
}
