import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../../assets/icons/arrow-up.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download.svg';

import './accordion.scss';

type AccordionProps = {
  title: string;
  date: string;
  children: ReactNode;
  download?: boolean;
  onDownload?: () => void;
};
export function Accordion({ title, children, date, download, onDownload }: AccordionProps) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="accordion">
      <div>
        <div className="accordion__row">
          <div className="accordion-header" onClick={toggleAccordion}>
            <div className="accordion__icon">{isOpen ? <ArrowUp /> : <ArrowDown />}</div>
            <h5 className="mb-0">
              {title}
              <span> | {date}</span>
            </h5>
          </div>
          {download && (
            <button className="accordion__download" onClick={onDownload}>
              <DownloadIcon />
            </button>
          )}
        </div>
        {isOpen && <div className="accordion-body">{children}</div>}
      </div>
    </div>
  );
}
