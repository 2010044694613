import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Details } from '../../../assets/icons/details.svg';
import { FormDropdown } from '../../../surgeries/components/layout/formDropdown';
import { MovementsInfo } from './MovementsInfo';
import { MovementsTable } from './movementsTable/MovementsTable';

import { useState } from 'react';
import { SourceOfMovement } from '../../../common';
import { Shift } from '../dentalMovements';
import './movementspanel.scss';

interface MovementsPanelProps {
  opened: boolean;
  shiftMode?: Shift;
  evolutionPanelWidth?: number | undefined;
  onClickClose: () => void;
}

type CSSProperties = {
  [key: string]: string | number;
};

export function MovementsPanel({ opened, shiftMode: shift, evolutionPanelWidth, onClickClose }: MovementsPanelProps) {
  const [t] = useTranslation();

  const classes = classNames('movementspanel', {
    opened,
    shift: shift !== Shift.None,
    ['shift-1--evolution']: shift === Shift.EvolutionPanelOpened,
    ['shift-1--info']: shift === Shift.InfoPanelOpened,
    ['shift-2']: shift === Shift.EvolutionAndInfoPanelsOpened
  });

  const customStyles: CSSProperties = {
    '--evolution-panel-width': `${evolutionPanelWidth}px`
  };

  return (
    <div className={classes} style={customStyles}>
      <div className="movementspanel-tools">
        <div className="movementspanel-top">
          <div>
            <Details /> <span className="movementspanel-top-title">{t('movementsPanel.title')}</span>
          </div>
          <div>
            <MovementsInfo />

            <Close className="clickable" onClick={onClickClose} />
          </div>
        </div>
      </div>
      {opened && <MovementsTable />}
    </div>
  );
}
