import React from 'react';
import UploadMockDentalMovementsFile from './UploadMockDentalMovementsFileAction';
import { CapturePosition } from '../../../../../common';

export default (actionProps: any) => {
  return (
    <UploadMockDentalMovementsFile
      teetharchPosition={CapturePosition.LOWER}
      isEndOfBlock={actionProps.block.isEndOfBlock}
    />
  );
};
