import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './assets/locales/en.json';
import esJson from './assets/locales/es.json';
import ptJson from './assets/locales/pt.json';
import frJson from './assets/locales/fr.json';

// On adding a new language, add it to the Language enum and langList
export enum Language {
  English = 'en',
  Spanish = 'es',
  Portuguese = 'pt',
  French = 'fr'
}

export const STORE_LANG_KEY = 'lang';

const langList = [Language.English, Language.Spanish, Language.Portuguese, Language.French];

const resources = {
  [Language.English]: {
    translation: enJson
  },
  [Language.Spanish]: {
    translation: esJson
  },
  [Language.Portuguese]: {
    translation: ptJson
  },
  [Language.French]: {
    translation: frJson
  }
};

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  const browserLang = navigator.language.split('-')[0]; // 'en-US' -> 'en'
  const language = langList.includes(browserLang as Language) ? browserLang : Language.English;
  localStorage.setItem(STORE_LANG_KEY, language);

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: Language.English,
    interpolation: {
      escapeValue: false
    }
  });

  return i18n;
};

export const i18n = createI18n();
