import { useCallback } from 'react';
import { CapturePosition, CaptureType } from '../../common/evergine/types';
import { isStringVoid } from '../../shared';
import { useCaseData } from './useCaseData';

export function useDownloadFiles(caseId: string) {
  const { patientCase } = useCaseData(caseId);

  const getCapture = (position: CapturePosition, type: CaptureType) => {
    if (position === CapturePosition.UPPER) {
      return type === CaptureType.DICOM ? patientCase.captures3D?.upperDICOM : patientCase.scannings?.upperModel3D;
    }
    return type === CaptureType.DICOM ? patientCase.captures3D?.lowerDICOM : patientCase.scannings?.lowerModel3D;
  };

  const isDownloadable = useCallback(
    (position: CapturePosition, type: CaptureType) => {
      if (!patientCase) {
        return;
      }

      const capture = getCapture(position, type);
      return !isStringVoid(capture?.url);
    },
    [patientCase]
  );

  const downloadCaptureFile = useCallback(
    (position: CapturePosition, type: CaptureType, modelsId: Record<string, Record<string, string>>) => {
      if (!patientCase) {
        return;
      }

      const file = getCapture(position, type);

      const link = document.createElement('a');

      link.href = file.url;
      link.setAttribute('download', file.name);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    [patientCase]
  );

  return [downloadCaptureFile, isDownloadable] as const;
}
