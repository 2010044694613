import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { IAuthService } from './iauth.service';

@injectable()
export class AuthService implements IAuthService {
  private readonly apiEndpoint = '/api/auth/check';
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  public authTokenUrlParamIndex = 'authToken';

  public getAccessToken(): string | null {
    return sessionStorage.getItem('authToken');
  }
  public setAccessToken(token: string): void {
    sessionStorage.setItem('authToken', token);
  }

  public async isValidToken(): Promise<boolean> {
    const response = await this._httpService.get(this.apiEndpoint);
    return Promise.resolve(response.status === 200);
  }
}
