import { ReactComponent as MDTraslation } from '../../../assets/icons/MD-traslation.svg';
import { ReactComponent as TIPAngle } from '../../../assets/icons/TIP-angle.svg';
import { ReactComponent as VLTraslation } from '../../../assets/icons/VL-traslation.svg';
import { ReactComponent as ExtrusionIntrusion } from '../../../assets/icons/extrusion-intrusion.svg';
import { ReactComponent as PureRotation } from '../../../assets/icons/pure-rotation.svg';
import { ReactComponent as Torque } from '../../../assets/icons/torque.svg';

import { useTranslation } from 'react-i18next';
import { IconTextInfo, Info } from '../../../common';

export function MovementsInfo() {
  const [t] = useTranslation();

  const infoPairings: IconTextInfo[] = [
    {
      text: t('movementsPanel.info.extrusionIntrusion'),
      icon: ExtrusionIntrusion
    },
    {
      text: t('movementsPanel.info.VLTraslation'),
      icon: VLTraslation
    },
    {
      text: t('movementsPanel.info.MDTraslation'),
      icon: MDTraslation
    },
    {
      text: t('movementsPanel.info.pureRotation'),
      icon: PureRotation
    },
    {
      text: t('movementsPanel.info.torque'),
      icon: Torque
    },
    {
      text: t('movementsPanel.info.tip'),
      icon: TIPAngle
    }
  ];

  return (
    <div>
      <Info iconTextInfos={infoPairings} />
    </div>
  );
}
