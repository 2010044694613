import { useCallback } from 'react';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { OrthodonticsPhasesKeys } from '../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { TypesActions } from '../../surgeries/components/layout';
import {
  useSaveAttaches,
  useSaveAxisAndRoots,
  useSaveFinalPosition,
  useSaveIPR,
  useSaveIntermediateSteps,
  useSaveSegmentation
} from '../orthodontics';
import { useSTLLoad } from '../useSTLLoad';
import { useCaseChanges } from './useCaseChanges';

export interface SaveChangesHook {
  saveChanges(): Promise<boolean>;
}

// This is a default hook that will be used when no hook is found for the current phase
// or, when save changes is not required (going back from a step without saving)
const defaultSaveChangesHook: Readonly<SaveChangesHook> = {
  saveChanges: async (): Promise<boolean> => {
    return Promise.resolve(true);
  }
};

export function useModalSaveChanges(param?: TypesActions) {
  const { getRouteKey } = useCaseChanges();
  const { currentVersion } = useOrthBoundStore();
  const { isCaseReseting } = useCommonBoundStore();

  const orthodonticsPhasesDictionary: { [key: string]: () => SaveChangesHook } = {
    [OrthodonticsPhasesKeys.STLLoad]: () => useSTLLoad(),
    [OrthodonticsPhasesKeys.TeethSegmentation]: () => useSaveSegmentation(),
    [OrthodonticsPhasesKeys.AxisAndRoots]: () => useSaveAxisAndRoots(),
    [OrthodonticsPhasesKeys.FinalPosition]: () => useSaveFinalPosition(),
    [OrthodonticsPhasesKeys.IntermediateSteps]: () => useSaveIntermediateSteps(),
    [OrthodonticsPhasesKeys.IPR]: () => useSaveIPR(),
    [OrthodonticsPhasesKeys.Attaches]: () => useSaveAttaches(),
    [OrthodonticsPhasesKeys.Publish]: () => defaultSaveChangesHook
  };

  const getPhaseSaveChange = useCallback(() => {
    const key = getRouteKey();
    const phaseFunction =
      key && param !== TypesActions.Previous ? orthodonticsPhasesDictionary[key] : () => defaultSaveChangesHook;
    return phaseFunction();
  }, [currentVersion, param, isCaseReseting]);

  return { getPhaseSaveChange };
}
