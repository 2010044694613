import { useEffect } from 'react';
import { BaseCaptures, CaptureType, PagesWithTools } from '../../../common';
import { useCommonBoundStore } from '../../../common/stores/useStore';

export function MeshCorrection() {
  const { captureType, setCaptureType } = useCommonBoundStore();

  useEffect(() => {
    if (!captureType || captureType === CaptureType.MODEL3D) {
      return;
    }

    setCaptureType(CaptureType.MODEL3D);
  }, [captureType]);
  return (
    <BaseCaptures allowedFileExtension="stl" type={CaptureType.MODEL3D} pageWithTools={PagesWithTools.MeshCorrection} />
  );
}
