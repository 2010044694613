import { StateCreator } from 'zustand';
import { CaseVersion, MinorVersion, PatientCase } from '../../../shared/cases/case';

export interface CaseDataSlice {
  caseId: string;
  patientCase: PatientCase;
  updateCaseId: (caseId: string) => void;
  updatePatientCase: (patientCase: PatientCase) => void;
  versions: CaseVersion[];
  updateVersions: (versions: CaseVersion[]) => void;
  versionToDelete: CaseVersion;
  updateVersionToDelete: (data: CaseVersion) => void;
  isVersionsDisabled: boolean;
  updateIsVersionDisabled: (value: boolean) => void;
  minorVersions: MinorVersion[];
  updateMinorVersions: (minorVersions: MinorVersion[]) => void;
  lowerModel3DId: string;
  updateLowerModel3DId: (newLowerModel3DId: string) => void;
  upperModel3DId: string;
  updateUpperModel3DId: (newUpperModel3DId: string) => void;
  isCaseModified: boolean;
  updateIsCaseModified: (value: boolean) => void;
  routeToGo: string;
  updateRouteToGo: (route: string) => void;
  isCaseReseting: boolean;
  updateIsCaseReseting: (value: boolean) => void;
}

export const createCaseDataSlice: StateCreator<CaseDataSlice, [], [], CaseDataSlice> = (set) => ({
  caseId: null,
  updateCaseId: (caseId) => set(() => ({ caseId: caseId })),
  patientCase: null,
  updatePatientCase: (patientCase) => set(() => ({ patientCase: patientCase })),
  versions: null,
  updateVersions: (newVersions) => set(() => ({ versions: newVersions })),
  versionToDelete: null,
  updateVersionToDelete: (data: CaseVersion) => set(() => ({ versionToDelete: data })),
  isVersionsDisabled: false,
  updateIsVersionDisabled: (value: boolean) => set(() => ({ isVersionsDisabled: value })),
  minorVersions: null,
  updateMinorVersions: (newMinorVersions: MinorVersion[]) => ({ minorVersions: newMinorVersions }),
  lowerModel3DId: null,
  updateLowerModel3DId: (newLowerModel3DId: string) => set(() => ({ lowerModel3DId: newLowerModel3DId })),
  upperModel3DId: null,
  updateUpperModel3DId: (newUpperModel3DId: string) => set(() => ({ upperModel3DId: newUpperModel3DId })),
  isCaseModified: false,
  updateIsCaseModified: (value: boolean) => set(() => ({ isCaseModified: value })),
  routeToGo: null,
  updateRouteToGo: (route: string) => set(() => ({ routeToGo: route })),
  isCaseReseting: false,
  updateIsCaseReseting: (value: boolean) => set(() => ({ isCaseReseting: value }))
});
