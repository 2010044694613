import React from 'react';
import Switch from '../switch/Switch';

import './labelSwitch.scss';

interface LabelSwitchProps {
  label: string;
  defaultValue?: boolean;
  handleToggle: () => void;
  isReversed?: boolean;
}

export function LabelSwitch({ label, handleToggle, defaultValue, isReversed }: LabelSwitchProps) {
  return (
    <div className={`labelswitch ${isReversed ? 'is-reversed' : ''}`}>
      <div className="labelswitch-label">{label}</div>
      <Switch handleToggle={handleToggle} defaultValue={defaultValue} />
    </div>
  );
}
