import { Tooth } from '../../common';

const mandibularTeethIndexesFromCanines = [33, 32, 31, 41, 42, 43];
const maxillaryTeethIndexesFromCanines = [13, 12, 11, 21, 22, 23];
const mandibularTeethIndexesFromFirstMolars = [36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46];
const maxillaryTeethIndexesFromFirstMolars = [16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26];
const ANTERIOR_REFERENCE = 0.772;
const TOTAL_REFERENCE = 0.913;

export function useBolton(lowerTeeth: Tooth[], upperTeeth: Tooth[]) {
  const _findEquivalentToothIndex = (index: number): number => {
    if (index < 20) return index + 10;
    if (index < 30) return index - 10;
    if (index < 40) return index + 10;
    return index - 10;
  };

  const _fillMissingTeeth = (teeth: Tooth[], allTeethIndexes: number[]): Tooth[] => {
    const existingTeethIndexes = teeth.map((t) => t.fdi);
    const missingTeethIndexes = allTeethIndexes.filter((index) => !existingTeethIndexes.includes(index));

    const missingTeeth = missingTeethIndexes
      .map((index: number): Tooth | null => {
        const equivalentToothIndex = _findEquivalentToothIndex(index);
        const equivalentTooth = teeth.find((t) => t.fdi === equivalentToothIndex);

        if (equivalentTooth !== undefined) {
          return { ...equivalentTooth, fdi: index };
        }

        return null;
      })
      .filter((tooth: Tooth) => tooth !== null);

    return [...teeth, ...missingTeeth];
  };

  const lowerTeethFixed = _fillMissingTeeth(lowerTeeth, mandibularTeethIndexesFromFirstMolars);
  const upperTeethFixed = _fillMissingTeeth(upperTeeth, maxillaryTeethIndexesFromFirstMolars);

  const _getSumWidthsAnteriorMandibular = (): number =>
    lowerTeethFixed
      ?.filter((t: Tooth) => mandibularTeethIndexesFromCanines.includes(t.fdi))
      .reduce((result: number, tooth: Tooth) => result + tooth.mesioDistalLength, 0);

  const _getSumWidthsAnteriorMaxyllary = (): number =>
    upperTeethFixed
      ?.filter((t: Tooth) => maxillaryTeethIndexesFromCanines.includes(t.fdi))
      .reduce((result: number, tooth: Tooth) => result + tooth.mesioDistalLength, 0);

  const _getSumWidthsTotalMandibular = (): number =>
    lowerTeethFixed
      ?.filter((t: Tooth) => mandibularTeethIndexesFromFirstMolars.includes(t.fdi))
      .reduce((result: number, tooth: Tooth) => result + tooth.mesioDistalLength, 0);

  const _getSumWidthsTotalMaxyllary = (): number =>
    upperTeethFixed
      ?.filter((t: Tooth) => maxillaryTeethIndexesFromFirstMolars.includes(t.fdi))
      .reduce((result: number, tooth: Tooth) => result + tooth.mesioDistalLength, 0);

  const getAnteriorBoltonIndex = (): string => {
    const sumWidthsAnteriorMandibular = _getSumWidthsAnteriorMandibular();
    const sumWidthsAnteriorMaxyllary = _getSumWidthsAnteriorMaxyllary();

    if (sumWidthsAnteriorMandibular / sumWidthsAnteriorMaxyllary > ANTERIOR_REFERENCE) {
      return (sumWidthsAnteriorMandibular - sumWidthsAnteriorMaxyllary * ANTERIOR_REFERENCE).toFixed(2);
    }

    return (sumWidthsAnteriorMaxyllary - sumWidthsAnteriorMandibular / ANTERIOR_REFERENCE).toFixed(2);
  };

  const getAnteriorBoltonPercentage = (): string => {
    return ((_getSumWidthsAnteriorMandibular() / _getSumWidthsAnteriorMaxyllary()) * 100).toFixed(2);
  };

  const getTotalBoltonIndex = (): string => {
    const sumWidthsTotalMandibular = _getSumWidthsTotalMandibular();
    const sumWidthsTotalMaxyllary = _getSumWidthsTotalMaxyllary();

    if (sumWidthsTotalMandibular / sumWidthsTotalMaxyllary > TOTAL_REFERENCE) {
      return (sumWidthsTotalMandibular - sumWidthsTotalMaxyllary * TOTAL_REFERENCE).toFixed(2);
    }

    return (sumWidthsTotalMaxyllary - sumWidthsTotalMandibular / TOTAL_REFERENCE).toFixed(2);
  };

  const getTotalBoltonPercentage = (): string =>
    ((_getSumWidthsTotalMandibular() / _getSumWidthsTotalMaxyllary()) * 100).toFixed(2);

  return {
    getAnteriorBoltonIndex,
    getAnteriorBoltonPercentage,
    getTotalBoltonIndex,
    getTotalBoltonPercentage
  };
}
