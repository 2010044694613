import { ICommand } from './command';

export default class EvergineCommand implements ICommand {
  public id: string;
  public isEvergineCommand: boolean;

  constructor(id: string) {
    this.id = id;
    this.isEvergineCommand = true;
  }

  execute(): Promise<void> {
    return window.App.webEventsProxy.common.requestRedo({ id: this.id });
  }

  undo(): Promise<void> {
    return window.App.webEventsProxy.common.requestUndo({ id: this.id });
  }
}
