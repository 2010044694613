import { ReactComponent as AutoCalculate } from '../../../assets/icons/evergineTools/auto-calc.svg';
import { ReactComponent as Paint } from '../../../assets/icons/paint.svg';
import { ToolbarConfigProps } from '../../../common';
import { debouncedSetState } from '../../../common/utils';
import { useOrthBoundStore } from '../../stores/useStore';

export const paintToolbarElement: ToolbarConfigProps = {
  icon: Paint,
  tooltipKey: 'evergineTools.paintSegmentation',
  labelKey: 'evergineTools.paintSegmentation',
  customId: 'orthTeethPaintButton',
  handleClick: () => {
    const currentValue = useOrthBoundStore.getState().showPaintPanel;
    debouncedSetState(useOrthBoundStore, { showPaintPanel: !currentValue });
  }
};

export const calcAISegmentationToolbarElement: ToolbarConfigProps = {
  icon: AutoCalculate,
  tooltipKey: 'evergineTools.autoSegmentation',
  labelKey: 'evergineTools.autoSegmentation',
  handleClick: () => {
    useOrthBoundStore.setState({ isTeethSegmentationConfirmed: true });
  }
};
