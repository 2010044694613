import { useEvergineStore } from 'evergine-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  EvergineToolbar,
  INJECTED_TYPES,
  PagesWithTools,
  Stage,
  Timeline,
  container,
  toolbarConfig
} from '../../../common';
import { useAttaches, useCaseId, useRenderModels } from '../../../hooks';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { AttachesTools, OrthTeethMovements } from '../evergineToolbarElements/tools';

import { LateralPanel } from '../../../common/components/lateralPanel';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useDentalMovements, useDentalMovementsStepsManager } from '../../../hooks/orthodontics';
import {
  AbilityAction,
  AbilityContext,
  AttachesInputDTO,
  ICalculationAttachesService,
  OrthoAbilitySubject
} from '../../../shared';
import { BoltonPanel } from '../boltonPanel';
import { Shift } from '../dentalMovements';
import { EvolutionPanel } from '../evolutionPanel';
import { MovementsPanel } from '../movementsPanel';
import { OcclusogramLegend } from '../occlusogramLegend';

import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../../../common/components/customTooltip';
import { useUtils } from '../../../hooks/shared/useUtils';

export type TooltipAttach = {
  x: number;
  y: number;
  type: number;
};

export function Attaches() {
  const [caseId] = useCaseId();
  const [t] = useTranslation();
  useRenderModels(caseId, Stage.Attaches);
  const { evergineReady } = useEvergineStore();
  const lowerDentalMovements = useOrthBoundStore((state) => state.lowerDentalMovements);
  const upperDentalMovements = useOrthBoundStore((state) => state.upperDentalMovements);
  const { updateMovements, stepSorter, getWidthFromEvolutionPanel, evolutionPanelWidth } = useUtils();
  const showAttachesPanel = useOrthBoundStore((state) => state.showAttachesPanel);
  const areAttachesCalculating = useOrthBoundStore((state) => state.areAttachesCalculating);
  const setAreAttachesCalculating = useOrthBoundStore((state) => state.setAreAttachesCalculating);
  const stageIsLoaded = useOrthBoundStore((state) => state.stageIsLoaded);
  const positionAttachTooltip = useOrthBoundStore((state) => state.positionAttachTooltip);
  const setMessageInfo = useBoundStore((state) => state.setMessageInfo);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const [canShowTimeline, setCanShowTimeline] = useState<boolean>(false);
  const { activeStep, setActiveStep } = useCommonBoundStore();
  const calculateAttachesService = container.get<ICalculationAttachesService>(
    INJECTED_TYPES.ICalculationAttachesService
  );
  const ability = useContext(AbilityContext);
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);
  const {
    showMovementsTable,
    showMiniOcclusogram,
    showBoltonPanel,
    showEvolutionPanel,
    setShowBoltonPanel,
    setShowEvolutionPanel,
    setShowMovementsTable
  } = useOrthBoundStore();
  const { dentalMovementsSteps, dentalMovementsApplyIPRList, setDentalMovementsApplyIPRList } =
    useDentalMovementsStepsManager(lowerDentalMovements, upperDentalMovements);
  const { typeTooltip, positionTooltip } = useAttaches();

  const pageTools = toolbarConfig[PagesWithTools.Attaches];
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (evergineReady) {
      window.App.webEventsProxy.common.setEditionMode(true);
      setWebBusy(false);
    }
  }, [evergineReady]);

  useEffect(() => {
    if (areAttachesCalculating && isAIEnabled) {
      setIsAIEnabled(false);
      setWebBusy(true);

      const sendDataToAI = async () => {
        try {
          const resultInputAttach: AttachesInputDTO = await window.App.webEventsProxy.attachments.getInputAttachments();
          const responseIA = await calculateAttachesService.getAIAutomaticAttaches(resultInputAttach);
          await window.App.webEventsProxy.attachments.updateAttachmentsFromAI(responseIA);
          updateMovements();
        } catch (error) {
          console.error(error);
        } finally {
          setIsAIEnabled(true);
          setWebBusy(false);
        }
      };

      sendDataToAI();
    }
    setAreAttachesCalculating(false);
  }, [areAttachesCalculating]);

  useEffect(() => {
    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady;
    setCanShowTimeline(result);
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  useEffect(() => {
    setMessageInfo(showAttachesPanel ? 'pageInfoMessages.attaches.intructions' : null);
  }, [showAttachesPanel]);

  const showLateralPanelIfAllowed = useCallback(() => {
    if (!ability || ability.can(AbilityAction.View, OrthoAbilitySubject.InfoPanel)) {
      return <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />;
    }
  }, [showInfoPanel, ability, handleInfoPanelToggle]);

  // TODO: intentar sacar a un hook o componente base
  const shiftMovementsPanel = useCallback(() => {
    if (showMovementsTable) {
      if (showEvolutionPanel && !showInfoPanel) {
        return Shift.EvolutionPanelOpened;
      }

      if (showInfoPanel && !showEvolutionPanel) {
        return Shift.InfoPanelOpened;
      }

      if (showInfoPanel && showEvolutionPanel) {
        return Shift.EvolutionAndInfoPanelsOpened;
      }
    }

    return Shift.None;
  }, [showMovementsTable, showEvolutionPanel, showInfoPanel]);

  return (
    <>
      {showLateralPanelIfAllowed()}
      {pageTools && evergineReady && <EvergineToolbar tools={pageTools} />}
      {showMiniOcclusogram && <OcclusogramLegend />}
      {showBoltonPanel && (
        <BoltonPanel
          opened={showBoltonPanel}
          mandibularTeeth={lowerDentalMovements?.teeth}
          maxillaryTeeth={upperDentalMovements?.teeth}
          onClickClose={() => setShowBoltonPanel(false)}
        />
      )}
      {dentalMovementsSteps.length > 0 && (
        <EvolutionPanel
          opened={showEvolutionPanel}
          onClickClose={() => setShowEvolutionPanel(false)}
          shift={showEvolutionPanel && showInfoPanel ? Shift.InfoPanelOpened : Shift.None}
          getWidthFunction={(width) => getWidthFromEvolutionPanel(width)}
          steps={dentalMovementsSteps}
          updateIPRList={setDentalMovementsApplyIPRList}
          applyIPRList={dentalMovementsApplyIPRList}
        />
      )}
      {positionAttachTooltip && <CustomTooltip text={typeTooltip} position={positionTooltip} />}
      <MovementsPanel
        opened={showMovementsTable}
        onClickClose={() => setShowMovementsTable(false)}
        shiftMode={shiftMovementsPanel()}
        evolutionPanelWidth={evolutionPanelWidth}
      />
      {showAttachesPanel && <AttachesTools />}
      {canShowTimeline && (
        <Timeline
          upperTeeth={upperDentalMovements?.teeth}
          lowerTeeth={lowerDentalMovements?.teeth}
          upperSteps={upperDentalMovements?.steps?.sort(stepSorter)}
          lowerSteps={lowerDentalMovements?.steps?.sort(stepSorter)}
          upperAttachments={upperDentalMovements?.attachments}
          lowerAttachments={lowerDentalMovements?.attachments}
          comparerActivated={false}
          vertical={false}
          enableFollowUpOverposition={false}
          storeCurrentStep={activeStep}
          updateStoreCurrentStep={(newValue: number) => setActiveStep(newValue)}
          isDesigner={true}
        />
      )}
    </>
  );
}
