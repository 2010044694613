import { AsideConfig, StepState } from './asideConfig';

export enum SurgeryOrderedRoutes {
  GeneralInformation = '/surgery/general-information',
  SpecificInformation = '/surgery/specific-information',
  DICOMCaptures = '/surgery/dicom-captures',
  StlCaptures = '/surgery/stl-captures',
  PhotoCaptures = '/surgery/photo-captures',
  Matching = '/surgery/matching',
  MatchingResult = '/surgery/result-matching',
  Implants = '/surgery/implants'
}

export const surgeryAsideConfig: AsideConfig[] = [
  {
    title: 'aside.information.title',
    tooltip: 'aside.information.tooltip',
    items: [
      {
        title: 'aside.information.items.general',
        route: SurgeryOrderedRoutes.GeneralInformation,
        defaultState: StepState.Validated,
        key: ''
      },
      {
        title: 'aside.information.items.specific',
        route: SurgeryOrderedRoutes.SpecificInformation,
        defaultState: StepState.Validated,
        key: ''
      }
    ]
  },
  {
    title: 'aside.surgery.files.title',
    tooltip: 'aside.surgery.files.tooltip',
    items: [
      {
        title: 'aside.surgery.files.items.captures',
        route: SurgeryOrderedRoutes.DICOMCaptures,
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.files.items.scan',
        route: SurgeryOrderedRoutes.StlCaptures,
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.files.items.photos',
        route: SurgeryOrderedRoutes.PhotoCaptures,
        defaultState: StepState.Pending,
        key: ''
      }
    ]
  },
  {
    title: 'aside.surgery.design.title',
    tooltip: 'aside.surgery.design.tooltip',
    items: [
      {
        title: 'aside.surgery.design.items.matching',
        route: SurgeryOrderedRoutes.Matching,
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.matchingResult',
        route: SurgeryOrderedRoutes.MatchingResult,
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.structure',
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.selectImplant',
        route: SurgeryOrderedRoutes.Implants,
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.placeImplant',
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.guideDesign',
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.guideEdition',
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.design.items.guidePreview',
        defaultState: StepState.Pending,
        key: ''
      }
    ]
  },
  {
    title: 'aside.surgery.export.title',
    tooltip: 'aside.surgery.export.tooltip',
    items: [
      {
        title: 'aside.surgery.export.items.guideExport',
        defaultState: StepState.Pending,
        key: ''
      }
    ]
  },
  {
    title: 'aside.surgery.finalDesign.title',
    items: [
      {
        title: 'aside.surgery.finalDesign.items.guidePreview',
        defaultState: StepState.Pending,
        key: ''
      },
      {
        title: 'aside.surgery.finalDesign.items.placeImplant',
        defaultState: StepState.Pending,
        key: ''
      }
    ]
  }
];
