import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as IPR } from '../../../../assets/icons/evolutionPanel/info/ipr.svg';
import { ApplyIprState, AttachmentState, IprLabel } from '../../../../common/evergine';
import { PROFILES_TYPE } from '../../../../shared/profile';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { EvolutionAttachment, EvolutionInterdentalDistance, EvolutionStep, EvolutionTooth } from '../EvolutionPanel';
import { ToothInfoBrick } from './ToothInfoBrick';

type QuadrantItem = {
  start: number;
  isAsc: boolean;
};

function getClassNameForIpr(applyIprState: ApplyIprState) {
  switch (applyIprState) {
    case ApplyIprState.None:
      return '';
    case ApplyIprState.PreIpr:
      return 'is-past';
    case ApplyIprState.ApplyIpr:
      return 'is-active';
    case ApplyIprState.PostIpr:
      return 'is-future';
  }
}

const getStartEndPiecesForQuadrant = (quadrantNumber: number) => {
  const quadrantStartEndPieces: Record<number, { start: number; end: number }> = {
    1: {
      start: 18,
      end: 11
    },
    2: {
      start: 21,
      end: 28
    },
    3: {
      start: 38,
      end: 31
    },
    4: {
      start: 41,
      end: 48
    }
  };

  return quadrantStartEndPieces[quadrantNumber];
};

type SelectedIprType = {
  stepDistance: EvolutionInterdentalDistance;
  stepIndex: number;
};

type QuadrantRowsType = {
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (applyIPRList: IprLabel[]) => void;
};

export function QuadrantRows({ steps, updateIPRList, applyIPRList }: QuadrantRowsType) {
  const [selectedIpr, setSelectedIpr] = useState<SelectedIprType>();
  const { userProfile } = useBoundStore();

  const canEditIPR = useMemo(() => userProfile?.type !== PROFILES_TYPE.client, [userProfile]);

  const getTeethIPRData = useCallback(
    (rightFdiTeethToFind: number) => applyIPRList.find((i) => i.fdiRight === rightFdiTeethToFind),
    [applyIPRList]
  );

  const hasTeethIpr = useCallback(
    (rightFdiTeethToFind: number, compareStepIndex?: number) => {
      const teethWithIpr = getTeethIPRData(rightFdiTeethToFind);

      if (compareStepIndex > 0) {
        return teethWithIpr?.applyStepIndex === compareStepIndex;
      }

      return !!teethWithIpr;
    },
    [getTeethIPRData]
  );

  const onDropIprHandler = useCallback(
    (
      event: React.DragEvent<HTMLDivElement>,
      newStepIndex: number,
      newInterdentalDistance: EvolutionInterdentalDistance,
      oldIPRSelected: SelectedIprType
    ) => {
      event.preventDefault();
      const oldIPRTeethRight = oldIPRSelected?.stepDistance?.rightToothFdi;
      const oldStepIndex = oldIPRSelected?.stepIndex;

      // Don't allow drop if teeth are the same
      if (oldIPRTeethRight !== newInterdentalDistance.rightToothFdi) {
        return;
      }

      // Don't allow drop if steps are the same
      if (oldStepIndex === newStepIndex) {
        return;
      }

      const newItems = [...applyIPRList];

      const teethIprIndex = newItems.findIndex((i) => i.fdiRight === oldIPRTeethRight);
      if (teethIprIndex < 0) {
        return;
      }

      const IPRItem = newItems[teethIprIndex];

      // Check if no value, in firstContactApplyStepIndex set IPR stepIndex
      // TODO: REVIEW THIS!!: Must not change MaxStepIndex. Why changed here?
      if (IPRItem?.applyStepIndex <= 0) {
        IPRItem.maxStepIndex = oldIPRSelected.stepIndex;
      }

      // Check if new step index is higher than the IPR first contact step, don't allow to drop
      if (IPRItem?.maxStepIndex < newStepIndex) {
        return;
      }

      IPRItem.applyStepIndex = newStepIndex;
      const newSelection = { stepDistance: newInterdentalDistance, stepIndex: newStepIndex };

      newInterdentalDistance.applyIprState = ApplyIprState.ApplyIpr;
      oldIPRSelected.stepDistance.applyIprState =
        oldIPRSelected.stepIndex < newStepIndex ? ApplyIprState.PreIpr : ApplyIprState.PostIpr;

      window.App.webEventsProxy.iprs.moveLabel(
        oldIPRSelected?.stepDistance?.leftToothFdi,
        oldIPRSelected?.stepDistance?.rightToothFdi,
        newStepIndex
      );
      newItems[teethIprIndex] = IPRItem;
      updateIPRList(newItems);
      setSelectedIpr(newSelection);
    },
    [applyIPRList]
  );

  // Click to change IPR it's easier if in the future, client doesn't want to use drag and drop
  const onSelectIpr = (newInterdentalDistance: EvolutionInterdentalDistance, newStepIndex: number) => {
    const newTeethIndex = newInterdentalDistance.rightToothFdi;
    if (!isIpr(newTeethIndex, newStepIndex)) {
      return;
    }

    const newSelection = { stepDistance: newInterdentalDistance, stepIndex: newStepIndex };
    setSelectedIpr(newSelection);
  };

  const onHandleDragOver = (e: any) => {
    e.preventDefault();
  };

  const isIpr = useCallback(
    (rightFdiTeethToCompare: number, stepIndex: number) => hasTeethIpr(rightFdiTeethToCompare, stepIndex),
    [hasTeethIpr]
  );

  const getBrick = useCallback(
    (
      fdi: number,
      interdentalDistance: EvolutionInterdentalDistance,
      step: EvolutionStep,
      someExistingTooth: boolean,
      attachmentState: AttachmentState
    ) => {
      return (
        <div
          className={`evolutiontable-piece`}
          // className={`evolutiontable-piece ${i === currentActiveStep ? 'is-highlighted' : ''}`}
          key={`piece-evolution-${step.index}-${fdi}`}
        >
          {interdentalDistance && step.index !== 0 && (
            <div
              className={`evolutiontable-piece--distance ${
                !!selectedIpr &&
                selectedIpr?.stepDistance?.rightToothFdi === interdentalDistance.rightToothFdi &&
                selectedIpr?.stepIndex === step.index
                  ? 'is-selected'
                  : ''
              }
          ${canEditIPR && isIpr(interdentalDistance.rightToothFdi, step.index) ? 'can-select' : ''}`}
              tabIndex={0}
              draggable={canEditIPR && isIpr(interdentalDistance?.rightToothFdi, step.index)}
              onDrop={(e) => onDropIprHandler(e, step.index, interdentalDistance, selectedIpr)}
              onMouseDown={() => canEditIPR && onSelectIpr(interdentalDistance, step.index)}
              id="drop_zone"
            >
              {interdentalDistance.applyIprState === ApplyIprState.ApplyIpr && (
                <div className={`evolutiontable-square evolutiontable-square--ipr`}>
                  <IPR className={`evolutiontable-ipr ${getClassNameForIpr(interdentalDistance.applyIprState)}`} />
                </div>
              )}
              {interdentalDistance.isSpaceVisible && (
                <div className={`evolutiontable-square evolutiontable-square--space`} />
              )}
              <span className="evolutiontable-distance">
                {(interdentalDistance.applyIprState === ApplyIprState.ApplyIpr ||
                  interdentalDistance?.isSpaceVisible) &&
                  getVisibleValue(interdentalDistance.distance)}
              </span>
            </div>
          )}

          <ToothInfoBrick
            evolutionTooth={step?.teeth?.find((tooth: EvolutionTooth) => tooth.fdi === fdi)}
            isMissingTooth={!someExistingTooth}
            attachmentState={attachmentState}
          />
        </div>
      );
    },
    [selectedIpr, isIpr, onDropIprHandler, onSelectIpr]
  );

  const getMinFinalTeethSpaceIndex = useCallback(
    (teethId: number, stepsToFilter: EvolutionStep[], lastStepIndex: number) => {
      const stepsCopy: EvolutionStep[] = JSON.parse(JSON.stringify(stepsToFilter));

      const lastStepDistances = stepsCopy.find((s) => s.index === lastStepIndex)?.interdentalDistances;
      const lastStepDistance = lastStepDistances?.find((i) => i.rightToothFdi === teethId);
      const teethStepDistances = stepsCopy
        .filter(
          (s) =>
            (s.interdentalDistances = s.interdentalDistances.filter(
              (i) => i.rightToothFdi === teethId && i.distance > 0 && i.applyIprState === ApplyIprState.None
            ))
        )
        .filter((s) => s.interdentalDistances.find((i) => i.distance === lastStepDistance?.distance));

      let minStep = -1;
      if (teethStepDistances.length > 0) {
        for (let i = teethStepDistances.length - 1; i >= 0; i--) {
          const prevStep = teethStepDistances[i - 1];
          if (prevStep?.index === teethStepDistances[i].index - 1) {
            minStep = prevStep.index;
          } else {
            minStep = teethStepDistances[i].index;
            break;
          }
        }
      }

      return minStep === 0 ? 1 : minStep;
    },
    []
  );

  const getCellPieceEvolutions = useCallback(
    (fdi: number) => {
      if (steps?.length <= 0) {
        return;
      }
      const lastStepIndex = steps[steps.length - 1].index;
      const teethMinSpaceIndex = getMinFinalTeethSpaceIndex(fdi, steps, lastStepIndex);
      const divs: JSX.Element[] = [];

      const someExistingTooth = steps.some((step: EvolutionStep) => step?.teeth.some((teeth) => teeth.fdi === fdi));

      steps.map((step: EvolutionStep) => {
        const attachmentState = step?.attachments?.find(
          (attachment: EvolutionAttachment) => attachment.toothFdi === fdi
        )?.state;

        const interdentalDistance = step?.interdentalDistances.find(
          (interdentalDistance: EvolutionInterdentalDistance) => interdentalDistance.rightToothFdi === fdi
        );

        if (!!interdentalDistance) {
          interdentalDistance.isSpaceVisible = teethMinSpaceIndex >= 0 && step.index === teethMinSpaceIndex;
        }

        divs.push(getBrick(fdi, interdentalDistance, step, someExistingTooth, attachmentState));
      });

      return divs;
    },
    [steps, selectedIpr, getBrick, getMinFinalTeethSpaceIndex]
  );

  const getRowPieceEvolutions = useCallback(
    (pieceNumber: number) => {
      const divs: JSX.Element[] = [];
      divs.push(
        <div className="evolutiontable-piece-number" key={pieceNumber}>
          {pieceNumber}
        </div>
      );

      const data = [divs, ...getCellPieceEvolutions(pieceNumber)];
      return data;
    },
    [getCellPieceEvolutions]
  );

  const getQuadrantPieceEvolution = useCallback(
    (quadrantNumber: number, asc: boolean) => {
      const divs: JSX.Element[] = [];
      const { start, end } = getStartEndPiecesForQuadrant(quadrantNumber);
      const getDirection = (i: number) => (asc ? i <= end : i >= end);
      let counter = start;

      for (counter; getDirection(counter); asc ? counter++ : counter--) {
        divs.push(
          <div className="evolutiontable-row" key={`quadrant-${uuidv4()}`} onDragOver={onHandleDragOver}>
            {getRowPieceEvolutions(counter)}
          </div>
        );
      }

      return divs;
    },
    [getStartEndPiecesForQuadrant, getRowPieceEvolutions]
  );

  const getVisibleValue = (distance: number) => {
    if (distance > 1 || distance < -1) {
      return distance;
    }

    const substringValue = distance < 0 ? 2 : 1;
    const value = Math.round(distance * 10) / 10;

    return distance < 1 ? value.toLocaleString().substring(substringValue) : value;
  };

  const rowsPerQuadrant = useMemo(() => {
    const quadrantsList: QuadrantItem[] = [
      { start: 1, isAsc: false },
      { start: 2, isAsc: true },
      { start: 3, isAsc: false },
      { start: 4, isAsc: true }
    ];

    return quadrantsList.map((quadrant: QuadrantItem) => {
      return (
        <div className="evolutiontable-quadrant" key={`quadrant-${uuidv4()}`}>
          {getQuadrantPieceEvolution(quadrant.start, quadrant.isAsc)}
        </div>
      );
    });
  }, [getQuadrantPieceEvolution]);

  return <>{rowsPerQuadrant}</>;
}
