import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { ReactComponent as Help } from '../../../assets/icons/help.svg';
import { useTranslation } from 'react-i18next';

import './info.scss';
import { IconTextInfo } from '../../models';

interface InfoProps {
  iconTextInfos: IconTextInfo[];
  darkStyle?: boolean;
}

export function Info({ iconTextInfos, darkStyle }: InfoProps) {
  const [t] = useTranslation();
  const [showInfoPanel, setShowInfoPanel] = useState<boolean>();
  const infoRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setShowInfoPanel(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="info" ref={infoRef}>
      <div
        className={`info-cta clickable ${showInfoPanel ? 'clicked' : ''}`}
        onClick={() => setShowInfoPanel(!showInfoPanel)}
      >
        <Help />
      </div>
      {showInfoPanel && (
        <div className={`info-panel ${darkStyle ? 'dark' : ''}`}>
          {!darkStyle && <div className="info-panel-title">{t('movementsPanel.info.title')}</div>}
          {iconTextInfos.map((iconText: IconTextInfo, i) => (
            <div>
              {
                <>
                  <iconText.icon />
                  <span>{iconText.text}</span>
                </>
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
