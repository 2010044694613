import { useTranslation } from 'react-i18next';
import { ReactComponent as IconFileZip } from '../../../assets/icons/file-zip.svg';
import './zipFilePane.scss';

export type ZipFilePaneProps = {
  fileName?: string;
};

export function ZipFilePane(props: ZipFilePaneProps) {
  const { fileName } = props;
  const [t] = useTranslation();

  return (
    <div className="zip-file-pane">
      <IconFileZip />
      {fileName ? t('imageManagement.common.directoryUploaded') : t('imageManagement.common.noFiles')}
      <span className="zip-file-pane-filename">{fileName}</span>
    </div>
  );
}
