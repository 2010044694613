import { useMemo } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { useOrthBoundStore } from '../../../../stores/useStore';

import { AttacheType } from './attacheType/AttacheType';

import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { ModalTypes } from '../../../../../surgeries/components/layout';
import { attacheTypes } from './attacheTypes';
import './attachesTools.scss';

export function AttachesTools() {
  const { setShowAttachesPanel, setAttachActive } = useOrthBoundStore();
  const [t] = useTranslation();
  const { noTeethSelected } = useUtils();

  const PANEL_SIZE = 312;

  const toolPosition = useMemo(() => {
    return document.getElementById('orthAttachesButton')?.getBoundingClientRect();
  }, []);

  const { toggleModalIsOpened } = useCommonBoundStore();

  const handleClose = () => {
    setShowAttachesPanel(false);
    setAttachActive(null);
  };

  const showDeleteModal = () => {
    toggleModalIsOpened(ModalTypes.ModalDeleteAttach);
  };
  // TODO: refactorizar y sacar el panel draggable a un componente reusable
  return (
    <Draggable
      bounds="body"
      handle=".handleOrthAttaches"
      defaultPosition={{ x: toolPosition.x - PANEL_SIZE, y: toolPosition.top }}
    >
      <div className="attachestools">
        <div className="attachestools-header">
          <div className="attachestools-title handleOrthAttaches">
            <div>{t('evergineTools.attaches')}</div>
          </div>

          <button type="button" className="attachestools-close" onClick={handleClose}>
            <CloseIcon className="clickable" />
          </button>
        </div>
        <div className="attachestools-content">
          {attacheTypes.map((attacheType) => (
            <AttacheType attacheType={attacheType} key={`attachetype-${attacheType.name}`} />
          ))}
          <button
            onClick={showDeleteModal}
            type="button"
            className="btn btn-outline-primary flex-fill attachestools-content__delete"
            disabled={noTeethSelected}
          >
            {t('common.delete')}
          </button>
        </div>
      </div>
    </Draggable>
  );
}
