import { inject, injectable } from 'inversify';
import { container } from '../../common';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { IAuthService } from '../auth';
import type { IHttpService } from '../http';
import { IUserProfileService } from './iprofile.service';
import { CLIENT_ROLE_ON_BACKEND, PROFILES_TYPE, UserProfile, UserProfileDTO } from './userProfile';

@injectable()
export class UserProfileService implements IUserProfileService {
  private readonly apiEndpoint = '/api/auth/check';
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  public async getProfile(): Promise<UserProfile> {
    const authService = container.get<IAuthService>(INJECTED_TYPES.IAuthService);
    const token = authService.getAccessToken();

    const response = (await this._httpService.get<UserProfileDTO>(`${this.apiEndpoint}`)).data.data;
    const usertype = this.getUserType(token);
    response.type = usertype;
    return response;
  }

  public getUserType = (token: string) => {
    const params = new URLSearchParams(window.location.search);

    // TODO: If usertype is in the URL, it means that the user is a client (for development purposes) remove it when the backend is ready
    const userTypeFromParam = params.get('usertype');

    if (userTypeFromParam == PROFILES_TYPE.client) {
      return PROFILES_TYPE.client;
    }

    const payloadObject = this.getUserDataFromToken(token);

    if (payloadObject === null) {
      return PROFILES_TYPE.employee;
    }

    const roles = payloadObject?.roles || [];

    // Is a client if they have only one role, and this role is ROLE_USER
    const isClient = roles[0] == CLIENT_ROLE_ON_BACKEND && roles.length == 1;

    return isClient ? PROFILES_TYPE.client : PROFILES_TYPE.employee;
  };

  public getUserDataFromToken = (token: string) => {
    if (!token) {
      return null;
    }

    const tokenParts = token.split('.');

    if (tokenParts.length !== 3) {
      return null;
    }

    const encodedPayload = tokenParts[1];
    const decodedPayload = atob(encodedPayload);

    try {
      return JSON.parse(decodedPayload);
    } catch (error) {
      console.error('Error parsing JWT payload:', error);

      return null;
    }
  };
}
