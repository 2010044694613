import { AxiosError } from 'axios';
import { injectable } from 'inversify';
import { ToastOptions, toast } from 'react-toastify';
import { IErrorHandlerService } from './ierror-handler.service';

@injectable()
export class ErrorHandlerService implements IErrorHandlerService {
  buildErrorMessage = (error: AxiosError | any): string => {
    let message = '';

    if (error instanceof AxiosError) {
      const statusText = error.response.statusText !== '' ? ` (${error.response.statusText})` : '';
      let detail = '';

      if (typeof error.response?.data?.message === 'string') {
        detail = error.response?.data?.message;
      } else if (typeof error.response?.data === 'string') {
        detail = error.response?.data;
      }

      message = `${error.message}${statusText} ${detail.substring(0, 255)}`;
    } else {
      message = error?.message || typeof error === 'string' ? error : '';
    }

    // ToDo: translate without hook
    // return message !== '' ? message : t('errors.unknownError');
    return message !== '' ? message : 'Unknown error';
  };

  getToastOptions = (error: AxiosError | any): ToastOptions => {
    if (error instanceof AxiosError && !!error.request?.responseURL) {
      const url = new URL(error.request.responseURL);
      return url.pathname.startsWith('/api') ? { icon: '❌' } : {};
    }
    return {};
  };

  showError = (error: AxiosError | any) => {
    toast.error(this.buildErrorMessage(error), this.getToastOptions(error));
  };
}
