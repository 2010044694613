import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Layers } from '../../../../assets/icons/evergineTools/layers.svg';
import { AbilityAction, AbilityContext, OrthoAbilitySubject } from '../../../../shared';
import { useOrthBoundStore } from '../../../stores/useStore';
import { ToolsDropdownBase, ToolsDropdownBaseItem } from './ToolsDropdownBase';

export function OrthLayers(props: any) {
  const { dataFor, handleClick, className } = props;
  const ability = useContext(AbilityContext);
  const canManageLabels = useMemo(
    () => ability && ability.can(AbilityAction.View, OrthoAbilitySubject.Labels),
    [ability]
  );
  const [t] = useTranslation();
  const [toggleAttachments, setToggleAttachments] = useState(true);
  const [toggleLabels, setToggleLabels] = useState(true);
  const [toggleOcclusogram, setToggleOcclusogram] = useState(false);
  const [toggleRoots, setToggleRoots] = useState(false);
  const [opacityLevelRoots, setOpacityLevelRoots] = useState(100);
  const [toggleGum, setToggleGum] = useState(true);
  const [opacityLevelGum, setOpacityLevelGum] = useState(100);
  const [toggleTeeth, setToggleTeeth] = useState(true);
  const [opacityLevelTeeth, setOpacityLevelTeeth] = useState(100);
  const { upperDentalMovements, lowerDentalMovements, setShowMiniOcclusogram, setShowIPR, showIPR } =
    useOrthBoundStore();
  const [toggleIpr, setToggleIpr] = useState(showIPR);

  useEffect(() => {
    if (!upperDentalMovements && !lowerDentalMovements) {
      return;
    }

    if (!canManageLabels) {
      // window.App.webEventsProxy.layers.showLabels(false);
    }
  }, [upperDentalMovements, lowerDentalMovements, canManageLabels]);

  const onToggleLabels = () => {
    const newToggleLabels = !toggleLabels;
    setToggleLabels(newToggleLabels);
    // window.App.webEventsProxy.layers.showLabels(newToggleLabels);
  };

  const onToggleAttachments = () => {
    const newToggleAttachments = !toggleAttachments;
    setToggleAttachments(newToggleAttachments);
    window.App.webEventsProxy.layers.showAttachments(newToggleAttachments);
  };

  const onToggleOcclusogram = () => {
    const newToggleOcclusogram = !toggleOcclusogram;
    setToggleOcclusogram(newToggleOcclusogram);
    setShowMiniOcclusogram(newToggleOcclusogram);
    window.App.webEventsProxy.layers.showOcclusogram(newToggleOcclusogram);
  };

  const onToggleIpr = () => {
    const newToggleIpr = !toggleIpr;
    setToggleIpr(newToggleIpr);
    setShowIPR(newToggleIpr);
    window.App.webEventsProxy.layers.showIprs(newToggleIpr);
  };

  const onToggleRoots = () => {
    const newToggleRoots = !toggleRoots;
    setToggleRoots(newToggleRoots);
    window.App.webEventsProxy.layers.showRoots(newToggleRoots);
  };

  const onChangeOpacityRoots = (value: number) => {
    setOpacityLevelRoots(value);
    window.App.webEventsProxy.layers.setRootsOpacity(value);
  };

  const onToggleGum = () => {
    const newToggleGum = !toggleGum;
    setToggleGum(newToggleGum);
    window.App.webEventsProxy.layers.showGums(newToggleGum);
  };

  const onChangeOpacityGum = (value: number) => {
    setOpacityLevelGum(value);
    window.App.webEventsProxy.layers.setGumsOpacity(value);
  };

  const onToggleTeeth = () => {
    const newToggleTeeth = !toggleTeeth;
    setToggleTeeth(newToggleTeeth);
    window.App.webEventsProxy.layers.showTeeth(newToggleTeeth);
  };

  const onChangeOpacityTeeth = (value: number) => {
    setOpacityLevelTeeth(value);
    window.App.webEventsProxy.layers.setTeethOpacity(value);
  };

  const orthLayerItems: ToolsDropdownBaseItem[] = [
    {
      name: t('evergineTools.occlusogram'),
      isDisabled: !upperDentalMovements || !lowerDentalMovements,
      isVisible: toggleOcclusogram,
      onClick: onToggleOcclusogram,
      order: 1
    },
    // {
    //   name: t('evergineTools.ipr'),
    //   isVisible: toggleIpr,
    //   onClick: onToggleIpr,
    //   isDisabled: !upperDentalMovements && !lowerDentalMovements,
    //   order: 2
    // },
    {
      name: t('evergineTools.attaches'),
      isVisible: toggleAttachments,
      onClick: onToggleAttachments,
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      order: 3
    },
    {
      name: t('evergineTools.roots'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleRoots,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelRoots,
      onClick: onToggleRoots,
      handleRangeSlider: onChangeOpacityRoots,
      order: 4
    },
    {
      name: t('evergineTools.gum'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleGum,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelGum,
      onClick: onToggleGum,
      handleRangeSlider: onChangeOpacityGum,
      order: 5
    },
    {
      name: t('evergineTools.teeth'),
      isDisabled: !upperDentalMovements && !lowerDentalMovements,
      isVisible: toggleTeeth,
      withRangeSlider: true,
      rangeSliderValue: opacityLevelTeeth,
      onClick: onToggleTeeth,
      handleRangeSlider: onChangeOpacityTeeth,
      order: 6
    }
  ];

  // ToDo: Apparently we will no longer be using labels in the app. Remove from everywhere.
  // if (canManageLabels) {
  //   orthLayerItems.push({
  //     name: t('evergineTools.labels'),
  //     isVisible: toggleLabels,
  //     onClick: onToggleLabels,
  //     isDisabled: !upperDentalMovements && !lowerDentalMovements,
  //     order: 6
  //   });
  // }

  return (
    <>
      <ToolsDropdownBase
        title={t('evergineTools.layers')}
        prefix="orthlayers"
        dataFor={dataFor}
        toggleIcon={Layers}
        className={className}
        handleToggleClick={handleClick}
        items={orthLayerItems}
      />
    </>
  );
}
