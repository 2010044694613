import JSZip from 'jszip';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import UploadLowerFileIcon from '../../../../../assets/icons/upload-folder-lower-jaw.svg';
import UploadUpperFileIcon from '../../../../../assets/icons/upload-folder-upper-jaw.svg';
import { DentalMovementGroup, Model3dType, NavBarAction } from '../../../../../common';
import { CapturePosition } from '../../../../../common/evergine';
import { useFiles } from '../../../../../hooks/useFiles';
import { TeethArchPosition } from '../../../../../models';
import { useOrthBoundStore } from '../../../../stores/useStore';
import { Model } from '../../../../../common/evergine/types';

interface UploadMockDentalMovementsFileProps {
  teetharchPosition: CapturePosition;
  isEndOfBlock?: boolean;
}

const dentalPosition: { [key: string]: CapturePosition } = {
  [TeethArchPosition.UPPER]: CapturePosition.UPPER,
  [TeethArchPosition.LOWER]: CapturePosition.LOWER,
  [TeethArchPosition.BOTH]: CapturePosition.BOTH
};

export default function UploadMockDentalMovementsFile(props: UploadMockDentalMovementsFileProps) {
  const [t] = useTranslation();
  const { teetharchPosition, isEndOfBlock } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setUpperDentalMovements, setLowerDentalMovements } = useOrthBoundStore();
  const { orthTeethArch } = useOrthBoundStore();
  const { isDir } = useFiles();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    let movements: DentalMovementGroup;
    const dynamicModels = 'DynamicModels';
    const dynamicModelsFullPath = '/Content/DynamicModels'; // /Content/Case

    if (!isDir(dynamicModelsFullPath)) {
      Module.FS.mkdir(dynamicModelsFullPath);
    }

    const promises: Promise<any>[] = [];
    const unzipper = new JSZip();
    const modelsToLoad: Model[] = [];

    await unzipper.loadAsync(file).then((zipData) => {
      zipData.forEach((relativePath, zipEntry) => {
        if (!zipEntry.dir) {
          if (zipEntry.name.endsWith('json')) {
            zipEntry.async('string').then((fileData) => {
              movements = JSON.parse(fileData) as DentalMovementGroup;
            });
          } else {
            promises.push(
              zipEntry.async('uint8array').then((fileData) => {
                const binData = new Int8Array(fileData);
                const fileFullPath = `${dynamicModelsFullPath}/${zipEntry.name}`;
                const filePath = `${dynamicModels}/${zipEntry.name}`;
                Module.FS.writeFile(fileFullPath, binData);
                modelsToLoad.push({
                  id: zipEntry.name.replace('.wepmd', ''),
                  uri: filePath,
                  teethArch: teetharchPosition,
                  model3dType: zipEntry.name.startsWith('tooth') ? Model3dType.Tooth : Model3dType.Gum
                });
              })
            );
          }
        }
      });

      Promise.all(promises).then(async () => {
        await window.App.webEventsProxy.common.loadModels(modelsToLoad);

        if (teetharchPosition === CapturePosition.UPPER) {
          setUpperDentalMovements(movements);
        } else {
          setLowerDentalMovements(movements);
        }
      });
    });
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".zip" style={{ display: 'none' }} />
      <NavBarAction
        dataFor={teetharchPosition.toString()}
        icon={teetharchPosition === CapturePosition.UPPER ? UploadUpperFileIcon : UploadLowerFileIcon}
        altText="Upload mock file"
        action={handleButtonClick}
        isEndOfBlock={isEndOfBlock}
      />
      <ReactTooltip id={teetharchPosition.toString()} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(
          `navbar.actions.information.${
            teetharchPosition === CapturePosition.UPPER ? 'uploadUpperFile' : 'uploadLowerFile'
          }`
        )}
      </ReactTooltip>
    </>
  );
}
