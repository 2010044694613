import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useCustomNavigate, useModalSaveChanges } from '../../../../../hooks/shared';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

type ModalNextStepTypes = {
  nextRoute?: string;
};

export function ModalOnlyOneFileInformation({ nextRoute }: ModalNextStepTypes) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { toggleModalIsOpened } = useCommonBoundStore();
  const { currentVersion } = useOrthBoundStore();
  const { getPhaseSaveChange } = useModalSaveChanges();
  const { saveChanges } = getPhaseSaveChange();
  const { getRouteKeepingParameters } = useCustomNavigate();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = useCallback(async () => {
    const result = await saveChanges();
    if (!result) {
      return;
    }

    navigate(getRouteKeepingParameters(nextRoute));
    toggleModalIsOpened('');
  }, [currentVersion, nextRoute]);

  return (
    <div className="modal-content" data-testid={'modal-onlyOneFileInformation'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.onlyOneFile.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.onlyOneFile.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.uploadMissingFile`)}
        </button>
        <button onClick={onContinueButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.nextWithOneArch`)}
        </button>
      </div>
    </div>
  );
}
