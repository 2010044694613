import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as AddImplantIcon } from '../../../assets/icons/add-implant.svg';

type AddImplantControlProps = {
  onClickAddImplant?: () => void;
};

export function AddImplantControl({ onClickAddImplant }: AddImplantControlProps) {
  const [t] = useTranslation();

  return (
    <div onClick={() => onClickAddImplant()} className="controls-item">
      <ReactTooltip id="addImplant" place="right" effect="solid" className="tooltip" type="dark">
        <span>{t(`implants.addImplant`)}</span>
      </ReactTooltip>
      <AddImplantIcon data-for="addImplant" data-tip></AddImplantIcon>
    </div>
  );
}
