import { FunctionComponent } from 'react';
import { ReactComponent as MDTraslation } from '../../../../../assets/icons/MD-traslation.svg';
import { ReactComponent as TIPAngle } from '../../../../../assets/icons/TIP-angle.svg';
import { ReactComponent as VLTraslation } from '../../../../../assets/icons/VL-traslation.svg';
import { ReactComponent as DistalRotation } from '../../../../../assets/icons/distal-rotation.svg';
import { ReactComponent as ExtrusionIntrusion } from '../../../../../assets/icons/extrusion-intrusion.svg';
import { ReactComponent as MesialRotation } from '../../../../../assets/icons/mesial-rotation.svg';
import { ReactComponent as Positioning } from '../../../../../assets/icons/positioning.svg';
import { ReactComponent as PureRotation } from '../../../../../assets/icons/pure-rotation.svg';
import { ReactComponent as Torque } from '../../../../../assets/icons/torque.svg';
import { MovementType } from '../../../../../common/evergine/types';
import { MeasureUnit } from '../../../../models';

export type TeethMovementsToolsType = {
  icon: FunctionComponent;
  titleKey: string;
  teethMovementType: MovementType;
  measureUnitType?: MeasureUnit;
  value?: number;
  disabled?: boolean;
  hideButtons?: boolean;
};

export const teethMovementsTools: TeethMovementsToolsType[] = [
  {
    icon: () => <ExtrusionIntrusion />,
    titleKey: 'dentalMovements.movementsTool.teeth.extrusionIntrusion',
    teethMovementType: MovementType.ExtrusionIntrusion,
    measureUnitType: MeasureUnit.Millimeter
  },
  {
    icon: () => <VLTraslation />,
    titleKey: 'dentalMovements.movementsTool.teeth.vlTraslation',
    teethMovementType: MovementType.TranslationVL,
    measureUnitType: MeasureUnit.Millimeter
  },
  {
    icon: () => <MDTraslation />,
    titleKey: 'dentalMovements.movementsTool.teeth.mdTraslation',
    teethMovementType: MovementType.TranslationMD,
    measureUnitType: MeasureUnit.Millimeter
  },
  {
    icon: () => <PureRotation />,
    titleKey: 'dentalMovements.movementsTool.teeth.pureRotation',
    teethMovementType: MovementType.PureRotation,
    measureUnitType: MeasureUnit.Degrees
  },
  {
    icon: () => <MesialRotation />,
    titleKey: 'dentalMovements.movementsTool.teeth.mesialRotation',
    teethMovementType: MovementType.MesialRotation,
    measureUnitType: MeasureUnit.Degrees
  },
  {
    icon: () => <DistalRotation />,
    titleKey: 'dentalMovements.movementsTool.teeth.distalRotation',
    teethMovementType: MovementType.DistalRotation,
    measureUnitType: MeasureUnit.Degrees
  },
  {
    icon: () => <Torque />,
    titleKey: 'dentalMovements.movementsTool.teeth.torque',
    teethMovementType: MovementType.Torque,
    measureUnitType: MeasureUnit.Degrees
  },
  {
    icon: () => <TIPAngle />,
    titleKey: 'dentalMovements.movementsTool.teeth.tip',
    teethMovementType: MovementType.TIP,
    measureUnitType: MeasureUnit.Degrees
  },
  {
    icon: () => <Positioning />,
    titleKey: 'dentalMovements.movementsTool.teeth.multiplePlane',
    teethMovementType: MovementType.MultiplePlane,
    disabled: true,
    hideButtons: true
  }
];
