import { Route } from 'react-router-dom';
import { CommonPagesUrl } from '../AppRouter';
import { GeneralInfo } from '../common';
import {
  DICOMCaptures,
  Implants,
  Matching,
  MatchingResult,
  PhotoCaptures,
  STLCaptures,
  SpecificInfo
} from './components';

export enum SurgeryPagesUrl {
  Matching = '/matching',
  ResultMatching = '/result-matching',
  Implants = '/implants'
}

export const SurgeryRoutes = () => {
  function buildSurgeryRoute(path: string) {
    return `surgery${path}/:caseId`;
  }

  return (
    <>
      <Route path={buildSurgeryRoute(CommonPagesUrl.GeneralInfo)} element={<GeneralInfo />} />
      <Route path={buildSurgeryRoute(CommonPagesUrl.SpecificInfo)} element={<SpecificInfo />} />
      <Route path={buildSurgeryRoute(CommonPagesUrl.DicomCaptures)} element={<DICOMCaptures />} />
      <Route path={buildSurgeryRoute(CommonPagesUrl.StlCaptures)} element={<STLCaptures />} />
      <Route path={buildSurgeryRoute(CommonPagesUrl.PhotoCaptures)} element={<PhotoCaptures />} />
      <Route path={buildSurgeryRoute(SurgeryPagesUrl.Matching)} element={<Matching />} />
      <Route path={buildSurgeryRoute(SurgeryPagesUrl.ResultMatching)} element={<MatchingResult />} />
      <Route path={buildSurgeryRoute(SurgeryPagesUrl.Implants)} element={<Implants />} />
    </>
  );
};
