import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Locked } from '../../../assets/icons/locked.svg';
import Logo from '../../../assets/images/logo.png';

import './forbiddenscreen.scss';

export function ForbiddenScreen() {
  const [t] = useTranslation();
  return (
    <div className="forbidden">
      <div className="forbidden-logo">
        <img className="w-100" src={Logo} alt={t('common.company')} />
      </div>
      <Locked />
      <div className="forbidden-message">
        <h2 className="forbidden-message_title">{t('forbidden.restrictedAccess')}</h2>
        <h3 className="forbidden-message_subtitle">{t('forbidden.contentNotAllowed')}</h3>
      </div>
    </div>
  );
}
