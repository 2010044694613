import classNames from 'classnames';

import { AttachmentState } from '../../../../common';
import { EvolutionTooth } from '../EvolutionPanel';
import './toothInfoBrick.scss';

interface ToothInfoBrickProps {
  evolutionTooth: EvolutionTooth;
  isMissingTooth: boolean;
  attachmentState?: AttachmentState;
}

export function ToothInfoBrick({ evolutionTooth, isMissingTooth, attachmentState }: ToothInfoBrickProps) {
  const baseClasas = 'toothinfobrick';

  const classes = classNames(baseClasas, {
    ['toothinfobrick--nomovement']: !evolutionTooth?.isMoved && !evolutionTooth?.isPassiveAligner,
    ['toothinfobrick--missingtooth']: isMissingTooth || evolutionTooth?.isExtracted,
    ['toothinfobrick--activealigner']: evolutionTooth?.isMoved,
    ['toothinfobrick--passivealigner']: evolutionTooth?.isPassiveAligner,
    ['toothinfobrick--attachestart']: attachmentState === AttachmentState.Start,
    ['toothinfobrick--attacheend']: attachmentState === AttachmentState.End,
    ['toothinfobrick--bothattache']: attachmentState === AttachmentState.Both
  });

  return <div className={classes}></div>;
}
