import { useTranslation } from 'react-i18next';
import './chatComment.scss';

type ChatComment = {
  author: string;
  timestamp: Date;
  body: string;
  isMe?: boolean;
  isFirstMessageToday?: boolean;
};

export function ChatComment(props: ChatComment) {
  const [t] = useTranslation();

  return (
    <div className="message">
      {props.isFirstMessageToday && (
        <div className="message-container_today">
          <span className="message-today">{t('chat.today')}</span>
        </div>
      )}
      <div>
        <span className="message-author">{props.author}</span>
        <span className="message-date">{props.timestamp.toLocaleString('es-es')}</span>
      </div>
      <div className={`message-content ${props.isMe && 'message-content--me'}`}>{props.body}</div>
    </div>
  );
}
